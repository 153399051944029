import { useState, useEffect } from 'react';
import { getFileByKey, uploadFile } from 'api/files';
import { useToasts } from 'components/Toasts';

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = (error) => reject(error);
  });

export const useFileUploader = ({ orgId, initialUploadedFile, onFileUpload, privateBucket }) => {
  const { pushToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();

  useEffect(() => {
    if (initialUploadedFile?.s3FileKey) {
      const fetchFile = async () => {
        setLoading(true);
        try {
          const file = await getFileByKey({ orgId, key: initialUploadedFile.s3FileKey });
          setUploadedFile(file);
        } catch (err) {
          pushToast('Failed to load file!', 'error');
          console.error({ message: err.message, component: 'useFileUploader', stack: err });
        }
        setLoading(false);
      };

      fetchFile();
    }
    // We just want to fetch the file once, so we don't want to re-run this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadFile = async ({ file }) => {
    setLoading(true);
    try {
      const base64File = await fileToBase64(file);
      const uploadData = {
        type: file.type,
        name: file.name,
        data: base64File,
        privateBucket,
      };
      setUploadedFile({
        ...uploadData,
        size: file.size,
      });

      const result = await uploadFile({
        orgId,
        file: uploadData,
      });

      setUploadedFile(result);
      onFileUpload(result);
    } catch (err) {
      pushToast('Failed to upload file!', 'error');
      console.error({ message: err.message, component: 'useFileUploader', stack: err });
    }
    setLoading(false);
  };

  const removeFile = () => {
    setUploadedFile(null);
    onFileUpload(null);
  };

  return {
    loading,
    uploadFile: handleUploadFile,
    uploadedFile,
    removeFile,
  };
};
