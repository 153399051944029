import { useCallback, useContext, useState } from 'react';

import { AppContext } from 'AppContext';
import { useInvoicePdfAPI } from 'api/billing';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';

import { invoicePDFDownload } from './utils';
import { PDFPreviewModal } from '../Common/PDFPreviewModal';

export const InvoicePDFPreviewModal = ({ onClose, invoiceId }) => {
  const { orgId } = useContext(AppContext);

  const { data: invoicePdf, isLoading: isPdfLoading, isFetching: isPdfFetching } = useInvoicePdfAPI({
    orgId,
    invoiceId,
    autoFetch: !!invoiceId,
  });

  return (
    <PDFPreviewModal
      onClose={onClose}
      onDownload={() => invoicePDFDownload({ invoicePDF: invoicePdf })}
      pdfData={invoicePdf}
      isPdfLoading={isPdfLoading}
      isPdfFetching={isPdfFetching}
    />
  );
};

export const usePDFPreviewModal = () => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const openModal = useCallback(
    (props) => {
      setShowModal(true);
      setModalProps(props ?? {});

      trackEvent({ name: EVENTS.OPEN_INVOICE_PDF_PREVIEW_MODAL });
    },
    [setShowModal, trackEvent],
  );

  const closeModal = useCallback(() => {
    setShowModal(false);
    setModalProps({});
  }, [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <InvoicePDFPreviewModal onClose={closeModal} {...modalProps} /> : null),
    [showModal, closeModal, modalProps],
  );

  return {
    openModal,
    closeModal,
    Modal,
    isModalOpen: showModal,
  };
};
