import React, { Fragment, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { TrashIcon } from 'components/Icons';
import { Row } from 'components/Core';
import { AppContext } from 'AppContext';
import { SwitchWithLabel } from 'components/Controls';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { REMINDER_ERROR_METADATA_FIELDS } from 'views/Billing/consts';
import { Popover, PopoverActions, PopoverButton, PopoverPrompt } from 'components/Portal';

import { SentEmailModal } from './SentEmailModal';
import { InvoicingScheduleContext } from '../../InvoicingScheduleModal/InvoicingScheduleContext';
import { getInvoiceNotSentReminders, getInvoiceSentReminders, hasReminderFailedToSend } from '../../utils';
import { BillingContext } from '../../BillingContext';
import { useSafelySwitchReminder } from '../useSafelySwitchReminder';

const HistoryWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  padding-left: 36px;
  overflow: auto;
  max-height: 100%;
  min-width: 290px;
  padding-top: 28px;
  gap: 4px;
  padding-bottom: 28px;
  padding-right: 32px;
  border-right: 1px solid var(--primaryBlack5);
  flex-direction: column;
`;

const HistoryTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  margin-bottom: 15px;
`;

const SwitchLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 4px;
  color: ${({ selected }) => (selected ? 'var(--primaryBlack)' : 'var(--primaryBlack70)')};
`;

const BlockTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: ${({ selected }) => selected && 'var(--primaryBlue)'};
`;

const PastTitle = styled.div`
  padding: 8px 12px;
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  opacity: 0.3;
`;

const PastEmails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 4px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: var(--primaryBlack10);
    left: -12.55px;
    bottom: 18px;
  }
`;

const NewEmails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 4px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: calc(100% + 6px);
    width: 0px;
    border-left: 1px dashed var(--primaryBlack10);
    z-index: 2;
    left: -12.55px;
    bottom: -49px;
  }

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: var(--primaryGray);
    width: 2px;
    left: -12.55px;
    bottom: -54px;
    z-index: 1;
  }
`;

const OverdueTitle = styled.div`
  padding: 8px 12px;
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--tertiaryRed);

  b {
    color: var(--primaryBlack);
  }
`;

const HistoryBlock = styled.div`
  padding: 12px;
  width: 202px;
  display: flex;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  border: 1px solid #f3f3f3;
  background: ${({ selected }) => (selected ? 'rgba(0, 117, 255, 0.10)' : 'white')};
  cursor: pointer;

  &:hover {
    background: var(--primaryBlack3);
  }

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    background-color: ${({ newEmail }) => (newEmail ? 'var(--primaryGray)' : 'var(--primaryBlack10)')};
    border: ${({ newEmail, selected }) =>
      selected ? '1px solid var(--primaryBlue)' : newEmail && '1px solid var(--primaryBlack10)'};
    width: 8px;
    height: 8px;
    left: -16.57px;
    border-radius: 100px;
    z-index: 4;
  }

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: var(--primaryGray);
    width: 16px;
    height: 16px;
    left: -16px;
    z-index: 3;
  }
`;

const TrashIconWrapper = styled.div`
  height: 16px;
  position: relative;
  cursor: pointer;

  &:hover {
    svg path {
      opacity: 1;
    }
  }

  svg {
    width: 16px;
    height: 16px;

    path {
      opacity: 0.3;
      fill: var(--primaryBlack);
    }
  }
`;

const BlockSubtitle = styled.div`
  color: ${({ selected }) => (selected ? 'var(--primaryBlack)' : 'var(--primaryBlack70)')};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const FailedToSendText = styled.span`
  font-style: italic;
  color: var(--tertiaryRed);
`;

export const SentInvoiceHistory = () => {
  const { integrations } = useContext(AppContext);

  const { invoiceFormValues, reminderFormValues, reminderFormRef, setSelectedReminder } = useContext(
    InvoicingScheduleContext,
  );
  const { removeBillingEmailReminder } = useContext(BillingContext);

  const [confirmReminderToSkip, setConfirmReminderToSkip] = useState();
  const [showSentEmailModal, setShowSentEmailModal] = useState(false);
  const [reminderForSentEmailModal, setReminderForSentEmailModal] = useState(null);

  const dateOfOverdue = dayjs.utc(invoiceFormValues?.date).add(invoiceFormValues?.days_to_pay, 'day');

  const notSentReminders = useMemo(
    () =>
      (getInvoiceNotSentReminders({ invoice: invoiceFormValues }) ?? [])?.sort(
        (a, b) => new Date(b.scheduled_for) - new Date(a.scheduled_for),
      ),
    [invoiceFormValues],
  );

  const sentReminders = useMemo(
    () =>
      (getInvoiceSentReminders({ invoice: invoiceFormValues }) ?? []).sort(
        (a, b) => new Date(b.scheduled_for) - new Date(a.scheduled_for),
      ),
    [invoiceFormValues],
  );

  const inboundEmails = useMemo(
    () =>
      (invoiceFormValues?.email_interactions?.filter((email) => !email?.email_type) ?? [])?.map((e) => ({
        ...e,
        subject_sent: e?.subject,
        body_sent: e?.content?.emailContent,
        scheduled_for: e?.received_at,
        sent_at: e?.received_at,
        is_inbound: true,
        sent_to: e?.recipients?.to,
      })),
    [invoiceFormValues],
  );

  const pastEmails = useMemo(
    () => [...inboundEmails, ...sentReminders]?.sort((a, b) => new Date(b?.sent_at) - new Date(a?.sent_at)),
    [inboundEmails, sentReminders],
  );

  const handleConfirmedSkipReminder = () => {
    removeBillingEmailReminder({
      emailReminderObject: confirmReminderToSkip,
      toDoMessage: 'reminder',
    });
    setConfirmReminderToSkip(null);
    setSelectedReminder(null);
  };

  const { checkAndSwitchReminder, ConfirmSaveModal } = useSafelySwitchReminder();

  const userEmail = integrations?.find((i) => i.type === INTEGRATION_TYPES.MAILER)?.metadata?.user_email;

  return (
    <HistoryWrapper data-cy="invoice-reminders-history">
      <HistoryTitle>Emails</HistoryTitle>

      <NewEmails>
        {!!notSentReminders?.length
          ? [...notSentReminders].map((reminder, index, arr) => {
              const reminderSelected = reminder?.id === reminderFormValues?.id;
              const reminderHasFailedToSend = hasReminderFailedToSend({ reminder });

              return (
                <Fragment key={reminder?.id}>
                  <HistoryBlock
                    newEmail
                    data-cy={`invoice-history__open-not-send-reminder-${index}`}
                    onClick={() => !reminderSelected && checkAndSwitchReminder({ clickedReminder: reminder })}
                    selected={reminderSelected}
                    notSentReminder
                  >
                    <Row horizontal="space-between" style={{ marginBottom: 8 }}>
                      <BlockTitle selected={reminderSelected}>
                        #{arr?.length - (index - pastEmails?.length - 1)}: Reminder
                        {reminderHasFailedToSend ? <FailedToSendText> (failed)</FailedToSendText> : null}
                      </BlockTitle>

                      <Row horizontal="space-between">
                        <TrashIconWrapper
                          data-cy={`invoice-history__delete-not-send-reminder-${index}`}
                          onClick={(event) => {
                            event.stopPropagation();
                            setConfirmReminderToSkip(reminder);
                          }}
                        >
                          <TrashIcon />

                          {confirmReminderToSkip?.id && confirmReminderToSkip?.id === reminder?.id && (
                            <Popover XOffset={0} YOffset={20} zIndex={51} darkMode width="200px">
                              <PopoverPrompt>Are you sure you want to skip this reminder?</PopoverPrompt>
                              <PopoverActions>
                                <PopoverButton
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setConfirmReminderToSkip(null);
                                  }}
                                >
                                  No
                                </PopoverButton>
                                <PopoverButton
                                  data-cy="preview-modal__history__skip-submit"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleConfirmedSkipReminder();
                                  }}
                                >
                                  Yes
                                </PopoverButton>
                              </PopoverActions>
                            </Popover>
                          )}
                        </TrashIconWrapper>
                      </Row>
                    </Row>

                    <Row horizontal="space-between">
                      <Row>
                        <SwitchWithLabel
                          bolded
                          name={`invoice-reminders-history__reminder-${index}-auto-send`}
                          disabled={
                            reminderHasFailedToSend &&
                            reminder?.metadata?.[REMINDER_ERROR_METADATA_FIELDS.SEND_EMAIL_ERROR_COUNT] >= 2
                          }
                          onChange={(value) => {
                            reminderSelected && reminderFormRef?.current?.setFieldValue('auto_send', value);
                          }}
                          checked={reminderSelected ? reminderFormValues?.auto_send : reminder?.auto_send}
                        />

                        <SwitchLabel selected={reminderSelected}>Auto-send</SwitchLabel>
                      </Row>

                      <BlockSubtitle selected={reminderSelected}>
                        {(reminderSelected ? reminderFormValues?.auto_send : reminder?.auto_send)
                          ? dayjs
                              .utc(reminderSelected ? reminderFormValues?.scheduled_for : reminder?.scheduled_for)
                              .format('MMM DD, YYYY')
                          : 'Manual Send'}
                      </BlockSubtitle>
                    </Row>
                  </HistoryBlock>
                </Fragment>
              );
            })
          : null}
      </NewEmails>

      <PastEmails>
        {!!pastEmails?.length ? (
          <>
            <PastTitle>Past emails</PastTitle>
            {pastEmails.map((reminder, index, arr) => (
              <Fragment key={reminder?.id}>
                <HistoryBlock
                  data-cy={`invoice-history__open-sent-reminder-${index}`}
                  onClick={() => {
                    setReminderForSentEmailModal(reminder);
                    setShowSentEmailModal(true);
                  }}
                >
                  <Row horizontal="space-between">
                    <BlockTitle>
                      #{arr?.length - (index - 1)}:{' '}
                      {reminder?.is_inbound
                        ? reminder?.sender?.includes(userEmail) &&
                          !reminder?.recipients?.to?.every((r) => r?.includes(userEmail))
                          ? 'Outgoing'
                          : 'Inbound'
                        : `Reminder`}
                    </BlockTitle>
                    <BlockSubtitle>
                      {dayjs(reminder?.sent_at ?? reminder?.scheduled_for).format('MMM DD, YYYY')}
                    </BlockSubtitle>
                  </Row>
                </HistoryBlock>
              </Fragment>
            ))}
          </>
        ) : null}

        {dayjs.utc().isAfter(dateOfOverdue) ? (
          <OverdueTitle>
            Overdue: <b>{dayjs(dateOfOverdue).format('MMM DD, YYYY')}</b>
          </OverdueTitle>
        ) : null}

        <HistoryBlock
          data-cy="invoice-history__open-email"
          onClick={() => {
            setReminderForSentEmailModal(null);
            setShowSentEmailModal(true);
          }}
        >
          <Row horizontal="space-between">
            <BlockTitle>#1: Invoice sent</BlockTitle>
            <BlockSubtitle>{dayjs(invoiceFormValues?.sent_at).format('MMM DD, YYYY')}</BlockSubtitle>
          </Row>
        </HistoryBlock>
      </PastEmails>

      {showSentEmailModal && (
        <SentEmailModal
          onClose={() => {
            setShowSentEmailModal(false);
            setReminderForSentEmailModal(null);
          }}
          reminder={reminderForSentEmailModal}
          invoice={invoiceFormValues}
        />
      )}
      <ConfirmSaveModal />
    </HistoryWrapper>
  );
};
