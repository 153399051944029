import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { ReactComponent as PlusIcon } from 'images/circle-plus.svg';
import { ReactComponent as DeleteSegment } from 'images/segments-trash.svg';
import { ReactComponent as InfinityIcon } from 'images/infinity-icon.svg';
import { Column, Row } from 'components/Core';
import { COMMITTED_POLICY, COMMITTED_START, ISO_CODE_TO_SYMBOL, NUMBER_FORMATS } from 'consts/global';
import { CustomersSizeSegmentsModal } from './CustomersSizeSegmentsModal';
import { MetadataSegmentsModal } from 'shared/MetadataSegmentsModal/MetadataSegmentsModal';
import { METADATA_SEGMENT_OBJECT_TYPES } from 'shared/Filters/MetadataFilter/consts';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { CircleCheckbox, ConfigPageDice, FieldBody, FieldCount, FieldTag, FieldTitle } from '../../styles';
import { CustomMetadataSegments } from './CustomMetadataSegments';
import {
  AddSegmentButton,
  SubscriptionWrapper,
  Subtitle,
  MainContent,
  StyledInput,
  TagsWrapper,
  SegmentsSubtitle,
  ItemsHeader,
  HeaderLine,
  HeaderCount,
  HeaderName,
  HeaderValue,
  ItemRow,
  ItemCount,
  ItemName,
  SegmentActions,
  DeleteIconContainer,
} from './styles';

export const SubscriptionData = ({
  configPageView,
  isARR,
  setIsARR,
  isCommitted,
  setIsCommitted,
  committedPolicy,
  setCommittedPolicy,
  committedStart,
  setCommittedStart,
  setInfluxOption,
  influxOption,
  influxMonths,
  setInfluxMonths,
  yearEndMonthIndex,
  setYearEndMonthIndex,
  segments,
  setSegments,
  customerMetadataSegments,
  setCustomerMetadataSegments,
  transactionMetadataSegments,
  setTransactionMetadataSegments,
  currencyISOCode,
  setCurrencyISOCode,
  integrationObjectFields,
  setReachedPage,
}) => {
  const [showCreateSegmentModal, setShowCreateSegmentModal] = useState(false);
  const [showMetadataSegmentModal, setShowMetadataSegmentModal] = useState(false);
  const [selectedSegment, setSelectedSegmentIndex] = useState(undefined);
  const [selectedMetadataSegmentIndex, setSelectedMetadataSegmentIndex] = useState(undefined);
  const [objectType, setObjectType] = useState();

  const crmFields = integrationObjectFields.CRM;
  const numberFormatter = useCurrencyNumberFormatter();

  const getMonths = () => {
    const months = [];
    for (let i = 0; i < 12; i++) {
      months.push(dayjs().month(i).format('MMMM'));
    }
    return months;
  };

  const modalMetadataSegmentOptions =
    objectType === METADATA_SEGMENT_OBJECT_TYPES.CUSTOMER ? customerMetadataSegments : transactionMetadataSegments;

  const onMetadataSegmentSubmit = ({ values }) => {
    const segmentForEdit = modalMetadataSegmentOptions?.[selectedMetadataSegmentIndex];

    const setMetadataSegments =
      objectType === METADATA_SEGMENT_OBJECT_TYPES.CUSTOMER
        ? setCustomerMetadataSegments
        : setTransactionMetadataSegments;

    if (segmentForEdit) {
      setMetadataSegments((previousSegments) =>
        previousSegments.map((segment, index) => (index === selectedMetadataSegmentIndex ? values : segment)),
      );
    } else {
      setMetadataSegments((previousSegments) => [...(previousSegments ?? []), values]);
    }
  };

  // null is the equivalent of infinity so should go last
  const displaySegments = segments.sort((a, b) =>
    a.amount === null ? 1 : b.amount === null ? -1 : a.amount - b.amount,
  );

  useEffect(() => {
    if (setReachedPage) setReachedPage(4);
  }, [setReachedPage]);

  return (
    <SubscriptionWrapper>
      {!configPageView ? (
        <>
          <Subtitle>Pick the correct word for the CRM!</Subtitle>
          <MainContent>
            And let's learn about how you typically look at <span>subscription data</span>
          </MainContent>
        </>
      ) : (
        <ConfigPageDice></ConfigPageDice>
      )}

      <FieldBody>
        <FieldCount configPageView={configPageView}>1</FieldCount>
        <FieldTitle>
          <span>
            Do you typically view data as <b>ARR or MRR ?</b>
          </span>
        </FieldTitle>

        <Row horizontal="space-between">
          <Row>
            <Row onClick={() => setIsARR(true)} style={{ cursor: 'pointer' }}>
              <CircleCheckbox data-cy="wizard__is-arr" checked={typeof isARR === 'boolean' && isARR} /> Annual recurring
              revenue
            </Row>

            <Row onClick={() => setIsARR(false)} style={{ cursor: 'pointer', marginLeft: 28 }}>
              <CircleCheckbox data-cy="wizard__mrr-checkbox" checked={typeof isARR === 'boolean' && !isARR} /> Monthly
              recurring revenue
            </Row>
          </Row>
        </Row>
      </FieldBody>

      <FieldBody>
        <FieldCount configPageView={configPageView}>2</FieldCount>
        <FieldTitle>
          <span>
            Do you count customer recurring revenue from the date the contract is signed <b>(Committed ARR)</b> or from
            the date that service goes live <b>(Classical ARR)</b>?
          </span>
        </FieldTitle>

        <Row horizontal="space-between">
          <Row>
            <Row onClick={() => setIsCommitted(false)} style={{ cursor: 'pointer' }}>
              <CircleCheckbox checked={typeof isCommitted === 'boolean' && !isCommitted} /> Classical ARR
            </Row>

            <Row onClick={() => setIsCommitted(true)} style={{ cursor: 'pointer', marginLeft: 28 }}>
              <CircleCheckbox
                data-cy="wizard__committed-mrr-checkbox"
                checked={typeof isCommitted === 'boolean' && isCommitted}
              />{' '}
              Committed ARR
            </Row>
          </Row>
        </Row>
      </FieldBody>

      {isCommitted && (
        <>
          <FieldBody data-cy="wizard__committed-policy">
            <FieldCount configPageView={configPageView}>3</FieldCount>
            <FieldTitle>
              <span>
                If the customer commits to both new revenue and upsells, do you take the new value as committed, or the
                highest value they committed to? (Consider ramp up deals, or commitments to upsell in the future)
              </span>
            </FieldTitle>

            <Row horizontal="space-between">
              <Row>
                <Row onClick={() => setCommittedPolicy(COMMITTED_POLICY.HIGHEST)} style={{ cursor: 'pointer' }}>
                  <CircleCheckbox
                    data-cy="wizard__committed-policy-highest"
                    checked={committedPolicy === COMMITTED_POLICY.HIGHEST}
                  />{' '}
                  Highest
                </Row>

                <Row
                  onClick={() => setCommittedPolicy(COMMITTED_POLICY.NEW_ONLY)}
                  style={{ cursor: 'pointer', marginLeft: 28 }}
                >
                  <CircleCheckbox
                    data-cy="wizard__committed-policy-new"
                    checked={committedPolicy === COMMITTED_POLICY.NEW_ONLY}
                  />
                  New Only
                </Row>
              </Row>
            </Row>
          </FieldBody>

          <FieldBody data-cy="wizard__committed-date">
            <FieldCount configPageView={configPageView}>4</FieldCount>
            <FieldTitle>
              <span>
                If a contract has a service start date BEFORE the book date, do you start counting revenue when it
                actually starts, or only on the date it is booked? (Consider a contract book date of Feb 1, but service
                start date is backdated to Jan 1)
              </span>
            </FieldTitle>

            <Row horizontal="space-between">
              <Row>
                <Row onClick={() => setCommittedStart(COMMITTED_START.BOOK_DATE)} style={{ cursor: 'pointer' }}>
                  <CircleCheckbox checked={committedStart === COMMITTED_START.BOOK_DATE} /> Book Date
                </Row>

                <Row
                  onClick={() => setCommittedStart(COMMITTED_START.EARLIEST_DATE)}
                  style={{ cursor: 'pointer', marginLeft: 28 }}
                >
                  <CircleCheckbox
                    data-cy="wizard__committed-date-earliest"
                    checked={committedStart === COMMITTED_START.EARLIEST_DATE}
                  />
                  Earliest Date
                </Row>
              </Row>
            </Row>
          </FieldBody>
        </>
      )}

      <FieldBody>
        <FieldCount configPageView={configPageView}>{isCommitted ? 5 : 3}</FieldCount>
        <FieldTitle>
          <span>If a customer contract expires, and then they renew one month late, which do you do:</span>
        </FieldTitle>

        <Column horizontal="flex-start">
          <Row
            onClick={() => {
              setInfluxOption('backdate');
              setInfluxMonths(undefined);
            }}
            style={{ cursor: 'pointer' }}
          >
            <CircleCheckbox checked={influxOption === 'backdate'} /> Back date the contract to the expiration of the
            previous one (Subscript recommendation)
          </Row>

          <Row
            onClick={() => {
              setInfluxOption('churnNew');
              setInfluxMonths(undefined);
            }}
            style={{ cursor: 'pointer', marginTop: 16 }}
          >
            <CircleCheckbox checked={influxOption === 'churnNew'} /> Call it churned and then new
          </Row>

          <Row onClick={() => setInfluxOption('influx')} style={{ cursor: 'pointer', marginTop: 16 }}>
            <CircleCheckbox data-cy="wizard__influx-checkbox" checked={influxOption === 'influx'} /> Give it some
            special status as revenue in flux or grace period or equivalent
          </Row>

          {influxOption === 'influx' && (
            <StyledInput
              data-cy="wizard__influx-input"
              onChange={(event) => setInfluxMonths(event.target.value)}
              value={influxMonths ?? 0}
              placeholder="Enter in flux or grace period (in months)..."
              type="number"
            />
          )}
        </Column>
      </FieldBody>

      <FieldBody>
        <FieldCount configPageView={configPageView}>{isCommitted ? 6 : 4}</FieldCount>
        <FieldTitle>
          <span>
            What month does your <b>Fiscal year end?</b>
          </span>
        </FieldTitle>

        <TagsWrapper>
          {getMonths().map((month, index) => (
            <FieldTag key={month} selected={index === yearEndMonthIndex} onClick={() => setYearEndMonthIndex(index)}>
              {month}
            </FieldTag>
          ))}
        </TagsWrapper>
      </FieldBody>

      <FieldBody data-cy="wizard__sizes">
        <FieldCount configPageView={configPageView}>{isCommitted ? 7 : 5}</FieldCount>
        <FieldTitle style={{ marginBottom: 8 }}>
          <span>How would you segment your customers by size?</span>
          <AddSegmentButton
            data-cy="wizard__add-size-segment"
            onClick={() => {
              setShowCreateSegmentModal(true);
              setSelectedSegmentIndex(undefined);
            }}
          >
            <PlusIcon /> Segment
          </AddSegmentButton>
        </FieldTitle>

        <SegmentsSubtitle>
          Can have up to 10 segments. Example: <b>Small</b> ≤{' '}
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: 3 })}k {isARR ? 'ARR' : 'MRR'};
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: 3 })}k {'<'} <b>Medium</b> ≤{' '}
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: 10 })}k; <b>Large</b> {'>'}{' '}
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: 10 })}k
        </SegmentsSubtitle>

        <ItemsHeader>
          <HeaderLine />
          <Row style={{ width: '100%' }} horizontal="space-between">
            <Row>
              <HeaderCount>#</HeaderCount>
              <HeaderName>Name</HeaderName>
            </Row>

            <HeaderValue>Upper bound of {isARR ? 'ARR' : 'MRR'}</HeaderValue>
          </Row>
        </ItemsHeader>

        {displaySegments.map((segment, index) => (
          <ItemRow key={`${segment.name}_${index}`}>
            <Row>
              <ItemCount smallPadding={index >= 9}>{index + 1}</ItemCount>
              <ItemName
                data-cy={`wizard__sizes__${segment.name}`}
                onClick={() => {
                  setShowCreateSegmentModal(true);
                  setSelectedSegmentIndex(index);
                }}
              >
                {segment.name}
              </ItemName>
            </Row>

            <SegmentActions>
              <span>
                {segment.amount === null ? (
                  <InfinityIcon data-cy="wizard__sized__infinty-icon" />
                ) : (
                  numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: segment.amount / (isARR ? 1 : 12) })
                )}
              </span>
              {!(index === displaySegments.length - 1 && isNil(segment.amount)) && (
                <DeleteIconContainer>
                  <DeleteSegment
                    onClick={() =>
                      setSegments(displaySegments.filter((filterSegment) => filterSegment.name !== segment.name))
                    }
                  />
                </DeleteIconContainer>
              )}
            </SegmentActions>
          </ItemRow>
        ))}
      </FieldBody>

      {configPageView && (
        <>
          {/* Custom Customer Metadata segments */}
          <CustomMetadataSegments
            setShowMetadataSegmentModal={setShowMetadataSegmentModal}
            setSelectedMetadataSegmentIndex={setSelectedMetadataSegmentIndex}
            setObjectType={setObjectType}
            crmFields={crmFields}
            objectType={METADATA_SEGMENT_OBJECT_TYPES.CUSTOMER}
            metadataSegments={customerMetadataSegments}
            setMetadataSegments={setCustomerMetadataSegments}
          />
          {/* Custom Transaction Metadata segments */}
          <CustomMetadataSegments
            setShowMetadataSegmentModal={setShowMetadataSegmentModal}
            setSelectedMetadataSegmentIndex={setSelectedMetadataSegmentIndex}
            setObjectType={setObjectType}
            crmFields={crmFields}
            objectType={METADATA_SEGMENT_OBJECT_TYPES.TRANSACTION}
            metadataSegments={transactionMetadataSegments}
            setMetadataSegments={setTransactionMetadataSegments}
          />
        </>
      )}

      {showCreateSegmentModal && (
        <CustomersSizeSegmentsModal
          currency={currencyISOCode}
          isARR={isARR}
          selectedSegment={selectedSegment}
          displaySegments={displaySegments}
          setSegments={setSegments}
          setShowCreateSegmentModal={setShowCreateSegmentModal}
        />
      )}

      {showMetadataSegmentModal && (
        <MetadataSegmentsModal
          selectedSegment={selectedMetadataSegmentIndex}
          displaySegments={modalMetadataSegmentOptions}
          saveOnSubmit={false}
          onSubmitted={onMetadataSegmentSubmit}
          setShowMetadataSegmentModal={setShowMetadataSegmentModal}
          integrationObjectFields={integrationObjectFields}
          objectType={objectType}
        />
      )}

      <FieldBody>
        <FieldTitle>
          <span>
            What <b>currency symbol</b> would you like displayed for your metrics?
          </span>
        </FieldTitle>

        <TagsWrapper>
          {Object.entries(ISO_CODE_TO_SYMBOL).map(([code, symbol]) => (
            <FieldTag
              key={code}
              selected={code === currencyISOCode}
              onClick={() => setCurrencyISOCode(code)}
              data-cy={`wizard__code-select-button--${code}`}
            >
              <b>
                {symbol} ({code})
              </b>
            </FieldTag>
          ))}
        </TagsWrapper>
      </FieldBody>
    </SubscriptionWrapper>
  );
};
