import React, { useContext, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { Centerer, FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { Button } from 'components/Buttons';
import { ReactComponent as CheckIcon } from 'images/check-with-no-circle.svg';
import { useAnalytics } from 'utils/hooks';
import { EVENTS } from 'consts/analytics';
import { INTEGRATION_SERVICES_WITH_CREATE_CREDIT_NOTE } from 'consts/integrations';
import { DIRECTIONS, TooltipContainer } from 'components/Tooltip';
import { getServiceCategory } from 'models/integration';
import { getModalInvoiceStatus } from 'views/Billing/InvoiceModal/utils';
import { INVOICE_STATUSES } from 'views/Billing/consts';

import { useInvoicingScheduleFrequencyDropdown } from './useInvoicingScheduleFrequencyDropdown';
import { AddInvoice } from './AddInvoice';
import { Tab, TabsContainer, TabCounter, WhiteButtonScheduleHeader, TabText } from './styles';
import { getSortedInvoices } from '../utils';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { CreditNoteContext } from '../CreditNoteContext';
import { ScheduleGenerationInfoModal } from './ScheduleGenerationInfoModal';
import { INVOICING_SCHEDULE_TABS_PANEL_TABS } from './consts';
import { InvoicesTab } from './InvoicesTab';
import { CreditNotesTab } from './CreditNotesTab';
import { ContractsButton } from './ContractsButton';
import { InvoicingScheduleFormErrors } from './InvoicingScheduleFormErrors';
import { InvoiceSettingsButton } from './InvoiceSettings/InvoiceSettingsButton';

const Container = styled(Flexer)`
  position: relative;
`;

const Wrapper = styled(FlexerColumn)`
  width: 360px;
  gap: 12px;
  min-width: 360px;
  position: relative;
  pointer-events: ${(props) => props.disabled && 'none'};
  padding-bottom: 12px;
  display: ${(props) => props.hide && 'none'};
  margin-left: 40px;
  z-index: 20;
`;

const SectionWrapper = styled(FlexerColumn)`
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  background: var(--primaryBlack2);
`;

const CheckIconWrapper = styled.div`
  svg {
    width: 16px;
    height: 16px;
    position: relative;
    top: 1px;

    path {
      fill: white;
    }
  }
`;

const GenerationInfoButton = styled(Button)`
  font-size: 12px;
  margin-bottom: 8px;
`;

export const InvoicingScheduleTabsPanel = ({ WarningsButton }) => {
  const { selectedTabsPanelTab, setSelectedTabsPanelTab, invoicingServiceDisplayName } = useContext(
    InvoicingScheduleContext,
  );
  const { creditNotes } = useContext(CreditNoteContext);
  const { errors: invoicingScheduleFormErrors } = useFormikContext();

  const {
    invoicingScheduleFormValues,
    scheduleFormRef,
    disableSaveScheduleButton,
    isScheduleDraft,
    invoicesCreatedInGl,
    fillScheduleWithDraftInvoices,
    invoicingService,
  } = useContext(InvoicingScheduleContext);

  const { trackEvent } = useAnalytics();

  const invoices = getSortedInvoices({ invoices: invoicingScheduleFormValues?.invoices });

  const setFieldValue = scheduleFormRef?.current?.setFieldValue;

  const {
    InvoicingScheduleFrequencyDropdown,
    isLoading: loading,
    generatedInvoicesExplanations,
  } = useInvoicingScheduleFrequencyDropdown({
    invoices,
    setFieldValue,
    importedInvoices: isScheduleDraft ? invoicesCreatedInGl : [],
  });

  const [showGenerationInfoModal, setShowGenerationInfoModal] = useState(false);

  const handleImportInvoicesSelect = useCallback(
    async (invoices) => {
      const prevInvoices = invoicingScheduleFormValues?.invoices ?? [];

      const newInvoices = [
        ...prevInvoices,
        ...invoices
          .filter((invoice) => !prevInvoices.some((i) => i.external_id === invoice.external_id))
          .map((invoice) => ({
            ...invoice,
            date: dayjs.utc(invoice.date).toDate(),
            unsavedId: uuidv4(),
            invoice_items: invoice?.invoice_items?.map((item) => ({ id: Math.random() * 100000, ...item })),
            importedOriginalInvoiceItems: invoice?.invoice_items,
            importedOriginalTotal: invoice?.amount ?? invoice?.Total,
            importedOriginalDate: invoice?.date,
            importedOriginalNumber: invoice?.invoice_number,
            is_imported: true,
            imported_service: invoicingService,
          })),
      ].sort((a, b) => dayjs.utc(a.date).valueOf() - dayjs.utc(b.date).valueOf());

      await fillScheduleWithDraftInvoices({
        invoices: newInvoices,
        frequency: invoicingScheduleFormValues?.invoicing_frequency,
      });
    },
    [
      fillScheduleWithDraftInvoices,
      invoicingScheduleFormValues?.invoices,
      invoicingScheduleFormValues?.invoicing_frequency,
      invoicingService,
    ],
  );

  const showSaveScheduleButton = isScheduleDraft && !!invoicingScheduleFormValues?.invoices?.length;

  const disableEditing = useMemo(
    () =>
      (invoices ?? []).some((invoice) => {
        const invoiceStatus = invoice ? getModalInvoiceStatus({ invoice }) : '';
        return [INVOICE_STATUSES.QUEUED_UP, INVOICE_STATUSES.PROCESSING].includes(invoiceStatus);
      }),
    [invoices],
  );

  return invoices.length || invoicingScheduleFormValues?.id ? (
    <Container>
      <Wrapper disabled={!invoicingScheduleFormValues?.customer_id}>
        <SectionWrapper gap="8px" width="100%">
          <FlexBetweenContainer gap="4px">
            <FlexerColumn gap="8px">
              <Flexer>
                <AddInvoice
                  onImportInvoicesSelect={handleImportInvoicesSelect}
                  allowImport={!isScheduleDraft}
                  importTooltipOverride={isScheduleDraft ? 'Please save the schedule first' : null}
                />
              </Flexer>
            </FlexerColumn>

            <InvoiceSettingsButton disableEditing={disableEditing} />

            <FlexerColumn gap="8px">
              <InvoicingScheduleFrequencyDropdown iconVersion />
            </FlexerColumn>
          </FlexBetweenContainer>

          {showSaveScheduleButton && (
            <FlexerColumn gap="8px">
              <WhiteButtonScheduleHeader
                green
                disabled={disableSaveScheduleButton || invoicingScheduleFormErrors?.invoices?.length > 0}
                onClick={() => !disableSaveScheduleButton && scheduleFormRef?.current?.submitForm()}
                data-cy="billing__invoice-schedule-modal__schedule-save-button"
              >
                <CheckIconWrapper>
                  <CheckIcon />
                </CheckIconWrapper>
                Save Invoicing Schedule
              </WhiteButtonScheduleHeader>

              {invoicingScheduleFormErrors?.invoices?.length > 0 &&
                invoicingScheduleFormValues?.invoices?.length > 0 && (
                  <InvoicingScheduleFormErrors
                    errors={invoicingScheduleFormErrors.invoices}
                    invoices={invoicingScheduleFormValues.invoices}
                  />
                )}

              <GenerationInfoButton
                onClick={() => {
                  trackEvent({
                    name: EVENTS.SEE_HOW_SCHEDULE_WAS_GENERATED_CLICKED,
                  });
                  setShowGenerationInfoModal(true);
                }}
              >
                See how this schedule was generated
              </GenerationInfoButton>
            </FlexerColumn>
          )}
        </SectionWrapper>

        <SectionWrapper gap="12px" style={{ height: '75vh', padding: '0' }}>
          <TabsContainer>
            <Tab
              isSelected={selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES}
              onClick={() => setSelectedTabsPanelTab(INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES)}
              data-cy="billing__invoice-schedule-modal__sidebar-tab--invoices"
            >
              <TabCounter>{invoices.length}</TabCounter>
              <TabText>Invoices</TabText>
            </Tab>

            <Tab
              isSelected={selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.CREDIT_NOTES}
              onClick={() => {
                INTEGRATION_SERVICES_WITH_CREATE_CREDIT_NOTE.includes(getServiceCategory(invoicingService)) &&
                  setSelectedTabsPanelTab(INVOICING_SCHEDULE_TABS_PANEL_TABS.CREDIT_NOTES);
              }}
              disabled={!INTEGRATION_SERVICES_WITH_CREATE_CREDIT_NOTE.includes(getServiceCategory(invoicingService))}
              data-cy="billing__invoice-schedule-modal__sidebar-tab--credit-notes"
            >
              <TooltipContainer
                width={250}
                tooltipStyles={{ borderRadius: '12px' }}
                fontSize="12px"
                backgroundColor={'var(--opaquePrimaryBlack50)'}
                direction={DIRECTIONS.TOP}
                hideArrow
                yOffset={10}
                xOffset={10}
                toolTipContent={
                  invoicingServiceDisplayName
                    ? `We don't support ${invoicingServiceDisplayName} yet`
                    : 'You need to connect to a general ledger in order to use this feature'
                }
                isVisible={!INTEGRATION_SERVICES_WITH_CREATE_CREDIT_NOTE.includes(getServiceCategory(invoicingService))}
              >
                <Centerer gap="8px">
                  <TabCounter data-cy="billing__invoice-schedule-modal__credit-notes-counter">
                    {creditNotes?.length ?? 0}
                  </TabCounter>
                  <TabText>Credit Note</TabText>
                </Centerer>
              </TooltipContainer>
            </Tab>

            <ContractsButton />
          </TabsContainer>

          {(selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES ||
            selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.WARNINGS) && (
            <InvoicesTab invoices={invoices} WarningsButton={WarningsButton} loading={loading} />
          )}

          {selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.CREDIT_NOTES && <CreditNotesTab />}
        </SectionWrapper>

        {showGenerationInfoModal && (
          <ScheduleGenerationInfoModal
            hideModal={() => setShowGenerationInfoModal(false)}
            generatedInvoicesExplanations={generatedInvoicesExplanations}
          />
        )}
      </Wrapper>
    </Container>
  ) : null;
};
