export const EMPTY_INVOICING_SCHEDULE = {
  customer_id: null,
  customer_name: null,
  id: null,
  invoicing_frequency: null,
  invoices: [],
};

// same with backend/src/components/invoicingSchedules/consts.js
export const INVOICE_ADDITION_OPTIONS = {
  ADD_NEW: 'addNew',
  MERGE: 'merge',
};

export const MISSING_EXTERNAL_ID_GROUP = 'Manually created';

export const INVOICE_FORM_FIELDS = [
  'amount',
  'auto_charge',
  'auto_send',
  'base_amount',
  'date',
  'days_to_pay',
  'email_addresses',
  'email_body',
  'email_subject',
  'internal_notes',
  'invoice_items',
  'invoice_number',
  'memo',
  'send_date',
  'po_number',
];
