import styled, { css } from 'styled-components';
import { Row } from 'components/Core';
import { CentererVertical } from 'components/Core';
import { ReactComponent as ExternalLink } from 'images/external-link-billing.svg';
import { FlexerColumn } from 'components/Core';

export const PanelContainer = styled(FlexerColumn)`
  width: 340px;
  height: 100%;
  border-radius: 0px 8px 8px 0px;
  border: 1px solid var(--primaryBlack5);
  background-color: ${({ isFloating }) => (isFloating ? 'var(--primaryGray)' : 'var(--primaryBlack2)')};
  display: ${({ show }) => (show ? 'auto' : 'none')};
  position: ${({ isFloating }) => (isFloating ? 'absolute' : 'relative')};
  left: ${({ XOffset }) => XOffset};
  top: ${({ YOffset }) => YOffset};
  z-index: 30;

  ${({ isFloating }) =>
    isFloating &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: var(--primaryBlack2);
      }
    `}
`;

export const Wrapper = styled(CentererVertical)`
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  gap: 8px;
  margin-bottom: 8px;
  border-top: ${({ hasBorder }) => (hasBorder ? '1px solid var(--accentGraySecond)' : 'none')};
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
`;

export const CheckBoxRow = styled(Row)`
  display: flex;
  align-self: stretch;
  font-size: 12px;
  justify-content: flex-start;
  margin-bottom: 4px;
`;

export const InvoiceInfo = styled.span`
  display: flex;
  align-items: center;
  margin-left: 8px;
  justify-content: flex-start;
  width: 287px;

  svg {
    margin-left: 5px;
  }
`;

export const InvoiceLink = styled.span`
  align-items: center;
  display: flex;
  flex-direction: row;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
`;

export const InvoiceDate = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 230px;
  line-height: 16px;
`;

export const TotalAmount = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  width: 100px;
  display: flex;
  justify-content: flex-end;
`;

export const PaidDate = styled.span`
  display: flex; // Align the check icon
  align-items: center;
  gap: 4px;
  width: 183px;
  justify-content: flex-start;

  span {
    color: var(--primaryBlack50);
  }
`;

export const LineItemRow = styled(Row)`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  margin-left: 6px;
`;

export const LineItemAmount = styled(Row)`
  width: 100px;
  justify-content: flex-end;
  margin-left: auto;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  color: var(--primaryBlack50);
`;

export const LineItemTitleRow = styled(Row)`
  display: flex;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  justify-content: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const StyledExternalLink = styled(ExternalLink)`
  &:hover {
    cursor: pointer;
  }
`;
