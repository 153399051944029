import React from 'react';

import { FEATURES, isFeatureActive } from 'utils/featureUtils';
import {
  ChartBarIcon,
  ChartDonut4Icon,
  CustomersIcon,
  GeometryIcon,
  ReceiptIcon,
  ReportIcon,
  SaveIcon,
  SettingsIcon,
  ToolsIcon,
  TransactionIcon,
  VectorTriangleIcon,
  WaterfallIcon,
} from 'components/Icons';
import { SPREAD_REPORT_TYPE } from 'views/Spreads/utils';
import { HEADER_TITLES } from '../Header';
import { TransactionsLink } from './TransactionsLink';
import { TransactionLinksPopover } from './TransactionsLinksPopover';
import { ConfigLink } from './ConfigLink';
import { ConfigLinksPopover } from './ConfigLinksPopover';
import { PopoverReportsSection } from './PopoverReportsSection';
import { DashboardLinksPopover } from './DashboardLinksPopover';
import { BillingLink } from './BillingLink';
import { BillingPopover } from './BillingPopover';

export const SECTION_TYPE = {
  ANCHOR: 'anchor',
  POPOVER: 'popover',
  CUSTOM: 'custom',
};

export const getSections = ({ active, isMVP, orgConfigs }) => {
  const sections = [
    {
      type: SECTION_TYPE.POPOVER,
      title: 'Dashboard',
      to: '/dashboard',
      explainTooltipText: 'All your subscription metrics at a glance',
      active: ['dashboard'].includes(active),
      icon: <ReportIcon />,
      PopoverContents: DashboardLinksPopover,
    },
    {
      type: SECTION_TYPE.POPOVER,
      title: 'Analysis',
      explainTooltipText: 'Get insights and analyse your revenue data',
      active: ['waterfall', 'revenue', 'spreads', 'cohorts'].includes(active),
      icon: <ChartBarIcon />,
      listItems: [
        {
          title: HEADER_TITLES.cohorts,
          description: 'How groups of customers who started in the same month have retained',
          to: '/cohorts',
          icon: <GeometryIcon />,
        },
        {
          title: HEADER_TITLES.revenue,
          description: 'Breakdown of customers who were new, upsold, downsold, or churned in a month',
          to: '/revenue',
          icon: <ReportIcon />,
        },
        {
          title: HEADER_TITLES.spreads,
          description: 'Revenue broken down by customer, by month',
          to: {
            pathname: `/spreads`,
            state: { spreadReportType: SPREAD_REPORT_TYPE.SaaS },
          },
          icon: <ChartDonut4Icon />,
        },
        {
          title: HEADER_TITLES.waterfall,
          description: 'Breakdown of recurring revenue by New, Upsell, Downsell, and Churn',
          to: '/waterfall',
          icon: <WaterfallIcon />,
        },
      ],
      ExtraPopoverSection: <PopoverReportsSection />,
    },
  ];

  if (isFeatureActive({ feature: FEATURES.BILLING, orgConfigs }) || isMVP) {
    sections.push({
      type: SECTION_TYPE.CUSTOM,
      title: 'Billing',
      explainTooltipText: 'Never miss sending invoices and get paid faster by reminders',
      icon: <ReceiptIcon size="24px" />,
      active: ['billing'].includes(active),
      to: '/billing',
      isNewFeature: true,
      PopoverContents: BillingPopover,
      NavbarLink: BillingLink,
    });
  }

  return [
    ...sections,
    {
      type: SECTION_TYPE.POPOVER,
      title: 'Tools',
      explainTooltipText: 'Build finance models and save your pages',
      icon: <ToolsIcon />,
      active: ['chart', 'forecasting', 'reports'].includes(active),
      listItems: [
        {
          title: HEADER_TITLES.chart,
          description: 'Create custom charts for a variety of metrics',
          to: '/chart',
          icon: <VectorTriangleIcon />,
        },
        // [JB 2022-03-10] Removing link for now (https://www.notion.so/teamsubscript/Should-we-sunset-Forecasting-feature-Next-steps-2c4b2c28cbac4eb68218554be894b461?pvs=4)
        /*{
        title: HEADER_TITLES.forecasting,
        description: 'Track scenarios with different revenue forecasts and set goals',
        to: FORECASTING_PAGES_ROUTES.FORECASTING,
        icon: <PresentationIcon />,
      },*/
        {
          title: HEADER_TITLES.reports,
          description: 'Customize and save all page settings for quick access to your reports',
          to: '/reports',
          icon: <SaveIcon />,
        },
        // Only show billing in MVP for now
      ],
    },
    {
      type: SECTION_TYPE.CUSTOM,
      title: 'Transactions',
      active: ['transactions'].includes(active),
      icon: <TransactionIcon />,
      to: '/transactions',
      PopoverContents: TransactionLinksPopover,
      NavbarLink: TransactionsLink,
    },
    {
      type: SECTION_TYPE.ANCHOR,
      title: HEADER_TITLES.customers,
      active: ['customers'].includes(active),
      to: '/customers',
      icon: <CustomersIcon />,
    },
    {
      type: SECTION_TYPE.CUSTOM,
      title: 'Settings',
      explainTooltipText: 'See your integrations and general settings',
      active: ['configure'].includes(active),
      icon: <SettingsIcon />,
      PopoverContents: ConfigLinksPopover,
      NavbarLink: ConfigLink,
      YOffset: 190,
    },
  ];
};
