import React, { useContext } from 'react';
import { ReactComponent as TableExportIcon } from 'images/table-export.svg';
import { WIDGET_TYPES } from './consts';
import { EditWidgetDropdown, POPOVER_OPTIONS, POPOVER_ACTIONS } from './EditWidgetDropdown';
import { ReportBuilderContext } from './ReportBuilderContext';
import { COMMON_METRICS_DASHBOARD_ID } from './consts';

const EXPORT_POPOVER_OPTIONS = [
  {
    action: 'export',
    title: 'Export to CSV file',
    icon: <TableExportIcon />,
  },
  {
    action: 'sync',
    title: 'Sync into Ext Product',
    icon: <TableExportIcon />,
  },
  {
    action: 'screenshot',
    title: 'Export to PNG',
    icon: <TableExportIcon />,
  },
];

const CUSTOM_POPOVER_OPTIONS = [POPOVER_OPTIONS[1], ...EXPORT_POPOVER_OPTIONS, POPOVER_OPTIONS[2]];

export const DataTableEditWidgetDropdown = ({ showSyncedExportModal, exportCSV, exportPNG, showDelete = true }) => {
  const { report, onEditWidget, onDeleteWidget } = useContext(ReportBuilderContext);
  const popoverOptions = (!showDelete ? CUSTOM_POPOVER_OPTIONS.slice(0, -1) : CUSTOM_POPOVER_OPTIONS) ?? [];

  if (report.id === COMMON_METRICS_DASHBOARD_ID) {
    const editOption = popoverOptions.find(({ action }) => action === POPOVER_ACTIONS.EDIT);
    if (editOption) {
      editOption.isDisabled = true;
      editOption.tooltip = (
        <>
          <b>Common SaaS Report</b> contains all metrics, but it isn't editable. To customize the table, please create a
          new report with a snapshot table.
        </>
      );
    }
  }

  const handleOptionClick = (action) => {
    switch (action) {
      case POPOVER_ACTIONS.EDIT:
        onEditWidget({ metric: { id: WIDGET_TYPES.DATA_TABLE } });
        break;
      case POPOVER_ACTIONS.DELETE:
        onDeleteWidget({ metric: { id: WIDGET_TYPES.DATA_TABLE } });
        break;
      case EXPORT_POPOVER_OPTIONS[0].action:
        exportCSV();
        break;
      case EXPORT_POPOVER_OPTIONS[1].action:
        showSyncedExportModal();
        break;
      case EXPORT_POPOVER_OPTIONS[2].action:
        exportPNG();
        break;
      default:
        break;
    }
  };

  return (
    <EditWidgetDropdown width="172px" XOffset={-50} onOptionClick={handleOptionClick} popoverOptions={popoverOptions} />
  );
};
