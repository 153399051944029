import React, { useContext, useState } from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { ProductActionsModal } from 'shared/ProductActionsModal';
import { CentererVertical, FlexBetweenContainer } from 'components/Core';
import { EditCircleIcon, InfoCircleOrange } from 'components/Icons';
import { FormikCustomSelector } from 'components/Controls';
import { MANUAL_GL_SERVICE_NAME } from 'views/Billing/consts';
import { InvoicingScheduleContext } from './InvoicingScheduleContext';

const ProductOptionDescription = styled.div`
  color: ${({ warning, color }) => color ?? (warning ? 'var(--tertiaryYellow)' : 'var(--primaryBlack70)')};
  width: ${({ width }) => width ?? 'auto'};
  overflow-wrap: break-word;
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
`;

const EditGLProductButton = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--primaryBlue10);
  color: var(--primaryBlue);
  font-weight: 700;
  font-size: 12px;
  z-index: 10;

  &:hover {
    background-color: var(--primaryBlue20);
  }
`;

export const ExternalProductSelector = ({
  index,
  valuesEntity,
  tooltipInputDisplay,
  disableEditing,
  handleChange,
  hideLabel,
}) => {
  const { invoicingService, productData, refetchProducts, productOptions, invoicingServiceDisplayName } = useContext(
    InvoicingScheduleContext,
  );
  const [showProductActionsModal, setShowProductActionsModal] = useState(false);
  const { values } = useFormikContext();

  const openProductActionsModal = (e) => {
    e.preventDefault();
    setShowProductActionsModal(true);
  };

  const Option = (props) => (
    <components.Option {...props}>
      <FlexBetweenContainer>
        <div style={{ fontWeight: 700 }} data-cy={`products__option-${index}-${props.data.label}`}>
          {props.data.label}
        </div>

        <EditCircleIcon
          data-cy={`schedule-modal__invoice__item-${index}-edit-product-${props.data.label}`}
          style={{ cursor: 'pointer' }}
          size="16px"
          fill="var(--primaryBlack30)"
          hoverFill="var(--primaryBlack)"
          onClick={openProductActionsModal}
        />
      </FlexBetweenContainer>
      <FlexBetweenContainer spacing="8px">
        <ProductOptionDescription width="33%">{props.data.name}</ProductOptionDescription>

        {invoicingService && invoicingService !== MANUAL_GL_SERVICE_NAME ? (
          <ProductOptionDescription warning={!props.data.subValue} width="67%" textAlign="right">
            <CentererVertical gap="4px">
              {props.data.subValue
                ? props.data.subLabel
                : `We'll create a new product in ${invoicingServiceDisplayName}`}
            </CentererVertical>
          </ProductOptionDescription>
        ) : (
          <ProductOptionDescription width="67%">{props.data.subLabel}</ProductOptionDescription>
        )}
      </FlexBetweenContainer>
    </components.Option>
  );

  const SingleValue = (props) => (
    <components.SingleValue {...props}>
      <FlexBetweenContainer alignItems="center" gap="8px">
        <div>{props.data.label}</div>
        {invoicingService ? (
          <ProductOptionDescription warning={!props.data.subValue}>
            <CentererVertical gap="4px">
              {props.data.subLabel}

              {!props.data.subValue && invoicingService !== MANUAL_GL_SERVICE_NAME ? (
                <>
                  <InfoCircleOrange size="12px" fill="var(--tertiaryYellow)" />
                  <EditGLProductButton onClick={openProductActionsModal}>Edit GL product</EditGLProductButton>
                </>
              ) : null}
            </CentererVertical>
          </ProductOptionDescription>
        ) : (
          <ProductOptionDescription color="var(--primaryBlack30)">{props.data.subLabel}</ProductOptionDescription>
        )}
      </FlexBetweenContainer>
    </components.SingleValue>
  );

  return (
    <>
      <FormikCustomSelector
        label={index === 0 && !hideLabel && 'Product line item'}
        secondaryLabel={index === 0 && invoicingService && !hideLabel && `${invoicingServiceDisplayName} Product`}
        tooltipInputDisplay={tooltipInputDisplay}
        placeholder="Select product"
        name={`${valuesEntity}[${index}].product_id`}
        options={productOptions}
        components={{ Option, SingleValue }}
        containerWidth="100%"
        isDisabled={disableEditing}
        fullWidth
        greyVer
        menuZIndex={showProductActionsModal ? 0 : null}
        handleChange={handleChange}
      />

      {showProductActionsModal && (
        <ProductActionsModal
          action="edit"
          product={(productData?.products ?? []).find((p) => p.id === values[valuesEntity][index].product_id)}
          onClose={(updatedProduct) => {
            if (updatedProduct) {
              refetchProducts();
            }
            setShowProductActionsModal(false);
          }}
        />
      )}
    </>
  );
};
