import React from 'react';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Flexer, FlexBetweenContainer, CentererVertical } from 'components/Core';
import { FilePlusIcon } from 'components/Icons';

const Wrapper = styled(FlexBetweenContainer)`
  border: 1px solid var(--accentGraySecond);
  box-shadow: 4px 4px 24px var(--primaryBlack4);
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 20px;
`;

export const Description = styled(CentererVertical)`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryBlack50);
`;

const RightWrapper = styled.div`
  padding-left: 30px;
  border-left: 1px solid var(--accentGraySecond);
`;

const DownloadTemplateWrapper = styled(Flexer)`
  cursor: pointer;
  gap: 10px;
`;

const DownloadTemplateButton = styled.div`
  background: var(--primaryBlue5);
  color: var(--primaryBlue);
  border-radius: 100px;
  padding: 8px 16px;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
`;

export const CsvUploadHeader = ({ entityName, homeCurrency }) => {
  const objectsToDescription = {
    transactions:
      'Update unconfirmed transactions or add new ones indicating the customer, product, recognition schedule, amount and dates for each transaction.',
    customers:
      'Update existing customers or add new ones indicating the customer name, email, and any other metadata information you want to track.',
    spreads: 'Add new event spreads to transactions.',
    'Usage-Events': 'Add new Usage-Events.',
    rates: `Update existing currency rates or add new ones by using uploaded CSV rates FROM the selected currencies TO your home currency (${homeCurrency})`,
    'Accounting-Spreads':
      'Update Accounting Spreads for transactions. Existing accounting spreads for transactions will be deleted and replaced by uploaded accounting spreads.',
    products: 'Add new products',
  };

  return (
    <Wrapper>
      <Description>{objectsToDescription[entityName]}</Description>
      <RightWrapper>
        <Link
          to={`${process.env.PUBLIC_URL}/templates/${capitalize(entityName)}_Template.csv`}
          target="_blank"
          download
        >
          <DownloadTemplateWrapper>
            <FilePlusIcon fill="var(--primaryBlue)" />
            <DownloadTemplateButton>Download Template</DownloadTemplateButton>
          </DownloadTemplateWrapper>
        </Link>
      </RightWrapper>
    </Wrapper>
  );
};
