import React, { useContext, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from 'AppContext';
import { INTEGRATION_SERVICES_WITH_CREATE_CREDIT_NOTE } from 'consts/integrations';
import { Popover, PopoverItemsContainer, PopoverListItem, usePortal } from 'components/Portal';
import { Centerer, Row } from 'components/Core';
import { DIRECTIONS, TooltipContainer } from 'components/Tooltip';
import { ReactComponent as PlusIcon } from 'images/plus-billing.svg';
import { CreditNoteContext } from '../CreditNoteContext';
import { getSortedInvoices } from '../utils';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { useSelectImportInvoicesModal } from '../InvoicingScheduleSelectImportInvoicesPanel';
import { WhiteButtonScheduleHeader } from './styles';

export const ActionsButton = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  opacity: 0.2;
  justify-content: center;
  border-radius: 100px;
  background-color: ${({ isSelected }) => isSelected && 'var(--primaryBlue10)'};

  &:hover {
    opacity: 1;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: var(--primaryBlue);
    }
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const ListItemLabel = styled(Centerer)`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  padding: 10px;
  font-weight: 900;
  background: ${({ isCreditNote }) => (isCreditNote ? 'var(--primaryYellow15)' : 'var(--primaryBlue10)')};
  color: ${({ isCreditNote }) => (isCreditNote ? 'var(--tertiaryYellow)' : 'var(--primaryBlue)')};
`;

const ListItemWrapper = styled(PopoverListItem)`
  opacity: ${({ disabled }) => disabled && '0.3'};
  cursor: ${({ disabled }) => disabled && 'default'};

  svg {
    width: 16px;
    height: 16px;

    g {
      opacity: 1;
    }

    path {
      fill: ${({ isTrashItem }) => (isTrashItem ? 'var(--primaryRed50)' : 'var(--primaryBlack50)')};
    }
  }
`;

const ADD_INVOICE_OPTIONS = {
  NEW: 'New Invoice',
  QUICKBOOKS: 'Existing from Quickbooks',
  XERO: 'Existing from Xero',
  NETSUITE: 'Existing from Netsuite',
  NEW_CREDIT_NOTE: 'New Credit Note',
};

const InvoiceActionTooltip = ({ action, tooltipOverride, children }) => {
  const TOOLTIP_CONTENT_BY_ACTION = {
    NEW: 'Create a new invoice, which you can send, sync to your GL, and more.',
    QUICKBOOKS: 'Import an existing invoice from Quickbooks',
    XERO: 'Import an existing invoice from Xero',
    NETSUITE: 'Import an existing invoice from Netsuite',
  };

  const TOOLTIP_Y_OFFEST_BY_ACTION = {
    NEW: -36,
    QUICKBOOKS: -44,
    XERO: -44,
    NETSUITE: -44,
    NEW_CREDIT_NOTE: -44,
  };

  return (
    <TooltipContainer
      width={250}
      tooltipStyles={{ borderRadius: '12px' }}
      fontSize="12px"
      backgroundColor={'var(--opaquePrimaryBlack50)'}
      direction={DIRECTIONS.TOP}
      hideArrow
      yOffset={TOOLTIP_Y_OFFEST_BY_ACTION[action]}
      xOffset={238}
      toolTipContent={tooltipOverride ?? TOOLTIP_CONTENT_BY_ACTION[action]}
      isVisible={!!(tooltipOverride ?? TOOLTIP_CONTENT_BY_ACTION[action])}
    >
      <Row>{children}</Row>
    </TooltipContainer>
  );
};

export const AddInvoice = ({ onImportInvoicesSelect, allowImport = true, importTooltipOverride = null }) => {
  const { orgId } = useContext(AppContext);
  const {
    includedTransactions,
    customer,
    fillScheduleWithDraftInvoices,
    currentInvoicingSchedule,
    invoicingScheduleFormValues,
    invoicingService,
    invoicesCreatedInGl,
    invoicingServiceDisplayName,
  } = useContext(InvoicingScheduleContext);
  const { addDraftCreditNote } = useContext(CreditNoteContext);

  const { triggerRef, togglePortal, isPortalVisible, hidePortal } = usePortal();

  const invoices = useMemo(() => getSortedInvoices({ invoices: invoicingScheduleFormValues?.invoices }), [
    invoicingScheduleFormValues?.invoices,
  ]);

  const addNewSubscriptInvoice = useCallback(() => {
    const newId = uuidv4();

    const newInvoice = {
      organization_id: orgId,
      customer_id: customer?.id,
      date: new Date(),
      amount: 0,
      sent_at: null,
      paid_at: null,
      invoice_items: includedTransactions.map((transaction) => ({
        organization_id: orgId,
        customer_id: transaction.customer_id,
        transaction_id: transaction.id,
        amount: 0,
        seats: transaction.seats ?? 1,
        product_id: transaction.product_id,
        id: Math.random() * 100000,
      })),
      currency: invoicingScheduleFormValues?.currency,
      unsavedId: newId,
    };

    fillScheduleWithDraftInvoices({ invoices: [newInvoice, ...invoices], applyRuleset: true });
  }, [
    orgId,
    customer,
    includedTransactions,
    invoices,
    fillScheduleWithDraftInvoices,
    invoicingScheduleFormValues?.currency,
  ]);

  const addInvoice = (event) => {
    event.preventDefault();
    togglePortal();
  };

  const { Modal: ImportInvoicesModal, openModal: openImportInvoicesModal } = useSelectImportInvoicesModal({
    onImportInvoicesSelect,
    selectedInvoices: currentInvoicingSchedule?.id ? [] : invoicesCreatedInGl,
  });

  const handleActionSelect = ({ action }) => {
    switch (action) {
      case 'NEW':
        addNewSubscriptInvoice();
        break;
      case 'QUICKBOOKS':
      case 'XERO':
      case 'NETSUITE':
        openImportInvoicesModal({
          customerId: customer.id,
        });
        break;
      case 'NEW_CREDIT_NOTE':
        addDraftCreditNote();
        break;
      default:
    }
    hidePortal();
  };

  const isActionDisabled = ({ action }) => {
    switch (action) {
      case 'QUICKBOOKS':
      case 'XERO':
      case 'NETSUITE':
        return { value: !allowImport };
      case 'NEW_CREDIT_NOTE':
        return {
          value: invoicingService ? !INTEGRATION_SERVICES_WITH_CREATE_CREDIT_NOTE.includes(invoicingService) : false,
          tooltip:
            invoicingService && !INTEGRATION_SERVICES_WITH_CREATE_CREDIT_NOTE.includes(invoicingService)
              ? invoicingServiceDisplayName
                ? `We don't support ${invoicingServiceDisplayName} yet`
                : 'You need to connect to a general ledger in order to use this feature'
              : null,
        };
      default:
        return { value: false };
    }
  };

  return (
    <>
      <Container ref={triggerRef}>
        <WhiteButtonScheduleHeader
          onClick={addInvoice}
          disabled={includedTransactions?.length === 0}
          data-cy="billing__invoice-schedule-modal__add-invoice-button"
        >
          <PlusIcon width="16px" style={{ marginLeft: 12 }} height="16px" />
          Add Invoice or Credit Note
        </WhiteButtonScheduleHeader>

        {isPortalVisible && (
          <Popover data-cy="invoicing-schedule-modal__add-invoice__portal" width="200px" YOffset={50}>
            <PopoverItemsContainer>
              {Object.entries(ADD_INVOICE_OPTIONS)
                // eslint-disable-next-line no-unused-vars
                .filter(([key]) => ['NEW', 'NEW_CREDIT_NOTE'].includes(key) || key.toLowerCase() === invoicingService)
                .map(([key, item]) => {
                  let { value: disabled, tooltip } = isActionDisabled({ action: key });
                  if (key.toLowerCase() === invoicingService && importTooltipOverride) {
                    tooltip = importTooltipOverride;
                  }
                  return (
                    <InvoiceActionTooltip key={key} action={key} tooltipOverride={tooltip}>
                      <ListItemWrapper
                        key={key}
                        onClick={!disabled ? () => handleActionSelect({ action: key }) : null}
                        disabled={disabled}
                        data-cy={`invoicing-schedule-modal__add-invoice-actions__action--${key}`}
                      >
                        <Centerer gap="12px">
                          <ListItemLabel isCreditNote={key === 'NEW_CREDIT_NOTE'}>
                            {key !== 'NEW_CREDIT_NOTE' ? 'IN' : 'CN'}
                          </ListItemLabel>
                          {item}
                        </Centerer>
                      </ListItemWrapper>
                    </InvoiceActionTooltip>
                  );
                })}
            </PopoverItemsContainer>
          </Popover>
        )}
      </Container>

      <ImportInvoicesModal />
    </>
  );
};
