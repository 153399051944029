import styled from 'styled-components';
import { Centerer, Flexer } from 'components/Core';
import { TabButton } from 'components/Buttons';

export const GeneratingPreview = styled(Centerer)`
  padding: 16px;
  background: #ffffff;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 4px 4px 28px var(--primaryBlack5);
  border-radius: 12px;

  font-weight: 600;
  font-size: 11px;
  line-height: 14px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Wrapper = styled(Flexer)`
  height: 100%;
  gap: 36px;
  padding: 0 36px 28px 36px;
`;

export const ScheduleTabs = styled.div`
  display: flex;
`;

export const StyledLeftTabButton = styled(TabButton)`
  margin-right: 0;
  border-top-right-radius: 0;
  margin-left: 16px;
  border-right: 0;
  border-bottom-right-radius: 0;
`;

export const StyledRightTabButton = styled(TabButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-right: 16px;
`;

export const InvoiceDetailsPanel = styled(Centerer)`
  width: 100%;
`;

export const InvoicePreviewCard = styled(Centerer)`
  flex-direction: column;
  align-items: flex-start;
  width: ${({ width }) => width ?? '350px'};
  height: ${({ height }) => height ?? '100%'};
  background: #fff;
  border-radius: 20px;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 8px 8px 60px 0px var(--primaryBlack4);
`;

export const InvoiceLink = styled(Flexer)`
  align-items: center;
  font-size: 20px;
  font-weight: 900;
  line-height: 26px;
  margin-left: 28px;

  color: var(--primaryBlue);

  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    path {
      fill: var(--primaryBlack70);
    }
  }
`;

export const PDFWrapper = styled(Centerer)`
  height: 100%;
  width: 100%;
  overflow: auto;

  .react-pdf__Document {
    max-height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: flex;
    overflow-y: auto;
    align-self: flex-start;
  }
`;
