import React from 'react';
import styled from 'styled-components';

import { ReactComponent as InactiveCheck } from 'images/inactive-check.svg';
import { ReactComponent as ActiveCheck } from 'images/active-check.svg';
import { ReactComponent as ActiveRadio } from 'images/active-radio.svg';
import { ReactComponent as InactiveRadio } from 'images/inactive-radio.svg';

const CheckBoxContainer = styled.div`
  height: 40px;
  min-width: 90px;
  background-color: ${({ active }) => (active ? 'var(--primaryBlue)' : '#F2F2F2')};
  border: 1px solid ${({ active }) => (active ? 'var(--primaryBlue)' : '#F2F2F2')};
  pointer-events: ${({ readonly }) => (readonly ? 'none' : 'initial')};
  border-radius: 100px;
  margin-right: 10px;
  padding: 12px 14px;
  cursor: pointer;

  &:hover {
    background-color: var(--primaryBlue);
    color: white;
    border: 1px solid var(--primaryBlue);
  }

  svg {
    margin-left: 14px;

    path {
      fill-opacity: ${({ readonly }) => (readonly ? '1' : '')};
    }
  }
`;

const CheckBoxTitle = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${({ active }) => (active ? 'white' : 'var(--primaryBlack)')};

  ${CheckBoxContainer}:hover & {
    color: white;
  }
`;

const CheckItem = ({ title, active, type, onClick, readonly }) => {
  const elemType =
    type === 'checkbox'
      ? { active: <ActiveCheck />, inActive: <InactiveCheck /> }
      : { active: <ActiveRadio />, inActive: <InactiveRadio /> };

  return (
    <CheckBoxContainer readonly={readonly} active={active} className="flexer mb-1" onClick={onClick}>
      <CheckBoxTitle active={active}>{title}</CheckBoxTitle>
      {elemType[active ? 'active' : 'inActive']}
    </CheckBoxContainer>
  );
};

export default CheckItem;
