import { isEmpty, isNil } from 'lodash';
import { includesSearchQuery } from 'utils/stringUtils';
import { RECOGNITION_TYPES } from 'consts/global';

import { INVOICE_ITEM_TYPES, INVOICE_ITEM_TYPE_TO_KEY, SUPPORTED_RECOGNITIONS } from 'views/Billing/consts';
import { transactionDisplayNameForBilling } from 'models/transaction';
import { getInvoiceData } from '../InvoiceModal/utils';
import { MISSING_EXTERNAL_ID_GROUP } from './consts';

export const ALREADY_SELECTED_GROUP_NAME = 'Existing transactions in schedule';

export const getSortedInvoices = ({ invoices = [] }) => {
  const draftInvoices = invoices.filter((invoice) => invoice.unsavedId);
  const createdInvoices = invoices.filter((invoice) => invoice.id);

  //not sort draft invoices (we get it from backend already in right order)
  const sortedCreatedInvoices = createdInvoices
    .map((i) => ({ ...i, date: new Date(i.date) })) // the dates from backend are strings, but updated dates are Date
    .sort((invoice1, invoice2) => (invoice1.date < invoice2.date ? -1 : 1));
  return [...draftInvoices, ...sortedCreatedInvoices];
};

export const groupTransactionsByExternalId = ({ transactions, searchQuery, asArray = false }) => {
  const transactionsByExternalId = transactions?.reduce((acc, transaction) => {
    if (!SUPPORTED_RECOGNITIONS.includes(transaction?.recognition)) return acc;

    const nameMatched = includesSearchQuery(transaction.name, searchQuery);

    const externalId = isEmpty(transaction.external_ids) ? MISSING_EXTERNAL_ID_GROUP : transaction.external_ids[0];
    if (nameMatched || includesSearchQuery(externalId, searchQuery)) {
      const groupId = externalId ?? MISSING_EXTERNAL_ID_GROUP;
      acc[groupId] = acc[groupId] ?? [];
      acc[groupId].push(transaction);
    }

    return acc;
  }, {});

  return asArray ? Object.values(transactionsByExternalId ?? {}) : transactionsByExternalId;
};

export const earliestAndLatestDatesForTransactions = (transactions) => {
  const allDates = [...(transactions ?? [])?.map((t) => t.start_date), ...(transactions ?? []).map((t) => t.end_date)];
  allDates?.sort();
  return { earliest: allDates?.[0], latest: allDates?.[allDates.length - 1] };
};

export const pushToastByChangedFields = ({ pushToast, invoicingScheduleData, invoice }) => {
  //on the curent stage if we call update invoicing schedule with 1 or 2 fields, means
  //that we change schedule auto_charge or auto_send, and we want to show special toast in this case
  if (Object.keys(invoicingScheduleData ?? {}) < 3) {
    if (!isNil(invoicingScheduleData.auto_charge)) {
      if (invoicingScheduleData.auto_charge) {
        pushToast(`You've successfully configured invoices to be automatically charge`, 'success');
      } else {
        pushToast(`Invoices will no longer be automatically charge`, 'success');
      }
    } else if (!isNil(invoicingScheduleData.auto_send)) {
      if (invoicingScheduleData.auto_send) {
        pushToast(
          `You've successfully configured future invoices to be automatically sent to ${invoice.customer_name}`,
          'success',
        );
      } else {
        pushToast(`Future invoices will no longer be sent automatically to ${invoice.customer_name}`, 'success');
      }
    }
  } else {
    pushToast('Successfully updated Invoicing Schedule!', 'success');
  }
};

//this function mutate invoiceChanges
export const clearInvoiceItemsForBulkEdit = ({ invoiceChanges, invoice }) => {
  if (
    Object.keys(invoiceChanges ?? {}).some((changeKey) => Object.values(INVOICE_ITEM_TYPE_TO_KEY).includes(changeKey))
  ) {
    for (const [type, key] of Object.entries(INVOICE_ITEM_TYPE_TO_KEY)) {
      if (invoiceChanges[key] === null) {
        //when we remove tax/fees/shipping we save it in changes as {invoice_taxes: null}
        invoiceChanges.invoice_items = invoice.invoice_items?.filter((item) => item.type !== type);
      }
    }
  } else if (!!invoiceChanges?.invoice_items?.length) {
    const updatedTaxShippingFeeItems = invoiceChanges.invoice_items?.filter((item) =>
      Object.keys(INVOICE_ITEM_TYPE_TO_KEY).includes(item.type),
    );
    const originalInvoiceItems = invoice.invoice_items.filter(
      (item) => !updatedTaxShippingFeeItems.map(({ type }) => type).includes(item.type),
    );
    invoiceChanges.invoice_items = [...originalInvoiceItems, ...updatedTaxShippingFeeItems];
  }

  delete invoiceChanges?.grouping;
};

//on creation or regeneration flow, if we save invoice we want also to apply this changes to all
//invoices in schedule
export const buildInvoicesFromChangedDraftInvoice = ({ invoices, applyToAll = true, invoiceChanges, savedInvoice }) => {
  const savedInvoiceWithData = getInvoiceData(savedInvoice);

  return invoices?.map((invoice) => {
    const changes = getInvoiceData(invoiceChanges);

    clearInvoiceItemsForBulkEdit({ invoiceChanges: changes, invoice });

    if (invoice?.unsavedId === savedInvoice?.unsavedId) {
      return savedInvoiceWithData;
    } else {
      return applyToAll ? { ...invoice, ...changes } : invoice;
    }
  });
};

export const getClearedInvoiceChanges = ({ invoiceChanges }) => {
  const changes = structuredClone(invoiceChanges);

  // remove fields that should be uniq
  delete changes?.invoice_number;
  delete changes?.paid_at;
  delete changes?.date;
  delete changes?.id;
  delete changes?.send_date;
  delete changes?.sent_at;
  delete changes?.invoice_payment_link;

  // we apply payment_options to other invoices on the backend in invoices service.update
  delete changes?.payment_options;

  return changes;
};

export const getInvoicesForBulkEdit = ({ ignoreCurrent, invoices, changes, currentInvoice }) => {
  return invoices
    ?.filter(
      (invoiceForFilter) =>
        (!invoiceForFilter.sent_at &&
          !invoiceForFilter.paid_at &&
          (ignoreCurrent ? invoiceForFilter?.id && invoiceForFilter?.id !== currentInvoice?.id : true)) ||
        (!ignoreCurrent && invoiceForFilter?.id && invoiceForFilter?.id === currentInvoice?.id),
    )
    ?.map((invoice) => {
      const currentInvoiceData = getInvoiceData(currentInvoice);

      if (invoice?.id === currentInvoice?.id) {
        return currentInvoiceData;
      } else {
        const invoiceChanges = getInvoiceData(changes);

        clearInvoiceItemsForBulkEdit({ invoiceChanges, invoice });

        return { id: invoice?.id, ...invoiceChanges };
      }
    });
};

export const getBilledAmount = ({ invoices, creditNotes, specificTransactionId }) => {
  const creditNoteAllocationsByInvoiceId =
    creditNotes?.reduce((acc, creditNote) => {
      for (const [invoiceId, amount] of Object.entries(creditNote?.allocations ?? {})) {
        acc[invoiceId] = (acc[invoiceId] ?? 0) + amount;
      }
      return acc;
    }, {}) ?? {};

  const creditNotesByTransactionId =
    creditNotes?.reduce((acc, creditNote) => {
      for (const item of creditNote?.items ?? []) {
        acc[item.transaction_id] = (acc[item.transaction_id] ?? 0) + item.amount;
      }
      return acc;
    }, {}) ?? {};

  const amounts = invoices.reduce(
    (acc, invoice) => {
      if (invoice.voided_at) return acc; // ignore voided invoices
      for (const invoiceItem of invoice.invoice_items ?? []) {
        if (Object.values(INVOICE_ITEM_TYPES).includes(invoiceItem.type)) continue; // ignore invoice_items that are tax, shipping, or fee items

        if (specificTransactionId && invoiceItem.transaction_id !== specificTransactionId) continue;

        const amount = invoiceItem.amount ?? 0;
        acc.total += amount;
        acc[invoiceItem.transaction_id] = (acc[invoiceItem.transaction_id] ?? 0) + amount;
      }
      acc.total -= creditNoteAllocationsByInvoiceId[invoice.id] ?? 0;
      return acc;
    },
    { total: 0 },
  );

  // Remove the allocated amount from the billed amount
  for (const [transactionId, amount] of Object.entries(creditNotesByTransactionId)) {
    if (amounts[transactionId]) amounts[transactionId] -= amount;
  }

  return amounts;
};

export const getTransactionAmountByRecognition = ({ transaction }) =>
  transaction.recognition === RECOGNITION_TYPES.tillCanceled
    ? transaction.recurring_amount
    : transaction.recognition === RECOGNITION_TYPES.eventRecurring
    ? transaction.accounting_spreads_total_amount
    : transaction.amount ?? 0; // for linear and non-recurring

export const getTransactionsAmount = ({ includedTransactions }) =>
  includedTransactions?.reduce(
    (acc, transaction) => {
      const amount = getTransactionAmountByRecognition({ transaction });
      acc.total += amount;
      acc[transaction.id] = amount;
      return acc;
    },
    { total: 0 },
  );

export const getDisplayNameByTransactionId = ({ includedTransactions }) =>
  includedTransactions?.reduce(
    (acc, transaction) => Object.assign(acc, { [transaction.id]: transactionDisplayNameForBilling(transaction) }),
    {},
  );

export const isProductBundle = (productMetadata) => productMetadata?.bundleItems?.length > 0;
