import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AppContext } from 'AppContext';
import { ACCOUNTING_SPREAD_REASON } from 'views/Spreads/utils';
import { useAccountingSpreadsAPI } from 'api/accountingSpreads/hooks';
import { SOURCE_TYPES } from '../TransactionsCsvUpload/consts';
import { SpreadsCsvUpload } from '../EventBasedSpreadsCsvUpload/SpreadsCsvUpload';
import { DEFAULT_ACCOUNTING_SPREADS_MAPPER } from './consts';

dayjs.extend(utc);

export const AccountingSpreadsCsvUpload = () => {
  const { organizations } = useContext(AppContext);

  const {
    operations: { bulkUpdateAccountingSpreads },
  } = useAccountingSpreadsAPI({
    orgId: organizations[0].id,
  });

  const history = useHistory();

  const [submittingForm, setSubmittingForm] = useState(false);

  const handleSubmit =
    ({ transactions, transactionsIdSourceAndType }) =>
    async (values) => {
      setSubmittingForm(true);
      const accountingSpreads = values?.map((row) => {
        const transaction =
          transactionsIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
            ? transactions?.find((transaction) => transaction?.id === row?.transaction_id)
            : transactions?.find((transaction) => transaction?.provider_object_id === row?.transaction_id);

        const customerId = transaction?.customer_id ?? transaction?.transaction_customer_id;

        return {
          customer_id: customerId,
          transaction_id: row?.transaction_id,
          amount: row?.amount,
          date: dayjs.utc(row?.date).format('YYYY-MM-DD'),
          reason: ACCOUNTING_SPREAD_REASON.OVERRIDE_AMOUNT,
        };
      });

      await bulkUpdateAccountingSpreads({
        data: accountingSpreads,
      });

      setTimeout(() => {
        history.push('/transactions');

        setSubmittingForm(false);
      }, 1000);
    };

  return (
    <SpreadsCsvUpload
      handleSubmit={handleSubmit}
      submittingForm={submittingForm}
      entityName="Accounting-Spreads"
      defaultMapper={DEFAULT_ACCOUNTING_SPREADS_MAPPER}
      eventRecognitionsOnly={false}
    />
  );
};
