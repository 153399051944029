import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
import defaultCalendarIcon from 'images/calendar-icon.svg';
import { Flexer, FlexerColumn } from 'components/Core';
import { ArrowBoldDownIcon } from 'components/Icons';
import { StyledInputCSS } from 'components/Controls/styles';

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StyledDatePicker = styled(ReactDatePicker)`
  ${StyledInputCSS}

  pointer-events: ${(props) => props.isOpen && 'none'};
  height: ${(props) => props.height};
  width: 100%;
  border-radius: 8px;
  padding: 0 12px;
  cursor: ${(props) => !props.disabled && 'pointer'};
  ${({ hideCalendarIcon, calendarIcon }) =>
    !hideCalendarIcon ? `background-image: url(${calendarIcon ? calendarIcon : defaultCalendarIcon});` : ''}
  background-repeat: no-repeat;
  background-position: right 11px center;

  ::placeholder {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: var(--primaryBlack30);
  }
`;

export const ControlButton = styled.button`
  background-color: initial;
  color: var(--primaryBlack);
  border: none;
  min-width: 30px;
  font-size: 28px;
  line-height: 28px;
  cursor: pointer;
`;

export const CustomHeader = styled.div`
  background: var(--accentGrayFourth);
  padding: 10px 5px;
  border-top-left-radius: 12px;
  border-top-right-radius: ${({ showTimeSelect }) => (showTimeSelect ? '0px' : '12px')};
  display: flex;
  justify-content: space-between;
`;

export const HeaderContent = styled.div`
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  padding: 6px 10px;
  background: #ffffff;
  box-shadow: 4px 4px 28px var(--primaryBlack5);
  border-radius: 4px;
`;

export const FilterHeadTime = styled(Flexer)`
  gap: 8px;
  background: ${({ disabled }) => (disabled ? 'var(--primaryBlack5)' : 'rgba(0, 133, 255, 0.1)')};
  border-radius: 4px;
  color: ${({ disabled }) => (disabled ? 'var(--primaryBlack)' : 'var(--primaryBlue)')};
  cursor: ${({ disabled }) => (disabled ? undefined : 'pointer')};
  font-weight: 700;
  margin: 0 8px;
  padding: 3px 4px;
  position: relative;

  &:hover {
    color: ${({ disabled }) => (disabled ? undefined : 'var(--secondaryBlue)')};
    background: ${({ disabled }) => (disabled ? undefined : 'rgba(0, 133, 255, 0.2)')};
  }

  &:focus {
    color: ${({ disabled }) => (disabled ? undefined : 'var(--secondaryBlue)')};
    background: ${({ disabled }) => (disabled ? undefined : 'rgba(0, 133, 255, 0.2)')};
  }
`;

export const DropdownHeadTime = styled(Flexer)`
  color: var(--primaryBlue);
  cursor: pointer;
  font-size: 20px;
  font-weight: 900;
  line-height: 26px;
`;

export const DropdownHeadTimeArrow = styled(ArrowBoldDownIcon)`
  margin: 5px 0 0 8px;
`;

export const DatePickerLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DatepickerLabel = styled.label`
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
  cursor: pointer;
  opacity: 0.4;
`;

export const PresetsWrapper = styled(FlexerColumn)`
  padding: 16px;
`;

export const PresetOption = styled(Flexer)`
  border: 1px solid var(--accentGraySecond);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 12px;
  cursor: pointer;

  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  background: ${({ selected }) => selected && 'var(--primaryBlack2)'};

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--primaryBlack30);
    margin: 0 8px;
  }

  :hover {
    background: var(--primaryBlack2);
  }
`;
