/* eslint-disable */
// TODO: ^ Remove this by the end of the full implementation of Usage-based engine
import React, { useContext, useMemo, useCallback, useState, useEffect } from 'react';
import { useGlobalFilter, useTable, usePagination, useRowSelect, useSortBy } from 'react-table';
import { difference, omitBy, isEmpty, isUndefined } from 'lodash';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { AppContext } from 'AppContext';
import { ReactComponent as GreyDot } from 'images/medium-grey-dot.svg';
import { TableColumnSettings } from 'shared/Common';
import { FilePlus } from 'components/Icons';
import { InlineButton } from 'components/Buttons';
import { Spacer, FlexerColumn } from 'components/Core';
import { customGlobalFilter, RowActionPopover, TableActionCell } from 'components/Table';
import { useInvoicingScheduleModal } from 'views/Billing/InvoicingScheduleModal';
import { useUsageSubscriptionsAPI } from 'api/usageBasedEngine';
import { usePagination as usePaginationButtons, useStateWithStorage } from 'utils/hooks';
import { SubscriptionsTable } from './SubscriptionsTable';
import { CustomerField, FadedText, NewSubscriptionButton, StyledLink } from './styles';
import { SUBSCRIPTION_TABLE_COLUMN_TITLES_BY_ID } from './consts';
import { SUBSCRIPTION_EDIT_MODAL_MODE, useSubscriptionEditModal } from './SubscriptionEditModal';
import { useSubscriptionDeleteModal } from './SubscriptionDeleteModal';
import { TableContainerLayout } from '../../Common/TableContainerLayout';
import { useEditPricingPlanModal } from '../PricingPlans/PricingPlanModal/EditPricingPlanModal';

const InvoiceCellContent = ({ invoicingFrequency, transactions, openInvoicingScheduleModal }) => {
  const invoiceScheduleId = transactions[0]?.invoicing_schedule_id;

  return transactions?.length > 0 ? (
    <StyledLink
      onClick={() =>
        openInvoicingScheduleModal(
          invoiceScheduleId
            ? {
                invoicingSchedule: { id: invoiceScheduleId },
              }
            : {
                includedTransactions: transactions,
                options: {
                  defaultFrequency: invoicingFrequency,
                  autoSetAutoCharge: true,
                },
              },
        )
      }
    >
      {invoiceScheduleId ? 'Open Invoice' : 'Create Invoice'}
    </StyledLink>
  ) : (
    <FadedText>Requires a transaction to create invoice</FadedText>
  );
};

export const SubscriptionsTableContainer = ({ customer }) => {
  const { orgId, formatDateWithLocale } = useContext(AppContext);

  const { openModal: openEditModal, SubscriptionEditModal } = useSubscriptionEditModal();
  const { openModal: openDeleteModal, SubscriptionDeleteModal } = useSubscriptionDeleteModal();

  const [searchQuery, setSearchQuery] = useState('');
  // TODO: Pass this to backend to handle sorting
  const [sortBy, setSortBy] = useState();

  const { currentPageIndex, setCurrentPageIndex, pageSize, setPageSize } = usePaginationButtons({
    cacheSuffix: 'usage-subscriptions-page',
  });

  const {
    data,
    isFetching,
    operations: {
      refetch: refetchUsageSubscriptions,
      bulkDeleteUsageSubscriptions,
      createTransactionsForUsageSubscriptions,
    },
  } = useUsageSubscriptionsAPI({
    orgId,
    params: omitBy(
      {
        'filters[customerIds]': customer ? [customer?.id] : undefined,
        'filters[searchQuery]': searchQuery || undefined,
        'pagination[page]': currentPageIndex,
        'pagination[limit]': pageSize,
        scopes: ['pagination_data', 'pricing_plans', 'customers', 'pricing_plan_tiers', 'transactions', 'discounts'],
        orderBy: ['updated_at', 'DESC'],
      },
      isUndefined,
    ),
  });

  const dataForTable = useMemo(() => {
    return (
      data?.data?.map((d) => ({
        ...d,
        // We use de-flattened data in modals
        pricing_plan_name: d.pricing_plan.name,
        customer_name: d.customer.name,
      })) ?? []
    );
  }, [data]);

  const { openModal: openEditPricingPlanModal, EditPricingPlanModal } = useEditPricingPlanModal();
  const { Modal: InvoicingScheduleModal, openModal: openInvoicingScheduleModal } = useInvoicingScheduleModal({
    onClose: refetchUsageSubscriptions,
  });

  const [creatingTransactionsForSubscriptions, setCreatingTransactionsForSubscriptions] = useState(false);
  const handleCreateTransactionsClick = async ({ subscription }) => {
    setCreatingTransactionsForSubscriptions(true);
    await createTransactionsForUsageSubscriptions.mutateAsync({ id: subscription.id });
    setCreatingTransactionsForSubscriptions(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: ({ getToggleAllPageRowsSelectedProps }) => <Checkbox {...getToggleAllPageRowsSelectedProps()} />,
        accessor: 'checkbox',
        id: 'checkbox',
        width: 32,
        Cell: ({ row }) => (
          <Checkbox
            checked={row.isSelected}
            onClick={() => {
              row.toggleRowSelected();
            }}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Customer',
        accessor: 'customer_name',
        id: 'customer_name',
        width: 150,
        Cell: ({ row }) => (
          <CustomerField>
            <InlineButton onClick={() => window.open(`/customers/${row.original.customer_id}`, '_blank')} isSecondary>
              {row.original.customer.name}
            </InlineButton>
          </CustomerField>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Pricing Plan',
        accessor: 'pricing_plan',
        id: 'pricing_plan',
        width: 150,
        Cell: ({ row }) => (
          <StyledLink onClick={() => openEditPricingPlanModal({ pricingPlan: row.original.pricing_plan })}>
            {row.original.pricing_plan?.name}
          </StyledLink>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Start Date',
        accessor: 'start_date',
        id: 'start_date',
        width: 150,
        Cell: ({ row }) => (
          <>
            {row.original.start_date ? (
              formatDateWithLocale(row.original.start_date)
            ) : (
              <FadedText>No start date</FadedText>
            )}
          </>
        ),
      },
      {
        Header: 'End Date',
        accessor: 'end_date',
        id: 'end_date',
        width: 150,
        Cell: ({ row }) => (
          <>
            {row.original.end_date ? formatDateWithLocale(row.original.end_date) : <FadedText>No end date</FadedText>}
          </>
        ),
      },
      {
        Header: 'Trial Units',
        accessor: 'trial_units',
        id: 'trial_units',
        width: 100,
        Cell: ({ row }) => <>{row.original.trial_units}</>,
      },
      {
        Header: 'Invoice',
        accessor: 'invoice',
        id: 'invoice',
        width: 100,
        Cell: ({ row }) => (
          <InvoiceCellContent
            invoicingFrequency={row.original.pricing_plan.invoicing_frequency}
            customerId={row.original.customer_id}
            transactions={row.original.transactions}
            refetchUsageSubscriptions={refetchUsageSubscriptions}
            openInvoicingScheduleModal={openInvoicingScheduleModal}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Transactions',
        accessor: 'transactions',
        id: 'transactions',
        width: 200,
        Cell: ({ row }) =>
          isEmpty(row.original.transactions) ? (
            creatingTransactionsForSubscriptions ? (
              <FadedText>Creating new transactions... </FadedText>
            ) : (
              <>
                <FadedText>No transaction associated. </FadedText>
                <StyledLink
                  data-cy={`subscriptions-table__transaction-create`}
                  key={row.original.id}
                  onClick={() => handleCreateTransactionsClick({ subscription: row.original })}
                >
                  Create transactions
                </StyledLink>
              </>
            )
          ) : (
            <FlexerColumn>
              {row.original.transactions.map((transaction) => (
                <StyledLink
                  data-cy={`subscriptions-table__transaction--${transaction.name}`}
                  key={transaction.id}
                  target="_blank"
                  href={`/transactions/${transaction.id}`}
                >
                  {transaction.name}
                </StyledLink>
              ))}
            </FlexerColumn>
          ),
        disableSortBy: true,
      },
      {
        accessor: 'actions',
        id: 'actions',
        width: 65,
        Cell: ({ row }) => (
          <TableActionCell>
            <RowActionPopover
              currentSelection={row.original}
              onEditClick={() => {
                openEditModal({ selectedSubscription: row.original, mode: SUBSCRIPTION_EDIT_MODAL_MODE.EDIT });
              }}
              onDeleteClick={() => {
                openDeleteModal({ selectedSubscription: row.original });
              }}
              dataCyPrefix={'usage-subscriptions'}
            />
          </TableActionCell>
        ),
        disableSortBy: true,
      },
    ],
    [creatingTransactionsForSubscriptions],
  );

  const [hiddenColumns, setHiddenColumns] = useStateWithStorage('subscriptions-table-hidden-columns', []);

  const tableProps = useTable(
    {
      columns,
      data: dataForTable,
      getSubRows: useCallback((row) => row.subRows || [], []),
      globalFilter: customGlobalFilter,
      manualPagination: true,
      manualSortBy: true,
      manualGlobalFilter: true,
      initialState: {
        hiddenColumns,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  // These values are returned from the useTable hook, but we need to store them in state
  // For this reason, we have these useEffect hooks
  useEffect(() => {
    const hiddenColumns = difference(
      columns.map((c) => c.id),
      tableProps.visibleColumns.map((vc) => vc.id),
    );

    setHiddenColumns(hiddenColumns);
  }, [columns, tableProps.visibleColumns, setHiddenColumns]);

  useEffect(() => {
    setSortBy(tableProps.state.sortBy);
  }, [tableProps.state.sortBy]);

  return (
    <>
      <TableContainerLayout
        columnsSettingsComponent={
          <>
            <NewSubscriptionButton
              onClick={() => {
                openEditModal({
                  selectedSubscription: customer
                    ? { customer_name: customer.name, customer_id: customer.id }
                    : undefined,
                  mode: SUBSCRIPTION_EDIT_MODAL_MODE.CREATE,
                });
              }}
            >
              <FilePlus />
              <Spacer width="4px" />
              <div style={{ width: 105 }}> New Subscription </div>
            </NewSubscriptionButton>
            <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />
            <TableColumnSettings
              tableName="subscriptions"
              numberValue={pageSize}
              handleShowResultsChange={(option) => setPageSize(option.value)}
              columnsTitles={SUBSCRIPTION_TABLE_COLUMN_TITLES_BY_ID}
              allColumns={tableProps.allColumns}
            />
          </>
        }
        setSearchQuery={setSearchQuery}
        isFetching={isFetching}
        rowsCount={data?.metadata?.totalCount}
        currentPageIndex={currentPageIndex ?? 1}
        setCurrentPageIndex={setCurrentPageIndex}
        pageCount={data?.metadata?.maxPage ?? 0}
        placeholder="Search by customer or pricing plan"
      >
        <SubscriptionsTable tableProps={tableProps} bulkDeleteUsageSubscriptions={bulkDeleteUsageSubscriptions} />
      </TableContainerLayout>

      <SubscriptionEditModal />
      <SubscriptionDeleteModal />
      <EditPricingPlanModal />
      <InvoicingScheduleModal />
    </>
  );
};
