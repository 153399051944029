import { useContext } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { TableActionButton } from 'components/Buttons';
import { ReactComponent as DangerIcon } from 'images/alert-circle-csv.svg';
import { ReactComponent as MailForward } from 'images/mail-forward.svg';
import { ReactComponent as NotAutoSendBackground } from 'images/reminder-banner__not-autosend.svg';
import { ReactComponent as AutoSendBackground } from 'images/reminder-banner__autosend.svg';
import { Row } from 'components/Core';
import { CONFIGURATION_TABS } from 'views/Configuration/consts';
import { BILLING_SUB_TABS } from 'views/Configuration/Tabs/SubscriptBillingTab/SubTabsHeader/SubTabsHeader';

import { BillingContext } from '../BillingContext';

dayjs.extend(require('dayjs/plugin/utc'));

const ReminderBannerWrapper = styled.div`
  padding: 4px;
  padding-right: 8px;
  position: relative;
  overflow: hidden;
  border-radius: 100px;
  border: 1px solid var(--primaryBlack5);
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;

  ${({ invoiceModalView }) =>
    !invoiceModalView &&
    `
    position: absolute;
    bottom: 12px;
    left: 17px;
  `}

  ${({ invoiceModalView, autoSendEnabled }) =>
    invoiceModalView &&
    `
    border: ${autoSendEnabled ? '1px solid var(--primaryBlue20)' : '1px solid var(--newRed20)'};
    background: white;
    box-shadow: 0px 8px 40px 0px rgba(255, 93, 93, 0.01);
    padding: 8px;
  `}

  span {
    opacity: 0.7;
    margin-right: 4px;
  }

  &:hover {
    border: ${({ invoiceModalView }) => !invoiceModalView && '1px solid var(--primaryBlue10)'};
    background: ${({ invoiceModalView }) => !invoiceModalView && 'var(--primaryBlue5)'};
  }
`;

const CommunicationsCounter = styled.div`
  width: 20px;
  min-width: 20px;
  margin-left: 4px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: var(--primaryBlue);
  background: var(--primaryBlue10);
  font-size: 10px;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
`;

const AutoSendButton = styled.div`
  margin-left: 12px;
  font-weight: 700;
  color: var(--primaryBlue);
  cursor: pointer;

  &:hover {
    color: var(--secondaryBlue);
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  height: 48px;
`;

const ModalViewInfoButton = styled.div`
  padding: 8px;
  padding-right: 12px;
  border-radius: 100px;
  background-color: ${({ autoSendEnabled }) => (autoSendEnabled ? 'var(--primaryBlue10)' : 'var(--newRed10)')};
  font-size: 12px;
  cursor: pointer;
  font-style: italic;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 16px;

  color: ${({ autoSendEnabled }) => (autoSendEnabled ? 'var(--primaryBlue)' : 'var(--tertiaryRed)')};
  margin-right: 12px;

  svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;

    path {
      fill: ${({ autoSendEnabled }) => autoSendEnabled && 'var(--primaryBlue)'};
    }

    g {
      opacity: 1;
    }
  }

  &:hover {
    background-color: ${({ autoSendEnabled }) => (autoSendEnabled ? 'var(--primaryBlue20)' : 'var(--newRed20)')};
    color: ${({ autoSendEnabled }) => (autoSendEnabled ? 'var(--secondaryBlue)' : 'var(--secondaryRed)')};
  }
`;

const RemindTableActionButton = styled(TableActionButton)`
  border-radius: 100px;
  margin-right: 12px;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: 4px 8px;

  &:hover {
    background-color: var(--primaryBlue20);
  }
`;

export const RemindersBanner = ({ invoice, invoiceModalView, onInvoiceClick }) => {
  const { orgConfigs, formatDateWithLocale } = useContext(AppContext);
  const { openInvoicingScheduleModal } = useContext(BillingContext);

  const { billingReminderDefaults } = orgConfigs;

  const nextReminder = invoice?.reminders?.find((reminder) => reminder.id === invoice?.next_reminder_id);

  const autoSendEnabled = nextReminder?.auto_send;

  const hasRemindersToSend = invoice.reminders.some((reminder) => !reminder.sent_at && !reminder.dismissed_at);

  return (
    <ReminderBannerWrapper
      data-cy={`${invoiceModalView ? 'modal' : 'table'}-reminder-banner`}
      autoSendEnabled={autoSendEnabled}
      invoiceModalView={invoiceModalView}
      onClick={() => {
        if (autoSendEnabled && !billingReminderDefaults?.enabled) {
          window
            .open(
              `${window.location.origin}/configure?tab=${CONFIGURATION_TABS.SUBSCRIPT_BILLING}&subTab=${BILLING_SUB_TABS.COLLECTION_SEQUENCE}`,
              '_blank',
            )
            .focus();
        } else {
          hasRemindersToSend &&
            onInvoiceClick({
              id: invoice?.id,
              invoicing_schedule_id: invoice?.invoicing_schedule_id,
              selectedReminder: nextReminder,
            });
        }
      }}
    >
      {invoiceModalView ? (
        <>
          <ModalViewInfoButton autoSendEnabled={autoSendEnabled && billingReminderDefaults?.enabled}>
            {autoSendEnabled && billingReminderDefaults?.enabled ? <MailForward /> : <DangerIcon />}

            {autoSendEnabled && billingReminderDefaults?.enabled
              ? `Subscript will remind on ${dayjs.utc(invoice?.reminder_date).format('MMM DD, YYYY')} for this invoice`
              : autoSendEnabled && !billingReminderDefaults?.enabled
              ? `Reminders turned off in settings`
              : `Remind on ${dayjs.utc(invoice?.reminder_date).format('MMM DD, YYYY')} for this invoice`}
          </ModalViewInfoButton>

          <BackgroundImage>{autoSendEnabled ? <AutoSendBackground /> : <NotAutoSendBackground />}</BackgroundImage>
        </>
      ) : (
        <>
          {' '}
          {hasRemindersToSend ? (
            <RemindTableActionButton
              data-cy={`invoices-table__remind-customer--${invoice?.customer_id}`}
              onClick={() =>
                onInvoiceClick({
                  id: invoice?.id,
                  invoicing_schedule_id: invoice?.invoicing_schedule_id,
                  selectedReminder: nextReminder,
                })
              }
              active
            >
              Remind
            </RemindTableActionButton>
          ) : null}
        </>
      )}

      <Row style={{ marginRight: 12 }}>
        <span>Days overdue: </span>
        {invoice?.days_overdue},
      </Row>

      <Row style={{ marginRight: 12 }}>
        <span>Last Reminder Email:</span>
        <b>{invoice?.last_communication ? formatDateWithLocale(invoice?.last_communication) : 'No records'}</b>
        <CommunicationsCounter>{invoice?.last_communication_count}</CommunicationsCounter>
      </Row>

      {!invoiceModalView && invoice?.reminder_date && hasRemindersToSend && (
        <div>
          <span>Next reminder:</span>{' '}
          {autoSendEnabled
            ? !billingReminderDefaults?.enabled
              ? `Enable reminders in settings to auto-send on `
              : `Will auto-send on `
            : ''}
          {formatDateWithLocale(invoice.reminder_date)}
        </div>
      )}

      {!autoSendEnabled && invoice?.next_reminder_id && hasRemindersToSend && (
        <AutoSendButton
          data-cy={`invoices-table__remind-customer--${invoice?.customer_id}__auto-send`}
          onClick={(event) => {
            event?.stopPropagation();

            openInvoicingScheduleModal({
              invoicingSchedule: { id: invoice?.invoicing_schedule_id },
              invoice,
              selectedReminder: { id: invoice.next_reminder_id },
            });
          }}
        >
          Enable auto-sending
        </AutoSendButton>
      )}
    </ReminderBannerWrapper>
  );
};
