import { useContext } from 'react';
import styled from 'styled-components';

import { FlexerColumn, FlexBetweenContainer, Flexer } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { ActionButton } from 'views/Billing/Common/ActionButton';
import { ReactComponent as TrashIcon } from 'images/trash.svg';

import { ImportInvoiceLine } from './ImportInvoiceLine';
import { useSelectImportInvoicesModal } from './useSelectImportInvoicesModal';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';

const Wrapper = styled(FlexerColumn)`
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  z-index: 30;
`;

const Header = styled(FlexBetweenContainer)`
  margin-bottom: 12px;
`;

const HeaderText = styled.div`
  font-style: italic;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
`;

const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
  opacity: 0.3;
  width: 18px;
  height: 18px;
  margin-top: 8px;

  path {
    fill: var(--primaryBlack);
  }

  &:hover {
    opacity: 1;
  }
`;

export const ImportInvoiceList = ({ importedInvoices, onRemoveInvoice, onImportInvoicesSelect }) => {
  const { customer, isScheduleDraft } = useContext(InvoicingScheduleContext);
  const { openModal, Modal: SelectImportInvoicesModal } = useSelectImportInvoicesModal({
    onImportInvoicesSelect,
    selectedInvoices: isScheduleDraft ? importedInvoices : [],
  });

  return (
    <Wrapper>
      <Header>
        <HeaderText>Invoices Imported</HeaderText>
        <TooltipContainer toolTipContent="Please select a customer" isVisible={!customer?.id} width={120}>
          <ActionButton
            disabled={!customer?.id}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              customer?.id && openModal({ customerId: customer.id });
            }}
          >
            Import Invoice(s)
          </ActionButton>
        </TooltipContainer>
      </Header>

      {importedInvoices.map((invoice) => (
        <Flexer width="100%" marginBottom="12px" key={invoice.id ?? invoice.external_id}>
          <ImportInvoiceLine externalInvoice={invoice} />
          {onRemoveInvoice && <StyledTrashIcon onClick={() => onRemoveInvoice(invoice)} />}
        </Flexer>
      ))}

      <SelectImportInvoicesModal />
    </Wrapper>
  );
};
