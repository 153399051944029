import React, { useState } from 'react';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
import { Centerer, FlexerColumn, FlexerRow, Spacer } from 'components/Core';
import { AIBetaTagIcon, ArrowNarrowLeftIcon } from 'components/Icons';
import { TextBox } from 'components/Core/Text';
import { ReactComponent as CheckCircle } from 'images/check.svg';

import { EmptyStateText } from './styles';
import { NumberCircle } from '../styles';

// https://github.com/wojtekmaj/react-pdf/wiki/Known-issues#create-react-app
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const IconWrapper = styled(Centerer)`
  padding: 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid var(--accentGraySecond);
`;

const MainText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
`;

const PDFWrapper = styled(Centerer)`
  width: 100%;
  max-width: calc(100% - 2em);
  height: 100%;
  margin: 1em 0;
  overflow-y: auto;

  // From example: https://github.com/wojtekmaj/react-pdf/blob/main/sample/create-react-app-5/src/Sample.css
  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .react-pdf__Page {
    margin: 1em 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }
`;

export const PreviewFile = ({ file, uploadNew }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  return !file ? (
    uploadNew ? (
      <FlexerColumn width="100%" height="100%" gap="24px" alignItems="center" justifyContent="center">
        <FlexerColumn width="300px" gap="8px" alignItems="flex-start">
          <AIBetaTagIcon />
          <Spacer height="10px" />
          <div style={{ textAlign: 'left' }}>
            <TextBox bold italic fontSize="20px" color="var(--primaryBlack30)">
              Create Transactions and invoices with
            </TextBox>
            <TextBox bold italic fontSize="20px">
              Subscript AI
            </TextBox>
          </div>
          <Spacer height="10px" />
          <FlexerRow alignItems="center">
            <NumberCircle inverted>1 </NumberCircle>
            <Spacer width="4px" />
            <TextBox italic>Upload contract files</TextBox>
          </FlexerRow>
          <Spacer height="5px" />
          <FlexerRow alignItems="center">
            <NumberCircle inverted>2 </NumberCircle>

            <Spacer width="4px" />
            <TextBox italic>Generate new transactions and invoices</TextBox>
          </FlexerRow>
          <Spacer height="5px" />
          <FlexerRow alignItems="center">
            <CheckCircle />
            <Spacer width="4px" />
            <TextBox italic> Check your data!</TextBox>
          </FlexerRow>
        </FlexerColumn>
      </FlexerColumn>
    ) : (
      <FlexerColumn width="100%" height="100%" gap="24px" alignItems="center" justifyContent="center">
        <IconWrapper>
          <ArrowNarrowLeftIcon />
        </IconWrapper>

        <FlexerColumn width="220px" gap="8px" alignItems="center">
          <MainText>Add an attachment</MainText>
          <EmptyStateText>The preview section will be available after at least 1 file has been added.</EmptyStateText>
        </FlexerColumn>
      </FlexerColumn>
    )
  ) : (
    <FlexerColumn width="100%" height="100%" alignItems="center" justifyContent="center">
      <Spacer height="42px" />
      <PDFWrapper>
        {file.data ? (
          <Document file={file.data} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (_, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        ) : file.file_private_url ? (
          <div>
            <MainText>Private URL</MainText>
            <EmptyStateText>Preview is not available for private URLs.</EmptyStateText>
            <a href={file.file_private_url} target="_blank" rel="noreferrer">
              Open file in new tab
            </a>
          </div>
        ) : (
          'No PDF preview available. Please contact Subscript team for assistance.'
        )}
      </PDFWrapper>
    </FlexerColumn>
  );
};
