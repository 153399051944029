import { useContext } from 'react';
import { AppContext } from 'AppContext';
import { capitalize } from 'utils/stringUtils';
import { COHORT_BY_OPTIONS, INSTALL_BY_OPTIONS } from 'views/Cohorts/consts';
import { GROWTH_TYPE_KEYS } from 'consts/global';
import { deserializeDataFilter, serializeDataFilter, updateDataFilter } from './useDataFilter';
import { useUrlStates } from './useUrlStates';

const GLOBAL_TOGGLES = [
  'isARR',
  'isCommitted',
  'rollup',
  'optimisticAnalytics',
  'countInfluxAsRenewed',
  'exchangeRatesType',
  'exchangeRatesDate',
];

// pageStates: States that come AppContext
// customAppSettingsStates: States that come from AppSettings
export const PAGE_CONTEXT_VALUES = {
  snapshot: {
    key: 'snapshot',
    pageStates: [...GLOBAL_TOGGLES, 'dataFilter', 'metadataFilter', 'isIncreases'],
    customAppSettingsStates: {
      collapseRows: 'dashboard.collapseRows',
      snapshotSelectedColumns: 'dashboard.businessSnapshot.columns',
      snapshotTableRows: 'dashboard.businessSnapshot.rows',
      metricsSetting: 'dashboard.metrics',
    },
    dataFilter: ['period', 'startMonth', 'endMonth'],
  },
  waterfall: {
    key: 'waterfall',
    pageStates: [...GLOBAL_TOGGLES, 'dataFilter', 'metadataFilter', 'isIncreases', 'waterfallGraphPeriod'],
    customAppSettingsStates: {
      snapshotSelectedColumns: 'dashboard.businessSnapshot.columns',
      showWaterFallExistingRow: 'dashboard.dataSnapshot.showExistingRow',
      showInfluxRow: 'dashboard.dataSnapshot.showInfluxRow',
      showInfluxMovementRow: 'dashboard.dataSnapshot.showInfluxMovementRow',
    },
    dataFilter: ['period', 'startMonth', 'endMonth', 'waterfallType', 'waterfallSegmentBy'],
  },
  transactions: {
    key: 'transactions',
    pageStates: [...GLOBAL_TOGGLES, 'dataFilter', 'metadataFilter'],
    customAppSettingsStates: {},
    dataFilter: ['transactionsConfirmation'],
  },
  cohort: {
    key: 'cohort',
    pageStates: [...GLOBAL_TOGGLES, 'dataFilter', 'metadataFilter'],
    customAppSettingsStates: {},
    dataFilter: [
      'period',
      'startMonth',
      'endMonth',
      'resolution',
      'revenueTypes',
      'cohortSegmentBy',
      'cohortInstallBy',
      'cohortInstallSecondBy',
    ],
  },
  revenue_details: {
    key: 'revenue_details',
    pageStates: [...GLOBAL_TOGGLES, 'dataFilter', 'metadataFilter'],
    customAppSettingsStates: {},
    dataFilter: ['revenueMonth'],
  },
  revenue_spread: {
    key: 'revenue_spread',
    pageStates: [...GLOBAL_TOGGLES, 'dataFilter', 'metadataFilter'],
    customAppSettingsStates: {},
    dataFilter: ['period', 'startMonth', 'endMonth', 'revenueTypes', 'spreadSegmentBy'],
  },
  chart_visualizer: {
    key: 'chart_visualizer',
    pageStates: [...GLOBAL_TOGGLES, 'dataFilter'],
    customAppSettingsStates: {},
  },
  customers_list: {
    key: 'customers_list',
    pageStates: [...GLOBAL_TOGGLES, 'dataFilter', 'metadataFilter'],
    customAppSettingsStates: {},
  },
  invoices_list: {
    key: 'invoices_list',
    pageStates: [...GLOBAL_TOGGLES, 'dataFilter', 'metadataFilter'],
    customAppSettingsStates: {},
  },
  dashboard_chart: {
    key: 'dashboard_chart',
    pageStates: [...GLOBAL_TOGGLES, 'dataFilter'],
    customAppSettingsStates: {},
  },
};

export const DEFAULT_STORAGE_VALUE = {
  installBy: INSTALL_BY_OPTIONS.revenueDollar,
  cohortBy: COHORT_BY_OPTIONS.subscriptionDate,
  segmentBy: null,
  growthType: GROWTH_TYPE_KEYS.MoM,
};

export const usePageContext = ({ page, includeInUrl = {} }) => {
  const {
    dataFilter,
    setDataFilter,
    metadataFilter,
    setMetadataFilter,
    appSettings: {
      isARR,
      isIncreases,
      isCommitted,
      optimisticAnalytics,
      countInfluxAsRenewed,
      rollup,
      exchangeRatesType,
      exchangeRatesDate,
      setIsARR,
      setIsIncreases,
      setRollup,
      setIsCommitted,
      setOptimisticAnalytics,
      setCountInfluxAsRenewed,
      setExchangeRatesType,
      setExchangeRatesDate,
    },
  } = useContext(AppContext);

  const globalStates = {
    isARR: [isARR, setIsARR],
    optimisticAnalytics: [optimisticAnalytics, setOptimisticAnalytics],
    rollup: [rollup, setRollup],
    exchangeRatesType: [exchangeRatesType, setExchangeRatesType],
    exchangeRatesDate: [exchangeRatesDate, setExchangeRatesDate],
    isIncreases: [isIncreases, setIsIncreases],
    isCommitted: [isCommitted, setIsCommitted],
    countInfluxAsRenewed: [countInfluxAsRenewed, setCountInfluxAsRenewed],
    dataFilter: [dataFilter, setDataFilter, serializeDataFilter, deserializeDataFilter, updateDataFilter],
    metadataFilter: [metadataFilter, setMetadataFilter],
    ...includeInUrl,
  };

  // Filter urlStates by page
  const urlStates = useUrlStates(
    PAGE_CONTEXT_VALUES[page].pageStates.concat(Object.keys(includeInUrl)).reduce((acc, key) => {
      acc[key] = globalStates[key];
      return acc;
    }, {}),
  );

  // Build urlState
  const urlState = Object.entries(urlStates).reduce((acc, [key, value]) => {
    acc[key] = value[0];
    acc[`set${capitalize(key)}`] = value[1];
    return acc;
  }, {});

  return {
    urlState,
  };
};
