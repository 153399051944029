import React, { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { AppContext } from 'AppContext';
import { InvoicesTable, groupInvoicesByMonth } from 'views/Billing/InvoicingTables';
import { useInvoicingScheduleModal } from 'views/Billing/InvoicingScheduleModal';
import { useInvoicesAPI } from 'api/billing';
import { INVOICES_DEFAULT_SORT_BY, INVOICES_SORT_BY_COLUMNS_NAME_MAPPING } from 'views/Billing/InvoicingTables/consts';
import { useStateWithStorage } from 'utils/hooks';
import { sortByToKnexOrderBy } from 'utils/tableUtils';

export const CustomerInvoicesTable = ({ customer }) => {
  const {
    orgId,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const [storageSortBy, setStorageSortBy] = useStateWithStorage('customer-invoices-page-sort-by');

  const {
    data: { data: invoices },
    isFetching: isFetchingInvoices,
  } = useInvoicesAPI({
    orgId,
    includeReminders: true,
    orderBy: isEmpty(storageSortBy)
      ? INVOICES_DEFAULT_SORT_BY
      : sortByToKnexOrderBy(storageSortBy, INVOICES_SORT_BY_COLUMNS_NAME_MAPPING),
    customerId: customer.id,
    includeChildren: true,
  });

  const { Modal: InvoicingScheduleModal, openModal: openInvoicingScheduleModal } = useInvoicingScheduleModal();
  const openInvoiceModal = (invoice) =>
    openInvoicingScheduleModal({ invoicingSchedule: { id: invoice?.invoicing_schedule_id }, invoice });

  const dataForTable = useMemo(() => (invoices ? groupInvoicesByMonth({ invoices }) : []), [invoices]);

  return (
    <>
      <InvoicesTable
        initialSortBy={storageSortBy}
        setSortBy={setStorageSortBy}
        currency={currency}
        isFetchingInvoices={isFetchingInvoices}
        dataForTable={dataForTable}
        onInvoiceClick={openInvoiceModal}
      />
      <InvoicingScheduleModal />
    </>
  );
};
