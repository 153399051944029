import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { INTEGRATION_SERVICES_WITH_VOID_CREDIT_NOTE } from 'consts/integrations';
import { ReactComponent as DotsIcon } from 'images/dots.svg';
import { Centerer, FlexBetweenContainer, FlexerRow } from 'components/Core';
import {
  Popover,
  PopoverItemsContainer,
  PopoverListItem,
  usePortal,
  Portal as PortalContainer,
  PopoverPrompt,
  PopoverActions,
  PopoverButton,
} from 'components/Portal';
import { TooltipContainer } from 'components/Tooltip';
import { TrashIcon } from 'components/Icons';
import { ReactComponent as DetachIcon } from 'images/unlink-icon.svg';
import { ReactComponent as VoidIcon } from 'images/ban-icon.svg';

import { ActionsButton } from './InvoiceActions/InvoiceActions';
import { DotsIconGreen, PanelHeaderButton } from '../panel.styles';
import { CreditNoteContext } from '../CreditNoteContext';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';

const Container = styled.div`
  position: relative;
`;

const ListItemWrapper = styled(FlexerRow)`
  gap: 6px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const CreditNotesActions = ({ headerView, isSelected, creditNote }) => {
  const {
    invoicingService,
    invoicingServiceDisplayName,
    currentInvoicingSchedule,
    refetchInvoicingSchedule,
  } = useContext(InvoicingScheduleContext);

  const { selectedCreditNoteId, removeCreditNote, voidCreditNote, setCreditNotes, selectedCreditNote } = useContext(
    CreditNoteContext,
  );

  const { triggerRef, togglePortal, hidePortal, isPortalVisible, Portal } = usePortal({
    XOffset: headerView ? 55 : -15,
    YOffset: 46,
  });

  const { top, left, height, bottom } = triggerRef?.current?.getBoundingClientRect() ?? {};

  const remainingHeight = window.innerHeight - bottom;

  const portalContentStyles = {
    zIndex: 999,
    position: 'absolute',
    left: left - document.getElementById('main-navigation')?.offsetWidth,
    top: top + height + document.getElementById('main-content')?.scrollTop,
  };

  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const [showVoidPopover, setShowVoidPopover] = useState(false);
  const [detachMode, setDetachMode] = useState(false);
  const toggleDeletePopover = () => setShowDeletePopover((prevValue) => !prevValue);
  const toggleVoidPopover = () => setShowVoidPopover((prevValue) => !prevValue);

  const handleDelete = async () => {
    if (selectedCreditNoteId.includes('Unsaved')) {
      setCreditNotes((prevCreditNotes) =>
        prevCreditNotes.filter((creditNote) => creditNote.unsavedId !== selectedCreditNoteId),
      );
    } else {
      await removeCreditNote.mutateAsync({
        id: selectedCreditNoteId,
        detachMode,
        integrationId: currentInvoicingSchedule?.integration_id,
      });
      refetchInvoicingSchedule();
    }
    toggleDeletePopover();
    hidePortal();
  };

  const handleVoid = async () => {
    await voidCreditNote.mutateAsync({
      id: selectedCreditNoteId,
      integrationId: currentInvoicingSchedule?.integration_id,
    });
    refetchInvoicingSchedule();
    toggleVoidPopover();
    hidePortal();
  };

  return (
    <>
      <Container ref={triggerRef}>
        {headerView ? (
          <PanelHeaderButton data-cy="credit-note-actions__toggle--header" onClick={togglePortal}>
            <Centerer gap="8px">
              More Actions
              <DotsIconGreen active={isPortalVisible} />
            </Centerer>
          </PanelHeaderButton>
        ) : (
          <ActionsButton data-cy="credit-note-actions__toggle--sidebar" onClick={togglePortal} isSelected={isSelected}>
            <DotsIcon />
          </ActionsButton>
        )}

        <Portal data-cy="credit-note-actions__portal">
          <Popover width="100px">
            <FlexBetweenContainer>
              <PopoverItemsContainer>
                {!selectedCreditNoteId.includes('Unsaved') &&
                  !creditNote.voided_at &&
                  INTEGRATION_SERVICES_WITH_VOID_CREDIT_NOTE.includes(invoicingService) && (
                    <PopoverListItem
                      onClick={() => {
                        setDetachMode(true);
                        toggleVoidPopover();
                        togglePortal();
                      }}
                      data-cy="credit-note-actions__action--void"
                    >
                      <ListItemWrapper>
                        <VoidIcon /> Void
                      </ListItemWrapper>
                    </PopoverListItem>
                  )}
                {!!creditNote.credit_note_external_url && (
                  <TooltipContainer
                    toolTipContent={`Delete this credit note on Subscript (but leave it on ${invoicingServiceDisplayName})`}
                    fontSize="12px"
                    width={200}
                  >
                    <PopoverListItem
                      onClick={() => {
                        setDetachMode(true);
                        toggleDeletePopover();
                        togglePortal();
                      }}
                      data-cy="credit-note-actions__action--detach"
                    >
                      <ListItemWrapper>
                        <DetachIcon /> Detach
                      </ListItemWrapper>
                    </PopoverListItem>
                  </TooltipContainer>
                )}
                <TooltipContainer
                  toolTipContent={
                    !!creditNote.allocated_externally
                      ? `This credit note cannot be deleted as it is already allocated in ${invoicingServiceDisplayName}`
                      : creditNote.credit_note_external_url
                      ? `Delete this credit note both on ${invoicingServiceDisplayName} and on Subscript`
                      : 'Delete this credit note on Subscript'
                  }
                  fontSize="12px"
                  width={200}
                >
                  <PopoverListItem
                    onClick={() => {
                      setDetachMode(false);
                      toggleDeletePopover();
                      togglePortal();
                    }}
                    disabled={!!creditNote.allocated_externally}
                    data-cy="credit-note-actions__action--delete"
                  >
                    <ListItemWrapper>
                      <TrashIcon /> Delete
                    </ListItemWrapper>
                  </PopoverListItem>
                </TooltipContainer>
              </PopoverItemsContainer>
            </FlexBetweenContainer>
          </Popover>
        </Portal>

        {showDeletePopover && (
          <PortalContainer>
            <div style={portalContentStyles}>
              <Popover XOffset={0} YOffset={remainingHeight < 95 ? -130 : 10} zIndex={51} darkMode width="200px">
                <PopoverPrompt>
                  Are you sure you want to delete this Credit Note on Subscript?{' '}
                  {!detachMode &&
                    selectedCreditNote?.credit_note_external_url &&
                    ` The Credit Note #${selectedCreditNote.credit_note_number} in ${invoicingServiceDisplayName} will be deleted as well.`}
                </PopoverPrompt>
                <PopoverActions>
                  <PopoverButton onClick={toggleDeletePopover}>No</PopoverButton>
                  <PopoverButton data-cy="credit-notes-actions--delete--confirm-yes" onClick={handleDelete} primary>
                    Yes
                  </PopoverButton>
                </PopoverActions>
              </Popover>
            </div>
          </PortalContainer>
        )}

        {showVoidPopover && (
          <PortalContainer>
            <div style={portalContentStyles}>
              <Popover XOffset={0} YOffset={remainingHeight < 95 ? -130 : 10} zIndex={51} darkMode width="200px">
                <PopoverPrompt>
                  {selectedCreditNote?.allocated_externally
                    ? `You have to delete this Credit Note's allocations on the ${invoicingServiceDisplayName} web application first. Click on Yes here after that's done.`
                    : selectedCreditNote?.credit_note_external_url
                    ? `The Credit Note #${selectedCreditNote.credit_note_number} in ${invoicingServiceDisplayName} will be voided as well.`
                    : 'Are you sure you want to void this Credit Note?'}
                </PopoverPrompt>
                <PopoverActions>
                  <PopoverButton onClick={toggleVoidPopover}>No</PopoverButton>
                  <PopoverButton data-cy="credit-notes-actions--void--confirm-yes" onClick={handleVoid} primary>
                    Yes
                  </PopoverButton>
                </PopoverActions>
              </Popover>
            </div>
          </PortalContainer>
        )}
      </Container>
    </>
  );
};
