import styled from 'styled-components';

export const InputLabel = styled.label`
  margin: ${({ margin }) => margin ?? '0px 0px 10px'};
  font-weight: normal;
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  line-height: 18px;
  display: block;
  opacity: 0.4;
`;

export const SelectField = ({ width, marginLeft, marginRight, marginBottom, label, dataCy, children }) => (
  <div data-cy={dataCy} style={{ width, marginLeft, marginBottom, marginRight: marginRight }}>
    <InputLabel>{label}</InputLabel>
    {children}
  </div>
);
