import { useMutation, useQueryClient } from 'react-query';
import { useToasts } from 'components/Toasts';
import { bulkUpdateAccountingSpreads as _bulkUpdateAccountingSpreads } from './requests';

export const CACHE_KEY = 'accountingSpreads';

export const useAccountingSpreadsAPI = ({ orgId, enableToasts = true }) => {
  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const queryClient = useQueryClient();

  const bulkUpdateAccountingSpreads = useMutation(({ data }) => _bulkUpdateAccountingSpreads({ orgId, data }), {
    onError: (err) => {
      pushError(err, 'Failed to bulk update accounting spreads.');
    },

    onSuccess: () => {
      pushToast('Successfully bulk update accounting spreads!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  return {
    operations: {
      bulkUpdateAccountingSpreads: bulkUpdateAccountingSpreads.mutateAsync,
    },
  };
};
