import { Row } from 'components/Core';
import { ReactComponent as RedWarnIcon } from 'images/red-warn.svg';
import { ReactComponent as SuccessIcon } from 'images/success-icon.svg';
import { ReactComponent as AuthorizedIcon } from 'images/authorized_icon.svg';

import { ConnectionContainer, ConnectionEstablished, StatusContainer } from '../styles';

const StatusComponent = ({ hasIntegration, isConfigurationRequired }) => {
  let Status = () => (
    <>
      <RedWarnIcon style={{ marginRight: 6 }} height={20} width={20} />
      <ConnectionEstablished>Connection not established</ConnectionEstablished>
    </>
  );

  if (hasIntegration && isConfigurationRequired) {
    Status = () => (
      <>
        <AuthorizedIcon style={{ marginRight: 6 }} height={20} width={20} />
        <ConnectionEstablished>You're authorized</ConnectionEstablished>
      </>
    );
  } else if (hasIntegration && !isConfigurationRequired) {
    Status = () => (
      <>
        <SuccessIcon style={{ marginRight: 6 }} height={20} width={20} />
        <ConnectionEstablished>Connection established</ConnectionEstablished>
      </>
    );
  }

  return (
    <StatusContainer>
      <Status />
    </StatusContainer>
  );
};

const ConnectionStatus = ({ isConfigurationRequired, hasIntegration }) => (
  <ConnectionContainer>
    <Row horizontal="space-between">
      <StatusComponent hasIntegration={hasIntegration} isConfigurationRequired={isConfigurationRequired} />
    </Row>
  </ConnectionContainer>
);

export default ConnectionStatus;
