import { useContext } from 'react';
import { Formik } from 'formik';
import { AppContext } from 'AppContext';
import { shouldSendEmailFromSubscript } from 'views/Billing/utils';
import { Centerer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { FormikOnFormChangeEffect } from 'components/Controls';
import { VALIDATION_SCHEMA } from 'views/Billing/schema';
import { INVOICE_STATUSES } from 'views/Billing/consts';
import { getInvoiceInitialValues, getModalInvoiceStatus } from 'views/Billing/InvoiceModal/utils';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { SingleInvoice } from './SingleInvoice/SingleInvoice';
import { SingleInvoiceEmptyState } from './SingleInvoice/SingleInvoiceEmptyState';

export const InvoiceScheduleSingleInvoicePanel = () => {
  const { orgConfigs } = useContext(AppContext);

  const {
    fetchedSelectedInvoice,
    invoiceUpdateLoading,
    isInvoiceLoading,
    invoiceFormRef,
    setInvoiceFormValues,
    isInvoiceFetching,
    setInvoicingScheduleFormValues,
    isInvoicePreviewModalOpen,
    scheduleFormRef,
    InvoicePreviewModal,
    isSelectedInvoiceImportedLoading,
    ReplacedTemplateVariablesModal,
    invoicingService,
    invoiceFormValues,
    invoicingScheduleFormValues,
    selectedInvoiceId,
  } = useContext(InvoicingScheduleContext);

  const sendEmailFromSubscript = shouldSendEmailFromSubscript({ orgConfigs });

  const sendFromService = sendEmailFromSubscript || invoiceFormValues?.auto_charge ? 'subscript' : invoicingService;

  const modalInvoiceStatus = fetchedSelectedInvoice ? getModalInvoiceStatus({ invoice: fetchedSelectedInvoice }) : '';
  const loadSilently = [INVOICE_STATUSES.PROCESSING, INVOICE_STATUSES.TAXES_PROCESSING].includes(modalInvoiceStatus);

  return (
    <>
      {!loadSilently &&
      (isInvoiceLoading || isInvoiceFetching || invoiceUpdateLoading || isSelectedInvoiceImportedLoading) ? (
        <Centerer style={{ width: '100%' }}>
          <CircleLoader name="single-invoice" />
        </Centerer>
      ) : fetchedSelectedInvoice && invoicingScheduleFormValues?.invoices?.length && selectedInvoiceId ? (
        <Formik
          innerRef={invoiceFormRef}
          enableReinitialize={true}
          initialValues={getInvoiceInitialValues(fetchedSelectedInvoice)}
          validationSchema={VALIDATION_SCHEMA({ isPreviewModal: isInvoicePreviewModalOpen, sendFromService })}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={() => {}}
        >
          {({ values, setFieldValue, getFieldMeta }) => {
            return (
              <>
                <SingleInvoice
                  invoice={fetchedSelectedInvoice}
                  values={values}
                  getFieldMeta={getFieldMeta}
                  setFieldValue={setFieldValue}
                />
                <FormikOnFormChangeEffect
                  onChange={(values) => {
                    setInvoiceFormValues(values);

                    //sync schedule form invoice with single invoice changes
                    const updatedInvoiceIndex = invoicingScheduleFormValues.invoices.findIndex((invoice) =>
                      invoice.id ? invoice.id === values?.id : invoice.unsavedId === values?.unsavedId,
                    );
                    invoicingScheduleFormValues.invoices[updatedInvoiceIndex] = values;
                    scheduleFormRef?.current?.validateForm(invoicingScheduleFormValues);
                    setInvoicingScheduleFormValues(invoicingScheduleFormValues);
                  }}
                />

                <InvoicePreviewModal />

                <ReplacedTemplateVariablesModal />
              </>
            );
          }}
        </Formik>
      ) : (
        <SingleInvoiceEmptyState />
      )}
    </>
  );
};
