import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { useWaterfallAPI } from 'api/waterfall';
import { HEADER_TITLES } from 'shared/Layout';
import { ReactComponent as CogIcon } from 'images/bx-cog.svg';
import { getAppSetting } from 'models/appSettings';
import { Spacer } from 'components/Core';
import { GreyDot } from 'components/Icons';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { RATES_TYPE } from 'consts/global';
import { UnconfirmedTransactionsBanner } from 'shared/Banners';
import { WaterfallTable } from 'shared/WaterfallTable';
import { GlobalTogglesContextProvider, GlobalTogglesFloatingContainer } from 'shared/GlobalToggles';
import { WaterfallTypeFilter } from 'shared/Filters';
import { FiltersAndSegmentBy } from 'shared/Filters/FiltersAndSegmentBy';
import { ShareButton } from 'shared/ShareButton';
import { useSyncedExport } from 'shared/SyncedExports';
import { HeaderReports } from 'shared/Reports';
import { getCustomSegmentDescriptors } from 'shared/MetadataSegmentsModal/utils';
import { reshapeDataForTable } from 'views/Dashboard';
import { dehumanize } from 'utils/stringUtils';
import { exportDashboardToExcel } from 'utils/export';
import {
  PAGE_CONTEXT_VALUES,
  useCachedMetrics,
  useRecurringRevenueCachedMetrics,
  useInvariantCheck,
} from 'utils/hooks';
import { getWaterfallData } from 'api/waterfall/requests';

import { InsightsProvider } from 'shared/Insights/InsightsProvider';
import { WaterfallContext } from './WaterfallContext';
import { getTableData, getARR } from './utils';
import TogglePopover from './TogglePopover';
import {
  checkSumOfSegments,
  checkRevenueStatsBasedOnLastMonth,
  checkCustomerStatsBasedOnLastMonth,
} from './invariantChecks';

dayjs.extend(utc);

const MainContainer = styled.div`
  width: 100%;
  position: relative;
  min-height: 700px;
`;

const ContentContainer = styled.div`
  margin-top: 28px;
  padding-bottom: 40px;
`;

const StyledCogIcon = styled(CogIcon)`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

export const WaterfallPage = () => {
  const { organizations, orgConfigs } = useContext(AppContext);
  const pageState = useContext(WaterfallContext);
  const {
    loading,
    setLoading,
    isARR,
    setIsARR,
    isCommitted,
    rollup,
    setRollup,
    isIncreases,
    setIsIncreases,
    dataFilter,
    setDataFilter,
    metadataFilter,
    setMetadataFilter,
    snapshotSelectedColumns,
    setSnapshotSelectedColumns,
    waterfallGraphPeriod,
    setWaterfallGraphPeriod,
    showWaterFallExistingRow,
    setShowWaterFallExistingRow,
    showInfluxRow,
    setShowInfluxRow,
    showInfluxMovementRow,
    setShowInfluxMovementRow,
    optimisticAnalytics,
    setOptimisticAnalytics,
    countInfluxAsRenewed,
    exchangeRatesType,
    exchangeRatesDate,
  } = pageState;

  const exportScreenshotRef = useRef();
  const chartRef = useRef();
  const { waterfallType, waterfallSegmentBy, waterfallSegmentByTimeseriesMonth, startMonth, endMonth } = dataFilter;

  const quarters = getAppSetting('quarters');
  const quartersYearOffset = getAppSetting('quartersYearOffset');
  const influxMonths = getAppSetting('influxMonths');
  const hasTransactionsWithRenewalDeadline = organizations[0].hasTransactionsWithRenewalDeadline;
  const useInFlux = !!influxMonths || hasTransactionsWithRenewalDeadline;

  const { data, isFetching, isLoading, defaultParams: defaultAPIParams } = useWaterfallAPI({
    orgId: organizations?.[0]?.id,
    startMonth,
    endMonth,
    rollup,
    segmentBy: waterfallSegmentBy,
    timeseriesMonth: waterfallSegmentByTimeseriesMonth,
    isCommitted,
    optimisticAnalytics,
    metadataFilter,
    waterfallUnits: waterfallType,
    countInfluxAsRenewed,
    ratesType: exchangeRatesType ?? RATES_TYPE.BOOKING_DATES,
    ratesDate: exchangeRatesDate,
  });

  const segmentTooltips = getCustomSegmentDescriptors({ orgConfigs });

  const getDataForTable = useCallback(
    ({ data, loading, params }) => {
      if (data && !loading) {
        const { tableData, summaryData, breakdownData } = getTableData({
          isARR,
          waterfallType,
          waterfallData: data,
        });

        const dataForTableParams = {
          organizations,
          startDate: dayjs.utc(data.startKey),
          endDate: dayjs.utc(data.endKey),
          rawData: tableData,
          isIncreases,
          showWaterFallExistingRow,
          selectedColumns: snapshotSelectedColumns,
          waterfallGraphPeriod,
          quarters,
          quartersYearOffset,
          summaryStats: summaryData,
          showInflux: useInFlux && showInfluxRow,
          showInfluxMovement: useInFlux && showInfluxMovementRow,
          segmentBy: waterfallSegmentBy,
          segmentBreakdowns: breakdownData,
          waterfallType,
          countInfluxAsRenewed,
          segmentTooltips,
          ...params,
        };

        return reshapeDataForTable(dataForTableParams);
      }
    },
    [
      countInfluxAsRenewed,
      isARR,
      isIncreases,
      organizations,
      quarters,
      quartersYearOffset,
      segmentTooltips,
      showInfluxMovementRow,
      showInfluxRow,
      showWaterFallExistingRow,
      snapshotSelectedColumns,
      useInFlux,
      waterfallGraphPeriod,
      waterfallSegmentBy,
      waterfallType,
    ],
  );

  const dataForTable = useMemo(() => getDataForTable({ data, loading }), [data, getDataForTable, loading]);

  const { SyncedExportInfoBar, SyncedExportModal, ExportButton } = useSyncedExport({
    exportScreenshotRef,
    orgId: organizations[0].id,
    type: `${isCommitted ? 'committed_' : ''}${dehumanize(dataFilter.waterfallType)}_waterfall`,
    customization: {
      startMonth: dayjs(dataFilter.startMonth).format('YYYY-MM'),
      endMonth: dayjs(dataFilter.endMonth).format('YYYY-MM'),
      ...metadataFilter,
      isCommitted,
      optimisticAnalytics,
      isARR,
      rollup,
      influxMonths,
      quarters,
      quartersYearOffset,
      countInfluxAsRenewed,
    },
    isFilterOn: (metadataFilter && Object.values(metadataFilter).length !== 0) || waterfallSegmentBy,
    startDateKey: 'startMonth',
    endDateKey: 'endMonth',
    // TODO: In https://github.com/ChifferCo/chiffer/issues/1242, just remove this onExport and all the frontend exporting
    // functions. Keeping it for now because the frontend is producing a nicer file for Excel with colors and formatting.
    onExport: async ({ selectAllData }) => {
      let adjustedData = dataForTable;

      if (selectAllData) {
        let fetchedData;
        while (!fetchedData) {
          const { data } = await getWaterfallData({
            orgId: organizations[0].id,
            params: {
              ...defaultAPIParams,
              customerMetadata: undefined,
              transactionMetadata: undefined,
              segmentBy: null,
              runInlineInTestMode: true,
            },
          });

          fetchedData = data;
        }

        adjustedData = getDataForTable({ data: fetchedData, params: { segmentBy: null } });
      }
      exportDashboardToExcel({
        reshapeData: adjustedData,
        title: `${dataFilter.waterfallType} Waterfall`,
        useColumnsForName: true,
      });
    },
  });

  // To keep the waterfall context loading in sync with useQuery isLoading
  useEffect(() => {
    setLoading(isLoading || isFetching || !data);
  }, [isLoading, setLoading, isFetching, data]);

  useInvariantCheck({
    // When optimistic is on, the sum based on last month's stats isn't really correct
    // See https://github.com/ChifferCo/chiffer/issues/4850
    readyData: !optimisticAnalytics ? data : null,
    checkers: [checkSumOfSegments, checkRevenueStatsBasedOnLastMonth, checkCustomerStatsBasedOnLastMonth],
    checkerContext: {
      startMonth,
      endMonth,
    },
  });

  useRecurringRevenueCachedMetrics({
    readyData:
      !countInfluxAsRenewed && data && !loading ? (isARR ? getARR(data, waterfallType) : data).revenueStats : null,
    getValue: ({ readyData }) =>
      Object.entries(readyData ?? {}).reduce(
        (acc, [month, object]) => Object.assign(acc, { [month]: object.Total }),
        {},
      ),
    context: 'Waterfall',
    isCommitted,
    rollup,
  });

  useCachedMetrics({
    readyData: !countInfluxAsRenewed && data && !loading ? data.customerStats : null,
    getValue: ({ readyData }) =>
      Object.entries(readyData ?? {}).reduce(
        (acc, [month, object]) => Object.assign(acc, { [month]: object.Total }),
        {},
      ),
    description: 'Recurring customer count',
    storageKey: `recurringCustomerCount.${rollup ? 'withRollup' : 'withoutRollup'}`,
    context: 'Waterfall',
  });

  useCachedMetrics({
    readyData: data && !loading ? data.customerStats : null,
    getValue: ({ readyData }) => readyData,
    description: 'Recurring customer waterfall',
    storageKey: `customerWaterfall.${rollup ? 'withRollup' : 'withoutRollup'}`,
    context: 'Waterfall',
  });

  return (
    <MainContainer>
      <HeaderReports
        headerTitle={HEADER_TITLES.waterfall}
        headerRight={
          <>
            <ShareButton chartRef={chartRef} headerTitle={HEADER_TITLES.waterfall} />
            <Spacer width="10px" />
            <ExportButton />
          </>
        }
        page={PAGE_CONTEXT_VALUES.waterfall.key}
        pageState={pageState}
      />

      <GlobalTogglesContextProvider urlState={pageState}>
        <InsightsProvider>
          <FiltersAndSegmentBy
            currentPageSegmentKey="waterfallSegmentBy"
            setLoading={setLoading}
            orgId={organizations[0].id}
            dataFilter={dataFilter}
            setDataFilter={setDataFilter}
            metadataFilter={metadataFilter}
            setMetadataFilter={setMetadataFilter}
            showConditions={true}
            showSize={false}
            hidePeriodSelection={true}
            segmentName="waterfallSegmentBy"
            allowSegment
          >
            <GreyDot spacing="4px" />
            <WaterfallTypeFilter />
          </FiltersAndSegmentBy>
        </InsightsProvider>

        {data?.hasUnconfirmedTransactions && <UnconfirmedTransactionsBanner />}

        <TimeLoaderContainer isFirstLoad={!data} isLoading={loading}>
          {loading || !data ? (
            <TimeLoader isFirstLoad={!data} pageName="waterfall" />
          ) : (
            <GlobalTogglesFloatingContainer>
              <ContentContainer>
                <SyncedExportInfoBar />
                <WaterfallTable
                  chartRef={chartRef}
                  exportScreenshotRef={exportScreenshotRef}
                  tableId="waterfall-table"
                  showStackedBarChart={true}
                  dataTableShowingBy={waterfallType}
                  dataForTable={dataForTable}
                  isARR={isARR}
                  setIsARR={setIsARR}
                  optimisticAnalytics={optimisticAnalytics}
                  setOptimisticAnalytics={setOptimisticAnalytics}
                  waterfallGraphPeriod={waterfallGraphPeriod}
                  setWaterfallGraphPeriod={setWaterfallGraphPeriod}
                  waterfallSegmentBy={waterfallSegmentBy}
                  waterfallSegmentByTimeseriesMonth={waterfallSegmentByTimeseriesMonth}
                  togglePopover={
                    <TogglePopover
                      setIsIncreases={setIsIncreases}
                      isIncreases={isIncreases}
                      setRollup={setRollup}
                      rollup={rollup}
                      snapshotSelectedColumns={snapshotSelectedColumns}
                      setSnapshotSelectedColumns={setSnapshotSelectedColumns}
                      showWaterfallExistingRow={showWaterFallExistingRow}
                      setShowWaterFallExistingRow={setShowWaterFallExistingRow}
                      showInfluxRow={showInfluxRow}
                      setShowInfluxRow={useInFlux && setShowInfluxRow}
                      showInfluxMovementRow={showInfluxMovementRow}
                      setShowInfluxMovementRow={useInFlux && !waterfallSegmentBy && setShowInfluxMovementRow}
                      popoverText="Settings"
                      countInfluxAsRenewed={countInfluxAsRenewed}
                      icon={<StyledCogIcon />}
                    />
                  }
                  waterfallType={waterfallType}
                  dataFilter={dataFilter}
                />
              </ContentContainer>
            </GlobalTogglesFloatingContainer>
          )}
        </TimeLoaderContainer>
      </GlobalTogglesContextProvider>

      <SyncedExportModal />
    </MainContainer>
  );
};
