import React, { useState, useContext, createContext } from 'react';
import { AppContext } from 'AppContext';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { getIntegrationDisplayName } from 'models/integration';
import { useCustomerAPI, useCustomersAPI } from 'api/customers/hooks';
import { useIntegrationsAPI } from 'api/integrations';
import { groupBy } from 'utils/arrayUtils';
import { INTERNAL_CUSTOMER_METADATA } from 'models/customer';
import { useToasts } from 'components/Toasts';

import { ERROR_IMPORT_ON_OTHER_CUSTOMER, useCustomerImports } from './useCustomerImports';
import { useRemoveImportConfirmModal } from './useRemoveImportConfirmModal';
import { useReingestCustomerModal } from './useReingestCustomerModal';
import { useManualSyncCustomerModal } from './useManualSyncCustomerModal';

export const GLCustomerContext = createContext();

export const GLCustomerContextProvider = ({
  children,
  customerId,
  setCustomerId,
  initialCustomerExternalId,
  initialCustomerData,
  editCustomer,
}) => {
  const { orgId, integrations } = useContext(AppContext);
  const { pushError } = useToasts();
  const [isLoading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState();
  const [customerGLId, setCustomerGLId] = useState();
  const [customerGLName, setCustomerGLName] = useState();
  const [integrationId, setIntegrationId] = useState();

  const glIntegrations = integrations?.filter((i) => i.type === INTEGRATION_TYPES.GL);
  const glIntegrationById = groupBy(glIntegrations, 'id', { uniqueness: true });
  const invoicingService = glIntegrationById[integrationId]?.service;
  const invoicingServiceLabel = getIntegrationDisplayName(glIntegrationById[integrationId]);

  const {
    operations: { invalidate },
  } = useCustomerAPI({ orgId, customerId, enabled: false });

  const {
    operations: { addCustomer },
  } = useCustomersAPI({ orgId, autoFetch: false });

  const {
    operations: { syncEntity },
  } = useIntegrationsAPI({ orgId, autoFetch: false });

  const { updateCustomerImport, ErrorUpdatingImportModal } = useCustomerImports();
  const handleSaveCustomerImport = async ({ shouldReingest }) => {
    setLoading(true);
    try {
      let chifferObjectId = customerId;

      // If customer does not exist we should create it
      if (!customerId) {
        chifferObjectId = (
          await addCustomer({
            data: {
              name: formValues?.name,
              metadata: formValues?.metadata,
              parent_customer_id: formValues?.parent_customer_id,
              invoicing_details: formValues?.invoicing_details,
            },
          })
        )?.id;
      } else if (
        initialCustomerData.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID]?.toString() !==
        formValues?.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID]?.toString()
      ) {
        await editCustomer({
          id: initialCustomerData.id,
          data: {
            metadata: formValues?.metadata,
          },
        });
      }

      // We need to update customer import with customerGLId first, so that we can use it in the reingestion
      await updateCustomerImport({
        integrationId,
        chifferObjectId: chifferObjectId,
        providerObjectId: customerGLId,
        metadata: { customer_name: customerGLName },
      });

      if (shouldReingest) {
        await syncEntity.mutateAsync({
          integrationId,
          entity: 'customers',
          externalIds: [customerGLId],
        });
      }

      if (!customerId) {
        setCustomerId(chifferObjectId);
      }
      invalidate();
    } catch (err) {
      console.log(err);
      // We can ignore ERROR_IMPORT_ON_OTHER_CUSTOMER errors because they are dealt inside useCustomerImports hook
      if (err !== ERROR_IMPORT_ON_OTHER_CUSTOMER) {
        pushError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const { openReingestCustomerModal, ReingestCustomerModal } = useReingestCustomerModal({
    onShouldReingest: (shouldReingest) => handleSaveCustomerImport({ shouldReingest }),
    integrationDisplayName: invoicingServiceLabel,
  });

  const { openManualSyncCustomerModal, ManualSyncCustomerModal } = useManualSyncCustomerModal({
    onShouldReingest: (shouldReingest) => handleSaveCustomerImport({ shouldReingest }),
    integrationDisplayName: invoicingServiceLabel,
  });

  const { openRemoveImportConfirmModal, RemoveImportConfirmModal } = useRemoveImportConfirmModal({
    serviceLabel: invoicingServiceLabel,
    setLoading,
    initialCustomerData,
    editCustomer,
    invoicingService,
    integrationId,
  });

  const handleSetCustomerGLImport = ({
    formValues,
    customerExternalId,
    customerExternalName,
    disableReingest,
    integrationId,
  }) => {
    setFormValues(formValues);
    setCustomerGLId(customerExternalId);
    setCustomerGLName(customerExternalName);
    setIntegrationId(integrationId);
    if (!disableReingest && customerExternalId && customerExternalId !== initialCustomerExternalId) {
      openReingestCustomerModal();
    }
  };

  const handleRemoveCustomerGLImport = ({ importId, integrationId }) => {
    setIntegrationId(integrationId);
    openRemoveImportConfirmModal({ importId });
  };

  const handleManualSyncCustomer = ({ customerExternalId, integrationId, formValues }) => {
    setIntegrationId(integrationId);
    setCustomerGLId(customerExternalId);
    setFormValues(formValues);

    openManualSyncCustomerModal();
  };

  return (
    <GLCustomerContext.Provider
      value={{
        isLoading,
        handleSetCustomerGLImport,
        handleRemoveCustomerGLImport,
        handleManualSyncCustomer,
        customerId,
        setCustomerId,
      }}
    >
      {children}
      <ErrorUpdatingImportModal />
      <ReingestCustomerModal />
      <RemoveImportConfirmModal />
      <ManualSyncCustomerModal />
    </GLCustomerContext.Provider>
  );
};
