import { useContext } from 'react';
import styled from 'styled-components';
import { components } from 'react-select';

import { AppContext } from 'AppContext';
import { useInvoiceMemoTemplatesAPI } from 'api/invoiceMemoTemplates/hooks';
import { SelectDropdownButton } from 'components/Buttons';
import { FormikCustomSelector } from 'components/Controls';
import { FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { EditCircleIcon } from 'components/Icons';
import { templateToHTMLString } from 'utils/htmlUtils';
import { useBillingEntityModal } from 'views/Configuration/Tabs/SubscriptBillingTab/BillingEntity/useBillingEntityModal';
import { SENDER_OPTIONS } from 'views/Configuration/Tabs/SubscriptBillingTab/BillingSenderSetttings/consts';

const OptionWrapper = styled(FlexerColumn)`
  gap: 8px;
  min-width: 350px;
  width: 100%;
  height: fit-content;
  background-color: ${({ isSelected }) => (isSelected ? 'var(--primaryBlack2)' : 'transparent')};
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: var(--primaryBlack2);
    opacity: 0.7;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 38px;
    border-bottom: 1px solid var(--primaryBlack5);
  }

  &:last-of-type {
    &::after {
      border-bottom: none;
    }
  }
`;

const Text = styled.div`
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  font-size: 12px;
  color: ${({ light, extraLight }) =>
    extraLight ? 'var(--primaryBlack50)' : light ? 'var(--primaryBlack70)' : 'var(--primaryBlack)'};
  width: ${({ width }) => width};
`;

const StyledEditCircleIcon = styled(EditCircleIcon)`
  z-index: 100;
`;

const Option = ({ entity, selectedValue, templates, onEdit }) => {
  const isSelected = entity.id === selectedValue;
  const template = templates.find((template) => template.id === entity.footer_memo_template_id);

  return (
    <OptionWrapper isSelected={isSelected} data-cy={`entity__selector-option--${entity.company_name}`}>
      <FlexBetweenContainer gap="16px">
        <Text light={isSelected} fontWeight={700}>{`${entity.entity_name ? `${entity.entity_name}: ` : ''}${
          entity.company_name
        }`}</Text>
        <StyledEditCircleIcon
          fill="var(--primaryBlack30)"
          size="16px"
          onClick={onEdit}
          data-cy={`entity__selector-option__edit-button--${entity.company_name}`}
        />
      </FlexBetweenContainer>

      <Flexer gap="8px">
        <Text width="60px" extraLight={!isSelected}>
          Address:
        </Text>
        <Text
          light={!isSelected}
          dangerouslySetInnerHTML={{
            __html: templateToHTMLString({
              text: entity.address,
            }),
          }}
          data-cy={`entity__selector-option__address-display--${entity.company_name}`}
        />
      </Flexer>

      {template && (
        <Flexer gap="8px">
          <Text width="60px" extraLight={!isSelected}>
            Footer:
          </Text>
          <Text
            light={!isSelected}
            dangerouslySetInnerHTML={{
              __html: templateToHTMLString({
                text: template.content,
              }),
            }}
          />
        </Flexer>
      )}
    </OptionWrapper>
  );
};

export const BillingEntitySelector = ({ isButton, name, value, setValue, ...props }) => {
  const { entities = [], orgConfigs, orgId } = useContext(AppContext);
  const { billingSenderDefaults } = orgConfigs;

  const { openBillingEntityModal, BillingEntityModal } = useBillingEntityModal();

  const { data: templates = [] } = useInvoiceMemoTemplatesAPI({ orgId });

  const selectedEntity = entities.find((entity) => entity.id === value);
  const displayName = selectedEntity
    ? `${selectedEntity.entity_name ? `${selectedEntity.entity_name}: ` : ''}${selectedEntity.company_name}`
    : '';

  return billingSenderDefaults?.sender !== SENDER_OPTIONS.GL ? (
    <>
      {isButton ? (
        <SelectDropdownButton
          name={name}
          showSelectedDirectly
          selected={displayName}
          options={entities.reduce(
            (acc, entity) => ({
              ...acc,
              [entity.id]: (
                <Option
                  entity={entity}
                  selectedValue={value}
                  templates={templates}
                  onEdit={() => openBillingEntityModal(entity)}
                />
              ),
            }),
            [],
          )}
          onSelect={(id) => setValue(id)}
          noOptionsStyles
          {...props}
        />
      ) : (
        <FormikCustomSelector
          label="Sender organization"
          name={name}
          options={entities.map((entity) => ({
            label: `${entity.entity_name ? `${entity.entity_name}: ` : ''}${entity.company_name}`,
            value: entity.id,
            entity,
          }))}
          components={{
            Option: (props) => (
              <components.Option {...props} getStyles={() => {}}>
                <Option
                  entity={props.data.entity}
                  selectedValue={value}
                  templates={templates}
                  onEdit={() => openBillingEntityModal(props.data.entity)}
                />
              </components.Option>
            ),
          }}
        />
      )}

      <BillingEntityModal />
    </>
  ) : null;
};
