import { useContext } from 'react';

import { AppContext } from 'AppContext';
import { FormikCustomSelector } from 'components/Controls';
import { GL_INTEGRATION_SERVICES, INTEGRATION_TYPES } from 'consts/integrations';
import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import { InvoicingScheduleContext } from './InvoicingScheduleContext';
import { CreditNoteContext } from './CreditNoteContext';

export const IntegrationSelector = () => {
  const { integrations } = useContext(AppContext);
  const { customerIntegrationId, invoicingScheduleFormValues } = useContext(InvoicingScheduleContext);
  const { creditNotes } = useContext(CreditNoteContext);

  const glIntegrations = integrations.filter(
    (integration) =>
      integration.type === INTEGRATION_TYPES.GL &&
      GL_INTEGRATION_SERVICES.includes(getServiceCategory(integration.service)),
  );
  const hasItemsConnectedToGl =
    invoicingScheduleFormValues?.invoices?.some((invoice) => invoice.invoice_external_id) ||
    creditNotes?.some((note) => note.external_id);

  return glIntegrations.length ? (
    <FormikCustomSelector
      name="integration_id"
      options={glIntegrations.map((integration) => ({
        label: `${getIntegrationDisplayName(integration)} / ${integration.id}`,
        value: integration.id,
      }))}
      label="Link with general ledger"
      placeholder="Select a GL..."
      containerWidth="100%"
      isDisabled={!!customerIntegrationId || hasItemsConnectedToGl}
      dataCy="invoicing-schedule-modal__general-ledger-selector"
    />
  ) : null;
};
