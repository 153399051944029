import { useCallback, useContext, useState, useMemo } from 'react';
import { isEmpty } from 'lodash';

import { SwitchWithLabel } from 'components/Controls';
import { Column } from 'components/Core';
import { ConfirmModal } from 'shared/ConfirmModal';

import { InvoicingScheduleContext } from './InvoicingScheduleContext';
import {
  buildInvoicesFromChangedDraftInvoice,
  getClearedInvoiceChanges,
  getInvoicesForBulkEdit,
  getSortedInvoices,
} from './utils';
import { getInvoiceData, getInvoiceInitialValues, invoiceGenerateChanges } from '../InvoiceModal/utils';
import { INVOICE_ITEM_TYPE_TO_KEY } from '../consts';
import { BillingContext } from '../BillingContext';
import { INVOICING_SCHEDULE_TABS_PANEL_TABS } from './InvoicingScheduleTabsPanel';

const SaveChangesModal = ({ onClose, onSave }) => {
  const [applyChangesToAllInvoices, setApplyChangesToAllInvoices] = useState(false);

  const handleConfirm = () => {
    onSave({ shouldSave: true, applyChangesToAllInvoices });
    onClose();
  };

  const handleDeny = () => {
    onSave({ shouldSave: false, applyChangesToAllInvoices });
  };

  return (
    <ConfirmModal
      title="Save Changes?"
      content={
        <Column>
          Do you wish to save changes made to this invoice?
          <div style={{ marginTop: 10 }}>
            <SwitchWithLabel
              onChange={() => setApplyChangesToAllInvoices(!applyChangesToAllInvoices)}
              name="billing__invoice-schedule__apply-to-all"
              checked={applyChangesToAllInvoices}
              bolded
              label="Apply these changes to all invoices"
              labelSize="11px"
            />
          </div>
        </Column>
      }
      denyButtonText="No, discard"
      confirmButtonText="Yes, save"
      onConfirm={handleConfirm}
      onDeny={handleDeny}
      onClose={onClose}
    />
  );
};

export const useSafelySwitchInvoice = (props) => {
  const {
    setSelectedInvoiceId,
    selectedInvoiceId,
    currentInvoicingSchedule,
    isScheduleDraft,
    scheduleFormRef,
    invoiceFormRef,
    invoiceFormValues,
    invoicingScheduleFormValues,
    fetchedSelectedInvoice,
    validateInvoiceFormAndExecuteAction,
    fillScheduleWithDraftInvoices,
    setSelectedTabsPanelTab,
    isInvoiceLoading,
    isInvoiceFetching,
  } = useContext(InvoicingScheduleContext);

  const afterActionFn = props?.afterActionFn;

  const { createInvoice, updateInvoice, bulkEditInvoices } = useContext(BillingContext);

  const [invoiceChanges, setInvoiceChanges] = useState(null);
  const [clickedInvoice, setClickedInvoice] = useState(null);
  const [modalOpened, setModalOpened] = useState();

  const invoices = useMemo(() => getSortedInvoices({ invoices: invoicingScheduleFormValues?.invoices }), [
    invoicingScheduleFormValues?.invoices,
  ]);

  const handleSave = useCallback(
    async ({ shouldSave = true, applyChangesToAllInvoices }) => {
      const invoice = clickedInvoice;
      const invoiceId = invoice?.id ?? invoice?.unsavedId;

      const changes = getClearedInvoiceChanges({ invoiceChanges });

      if (invoiceFormRef?.current) {
        if (shouldSave) {
          await validateInvoiceFormAndExecuteAction({
            action: async () => {
              if (isScheduleDraft) {
                // Saving on unsaved invoicing schedule
                await fillScheduleWithDraftInvoices({
                  invoices: buildInvoicesFromChangedDraftInvoice({
                    invoices,
                    applyToAll: applyChangesToAllInvoices,
                    invoiceChanges: changes,
                    savedInvoice: invoiceFormValues,
                  }),
                });
              } else if (invoiceFormValues?.unsavedId) {
                // Saving draft invoice
                await scheduleFormRef?.current?.setFieldValue(
                  'invoices',
                  invoicingScheduleFormValues?.invoices?.filter(
                    (invoice) => invoice?.unsavedId !== invoiceFormValues?.unsavedId,
                  ),
                );

                await createInvoice({
                  data: {
                    ...getInvoiceData(invoiceFormValues),
                    invoicing_schedule_id: invoicingScheduleFormValues?.id,
                  },
                });

                if (applyChangesToAllInvoices) {
                  await bulkEditInvoices({
                    data: getInvoicesForBulkEdit({
                      ignoreCurrent: true,
                      currentInvoice: invoiceFormValues,
                      invoices: invoicingScheduleFormValues?.invoices,
                      changes,
                    }),
                  });
                }
              } else {
                // Saving existing invoice
                if (applyChangesToAllInvoices) {
                  await bulkEditInvoices({
                    data: getInvoicesForBulkEdit({
                      ignoreCurrent: false,
                      currentInvoice: invoiceFormValues,
                      invoices: invoicingScheduleFormValues?.invoices,
                      changes,
                    }),
                  });
                } else {
                  await updateInvoice({
                    invoice: invoiceFormValues,
                    integrationId: currentInvoicingSchedule?.integration_id,
                  });
                }
              }

              return invoiceId && setSelectedInvoiceId(invoiceId);
            },
          });
        } else {
          invoiceFormRef.current.resetForm();
          invoiceId && setSelectedInvoiceId(invoiceId);
        }
      }

      if (afterActionFn) afterActionFn();
    },
    // eslint-disable-next-line
    [
      currentInvoicingSchedule,
      clickedInvoice,
      invoiceFormValues,
      invoicingScheduleFormValues?.invoices,
      validateInvoiceFormAndExecuteAction,
      invoiceFormRef,
      invoiceChanges,
      setSelectedInvoiceId,
    ],
  );

  const checkAndSwitchInvoice = useCallback(
    ({ clickedInvoice }) => {
      setSelectedTabsPanelTab(INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES);

      const invoice = clickedInvoice;

      setClickedInvoice(invoice);

      setInvoiceChanges(null);

      if (
        selectedInvoiceId !== invoice?.unsavedId &&
        selectedInvoiceId !== invoice?.id &&
        !isInvoiceLoading &&
        !isInvoiceFetching
      ) {
        const source = fetchedSelectedInvoice ? getInvoiceInitialValues(fetchedSelectedInvoice) : {};
        const target = invoiceFormValues ? getInvoiceInitialValues(invoiceFormValues) : {};

        const invoiceChanges = invoiceGenerateChanges({
          source,
          target,
          fields: Object.keys(source).concat(Object.values(INVOICE_ITEM_TYPE_TO_KEY)),
        });

        if (!isEmpty(invoiceChanges) && (source?.is_imported || (!source?.paid_at && !source?.sent_at))) {
          setInvoiceChanges(invoiceChanges);
          setModalOpened(true);
          return;
        }
        (!!invoice?.id || !!invoice?.unsavedId) && setSelectedInvoiceId(invoice?.id ?? invoice?.unsavedId);

        if (afterActionFn) afterActionFn();
      }
    },
    [
      selectedInvoiceId,
      invoiceFormValues,
      afterActionFn,
      fetchedSelectedInvoice,
      setSelectedInvoiceId,
      setModalOpened,
      isInvoiceLoading,
      isInvoiceFetching,
      setSelectedTabsPanelTab,
    ],
  );

  return {
    checkAndSwitchInvoice,
    ConfirmSaveModal: () =>
      modalOpened ? <SaveChangesModal onSave={handleSave} onClose={() => setModalOpened(false)} /> : null,
    isSaveModalVisible: modalOpened,
  };
};
