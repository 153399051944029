import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CsvUpload } from 'shared/CsvUpload';
import { getColumns } from './columns';
import {
  DEFAULT_TRANSACTIONS_MAPPER,
  DEFAULT_TRANSACTIONS_TRANSFORMATIONS,
  INTERNAL_CUSTOMER_ID_SOURCE,
  INTERNAL_TRANSACTION_ID_SOURCE,
  NAME_MATCHING_SOURCE,
  SOURCE_TYPES,
  UPLOADABLE_OBJECTS,
} from './consts';
import { AppContext } from 'AppContext';
import { FEATURES, isFeatureActive } from 'utils/featureUtils';
import { getSchema } from './schema';
import { CUSTOMER_TYPES_OPTIONS } from 'shared/Filters';
import { useConfirmModal } from 'shared/ConfirmModal';
import { getFormFinalTransactions, getInitialIdSource, getOrgIdSources } from './utils';
import { useImportsAPI } from 'api/imports';
import { useCustomersAPI } from 'api/customers';
import { useTransactionsAPI } from 'api/transactions';

export const TransactionsCsvUpload = () => {
  const {
    organizations,
    integrations,
    orgConfigs,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);
  // `customerIdSourceAndType` (and `transactionsIdSourceAndType`) has object type
  // Example: { type: SOURCE_TYPES.INTERNAL, source: 'User ID' };

  const [transactionsIdSourceAndType, setTransactionsIdSourceAndType] = useState({});

  const [customerIdSourceAndType, setCustomerIdSourceAndType] = useState({});

  const [formTransactions, setFormTransactions] = useState();

  const [submittingForm, setSubmittingForm] = useState(false);

  const hasBillingEnabled = isFeatureActive({ feature: FEATURES.BILLING, orgConfigs });

  const { uploadCsvCustomersIdSource } = orgConfigs;

  const customersListParams = useMemo(
    () => ({
      limit: 50000,
      types: [CUSTOMER_TYPES_OPTIONS.LIVE, CUSTOMER_TYPES_OPTIONS.PROSPECT],
      // If matching internally by a metadata, we only need to fetch customers with existing id in metadata
      notNullMetadataField: [NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(customerIdSourceAndType.source)
        ? undefined
        : customerIdSourceAndType.source,
    }),
    [customerIdSourceAndType],
  );

  const {
    openConfirmModal: openConfirmCreateInvoicesModal,
    ConfirmModal: ConfirmCreateInvoicesModal,
  } = useConfirmModal({
    title: 'Create invoices?',
    content: 'Would you like to auto-generate invoices for these transactions?',
    denyButtonText: 'No',
    confirmButtonText: 'Yes, create invoices',
    onConfirm: async () => {
      await bulkUploadTransactions.mutateAsync({
        data: formTransactions,
        params: { autoCreateInvoicingSchedules: true },
      });
    },
    onDeny: async () => {
      await bulkUploadTransactions.mutateAsync({
        data: formTransactions,
        params: { autoCreateInvoicingSchedules: false },
      });
    },
  });

  const customersIdSources = useMemo(() => {
    const sources = {
      ...getOrgIdSources({
        integrations,
        internalSource: uploadCsvCustomersIdSource,
        suffix: 'customer ID',
      }),
      [INTERNAL_CUSTOMER_ID_SOURCE]: INTERNAL_CUSTOMER_ID_SOURCE,
    };

    sources[NAME_MATCHING_SOURCE] = 'Customer name';

    return sources;
  }, [integrations, uploadCsvCustomersIdSource]);

  useEffect(() => {
    if (organizations) {
      setCustomerIdSourceAndType(getInitialIdSource({ idSources: customersIdSources }));
    }
  }, [organizations, customersIdSources]);

  // With EXTERNAL source we get Customers from our imports table
  const {
    data: dataCustomerImports,
    isLoading: isCustomerImportsLoading,
    operations: { refetch: refetchCustomerImports },
  } = useImportsAPI({
    orgId: organizations?.[0]?.id,
    filters: {
      providerName: customerIdSourceAndType.source,
      chifferObjectName: 'customer',
      includeCustomerName: true,
    },
    autoFetch: false,
  });

  // With INTERNAL source we get Customers from our customers table
  const {
    data: dataCustomers,
    isLoading: isCustomersLoading,
    operations: { refetch: refetchCustomers },
  } = useCustomersAPI({
    orgId: organizations?.[0]?.id,
    filters: { params: customersListParams },
    autoFetch: false,
  });

  // With INTERNAL source we get Transactions from our transactions table
  const {
    data: dataTransactions,
    isLoading: isTransactionsLoading,
    operations: { refetch: refetchTransactions },
  } = useTransactionsAPI({
    orgId: organizations?.[0]?.id,
    filters: {
      limit: 30000,
    },
    autoFetch: false,
  });

  const {
    operations: { bulkUpload: bulkUploadTransactions },
  } = useTransactionsAPI({
    orgId: organizations[0].id,
    autoFetch: false,
  });

  // With EXTERNAL source we get Transactions from our imports table
  const {
    data: dataTransactionImports,
    isLoading: isTransactionImportsLoading,
    operations: { refetch: refetchTransactionImports },
  } = useImportsAPI({
    orgId: organizations?.[0]?.id,
    filters: {
      providerName: transactionsIdSourceAndType.source,
      chifferObjectName: 'transaction',
      includeTransactionFields: true,
    },
    autoFetch: false,
  });

  const transactionsIdSources = useMemo(
    () =>
      getOrgIdSources({
        integrations,
        internalSource: INTERNAL_TRANSACTION_ID_SOURCE,
        suffix: 'transaction ID',
      }),
    [integrations],
  );

  useEffect(() => {
    if (organizations) {
      setTransactionsIdSourceAndType(getInitialIdSource({ idSources: transactionsIdSources }));
    }
  }, [organizations, transactionsIdSources]);

  // Fetch Transactions
  useEffect(() => {
    if (transactionsIdSourceAndType?.type === SOURCE_TYPES.INTERNAL) {
      refetchTransactions();
    } else {
      refetchTransactionImports();
    }
  }, [transactionsIdSourceAndType, refetchTransactions, refetchTransactionImports]);

  // Fetch Customers
  useEffect(() => {
    if (customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL) {
      refetchCustomers();
    } else {
      refetchCustomerImports();
    }
  }, [customerIdSourceAndType, refetchCustomers, refetchCustomerImports]);

  const transactions = useMemo(
    () => (transactionsIdSourceAndType?.type === SOURCE_TYPES.INTERNAL ? dataTransactions : dataTransactionImports),
    [dataTransactionImports, dataTransactions, transactionsIdSourceAndType?.type],
  );

  const transactionsSelectOptions = useMemo(
    () =>
      transactions
        ?.map((transaction) => ({
          value:
            transactionsIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
              ? transaction.id
              : transaction.provider_object_id,
          label:
            transaction.transaction_name ??
            transaction.name ??
            `No name / ${
              transactionsIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
                ? transaction.id
                : transaction.provider_object_id
            }`,
        }))
        .sort((a, b) => a?.label?.localeCompare(b?.label)),
    [transactions, transactionsIdSourceAndType?.type],
  );

  const customers = useMemo(
    () => (customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL ? dataCustomers : dataCustomerImports),
    [dataCustomers, dataCustomerImports, customerIdSourceAndType?.type],
  );

  const customersSelectOptions = useMemo(
    () =>
      customers
        ?.map((customer) => ({
          value: [NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(customerIdSourceAndType?.source)
            ? customer.id
            : customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
            ? customer?.metadata?.[uploadCsvCustomersIdSource]
            : customer.provider_object_id,
          label:
            customer.customer_name ??
            customer.name ??
            `No name / ${
              customerIdSourceAndType?.source === NAME_MATCHING_SOURCE
                ? customer.id
                : customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
                ? customer.metadata?.[uploadCsvCustomersIdSource]
                : customer.provider_object_id
            }`,
        }))
        .sort((a, b) => a?.label?.localeCompare(b?.label)),
    [customers, customerIdSourceAndType, uploadCsvCustomersIdSource],
  );

  const customersById = useMemo(
    () =>
      customers?.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {}),
    [customers],
  );

  const [selectedMetadataFields, setSelectedMetadataFields] = useState([]);

  const tableSchema = useMemo(() => {
    return getSchema({
      customers,
      transactions,
      products: organizations[0]?.products,
      customerIdSourceAndType,
      transactionsIdSourceAndType,
      uploadCsvCustomersIdSource,
    });
  }, [
    customers,
    transactions,
    organizations,
    customerIdSourceAndType,
    transactionsIdSourceAndType,
    uploadCsvCustomersIdSource,
  ]);

  const getColumnsWithCsvUploadState = useCallback(
    ({
      fuzzyRowsMapper,
      defaultMapper,
      setFuzzyRowsMapper,
      mappedData,
      formRef,
      csvColumnsMapper,
      setCsvColumnsMapper,
      csvColumns,
    }) =>
      getColumns({
        objects: UPLOADABLE_OBJECTS.TRANSACTIONS,
        customerIdSourceAndType,
        organization: organizations[0],
        mappedData,
        formRef,
        csvColumns,
        csvColumnsMapper,
        setCsvColumnsMapper,
        currency,
        selectedMetadataFields,
        setSelectedMetadataFields,

        customers,
        customersSelectOptions,
        customersById,
        customersIdSources,
        setCustomerIdSourceAndType,
        uploadCsvCustomersIdSource,

        transactions,
        transactionsSelectOptions,
        transactionsIdSources,
        transactionsIdSourceAndType,
        setTransactionsIdSourceAndType,

        fuzzyRowsMapper,
        setFuzzyRowsMapper,

        defaultMapper,
      }),
    [
      customers,
      customersSelectOptions,
      customersById,
      customerIdSourceAndType,
      organizations,
      customersIdSources,
      uploadCsvCustomersIdSource,
      setCustomerIdSourceAndType,
      transactionsIdSourceAndType,
      transactionsIdSources,
      setTransactionsIdSourceAndType,
      transactions,
      transactionsSelectOptions,
      currency,
      selectedMetadataFields,
      setSelectedMetadataFields,
    ],
  );

  const handleSubmit = async (values) => {
    setSubmittingForm(true);

    const formTransactions = getFormFinalTransactions({
      formTransactions: values,
      transactionsIdSourceAndType,
      uploadCsvCustomersIdSource,
      customerIdSourceAndType,
      subscriptTransactions: transactions,
      subscriptCustomers: customers,
      organizations,
    });

    if (hasBillingEnabled) {
      setFormTransactions(formTransactions);
      openConfirmCreateInvoicesModal();
    } else {
      await bulkUploadTransactions.mutateAsync({ data: formTransactions });
    }
  };

  const isLoading =
    submittingForm ||
    isCustomerImportsLoading ||
    isCustomersLoading ||
    isTransactionsLoading ||
    isTransactionImportsLoading;

  return (
    <>
      <CsvUpload
        entityName={UPLOADABLE_OBJECTS.TRANSACTIONS}
        isLoading={isLoading}
        schema={tableSchema}
        handleSubmit={handleSubmit}
        defaultMapper={DEFAULT_TRANSACTIONS_MAPPER}
        csvColumnsTransformations={DEFAULT_TRANSACTIONS_TRANSFORMATIONS}
        getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
        selectedMetadataFields={selectedMetadataFields}
      />

      <ConfirmCreateInvoicesModal />
    </>
  );
};
