import React, { useContext, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ISO_CODE_TO_SYMBOL, MAGIC_METADATA, NUMBER_FORMATS } from 'consts/global';
import { Flexer, FlexerColumn } from 'components/Core';
import { FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { AlertCircleIcon, RefreshIcon, TrashIcon } from 'components/Icons';
import { DescriptionInput } from 'views/Billing/Common/styles';
import { InvoicingScheduleContext } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleContext';
import { isProductBundle } from 'views/Billing/InvoicingScheduleModal/utils';
import { ExternalProductSelector } from 'views/Billing/InvoicingScheduleModal/ExternalProductSelector';
import {
  AlertWrapper,
  InvoiceItemRecognition,
  RemoveWrapper,
  SyncAmountButton,
  TaxCodeDescription,
} from 'views/Billing/InvoicingScheduleModal/InvoiceScheduleWarnings/styles';
import { Popover, PopoverActions, PopoverPrompt, PopoverWrapper } from 'components/Portal';
import { useClickOutside } from 'utils/hooks';
import { numberFormatter } from 'utils/formatters';
import { ReactComponent as CornerArrowIcon } from 'images/corner-down-right-icon.svg';
import { InvoiceItemBundleItems } from './InvoiceItemBundleItems';
import {
  TooltipInfo,
  TooltipOpenTransactionLink,
  TooltipTransactionInfo,
  TooltipTransactionName,
  TransactionTooltipBody,
} from './styles';

const StyledCornerArrowIcon = styled(CornerArrowIcon)`
  width: 16px;
  height: 16px;

  path {
    fill: var(--primaryBlack20);
  }
`;

export const InvoiceItem = ({
  index,
  item,
  disableEditing,
  setFieldValue,
  values,
  syncInvoiceFields,
  isDragging,
  currency,
  products,
  isGroupItem,
  groupIndex,
  subIndex,
  isLastItem,
  hideItem,
}) => {
  const [showSyncPopover, setShowSyncPopover] = useState(false);

  const popoverRef = useClickOutside(() => setShowSyncPopover(false));

  const { transactionOptions, includedTransactions, invoicingServiceDisplayName } = useContext(
    InvoicingScheduleContext,
  );

  const hasAmountDiscrepancy =
    isProductBundle(item.metadata) &&
    item.metadata.bundleItems.reduce((acc, curr) => acc + (curr.unit_price ?? 0) * curr.seats, 0) !== item.amount;

  const removeInvoiceItem = useCallback(
    (invoiceItemIndex) => {
      const newInvoiceItems = ([...values.invoice_items] ?? []).filter((_, index) => index !== invoiceItemIndex);
      setFieldValue(`invoice_items`, newInvoiceItems);
      values.grouping &&
        setFieldValue(
          `grouping`,
          values.grouping
            .map((group, i) =>
              i === groupIndex
                ? {
                    ...group,
                    invoice_items_ids: group.invoice_items_ids.filter((_, si) => si !== subIndex),
                  }
                : group,
            )
            .filter((group) => !!group.invoice_items_ids?.length),
        );

      syncInvoiceFields({
        ...values,
        invoice_items: newInvoiceItems,
      });
    },
    [values, setFieldValue, syncInvoiceFields, groupIndex, subIndex],
  );

  const syncInvoiceItemAmount = useCallback(
    (updatedBundleItems) => {
      const totalAmount = updatedBundleItems.reduce((acc, curr) => acc + (curr.unit_price ?? 0) * curr.seats, 0);
      setFieldValue(`invoice_items[${index}].amount`, totalAmount);
      syncInvoiceFields({
        ...values,
        invoice_items: values.invoice_items.map((item, i) => (i === index ? { ...item, amount: totalAmount } : item)),
      });
    },
    [values, index, setFieldValue, syncInvoiceFields],
  );

  const handleProductChange = useCallback(
    ({ value: productId }) => {
      const selectedProduct = products.find((product) => product.id === productId);
      const bundleItems = selectedProduct.metadata?.bundle_items || [];
      const updatedInvoiceItems = values.invoice_items.map((invoiceItem) => {
        if (invoiceItem.id === item.id) {
          return {
            ...invoiceItem,
            product_id: productId,
            metadata: {
              ...invoiceItem.metadata,
              bundleItems: bundleItems.map((bundleItem) => ({
                id: bundleItem.id,
                name: bundleItem.name,
                seats: bundleItem.seats,
                unit_price: bundleItem.unit_price,
              })),
            },
          };
        }
        return invoiceItem;
      });
      setFieldValue('invoice_items', updatedInvoiceItems);
    },
    [products, values.invoice_items, item.id, setFieldValue],
  );

  const handleTransactionChange = useCallback(
    ({ value: transactionId }) => {
      const selectedTransaction = transactionOptions.find(({ value }) => value === transactionId);
      const updatedInvoiceItems = values.invoice_items.map((invoiceItem) => {
        if (invoiceItem.id === item.id) {
          return {
            ...invoiceItem,
            transaction_id: transactionId,
            product_id: selectedTransaction?.transaction?.product_id,
          };
        }
        return invoiceItem;
      });
      setFieldValue('invoice_items', updatedInvoiceItems);
    },
    [transactionOptions, values.invoice_items, item.id, setFieldValue],
  );

  // Stuff for dragging and dropping
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
    paddingTop: (index === 0 && groupIndex === 0 && subIndex === 0) || isGroupItem ? 6 : 20,
    paddingBottom: (!isGroupItem || isLastItem) && 20,
    visibility: isDragging || hideItem ? 'hidden' : 'visible',
    borderBottom: isLastItem && '1px solid var(--primaryBlack5)',
  };

  const getTooltipForTransaction = (index) => {
    const transactionId = values?.invoice_items?.[index]?.transaction_id;
    const transaction = includedTransactions.find((trx) => trx?.id === transactionId);

    return (
      <TransactionTooltipBody>
        <TooltipInfo>Transaction being invoiced. Not shown to customer</TooltipInfo>

        <TooltipTransactionName>{transaction?.name ?? 'No transaction name'}</TooltipTransactionName>

        <TooltipTransactionInfo>
          <div>{transaction?.product_name ?? 'No product name'}</div>

          <div>
            {numberFormatter({
              rawValue: transaction?.amount,
              type: NUMBER_FORMATS.CURRENCY,
              currency: currency,
            })}
          </div>
        </TooltipTransactionInfo>

        <TooltipOpenTransactionLink to={`/transactions/${transactionId}`} target="_blank">
          Open transaction
        </TooltipOpenTransactionLink>
      </TransactionTooltipBody>
    );
  };

  return (
    <FlexerColumn
      key={index}
      gap="8px"
      data-cy={`invoice-items__items-${index}`}
      style={style}
      ref={setNodeRef}
      {...attributes}
    >
      <Flexer gap="16px" width="100%" alignItems="center">
        <FlexerColumn gap="8px" width="100%">
          <Flexer gap="12px" alignItems="center">
            <InvoiceItemRecognition
              {...listeners}
              index={index}
              disableEditing={disableEditing}
              isGroupItem={isGroupItem}
            >
              {isGroupItem ? <StyledCornerArrowIcon /> : groupIndex + 1}
            </InvoiceItemRecognition>

            <FormikCustomSelector
              toolTipWidth={290}
              tooltipInputDisplay={getTooltipForTransaction(index)}
              placeholder="Select transaction"
              name={`invoice_items[${index}].transaction_id`}
              options={transactionOptions}
              containerWidth="80%"
              isDisabled={disableEditing}
              handleChange={handleTransactionChange}
              greyVer
            />

            <ExternalProductSelector
              index={index}
              valuesEntity="invoice_items"
              tooltipInputDisplay="Product being invoiced. Shown as line item"
              disableEditing={disableEditing}
              handleChange={handleProductChange}
              hideLabel
            />

            <FormikCustomInput
              name={`invoice_items[${index}].seats`}
              data-cy={`schedule-modal__invoice__item-${index}-seats${isGroupItem ? '__merged' : ''}`}
              type="number"
              precision={2}
              inputWidth="100px"
              isDisabled={disableEditing}
              handleChange={(value) => {
                setFieldValue(`invoice_items[${index}].seats`, value);
                syncInvoiceFields({
                  ...values,
                  invoice_items: values.invoice_items.map((item, i) =>
                    i === index ? { ...item, seats: value } : item,
                  ),
                });
              }}
            />
            <FormikCustomInput
              inputWidth="120px"
              name={`invoice_items[${index}].amount`}
              data-cy={`schedule-modal__invoice__item-${index}-amount${isGroupItem ? '__merged' : ''}`}
              suffix={ISO_CODE_TO_SYMBOL[values?.invoice_items?.[0]?.currency ?? currency] ?? '$'}
              precision={2}
              type="number"
              isDisabled={disableEditing}
              hasError={hasAmountDiscrepancy}
              handleChange={(value) => {
                setFieldValue(`invoice_items[${index}].amount`, value);

                syncInvoiceFields({
                  ...values,
                  invoice_items: values.invoice_items.map((item, i) =>
                    i === index ? { ...item, amount: value } : item,
                  ),
                });
              }}
            />
          </Flexer>
        </FlexerColumn>

        <PopoverWrapper ref={popoverRef} onMouseEnter={() => setShowSyncPopover(true)}>
          <AlertWrapper data-cy={`invoice_items[${index}]__amount-mismatch-alert`} isVisible={hasAmountDiscrepancy}>
            <AlertCircleIcon size="16px" />

            {showSyncPopover && (
              <Popover
                darkMode
                width="280px"
                YOffset={20}
                XOffset={-100}
                onMouseLeave={() => setShowSyncPopover(false)}
                showArrow
              >
                <PopoverPrompt opacity={1}>
                  Amount does not match the total sum of bundle items. When saved to {invoicingServiceDisplayName}, it
                  will use the total sum instead
                </PopoverPrompt>
                <PopoverActions padding="0px 8px 8px">
                  <SyncAmountButton
                    data-cy={`invoice_items[${index}]__sync-amount-button`}
                    icon={<RefreshIcon />}
                    iconFillColor="var(--primaryBlack)"
                    onClick={() => syncInvoiceItemAmount(item.metadata.bundleItems)}
                  >
                    <span>Sync Amount</span>
                  </SyncAmountButton>
                </PopoverActions>
              </Popover>
            )}
          </AlertWrapper>
        </PopoverWrapper>

        <RemoveWrapper
          data-cy={`invoice_items[${index}]__remove${isGroupItem ? '__merged' : ''}`}
          onClick={() => removeInvoiceItem(index)}
        >
          <TrashIcon />
        </RemoveWrapper>
      </Flexer>

      {!isGroupItem && (
        <DescriptionInput>
          <FormikCustomInput
            name={`invoice_items[${index}].description`}
            isDisabled={disableEditing}
            placeholder="Enter the invoice item description"
            data-cy={`schedule-modal__invoice__item-${index}-description${isGroupItem ? '__merged' : ''}`}
            handleChange={(value) => setFieldValue(`invoice_items[${index}].description`, value)}
          />
        </DescriptionInput>
      )}

      {/* Display items inside bundle */}
      {isProductBundle(item.metadata) && (
        <InvoiceItemBundleItems
          items={item.metadata.bundleItems}
          invoiceItemIndex={index}
          disableEditing={disableEditing}
          values={values}
          setFieldValue={setFieldValue}
          syncInvoiceItemAmount={syncInvoiceItemAmount}
          invoicingServiceDisplayName={invoicingServiceDisplayName}
        />
      )}

      {item?.metadata?.[MAGIC_METADATA.EXTERNAL_TAX_CODE] && (
        <TaxCodeDescription>
          {`${invoicingServiceDisplayName} Tax Code: ${item.metadata[MAGIC_METADATA.EXTERNAL_TAX_CODE]}`}
        </TaxCodeDescription>
      )}
    </FlexerColumn>
  );
};
