import { useCallback, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { NUMBER_FORMATS } from 'consts/global';
import { AppContext } from 'AppContext';
import { numberFormatter } from 'utils/formatters';
import { pluralize } from 'utils/stringUtils';
import { useInvoicingSchedulesAPI } from 'api/billing';
import { Flexer, FlexerColumn, Row } from 'components/Core';
import { AlertFilledIcon } from 'components/Icons';
import { ReactComponent as DeleteIcon } from 'images/trash.svg';
import { ReactComponent as ExternalLinkIcon } from 'images/external-link-billing.svg';
import { DeleteConfirmationModal } from 'shared/DeleteConfirmationModal';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { InvoicingScheduleContext } from './InvoicingScheduleContext';
import { earliestAndLatestDatesForTransactions } from './utils';
import { BillingContext } from '../BillingContext';

const AdditionalModalBody = styled(Flexer)`
  background-color: var(--primaryRed5);
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
`;

const ExternalInvoicesList = styled.div`
  background: white;
  border-radius: 8px;
  border: 1px solid var(--accentGraySecond);
`;

const BodyHeader = styled.span`
  font-size: 14px;
  font-weight: 900;
`;

const BodyDescription = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: var(--primaryBlack70);
`;

const ExternalLink = styled.a`
  font-size: 12px;
  font-weight: 700;
  display: flex;
  text-align: left;
  white-space: nowrap;
  overflow: visible;
  justify-content: flex-start;

  svg {
    min-width: 20px;
    height: 20px;
    margin-left: 8px;
  }
`;

const Invoice = styled.span`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  border-bottom: 1px solid var(--accentGraySecond);
  text-align: right;
  font-size: 12px;
  padding: 12px;

  &:last-child {
    border-bottom: none;
  }
`;

const REMOVE_INVOICE_FROM_INTEGRATION_ERRORS = [
  'One or more of the invoices on this schedule have been sent, voided, or paid. Cannot delete schedule',
  `Cannot modify invoices that are of status "AUTHORISED", "VOIDED", or "DELETED"`,
  'not found in Xero. Xero Id',
  'not found in Quickbooks. Quickbooks Id',
  'was deleted in Quickbooks.',
];

export const InvoicingScheduleDeleteModal = () => {
  const {
    orgId,
    currentInvoicingSchedule,
    refetchInvoicingSchedule,
    setShowDeleteModal,
    closeModal,
    invoicingScheduleFormValues,
    detachInvoicesBeforeRemoveSchedule,
    setDetachInvoicesBeforeRemoveSchedule,
  } = useContext(InvoicingScheduleContext);
  const { bulkDetachInvoices } = useContext(BillingContext);
  const {
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);
  const { currentTransaction, setCurrentTransaction } = useContext(TransactionContext) ?? {};

  const {
    operations: { removeInvoicingSchedule },
  } = useInvoicingSchedulesAPI({ orgId: orgId, autoFetch: false });

  const externalInvoices = invoicingScheduleFormValues?.invoices?.filter((invoice) => !!invoice?.invoice_external_url);

  useEffect(() => {
    if (!externalInvoices?.length) setDetachInvoicesBeforeRemoveSchedule(false);
  }, [externalInvoices, setDetachInvoicesBeforeRemoveSchedule]);

  const handleDelete = useCallback(() => {
    const handler = async () => {
      try {
        if (detachInvoicesBeforeRemoveSchedule) {
          await bulkDetachInvoices({
            data: externalInvoices,
          });
        }
        await removeInvoicingSchedule.mutateAsync({ id: currentInvoicingSchedule.id });
        if (currentTransaction?.invoicing_schedule_id) {
          // Removes invoicing schedule from transaction details
          setCurrentTransaction({ ...currentTransaction, invoicing_schedule_id: null });
        }

        setShowDeleteModal(false);
        closeModal();
      } catch (err) {
        const errMessage = err?.response?.data?.errors?.message;

        if (
          REMOVE_INVOICE_FROM_INTEGRATION_ERRORS.some((err) => errMessage.includes(err)) &&
          !!externalInvoices?.length
        ) {
          setDetachInvoicesBeforeRemoveSchedule(true);
          await refetchInvoicingSchedule();
        }

        console.error({ message: err.message, component: 'InvoicingScheduleDeleteModal.js', stack: err });
      }
    };
    handler();
  }, [
    removeInvoicingSchedule,
    setShowDeleteModal,
    closeModal,
    bulkDetachInvoices,
    detachInvoicesBeforeRemoveSchedule,
    currentInvoicingSchedule.id,
    setDetachInvoicesBeforeRemoveSchedule,
    externalInvoices,
    refetchInvoicingSchedule,
    currentTransaction,
    setCurrentTransaction,
  ]);

  const { earliest, latest } = earliestAndLatestDatesForTransactions(currentInvoicingSchedule.transactions);

  const additionalModalBody = (
    <>
      {detachInvoicesBeforeRemoveSchedule && (
        <>
          <AdditionalModalBody data-cy="invoicing-schedule__remove__detach-section" direction="column">
            <Row vertical="flex-start">
              <AlertFilledIcon width={20} height={20} style={{ marginRight: 8, minWidth: 20 }} />
              <i>
                Your GL <b style={{ color: 'var(--tertiaryRed)' }}>will not allow Subscript to delete</b> the invoices
                below automatically, but you can do it manually in your GL
              </i>
            </Row>

            <ExternalInvoicesList>
              {externalInvoices?.map((invoice) => (
                <Invoice key={invoice?.id}>
                  <ExternalLink onClick={() => window.open(invoice?.invoice_external_url, '_blank')}>
                    {invoice?.invoice_number ?? 'Invoice'}

                    <ExternalLinkIcon />
                  </ExternalLink>
                  <div>{dayjs.utc(invoice?.date).format('MMM D, YYYY')}</div>
                  <div>
                    {numberFormatter({
                      rawValue: invoice?.amount,
                      type: NUMBER_FORMATS.CURRENCY,
                      decimalPlaces: 0,
                      currency: invoice?.currency ?? defaultOrgCurrency,
                    })}
                  </div>
                </Invoice>
              ))}
            </ExternalInvoicesList>
          </AdditionalModalBody>

          <div style={{ marginTop: 8 }}>
            We’ll detach the {pluralize(externalInvoices?.length, 'invoice')} above and then proceed to delete the
            schedule and all related invoices in Subscript.
          </div>

          <b style={{ fontSize: 12 }}>
            <i>This cannot be un-done.</i>
          </b>
        </>
      )}

      <AdditionalModalBody>
        <DeleteIcon />
        <FlexerColumn gap="8px">
          <BodyHeader>{`${currentInvoicingSchedule.customer_name}: ${numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue:
              currentInvoicingSchedule.totalAmount ??
              (currentInvoicingSchedule.invoices ?? []).reduce((acc, curr) => acc + curr?.amount, 0),
            decimalPlaces: 2,
            currency: currentInvoicingSchedule.currency ?? defaultOrgCurrency,
          })}`}</BodyHeader>
          <BodyDescription>{`${currentInvoicingSchedule.invoices.length} invoices (${dayjs
            .utc(earliest)
            .format('MMM D, YYYY')} - ${dayjs.utc(latest).format('MMM D, YYYY')})`}</BodyDescription>
        </FlexerColumn>
      </AdditionalModalBody>
    </>
  );

  return (
    <DeleteConfirmationModal
      removeButtonText={detachInvoicesBeforeRemoveSchedule ? 'Detach & Delete' : 'Delete'}
      modalName="invoicing-schedule-delete-modal"
      modalTitle="Remove Invoices and Schedule"
      modalDescription={
        detachInvoicesBeforeRemoveSchedule
          ? ''
          : "The schedule, all invoices in Subscript, and all invoices sync'd to your General Ledger will be deleted. This cannot be un-done."
      }
      isLoading={removeInvoicingSchedule.isLoading}
      onClose={() => setShowDeleteModal(false)}
      onDeleteConfirm={handleDelete}
      additionalModalBody={additionalModalBody}
    />
  );
};
