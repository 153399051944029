import { WATERFALL_TYPE_OPTIONS } from 'shared/Filters';

export const getARR = (dataMRR, waterfallType) => {
  const result = JSON.parse(JSON.stringify(dataMRR)); //making a deep copy

  for (const monthMetrics of Object.values(result.revenueStats)) {
    for (const metric of Object.keys(monthMetrics)) {
      monthMetrics[metric] = monthMetrics[metric] * 12;
    }
  }

  for (const summaryMetric of Object.values(result.summaryStats)) {
    const revenueStats = summaryMetric.waterfall.revenue;
    for (const metric of Object.keys(revenueStats)) {
      revenueStats[metric] = revenueStats[metric] * 12;
    }

    if (waterfallType === 'Revenue') {
      const breakdownStats = summaryMetric.waterfall.breakdown;
      for (const segmentType of Object.keys(breakdownStats)) {
        for (const breakdownUnit of Object.keys(breakdownStats[segmentType])) {
          for (const breakdownMetric of Object.keys(breakdownStats[segmentType][breakdownUnit])) {
            for (const segmentKey of Object.keys(breakdownStats[segmentType][breakdownUnit][breakdownMetric])) {
              breakdownStats[segmentType][breakdownUnit][breakdownMetric][segmentKey] =
                breakdownStats[segmentType][breakdownUnit][breakdownMetric][segmentKey] * 12;
            }
          }
        }
      }
    }
  }

  if (waterfallType === 'Revenue') {
    const breakdown = result.breakdowns;
    for (const monthKey of Object.keys(breakdown)) {
      for (const segmentType of Object.keys(breakdown[monthKey])) {
        for (const breakdownUnit of Object.keys(breakdown[monthKey][segmentType])) {
          for (const breakdownMetric of Object.keys(breakdown[monthKey][segmentType][breakdownUnit])) {
            for (const segmentKey of Object.keys(breakdown[monthKey][segmentType][breakdownUnit][breakdownMetric])) {
              breakdown[monthKey][segmentType][breakdownUnit][breakdownMetric][segmentKey] =
                breakdown[monthKey][segmentType][breakdownUnit][breakdownMetric][segmentKey] * 12;
            }
          }
        }
      }
    }
  }

  return result;
};

export const getTableData = ({ isARR, waterfallType, waterfallData }) => {
  let data = waterfallData;
  switch (waterfallType) {
    case WATERFALL_TYPE_OPTIONS.revenue:
      data = isARR ? getARR(waterfallData, waterfallType) : waterfallData;
      return {
        tableData: data.revenueStats,
        summaryData: data.summaryStats,
        breakdownData: data.breakdowns,
      };
    case WATERFALL_TYPE_OPTIONS.customers:
      return {
        tableData: data.customerStats,
        summaryData: data.summaryStats,
        breakdownData: data.breakdowns,
      };
    case WATERFALL_TYPE_OPTIONS.seats:
      return {
        tableData: data.seatsStats,
        summaryData: data.summaryStats,
        breakdownData: data.breakdowns,
      };
    default:
  }
};
