import { FlexerColumn, Row, Spacer } from 'components/Core';
import { SEARCH_CONFIDENCE, bulkFuzzySearch } from 'utils/stringUtils';

const FuzzyMatchTooltipContent = ({ original, matched, text }) => (
  <FlexerColumn>
    <Row>
      Original value: <Spacer width="5px" /> <b> {original} </b>
    </Row>
    <Row>
      Matched to: <Spacer width="5px" />
      <b> {matched} </b>
    </Row>
    <Spacer height="10px" />
    <Row> {text} </Row>
  </FlexerColumn>
);

export const getFuzzyCellData = ({
  rowValue,
  dataset,
  candidateValueKey = 'id',
  candidateTitleKey = 'name',
  columns = ['name'],
}) => {
  const fuzzyRowValue = bulkFuzzySearch({
    queries: [rowValue],
    dataset: dataset,
    columns,
  });

  const { data: candidates, query, confidence } = fuzzyRowValue?.[0];

  const value = confidence > SEARCH_CONFIDENCE.NO_MATCH ? candidates?.[0]?.[candidateValueKey] : rowValue;

  const fuzzyTooltip =
    confidence === SEARCH_CONFIDENCE.FUZZY ? (
      candidates.length > 1 ? (
        <FuzzyMatchTooltipContent
          original={query}
          matched={candidates?.[0]?.[candidateTitleKey]}
          text={`There were ${candidates.length} possible fuzzy matches. If this isn't the desired match, please select the correct one among the candidates whose names fuzzily match with your file data`}
        />
      ) : (
        <FuzzyMatchTooltipContent
          original={query}
          matched={candidates?.[0]?.[candidateTitleKey]}
          text="Please verify that this is the correct candidate, and edit the CSV file and re-upload otherwise"
        />
      )
    ) : confidence === SEARCH_CONFIDENCE.NO_MATCH ? (
      'We cannot find any matching candidates from your CSV file. Please select one here, or edit the file and re-upload'
    ) : undefined;

  return { candidates, fuzzyTooltip, query, confidence, value };
};
