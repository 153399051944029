import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { useCSVReader, formatFileSize } from 'react-papaparse';
import styled from 'styled-components';
import { cssVar } from 'polished';
import { IconButton } from 'components/Buttons';
import { GreyDot } from 'components/Icons';
import { ReactComponent as ArrowRight } from 'images/arrow-narrow-right.svg';
import { ReactComponent as UploadIcon } from 'images/upload-csv-icon.svg';
import { ReactComponent as UploadedIcon } from 'images/uploaded-csv-icon.svg';
import { ReactComponent as ButtonIcon } from 'images/metered-csv-icon.svg';
import { ReactComponent as DragIcon } from 'images/drag-drop.svg';
import { ReactComponent as ClickIcon } from 'images/click-icon.svg';

export const Uploader = styled.div`
  min-height: ${({ smallView }) => (smallView ? '88px' : '60vh')};
  padding: ${({ smallView }) => smallView && '20px'};
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${({ zoneHover, fileLoaded }) =>
    zoneHover ? (fileLoaded ? 'var(--primaryBlue10)' : 'var(--primaryBlue5)') : 'var(--primaryBlue3)'};
  border: ${({ zoneHover }) => (zoneHover ? '1px dashed var(--primaryBlue)' : '1px dashed var(--primaryBlue20)')};
  border-radius: 20px;

  &:hover {
    background-color: var(--primaryBlue5);
    border: 1px dashed var(--primaryBlue);
  }
`;

export const Zone = styled.div`
  display: flex;
  flex-direction: ${({ smallView }) => (smallView ? 'row' : 'column')};
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
`;

export const UploadHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  border-bottom: 1px solid var(--primaryBlue5);
`;

export const StyledProgressBar = styled.div`
  span {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--primaryBlue5) !important;
    height: 100% !important;
    border-radius: 20px !important;
    border: none !important;
    box-shadow: none !important;
  }
`;

export const HeaderItem = styled.div`
  display: flex;
  padding: 20px;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  white-space: nowrap;

  a {
    margin-left: 4px;
    color: var(--primaryBlue);

    &:hover {
      color: var(--secondaryBlue);
    }
  }
`;

export const HeaderNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  background: rgba(64, 80, 98, 0.98);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  font-weight: 900;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
`;

export const SubInfo = styled.div`
  opacity: 0.5;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
`;

export const ZoneInfo = styled.div`
  display: flex;
  flex-direction: ${({ smallView }) => (smallView ? 'row' : 'column')};
  align-items: center;
`;

export const InfoText = styled.div`
  margin-top: ${({ smallView }) => !smallView && '20px'};
  display: flex;

  span {
    margin-left: 4px;
    color: var(--primaryBlue);
  }
`;

export const CancelButton = styled.div`
  color: var(--primaryRed);
  margin-left: 4px;
  position: relative;
  z-index: 5;

  &:hover {
    color: var(--secondaryRed);
  }
`;

export const ZoneFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ smallView }) => !smallView && '24px'};
  align-items: ${({ smallView }) => (smallView ? 'flex-end' : 'center')};

  span {
    font-size: 12px;
    line-height: 16px;
    opacity: ${({ smallView }) => !smallView && '0.2'};
    display: ${({ smallView }) => smallView && 'flex'};
    color: ${({ smallView }) => smallView && 'var(--primaryBlue)'};
  }
`;

export const StyledIconButton = styled(IconButton)`
  position: relative;
  z-index: 5;
  border: 1px solid var(--accentGraySecond);
  margin-top: 20px;
`;

export const FooterIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 68px;
  margin-bottom: 8px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ smallView }) => (smallView ? 'flex-start' : 'center')};
  background-color: ${({ smallView }) => smallView && 'var(--primaryBlue5)'};
  margin-left: ${({ smallView }) => smallView && '12px'};
  border-radius: ${({ smallView }) => smallView && '8px'};
  padding: ${({ smallView }) => smallView && '4px 8px'};
`;

export const OpacityText = styled.div`
  opacity: 0.3;
  margin-left: 4px;
  color: var(--primaryBlack);
`;

// TODO: Migrate to FileUploader if possible
export const CsvUploader = ({ smallView, setParsedCsv, entityName }) => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [draftParsedCsv, setDraftParsedCsv] = useState();

  const [uploadedFile, setUploadedFile] = useState();

  useEffect(() => {
    if (!uploadedFile) setParsedCsv(null);
  }, [uploadedFile, setParsedCsv]);

  return (
    <Uploader smallView={smallView} fileLoaded={draftParsedCsv} zoneHover={zoneHover}>
      {!smallView && (
        <UploadHeader>
          <HeaderItem>
            <HeaderNumber>1</HeaderNumber>
            Download a
            <Link
              to={`${process.env.PUBLIC_URL}/templates/${capitalize(entityName)}_Template.csv`}
              target="_blank"
              download
            >
              template
            </Link>
          </HeaderItem>
          <ArrowRight />
          <HeaderItem>
            <HeaderNumber>2</HeaderNumber>
            Paste your data and upload the file
          </HeaderItem>
          <ArrowRight />
          <HeaderItem>
            <HeaderNumber>3</HeaderNumber>
            Check your data and finish uploading!
          </HeaderItem>
        </UploadHeader>
      )}

      <CSVReader
        config={{ header: true }}
        onUploadAccepted={(results) => {
          setZoneHover(true);

          //Small view is setting after the first upload
          //and in small view we dont have confirmation after upload csv
          //so we setParsedCsv instantly
          if (smallView) {
            setDraftParsedCsv(results);
            setParsedCsv(results);
          } else {
            setDraftParsedCsv(results);
          }
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => {
          setUploadedFile(acceptedFile);
          return (
            <Zone smallView={smallView} {...getRootProps()}>
              {!smallView && <div />}

              {acceptedFile ? (
                <div>
                  <ZoneInfo smallView={smallView}>
                    {zoneHover && draftParsedCsv ? (
                      <UploadedIcon width={smallView ? 48 : 68} height={smallView ? 48 : 68} />
                    ) : (
                      <UploadIcon />
                    )}
                    <InfoWrapper smallView={smallView}>
                      <InfoText smallView={smallView}>
                        {zoneHover && draftParsedCsv ? 'You’ve uploaded a file' : 'You’re uploading a file now'}.
                        <CancelButton {...getRemoveFileProps()}>{smallView ? 'Cancel import' : 'Cancel'}</CancelButton>
                      </InfoText>
                      <SubInfo>
                        {acceptedFile.name} ({formatFileSize(acceptedFile.size)})
                      </SubInfo>
                    </InfoWrapper>
                    {!smallView && (
                      <StyledIconButton
                        disabled={!draftParsedCsv}
                        color={cssVar('--accentGraySecond')}
                        icon={<ButtonIcon />}
                        onClick={(event) => {
                          event.stopPropagation();
                          setParsedCsv(draftParsedCsv);
                        }}
                        iconFillColor={cssVar('--primaryGreen')}
                      >
                        Check {entityName}
                      </StyledIconButton>
                    )}
                  </ZoneInfo>
                  <StyledProgressBar>
                    <ProgressBar />
                  </StyledProgressBar>
                </div>
              ) : (
                <ZoneInfo data-cy="upload-csv__load-zone">
                  <UploadIcon />
                  <InfoText>
                    Please
                    <span>upload file with {entityName}</span>
                  </InfoText>

                  <SubInfo>(csv only, less 100 mb)</SubInfo>
                </ZoneInfo>
              )}

              <ZoneFooter smallView={smallView}>
                <FooterIcons>
                  <ClickIcon style={{ opacity: 0.3 }} />
                  <GreyDot spacing={0} />
                  <DragIcon />
                </FooterIcons>
                <span>
                  {smallView ? (
                    <>
                      For re-uploading
                      <OpacityText>click or drag&drop</OpacityText>
                    </>
                  ) : (
                    'click or drag&drop for uploading'
                  )}
                </span>
              </ZoneFooter>
            </Zone>
          );
        }}
      </CSVReader>
    </Uploader>
  );
};
