import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { useQueryPolling } from 'api/jobHooks.helper';
import { getWaterfallData } from './requests';

export const CACHE_KEY = 'waterfall';

export const useWaterfallAPI = ({
  orgId,
  startMonth,
  endMonth,
  segmentBy,
  waterfallUnits,
  rollup,
  optimisticAnalytics,
  isCommitted,
  metadataFilter,
  autoFetch = true,
  timeseriesMonth,
  countInfluxAsRenewed,
  ratesType,
  ratesDate,
}) => {
  const queryClient = useQueryClient();

  // Keeping logic that was on the Waterfall Page
  const startMonthKey = `${startMonth.getFullYear()}-${startMonth.getMonth() + 1}-1`;
  const endMonthKey = `${endMonth.getFullYear()}-${endMonth.getMonth() + 1}-1`;

  const timeseriesMonthKey = timeseriesMonth ? dayjs(timeseriesMonth).format('YYYY-MM-DD') : undefined;

  const defaultParams = {
    startMonth: startMonthKey,
    endMonth: endMonthKey,
    segmentBy,
    timeseriesMonth: timeseriesMonthKey,
    rollup,
    isCommitted,
    optimisticAnalytics,
    waterfallUnits,
    countInfluxAsRenewed,
    customerMetadata: JSON.stringify(metadataFilter?.customerMetadata),
    transactionMetadata: JSON.stringify(metadataFilter?.transactionMetadata),
    ratesType,
    ratesDate,
  };

  const params = autoFetch ? defaultParams : {};

  const { data, error, isLoading, isFetching } = useQueryPolling({
    key: [CACHE_KEY, orgId, params],
    fetchFn: () => getWaterfallData({ orgId, params: { ...params, runInlineInTestMode: true } }),
    fetchWithJobIdFn: (jobId) => getWaterfallData({ orgId, params: { jobId } }),
    queryOptions: {
      enabled: autoFetch && !!orgId,
    },
    pollingErrorMessage: `Waterfall is taking too long to load for org ${orgId}`,
  });

  const refetch = () => queryClient.invalidateQueries(CACHE_KEY);

  return {
    data,
    error,
    isLoading,
    isFetching,
    refetch,
    defaultParams,
  };
};
