import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { ModalCloseIcon, ModalHeader, ModalTitleText } from 'components/Modal';
import { LinkBackButton } from 'components/Buttons';
import { CentererVertical, Flexer } from 'components/Core';
import { ReportIcon } from 'components/Icons';
import { CustomDatePicker } from 'components/Controls';

const ReportIconContainer = styled.div`
  padding: 10px 10px 4px 10px;
  box-shadow: 4px 4px 24px 0px var(--primaryBlack4);
  border: 1px solid var(--accentGraySecond);
  border-radius: 100px;
`;

const GreyText = styled.i`
  color: var(--primaryBlack50);
`;

export const AccountingMonthModalHeader = ({ month, revenueType, onClose, customerName, setCustomerId, setMonth }) => (
  <ModalHeader padding>
    <ModalCloseIcon onClose={onClose} data-cy="accounting-month-explain-modal__close-button" />

    <Flexer gap="16px" alignItems="center">
      {customerName ? (
        <LinkBackButton onClick={() => setCustomerId(null)}>Back</LinkBackButton>
      ) : (
        <ReportIconContainer>
          <ReportIcon />
        </ReportIconContainer>
      )}
      <ModalTitleText>
        <b>
          <CentererVertical gap="12px">
            <span>{`${revenueType} in`}</span>
            <CustomDatePicker
              name="accounting-spreads-modal__month"
              dropdownView
              showMonthYearPicker
              selected={dayjs.utc(`${month}-15`).toDate()}
              onChange={(month) => {
                setMonth(dayjs.utc(month).format('YYYY-MM'));
              }}
            />
            {customerName ? (
              <span>
                for <GreyText>{customerName}</GreyText>
              </span>
            ) : null}
          </CentererVertical>
        </b>
      </ModalTitleText>
    </Flexer>
  </ModalHeader>
);
