import dayjs from 'dayjs';
import { useQueryPolling } from 'api/jobHooks.helper';
import { getMonthlyBreakdown } from './requests';

export const CACHE_KEY = 'monthlyBreakdown';

export const useMonthlyBreakdownAPI = ({
  orgId,
  startMonth,
  startYear,
  endMonth,
  endYear,
  metadataFilter,
  rollup,
  plugins,
  optimisticAnalytics,
  autoFetch = true,
  isCommitted,
  countInfluxAsRenewed,
  growthType,
  segmentBy,
  includeLastTransactionUpdatedAt,
  hasCostAttribution,
  referral,
  usesMetricsEngine,
  ratesType,
  ratesDate,
}) => {
  const params = autoFetch
    ? {
        startmo: startMonth + 1,
        startyear: startYear,
        endmo: endMonth + 1,
        endyear: endYear,
        rollup,
        plugins,
        isCommitted,
        optimisticAnalytics,
        countInfluxAsRenewed,
        growthType,
        segmentBy,
        includeLastTransactionUpdatedAt,
        hasCostAttribution,
        referral,
        usesMetricsEngine,
        ratesType,
        ratesDate,
      }
    : {};

  const { data, error, isLoading, isFetching, refetch } = useQueryPolling({
    key: [CACHE_KEY, orgId, metadataFilter, params],
    fetchFn: () => getMonthlyBreakdown({ orgId, body: metadataFilter, params }),
    fetchWithJobIdFn: (jobId) => getMonthlyBreakdown({ orgId, params: { jobId } }),
    queryOptions: {
      enabled:
        autoFetch &&
        !!orgId &&
        plugins !== null &&
        plugins !== undefined &&
        dayjs(new Date(startYear, startMonth))
          .startOf('month')
          .diff(dayjs(new Date(endYear, endMonth)).endOf('month'), 'month') < 0,
    },
    pollingErrorMessage: `Dashboard is taking too long to load for org ${orgId}`,
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    refetchMonthlyBreakdown: refetch,
  };
};
