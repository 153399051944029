import styled from 'styled-components';
import { Centerer, Flexer, FlexBetweenContainer, FlexerColumn, FlexerRow } from 'components/Core';
import { IconButton } from 'components/Buttons';
import { ReactComponent as RotateClockwise2Icon } from 'images/rotate-clockwise-2.svg';

export const VerticalDivider = styled.div`
  width: 1px;
  height: 100%;
  background: var(--accentGraySecond);
`;

export const ApplyToAllPopover = styled.div`
  padding: 12px;
  border-radius: 12px;
  border: 1px solid var(--neutralGray);
  background: white;
  display: flex;
  flex-direction: column;
  width: 180px;
  position: absolute;
  align-items: center;
  right: -15px;
  z-index: 100;
  top: 38px;
  box-shadow: 16px 16px 60px 0px var(--primaryBlack20);

  svg {
    width: 20px;
    height: 20px;
    margin-bottom: 8px;
  }
`;

export const ApplyToAllPopoverText = styled.div`
  font-size: 12px;
  font-style: italic;
  line-height: 16px;
  text-align: center;
  margin-bottom: 12px;
`;

export const Queue = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

export const Reminder = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -8px;
`;

export const RemoveWrapper = styled(FlexerColumn)`
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    opacity: 0.3;

    path {
      fill: var(--primaryBlack);
    }
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

export const ReminderNumber = styled.div`
  font-weight: 900;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 4px 4px 24px var(--primaryBlack4);
  border-radius: 100px;
  background-color: ${({ active }) => active && 'var(--primaryBlue10)'};
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 20px var(--primaryBlack10);
  }
`;

export const ReminderSkipButton = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  margin-left: 4px;
  padding: 4px 8px;
  border: 1px solid var(--primaryBlack10);
  border-radius: 100px;
  cursor: pointer;

  &:hover {
    background-color: var(--primaryBlack5);
  }
`;

export const InvoiceItemColunmTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
`;

export const TabsText = styled.div`
  margin: 0 12px;
  font-size: 16px;
  line-height: 22px;
`;

export const InvoiceItemRecognition = styled(Centerer)`
  min-width: 24px;
  height: 24px;
  background: ${({ isGroupItem }) => (isGroupItem ? 'transparent' : '#ffffff')};
  border: ${({ isGroupItem }) => (isGroupItem ? 'none' : '1px solid var(--primaryBlack5)')};
  border-radius: 8px;
  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--primaryBlack30);
  cursor: ${({ disableEditing }) => (disableEditing ? 'pointer' : 'move')};
`;

export const InvoiceItemTotal = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack40);

  padding-right: 36px;

  span {
    color: var(--primaryBlack);
    font-weight: 700;
    margin-left: 8px;
  }
`;

export const InvoiceItemLink = styled(Flexer)`
  align-items: flex-end;
  height: 100%;
  padding-bottom: 6px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    path {
      opacity: 0.3;
    }
  }

  &:hover {
    svg {
      path {
        opacity: 0.7;
      }
    }
  }
`;

export const RotateIcon = styled(RotateClockwise2Icon)`
  path {
    fill: var(--secondaryYellow);
  }
`;

export const InputSubtext = styled.span`
  margin-top: -20px;
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
  color: var(--primaryBlack50);
  font-size: 12px;
  padding: 4px 12px;
  padding-top: 8px;
  border: 1px solid var(--accentGraySecond);
  border-top: 0px;
  border-radius: 0px 0px 8px 8px;
`;

export const EqualSign = styled.span`
  margin-top: 20px;
  color: var(--primaryBlack40);
`;

export const Divider = styled.hr`
  height: 1px;
  width: 100%;
  border: none;
  margin: 12px 0;
  background-color: var(--accentGraySecond);
`;

export const InternalNotesLabel = styled(FlexBetweenContainer)`
  align-items: center;

  span {
    font-weight: 700;
    font-size: 12px;
  }
`;

export const PaidAtDatePickerContainer = styled(Centerer)`
  padding: 8px;
`;

export const PaidAtLabel = styled.span`
  color: var(--white60);
`;

export const PaidAtDatePicker = styled(FlexBetweenContainer)`
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: var(--dark20);
  border: 1px solid var(--dark10);
  font-weight: 400;
  font-size: 12px;
  color: white;

  &:hover {
    background-color: var(--dark10);
  }
`;

export const SectionTitle = styled.div`
  color: var(--primaryBlack);
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
`;

export const BillingAddressLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack40);

  span {
    color: var(--primaryBlack);
  }
`;

export const BillingAddress = styled.div`
  font-size: 12px;
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  font-weight: 700;
  color: var(--primaryBlack);
  line-height: 16px;
`;

export const InfoIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: ${({ labelDirection, labelFlex }) =>
    labelFlex === 'end' || labelDirection === 'left' ? '8px' : '0px'};

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const UnlockFieldsButton = styled(Centerer)`
  cursor: pointer;
  background-color: var(--dark15);
  padding: 8px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 900;
`;

export const ScheduleEmailSection = styled(FlexerRow)`
  border: 1px solid var(--accentGraySecond);
  padding: 12px;
  border-radius: 8px;
`;

export const InvoiceItemsWrapper = styled.div`
  padding: 16px;
  border-radius: 8px;
  gap: 12px;
  background-color: var(--primaryBlack2);
`;

export const Counter = styled.div`
  color: white;
  background-color: var(--primaryRed);
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  padding: 2px 5px;
  border-radius: 4px;
  margin-right: 8px;
`;

export const DismissAllButton = styled.div`
  border-radius: 100px;
  border: 1px solid var(--accentGraySecond);
  background: white;
  display: flex;
  align-items: center;
  padding: 8px;
  box-shadow: 2px 2px 12px 0px var(--primaryBlack2);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;

  svg {
    margin-right: 0 !important;
    margin-left: 8px;

    path {
      fill: var(--darkGreen);
    }
  }

  &:hover {
    box-shadow: 2px 2px 12px 0px var(--primaryBlack10);
  }
`;

export const WarningsHeader = styled.div`
  font-size: 14px;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  box-shadow: 2px 24px 48px 0px var(--primaryBlack5);
  font-weight: 900;
  width: 100%;
  line-height: 20px;
  border-bottom: 1px solid var(--primaryBlack10);
  padding: 16px 40px;

  svg {
    margin-right: 8px;
  }
`;

export const WarningsContainer = styled.div`
  padding: 4px 40px;
  flex-grow: 1;
  width: 100%;
`;

export const DismissButton = styled.div`
  padding: 4px 8px;
  font-size: 11px;
  font-style: italic;
  margin-left: 8px;
  font-weight: 700;
  line-height: 14px;
  border-radius: 100px;
  cursor: pointer;
  border: 1px solid var(--primaryBlack5);
  margin-top: -4px;

  &:hover {
    border: 1px solid var(--primaryBlack20);
  }
`;

export const Warning = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px 4px;
  font-size: 12px;
  font-style: normal;
  width: 100%;
  font-weight: 400;
  line-height: 16px;
  border-bottom: 1px solid var(--accentGraySecond);
`;

export const WarningCounter = styled.div`
  color: var(--tertiaryRed);
  border: 1px solid var(--tertiaryRed);
  border-radius: 4px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
`;

export const InvoiceWarningContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid var(--primaryRed10);
  color: var(--primaryRed);
  background: var(--primaryRed10);

  ${({ active }) =>
    active &&
    `color: var(--secondaryRed);
    background: var(--newRed15);
    border: 1px solid var(--newRed35);

    svg path {
      fill: var(--secondaryRed);
    }

    ${Counter} {
      background: var(--secondaryRed);
    }`};

  &:hover {
    color: var(--secondaryRed);
    background: var(--newRed15);
    border: 1px solid var(--newRed35);

    svg path {
      fill: var(--secondaryRed);
    }

    ${Counter} {
      background: var(--secondaryRed);
    }
  }
`;

export const AlertWrapper = styled(FlexerColumn)`
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  cursor: ${({ isVisible }) => (isVisible ? 'pointer' : 'default')};

  margin-left: -14px;

  span {
    height: 16px;
  }
`;

export const BundleItemsWrapper = styled(FlexerColumn)`
  background-color: var(--primaryBlack3);
  border-radius: 8px;
  padding: 8px;
`;

export const BundleItemTitle = styled(FlexerRow)`
  color: var(--primaryBlack40);

  svg {
    opacity: 0.2;
  }
`;

export const BundleItemName = styled.div`
  width: 100%;

  input {
    background-color: var(--primaryBlack3);
    border: none;
  }
`;

export const SyncAmountButton = styled(IconButton)`
  width: 100%;
  margin-top: 8px;

  svg {
    width: 15px;
    height: 15px;
  }

  span {
    font-size: 12px;
  }
`;

export const TaxCodeDescription = styled(Flexer)`
  padding-right: 50px;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryBlack40);
`;
