import React from 'react';
import { HEADER_TITLES } from 'shared/Layout';
import { ReactComponent as ArrowDown } from 'images/arrows-bold-down.svg';
import { humanize } from 'utils/stringUtils';
import { ConfigLinksPopover } from 'shared/Layout/Navbar/ConfigLinksPopover';
import { usePortal } from 'components/Portal';
import { SYNC_ENTITIES, SyncEntityButton } from 'shared/Common';
import { Spacer } from 'components/Core';
import { getIntegrationDisplayName } from 'models/integration';

import { ConfigurationHead, ConfigurationTitle, ActiveTab, StyledArrow } from './styles';

const ConfigurationHeader = ({ activeTab, selectedIntegration }) => {
  const { triggerRef, togglePortal, isPortalVisible, hidePortal, Portal } = usePortal();

  const { type } = selectedIntegration ?? {};

  return (
    <>
      <ConfigurationHead>
        <ConfigurationTitle>
          {HEADER_TITLES.configure}:
          <ActiveTab ref={triggerRef} onClick={togglePortal}>
            {type ? `${getIntegrationDisplayName(selectedIntegration)} (${type})` : humanize(activeTab)}
            <StyledArrow active={isPortalVisible}>
              <ArrowDown width={8} height={8} />
            </StyledArrow>
          </ActiveTab>
          {activeTab === SYNC_ENTITIES.PRODUCTS && (
            <>
              <Spacer width="12px" />
              <SyncEntityButton entity={SYNC_ENTITIES.PRODUCTS} />
            </>
          )}
        </ConfigurationTitle>
      </ConfigurationHead>

      {isPortalVisible && (
        <Portal>
          <ConfigLinksPopover pageHeaderView onClose={hidePortal} />
        </Portal>
      )}
    </>
  );
};

export { ConfigurationHeader };
