import React, { useCallback, useState } from 'react';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { SelectImportInvoicesModal } from './SelectImportInvoicesModal';

export const useSelectImportInvoicesModal = ({ onImportInvoicesSelect, selectedInvoices }) => {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const [customerId, setCustomerId] = useState();

  const openModal = useCallback(
    ({ customerId }) => {
      setCustomerId(customerId);
      setShowModal(true);

      trackEvent({
        name: EVENTS.OPEN_SELECT_INVOICES_TO_IMPORT,
        properties: { customerId },
      });
    },
    [trackEvent, setCustomerId, setShowModal],
  );

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const Modal = useCallback(
    () =>
      showModal ? (
        <SelectImportInvoicesModal
          customerId={customerId}
          onClose={closeModal}
          onImportInvoicesSelect={onImportInvoicesSelect}
          selectedInvoices={selectedInvoices}
        />
      ) : null,
    [showModal, customerId, closeModal, onImportInvoicesSelect, selectedInvoices],
  );

  return {
    openModal,
    Modal,
  };
};
