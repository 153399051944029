import React, { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { CsvTable } from './CsvTable';

export const ImportForm = ({
  currentTab,
  mappedData,
  parsedCsv,
  formRef,
  schema,
  csvColumnsMapper = {},
  entityName,
  setCsvColumnsMapper,
  setFormIsValid,
  handleSubmit,
  getColumnsWithCsvUploadState,
  fuzzyRowsMapper,
  defaultMapper,
  setFuzzyRowsMapper,
  showViewModes,
}) => {
  const csvColumns = Object.keys(parsedCsv?.data?.[0] ?? {}).map((columnKey) => ({
    value: columnKey,
    label: columnKey,
  }));

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const columns = useMemo(
    () =>
      getColumnsWithCsvUploadState({
        fuzzyRowsMapper,
        csvColumnsMapper,
        setCsvColumnsMapper,
        csvColumns,
        mappedData,
        formRef,
        defaultMapper,
        setFuzzyRowsMapper,
      }),
    [
      fuzzyRowsMapper,
      csvColumnsMapper,
      setCsvColumnsMapper,
      csvColumns,
      mappedData,
      formRef,
      defaultMapper,
      getColumnsWithCsvUploadState,
      setFuzzyRowsMapper,
    ],
  );

  return (
    <Formik
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={mappedData}
      innerRef={formRef}
    >
      <Form>
        <CsvTable
          fuzzyRowsMapper={fuzzyRowsMapper}
          currentTab={currentTab}
          setFormIsValid={setFormIsValid}
          formRef={formRef}
          columns={columns}
          entityName={entityName}
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
          showViewModes={showViewModes}
        />
      </Form>
    </Formik>
  );
};
