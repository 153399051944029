import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { numberFormatter } from 'utils/formatters';
import { InlineButton } from 'components/Buttons';
import { CentererVertical, Flexer, FlexerColumn, FlexerRow } from 'components/Core';
import { CustomDatePicker, FormikCustomInput } from 'components/Controls';
import { templateToHTMLString } from 'utils/htmlUtils';
import { CREDIT_NOTE_DYNAMIC_TAGS, DEFAULT_AVAILABLE_TAGS } from 'consts/billing';
import { useMemoTemplateDropdownButton } from 'views/Billing/Common/useMemoTemplateDropdownButton';
import {
  CREDIT_NOTE_JOB_STATUS,
  CREDIT_NOTE_METADATA,
  MANUAL_GL_SERVICE_NAME,
  NO_GL_SERVICE_NAME,
} from 'views/Billing/consts';
import { TooltipContainer } from 'components/Tooltip';
import { InvoiceItemColumnTitle } from 'views/Billing/Common/styles';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { AmountBalanceWrapper, FormWrapper, AutomaticTaxNotification, TaxesLoader } from '../styles';
import { CreditNoteContext } from '../CreditNoteContext';
import { SectionTitle } from '../InvoiceScheduleWarnings/styles';
import { CreditNoteItem } from './CreditNoteItem';
import { AllocateCreditNoteSection } from './AllocateCreditNoteSection';
import { CreditNoteTitleInput } from './CreditNoteTitleInput';
import { CreditNoteFailedSendWarning } from './CreditNoteWarnings/CreditNoteFailedSendWarning';

const ItemsWrapper = styled(FlexerColumn)`
  width: 100%;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  background: var(--primaryBlack2);
`;

const MemoTemplateDropdown = ({ isDisabled, setContent }) => {
  const { MemoTemplateDropdownButton, templates } = useMemoTemplateDropdownButton({
    isDisabled,
    name: 'credit-note-view__memo-templates-dropdown',
    buttonText: 'Templates',
    onSelect: (id) => {
      const content = templates?.find((template) => template.id === id)?.content;
      setContent(content);
    },
  });
  return <MemoTemplateDropdownButton />;
};

export const SingleCreditNote = ({ isWaitingForTaxes, pollingTaxesData }) => {
  const {
    appSettings: { currencyISOCode: orgCurrency },
  } = useContext(AppContext);
  const {
    usesGLConfiguredTaxCodes,
    invoicingServiceDisplayName,
    invoicingService,
    transactionMetadataTags,
    customerMetadataTags,
  } = useContext(InvoicingScheduleContext);
  const { getEmptyCreditNoteItem, selectedCreditNote } = useContext(CreditNoteContext);
  const { values, setFieldValue, getFieldMeta } = useFormikContext();

  const currency = values?.currency ?? orgCurrency;
  const readOnly = !!values?.allocated_externally || !!values?.voided_at;

  const subtotal = (values?.items ?? []).reduce((acc, curr) => acc + (curr.amount ?? 0), 0);
  const totalTax = pollingTaxesData ?? values?.total_tax;

  const availableTags = [
    ...DEFAULT_AVAILABLE_TAGS,
    ...CREDIT_NOTE_DYNAMIC_TAGS,
    ...transactionMetadataTags,
    ...customerMetadataTags,
  ];

  const addCreditNoteItem = useCallback(
    (event) => {
      event.preventDefault();
      setFieldValue(`items`, [...(values.items ?? []), getEmptyCreditNoteItem()]);
    },
    [setFieldValue, values, getEmptyCreditNoteItem],
  );

  return (
    <FormWrapper data-cy="credit-note-view__form">
      <FlexerColumn width="100%" gap="16px">
        {selectedCreditNote?.metadata?.[CREDIT_NOTE_METADATA.JOB_STATUS] === CREDIT_NOTE_JOB_STATUS.FAILED && (
          <CreditNoteFailedSendWarning creditNote={selectedCreditNote} />
        )}

        <FlexerRow width="100%" gap="16px">
          <FlexerColumn gap="16px" flexGrow={1}>
            <CustomDatePicker
              formik
              name="date"
              label="Credit Note Date"
              dateFormat="MMM dd, yyyy"
              meta={getFieldMeta('date')}
              selected={values?.date ? formatDateForDatepicker(values?.date) : null}
              onChange={(name, val) => setFieldValue(name, updateDateFromDatePicker(val))}
              disabled={readOnly}
              data-cy="credit-note-view__form__date"
            />
          </FlexerColumn>

          <FlexerColumn gap="16px" flexGrow={1}>
            <CreditNoteTitleInput isDisabled={readOnly} />
          </FlexerColumn>

          <FlexerColumn gap="16px" flexGrow={1}>
            <FormikCustomInput
              name="credit_note_number"
              label="Credit Note Number"
              placeholder={
                [MANUAL_GL_SERVICE_NAME, NO_GL_SERVICE_NAME].includes(invoicingService)
                  ? ''
                  : `Enter or get from ${invoicingServiceDisplayName}`
              }
              type="text"
              labelTooltipContent={
                [MANUAL_GL_SERVICE_NAME, NO_GL_SERVICE_NAME].includes(invoicingService)
                  ? null
                  : `Leave this blank to generate the number by ${invoicingServiceDisplayName} once you apply this to an invoice that is saved in ${invoicingServiceDisplayName}`
              }
              isDisabled={readOnly}
              data-cy="credit-note-view__form__credit_note_number"
            />
          </FlexerColumn>
        </FlexerRow>

        <FlexerRow width="100%" gap="16px">
          <FlexerColumn width="100%">
            <FormikCustomInput
              name="memo"
              disableLabelOpacity
              label={<SectionTitle>Memo: </SectionTitle>}
              data-cy="credit-note-view__memo"
              inputHeight="166px"
              HTMLString={templateToHTMLString({ text: values?.memo, availableTags })}
              availableTags={availableTags}
              type="HTMLEdit"
              TopRightHeader={
                <CentererVertical gap="8px">
                  <MemoTemplateDropdown
                    isDisabled={readOnly}
                    setContent={(content) => setFieldValue('memo', content)}
                  />
                </CentererVertical>
              }
              isDisabled={readOnly}
              background="var(--lightGray)"
            />
          </FlexerColumn>

          <FlexerColumn width="100%">
            <FormikCustomInput
              name="footer_memo"
              disableLabelOpacity
              label={<SectionTitle>Footer Memo: [BETA]</SectionTitle>}
              data-cy="credit-note-view__footer-memo"
              inputHeight="166px"
              HTMLString={templateToHTMLString({ text: values?.footer_memo, availableTags })}
              availableTags={availableTags}
              type="HTMLEdit"
              TopRightHeader={
                <CentererVertical gap="8px">
                  <MemoTemplateDropdown
                    isDisabled={readOnly}
                    setContent={(content) => setFieldValue('footer_memo', content)}
                  />
                </CentererVertical>
              }
              isDisabled={readOnly}
            />
          </FlexerColumn>
        </FlexerRow>

        <ItemsWrapper>
          <AllocateCreditNoteSection readOnly={readOnly} />

          <InvoiceItemColumnTitle>Credit Note Items</InvoiceItemColumnTitle>
          {(values?.items ?? []).map((item, index) => (
            <CreditNoteItem
              key={index}
              index={index}
              item={item}
              allocatedExternally={!!values?.allocated_externally}
              readOnly={!!values.voided_at}
            />
          ))}

          <Flexer justifyContent="space-between" width="100%" style={{ marginTop: 16 }}>
            <div>
              <Flexer gap="8px" justifyContent="flex-start">
                <TooltipContainer
                  toolTipContent={`This credit note was already allocated in ${invoicingServiceDisplayName} so you cannot add more items`}
                  isVisible={!!values?.allocated_externally}
                >
                  <InlineButton
                    lineHeight="15px"
                    fontSize="12px"
                    isSecondary
                    withBackground
                    onClick={addCreditNoteItem}
                    type="button"
                    data-cy="credit-note-view__form__add-item-button"
                    disabled={readOnly}
                  >
                    Add Item
                  </InlineButton>
                </TooltipContainer>
              </Flexer>
            </div>

            <FlexerColumn alignItems="flex-end" gap="8px">
              {isWaitingForTaxes ? (
                <TaxesLoader invoicingServiceDisplayName={invoicingServiceDisplayName} loaderName="credit-note-taxes" />
              ) : (
                usesGLConfiguredTaxCodes &&
                !values?.external_id && (
                  <AutomaticTaxNotification>
                    {`Save the credit note in ${invoicingServiceDisplayName} so that Subscript can use the `}
                    <span>{`tax calculations from ${invoicingServiceDisplayName}`}</span>
                  </AutomaticTaxNotification>
                )
              )}
              <AmountBalanceWrapper>
                <div>
                  {!isWaitingForTaxes ? 'Subtotal' : 'Total'}:{' '}
                  <span data-cy="credit-note-view__subtotal">
                    {numberFormatter({
                      rawValue: subtotal,
                      type: NUMBER_FORMATS.CURRENCY,
                      decimalPlaces: 2,
                      currency,
                    })}
                  </span>
                </div>
                {!isWaitingForTaxes && (
                  <>
                    <div>
                      Total taxes:{' '}
                      <span data-cy="credit-note-view__total-taxes">
                        {numberFormatter({
                          rawValue: totalTax ?? 0,
                          type: NUMBER_FORMATS.CURRENCY,
                          decimalPlaces: 2,
                          currency,
                        })}
                      </span>
                    </div>
                    <div>
                      Total amount:{' '}
                      <span data-cy="credit-note-view__total-amount">
                        {numberFormatter({
                          rawValue: values.amount ?? subtotal + (totalTax ?? 0),
                          type: NUMBER_FORMATS.CURRENCY,
                          decimalPlaces: 2,
                          currency,
                        })}
                      </span>
                    </div>
                  </>
                )}
              </AmountBalanceWrapper>
            </FlexerColumn>
          </Flexer>
        </ItemsWrapper>
      </FlexerColumn>
    </FormWrapper>
  );
};
