import React from 'react';
import { Row } from 'components/Core';
import CheckItem from './CheckItem';

const PipelinesStages = ({ selectedCRMIntegration, closedWonStages, closedWonClickHandler }) => {
  return (
    <>
      {!selectedCRMIntegration?.metadata?.closedWonStages &&
        selectedCRMIntegration?.metadata?.pipelines?.length > 0 &&
        selectedCRMIntegration.metadata.pipelines.map((pipeline) => (
          <Row horizontal="start" className="mb-2" style={{ flexWrap: 'wrap' }} key={pipeline.id}>
            {/* TODO: this needs to become controlled components */}
            {pipeline.stages.map((stageObject) => (
              <CheckItem
                type="checkbox"
                title={stageObject.label}
                active={closedWonStages.includes(stageObject.id)}
                key={stageObject.id}
                onClick={() => closedWonClickHandler(stageObject.id)}
              />
            ))}
          </Row>
        ))}
      {!selectedCRMIntegration?.metadata?.pipelines?.length && (
        <>No pipelines were found! Are you sure you have pipelines in Hubspot?</>
      )}
      {Boolean(selectedCRMIntegration?.metadata?.closedWonStages) && (
        <Row horizontal="start" className="mb-2" style={{ flexWrap: 'wrap' }}>
          {/* We allow the user to select closed-won stages from all pipelines, so we want to show them for all pipelines */}
          {selectedCRMIntegration?.metadata?.pipelines?.map((pipeline) => {
            return selectedCRMIntegration?.metadata?.closedWonStages.map((stage) => {
              const matchedStage = pipeline.stages.find((pipeStage) => pipeStage.id === stage);
              return (
                matchedStage && <CheckItem type="checkbox" title={matchedStage['label']} readonly={true} key={stage} />
              );
            });
          })}
        </Row>
      )}
    </>
  );
};

export default PipelinesStages;
