import React from 'react';
import { Fragment } from 'react';
import { ReactComponent as ResetIcon } from 'images/multiselect-x.svg';
import { Wrapper, Title, StyledResetIcon, StyledButton } from './MultiselectRibbon.styles';

export const MULTISELECT_RIBBON_BUTTONS_ROLES = {
  PRIMARY: 'primary',
  REMOVE: 'destructive',
};

const MultiselectRibbon = ({ label, actions, left, insertActionsDirectly = false, onResetSelection, dataCy }) => (
  <Wrapper left={left} data-cy={dataCy}>
    {label && <Title>{label}</Title>}
    {insertActionsDirectly
      ? actions
      : actions.map((action) =>
          !action.hidden ? (
            <Fragment key={action.label}>
              <StyledButton
                data-cy={`multiselect-ribbon__button--${action.label}`}
                role={action.role}
                isRound={action.isRound}
                onClick={action.onClick}
              >
                {action.label}
              </StyledButton>
            </Fragment>
          ) : null,
        )}
    {onResetSelection && (
      <StyledResetIcon onClick={() => onResetSelection()}>
        <ResetIcon />
      </StyledResetIcon>
    )}
  </Wrapper>
);

export { MultiselectRibbon };
