import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserAPI } from 'api/users';
import { initiateIntegrationLogin, initiateGoogleApi, useIntegrationsAPI } from 'api/integrations';
import { useOrganizationMetadataAPI } from 'api/organizations';
import { INTEGRATION_TYPES, INTEGRATION_SERVICES, GMAIL_FEATURES } from 'consts/integrations';
import { FEATURES, isFeatureActive } from 'utils/featureUtils';
import { capitalize } from 'utils/stringUtils';
import { useMVP } from 'utils/hooks';
import { ReactComponent as DoubleBox } from 'images/double-box-divider.svg';
import { ReactComponent as RedWarnIcon } from 'images/red-warn.svg';
import { Centerer } from 'components/Core';
import { CONFIGURATION_TABS } from 'views/Configuration/consts';
import { OAUTH_CALLBACK_PATH } from 'views/Wizard/consts';
import { AddIntegrationModal } from 'shared/AddIntegrationModal';
import { getIntegrationDisplayName } from 'models/integration';
import { MANUAL_GL_SERVICE_NAME } from 'views/Billing/consts';

import { PopoverLinkActionButton, PopoverLinkContents, PopoverLinkContentsItem } from './PopoverLinkContents';
import { BillingConfigSubLinksPopover } from './SubLinkPopovers/BillingConfigSubLinksPopover';

const IconContainer = styled(Centerer)`
  height: 28px;
  width: 28px;
  border: 1px solid var(--accentGraySecond);
  border-radius: 8px;
  background: #fff;
  position: absolute;
  top: ${({ top }) => top ?? '0'};
  transform: translate(-50%, 0);
  left: 50%;
`;

const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Divider = styled.hr`
  height: 1px;
  width: 100%;
  border: none;
  margin: 12px 0;
  background-color: var(--accentGraySecond);
`;

export const StyledRedWarnIcon = styled(RedWarnIcon)`
  path {
    fill: var(--primaryRed) !important;
  }
`;

export const ConfigLinksPopover = ({ pageHeaderView, onClose }) => {
  const title = 'Settings';

  const { orgId, integrations, orgConfigs } = useContext(AppContext);

  const isMVP = useMVP();

  const { data: organizationMetadata } = useOrganizationMetadataAPI({ orgId });
  const { currencies: allOrgNonHomeCurrencies } = organizationMetadata ?? {};

  const { data: disauthenticatedIntegrations } = useIntegrationsAPI({
    orgId,
    params: {
      includeProducts: false,
      onlyDisauthenticated: true,
    },
    refetchOnWindowFocus: 'always',
  });
  const { user: authUser = {} } = useAuth0();
  const { data: userData } = useUserAPI({ email: authUser.email });

  const [showAddIntegrationModal, setShowAddIntegrationModal] = useState(false);
  const toggleAddIntegrationModal = (event) => {
    event?.stopPropagation();
    setShowAddIntegrationModal(!showAddIntegrationModal);
  };

  const integrationsForDropdown = integrations.filter(
    (integration) =>
      [INTEGRATION_TYPES.CRM, INTEGRATION_TYPES.GL].includes(integration.type) &&
      integration.service !== MANUAL_GL_SERVICE_NAME &&
      !disauthenticatedIntegrations.map((int) => int.id).includes(integration.id),
  );

  const showBillingSettings = isFeatureActive({ feature: FEATURES.BILLING, orgConfigs }) || isMVP;

  return (
    <>
      <PopoverLinkContents title={title}>
        {disauthenticatedIntegrations?.length > 0 && (
          <>
            {disauthenticatedIntegrations.map((integration) => (
              <PopoverLinkContentsItem
                key={integration?.id}
                title={title}
                onClick={() =>
                  integration?.service === INTEGRATION_SERVICES.GMAIL
                    ? initiateGoogleApi({ params: { features: [GMAIL_FEATURES.SEND_EMAIL] } })
                    : initiateIntegrationLogin(userData.id, integration.service, OAUTH_CALLBACK_PATH.CONFIGS)
                }
                item={{
                  title: `Reintegrate ${capitalize(integration.service)}`,
                  description: 'Integration was disconnected',
                  showArrowRight: !pageHeaderView,
                  icon: <StyledRedWarnIcon />,
                }}
                highlighted
              />
            ))}
            <Divider />
          </>
        )}
        <PopoverLinkContentsItem
          title={title}
          item={{
            title: 'Members',
            description: 'Manage access of your team',
            to: `/configure?tab=${CONFIGURATION_TABS.MEMBERS}`,
            showArrowRight: !pageHeaderView,
          }}
          onClick={onClose}
        />
        <PopoverLinkContentsItem
          title={title}
          item={{
            title: 'Products',
            description: 'Set a recognition for each product',
            to: `/configure?tab=${CONFIGURATION_TABS.PRODUCTS}`,
            showArrowRight: !pageHeaderView,
          }}
          onClick={onClose}
        />
        <PopoverLinkContentsItem
          title={title}
          item={{
            title: 'General Settings',
            description: 'Settings that apply to all data',
            to: `/configure?tab=${CONFIGURATION_TABS.GENERAL_SETTINGS}`,
            showArrowRight: !pageHeaderView,
          }}
          onClick={onClose}
        />

        {allOrgNonHomeCurrencies?.length > 0 && (
          <PopoverLinkContentsItem
            title={title}
            item={{
              title: 'Exchange Rates',
              to: `/configure?tab=${CONFIGURATION_TABS.EXCHANGE_RATES}`,
              showArrowRight: !pageHeaderView,
            }}
            onClick={onClose}
          />
        )}

        {showBillingSettings && (
          <BillingConfigSubLinksPopover title={title} pageHeaderView={pageHeaderView} onClose={onClose} />
        )}
        <RelativeContainer>
          <Divider />

          <IconContainer>
            <DoubleBox />
          </IconContainer>
        </RelativeContainer>
        {integrationsForDropdown.map(({ service, type, id, metadata, display_name }) => (
          <PopoverLinkContentsItem
            key={id}
            title={title}
            item={{
              title: `${getIntegrationDisplayName({ display_name, service })} (${type})`,
              description: service.includes(INTEGRATION_SERVICES.SALESFORCE)
                ? metadata?.instance_url ?? ''
                : 'Integration settings',
              to: `/configure?tab=${CONFIGURATION_TABS.SOURCES}&integration=${id}`,
              showArrowRight: !pageHeaderView,
            }}
            onClick={onClose}
          />
        ))}
        <PopoverLinkActionButton parentTitle={title} title="Add Integration" onClick={toggleAddIntegrationModal} />
      </PopoverLinkContents>

      {showAddIntegrationModal && <AddIntegrationModal onClose={toggleAddIntegrationModal} />}
    </>
  );
};
