import styled from 'styled-components';
import { ReactComponent as CheckIcon } from 'images/check.svg';
import decorImage from 'images/schedule-guide-image.svg';
import { BlueText, Column } from 'components/Core';
import { useContext } from 'react';
import { InvoicingScheduleContext } from './InvoicingScheduleContext';

const Wrapper = styled.div`
  flex: 1;
  padding: 28px 40px;
  border-right: 1px solid var(--accentGraySecond);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => props.disabled && '0.3'};
  pointer-events: ${(props) => props.disabled && 'none'};
  background-image: url(${decorImage});
  background-repeat: no-repeat;
  background-position: center bottom;

  @media (min-width: 1280px) {
    min-width: 420px;
  }

  @media (min-width: 1440px) {
    min-width: 446px;
  }
`;

const Title = styled.div`
  width: 280px;
  font-style: italic;
  font-weight: 900;
  font-size: 30px;
  line-height: 40px;
  color: var(--primaryBlack30);
  margin-bottom: 20px;

  b {
    color: var(--primaryBlack);
  }
`;

const ListItems = styled.div``;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
`;

const Counter = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dark7);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  font-style: italic;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: white;
`;

const ItemText = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const InvoicingGuidePanel = () => {
  const { invoicingScheduleFormValues } = useContext(InvoicingScheduleContext);

  return (
    <Wrapper disabled={!!invoicingScheduleFormValues?.invoices?.length}>
      <Column horizontal="start" style={{ width: 280 }}>
        <Title>
          Creating
          <br /> and sending <b>invoices</b>
        </Title>

        <ListItems>
          <Item>
            <Counter>1</Counter>
            <ItemText>
              Select a <BlueText>customer</BlueText>
            </ItemText>
          </Item>

          <Item>
            <Counter>2</Counter>
            <ItemText>
              <BlueText>Add transactions</BlueText> for invoice(s)
            </ItemText>
          </Item>

          <Item>
            <Counter>3</Counter>
            <ItemText>
              Generate a <BlueText>schedule</BlueText>
            </ItemText>
          </Item>

          <Item>
            <CheckIcon style={{ marginRight: 8 }} />
            <ItemText>Check invoices and send them!</ItemText>
          </Item>
        </ListItems>
      </Column>
    </Wrapper>
  );
};
