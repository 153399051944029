import { useContext } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { ReactComponent as DatabaseIcon } from 'images/database.svg';
import { useStateWithStorage } from 'utils/hooks';
import { Centerer, Flexer } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { TabButton } from 'components/Buttons';
import { Loader } from 'components/Loaders';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { SectionIcon, SectionTitle } from 'shared/TransactionContent/styles';
import { useCombinedMetadata, useMetadataActionsModal } from 'shared/Common';
import { METADATA_FILTER_TYPES } from 'shared/Filters/MetadataFilter';

import { TransactionModalMetadataBody } from './TransactionModalMetadataBody';
import { MetadataWrapper, MetadataHeader } from './styles';

const LoadingMetadataText = styled.span`
  color: var(--primaryDark);
  opacity: 0.5;
  font-style: italic;
  font-size: 0.8rem;
  margin-top: 5px;
  display: inline-block;
`;

export const METADATA_TABS = { TRANSACTION: 'Transaction', CUSTOMER: 'Customer' };

export const TransactionModalMetadata = ({ disabled }) => {
  const { currentTransaction, organization, customer } = useContext(TransactionContext);

  const { setFieldValue, values } = useFormikContext();

  const [selectedTab, setSelectedTab] = useStateWithStorage(
    'transaction-modal-selected-metadata-tab',
    METADATA_TABS.TRANSACTION,
  );

  const { combinedMetadataOptions, isLoading } = useCombinedMetadata({
    orgId: organization.id,
    currentValues:
      selectedTab === METADATA_TABS.TRANSACTION
        ? {
            ...currentTransaction?.metadata,
            ...values?.metadata,
          }
        : {
            ...customer?.metadata,
          },
    metadataFilterType:
      selectedTab === METADATA_TABS.TRANSACTION ? METADATA_FILTER_TYPES.TRANSACTIONS : METADATA_FILTER_TYPES.CUSTOMERS,
  });

  const formatToValueStructure = ({ draft }) =>
    draft.reduce(
      (obj, item) => ({
        ...obj,
        [item.key]: item.value,
      }),
      {},
    );

  const handleFormChange = (newMetadata) => {
    if (selectedTab === METADATA_TABS.TRANSACTION) {
      setFieldValue('metadata', newMetadata);
    }
  };

  const handleOnCloseMetadataModal = (metadataItem) => {
    if (metadataItem) {
      const metadataItems = {
        ...formatToValueStructure({ draft: combinedMetadataOptions }),
        [metadataItem.key]: metadataItem.value,
      };

      handleFormChange(metadataItems);
    }
  };

  const { openCreateMetadataModal, MetadataActionsModal } = useMetadataActionsModal({
    organizationId: organization?.id,
    onClose: handleOnCloseMetadataModal,
  });

  return (
    <MetadataWrapper>
      <MetadataHeader>
        <Centerer>
          <SectionIcon>
            <DatabaseIcon />
          </SectionIcon>
          <TooltipContainer
            width={200}
            toolTipContent={
              'Metadata on customers and transactions lets you do analysis on your data cut in different ways'
            }
          >
            <SectionTitle>Metadata of:</SectionTitle>
          </TooltipContainer>
          <Flexer alignItems="center" justifyContent="flex-start">
            {Object.values(METADATA_TABS).map((tab) => (
              <TabButton
                isBig={false}
                key={tab}
                active={selectedTab === tab}
                onClick={() => {
                  setSelectedTab(tab);
                }}
                data-cy={`transaction-modal__metadata__${tab}-tab`}
              >
                {tab}
              </TabButton>
            ))}
          </Flexer>
        </Centerer>
      </MetadataHeader>
      {isLoading ? (
        <div
          className="w-100 flexer"
          style={{
            flexDirection: 'column',
            margin: 'auto',
          }}
        >
          <Loader containerStyles={{ width: 40 }} />
          <LoadingMetadataText>Fetching metadata...</LoadingMetadataText>
        </div>
      ) : (
        <TransactionModalMetadataBody
          metadata={combinedMetadataOptions}
          selectedTab={selectedTab}
          openCreateMetadataModal={openCreateMetadataModal}
          handleFormChange={handleFormChange}
          disabled={disabled}
        />
      )}
      <MetadataActionsModal />
    </MetadataWrapper>
  );
};
