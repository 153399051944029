import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { useImportsAPI } from 'api/imports';
import { useConfirmModal } from 'shared/ConfirmModal';
import { useToasts } from 'components/Toasts';
import { INTERNAL_CUSTOMER_METADATA } from 'models/customer';

export const useRemoveImportConfirmModal = ({
  serviceLabel,
  setLoading,
  initialCustomerData,
  editCustomer,
  invoicingService,
  integrationId,
}) => {
  const { orgId } = useContext(AppContext);
  const { pushError } = useToasts();
  const [selectedImportToRemove, setSelectedImportToRemove] = useState();

  const {
    operations: { deleteImport },
  } = useImportsAPI({
    orgId,
    autoFetch: false,
  });

  const { openConfirmModal, ConfirmModal } = useConfirmModal({
    title: 'Delete customer association?',
    content: <div>Are you sure you want to delete this {serviceLabel} customer association?</div>,
    closeButtonText: 'No, cancel',
    confirmButtonText: 'Yes, delete',
    onConfirm: async () => {
      const metadata = JSON.parse(JSON.stringify(initialCustomerData.metadata ?? {})); // making a deep copy

      const hasMetadaToRemove = metadata[`${invoicingService}_id`] || metadata.invoicing_details?.[invoicingService];
      const shouldUpdateCustomer =
        hasMetadaToRemove ||
        initialCustomerData.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID]?.toString() !==
          integrationId?.toString();

      if (hasMetadaToRemove) {
        delete metadata[`${invoicingService}_id`];
        delete metadata.invoicing_details?.[invoicingService];
      }

      try {
        setLoading(true);

        await deleteImport.mutateAsync({ importId: selectedImportToRemove });

        shouldUpdateCustomer &&
          (await editCustomer({
            id: initialCustomerData.id,
            data: {
              metadata: {
                ...metadata,
                [INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID]: integrationId,
              },
              invoicing_details: Object.keys(initialCustomerData.invoicing_details ?? {}).reduce((obj, key) => {
                const initalValue = initialCustomerData.invoicing_details?.[key];
                const glValue = initialCustomerData.metadata?.invoicing_details?.[invoicingService]?.[key];
                return {
                  ...obj,
                  [key]: glValue ? initalValue || glValue : initalValue,
                };
              }, []),
            },
            overwriteAllMetadata: true,
          }));

        setSelectedImportToRemove(null);
      } catch (err) {
        pushError(err);
      } finally {
        setLoading(false);
      }
    },
    onClose: () => {
      setSelectedImportToRemove(null);
    },
  });

  return {
    RemoveImportConfirmModal: ConfirmModal,
    openRemoveImportConfirmModal: ({ importId }) => {
      setSelectedImportToRemove(importId);
      openConfirmModal();
    },
  };
};
