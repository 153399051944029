import dayjs from 'dayjs';

import { Modal, ModalBody, ModalCloseIcon, ModalContainer, ModalTitleText } from 'components/Modal';

import { InvoiceDetailsForm } from '../InvoiceDetailsForm';
import { RemindForm } from '../InvoiceRemindForm/RemindForm';

export const SentEmailModal = ({ invoice, reminder, onClose }) => {
  return (
    <ModalContainer zIndex={100}>
      <Modal padding="32px" maxHeight="90vh" width="750px" overflow="auto" background="var(--primaryGray)">
        <ModalCloseIcon data-cy="sent-email-modal__close-button" onClose={onClose} />

        <ModalBody paddingLeft="36px" paddingRight="36px">
          <ModalTitleText compact>
            <b>
              {reminder ? 'Email' : 'Invoice'} was sent on{' '}
              {dayjs(reminder ? reminder?.sent_at : invoice?.sent_at).format('MMM DD, YYYY [at] HH:MM')}
            </b>
          </ModalTitleText>

          {reminder ? (
            <RemindForm sentPreview invoice={invoice} selectedReminder={reminder} setReminderFormValues={() => {}} />
          ) : (
            <InvoiceDetailsForm invoice={invoice} />
          )}
        </ModalBody>
      </Modal>
    </ModalContainer>
  );
};
