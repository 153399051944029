import dayjs from 'dayjs';
import { useQuery, useQueryClient } from 'react-query';
import { getRevenueDetails } from './requests';

export const CACHE_KEY = 'revenueDetails';

export const useRevenueDetailsAPI = ({
  orgId,
  month,
  year,
  segmentBy,
  timeseriesMonth,
  selectedSegment,
  metadataFilter,
  rollup,
  requiredPlugins,
  autoFetch = true,
  isCommitted,
  optimisticAnalytics,
  includeLastTransactionUpdatedAt,
  summaryStartMonth,
  countInfluxAsRenewed,
  ratesType,
  ratesDate,
}) => {
  const queryClient = useQueryClient();

  const timeseriesMonthKey = timeseriesMonth ? dayjs(timeseriesMonth).format('YYYY-MM-DD') : undefined;

  const params = {
    month,
    year,
    rollup,
    isCommitted,
    optimisticAnalytics,
    includeLastTransactionUpdatedAt,
    summaryStartMonth,
    countInfluxAsRenewed,
    customerMetadata: JSON.stringify(metadataFilter?.customerMetadata),
    transactionMetadata: JSON.stringify(metadataFilter?.transactionMetadata),
    segmentBy,
    timeseriesMonth: timeseriesMonthKey,
    selectedSegment,
    ratesType,
    ratesDate,
  };

  const { data, error, isLoading, isFetching } = useQuery(
    [CACHE_KEY, orgId, params],
    () => getRevenueDetails({ orgId, body: metadataFilter, params }),
    {
      enabled: autoFetch && !!orgId && requiredPlugins !== null,
    },
  );

  const refetchRevenueDetails = () => queryClient.invalidateQueries(CACHE_KEY);

  return {
    data,
    error,
    isLoading,
    isFetching,
    refetchRevenueDetails,
  };
};
