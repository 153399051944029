import styled from 'styled-components';
import { FlexerColumn, CentererVertical } from 'components/Core';

export const SectionContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

export const MetricsCard = styled.div`
  width: 100%;
  height: 142px;
  display: flex;
  justify-content: space-between;
`;

export const LeftSide = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const RightSide = styled.div`
  background: var(--primaryBlack2);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid white;
`;

export const PreviousMonthLabel = styled(CentererVertical)`
  width: fit-content;
  color: var(--primaryBlack50);
  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  border-radius: 4px;
  padding: 4px;
  border: 1px solid var(--accentGraySecond);
`;

export const NewMonthLabel = styled(CentererVertical)`
  width: fit-content;
  font-weight: 900;
  font-size: 9px;
  border-radius: 4px;
  padding: 4px;
  border: 1px solid var(--primaryBlack5);
`;

export const Headers = styled.span`
  display: flex;
  flex-direction: column;
`;

export const GreyHeader = styled.span`
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  opacity: 0.3;
  margin-bottom: 8px;
`;

export const HeaderValue = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`;

export const MetricRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GreyLabel = styled.span`
  margin-right: 16px;
  font-size: 12px;
  color: #767676;
`;

export const MetricValue = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

export const Trend = styled.span`
  color: ${(props) => props.color};
  font-weight: 900;
  font-size: 8px;
  border-radius: 4px;
  padding: 2px 4px;
  margin-left: 4px;
  background-color: ${(props) => props.backgroundColor};
`;

export const Percentage = styled.span`
  font-weight: bold;
  font-size: 10px;
  padding-right: 4px;
`;

export const RenewalMetricContainer = styled.div`
  width: 32%;
  border: 1px solid var(--accentGraySecond);
  border-radius: 16px;
  padding: ${({ padding }) => padding};
`;

export const MetricContent = styled(FlexerColumn)`
  height: 100%;
  justify-content: space-between;
  padding: 20px;
`;

export const LabelContainer = styled(CentererVertical)`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 8px;
  font-weight: 900;
  font-size: 9px;
  align-self: flex-start;
`;

export const Customers = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
`;
