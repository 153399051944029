import { useCallback, useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { AppContext } from 'AppContext';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalTitle,
} from 'components/Modal';
import { Spacer } from 'components/Core';
import { useEntityAPI } from 'api/billingEntities/hooks';
import { useInvoiceMemoTemplatesAPI } from 'api/invoiceMemoTemplates/hooks';
import { DEFAULT_AVAILABLE_TAGS } from 'consts/billing';
import { useGetAvailableTags } from 'api/billing';

import { BillingCompanyInformation } from './BillingCompanyInformation';
import { FooterMemoInput } from './FooterMemoInput';

const validationSchema = Yup.object({
  company_name: Yup.string().trim().required('Please enter company name'),
  address: Yup.string().required('Please enter company address'),
  footer_memo: Yup.string().nullable(),
  footer_memo_name: Yup.string().when('footer_memo', {
    is: (val) => val && val.trim(),
    then: Yup.string().required('Please name footer memo').typeError('Please name footer memo'),
    otherwise: Yup.string().nullable(),
  }),
  footer_memo_template_id: Yup.number().nullable(),
});

export const BillingEntityModal = ({ onClose, entity }) => {
  const { orgId } = useContext(AppContext);
  const {
    operations: { createEntity, updateEntity },
  } = useEntityAPI({ orgId, autoFetch: false });
  const {
    data: templates = [],
    operations: { createInvoiceMemoTemplate, updateInvoiceMemoTemplate },
  } = useInvoiceMemoTemplatesAPI({ orgId });

  const {
    transactionMetadataTags = [],
    customerMetadataTags = [],
    transactionCustomerMetadataTags = [],
  } = useGetAvailableTags({ orgId });

  const handleSave = async (values) => {
    const { id, footer_memo, footer_memo_name, ...dataToUpdate } = values;

    const currentTemplate = templates.find((template) => template.id === values.footer_memo_template_id);
    const templateHasChanged =
      currentTemplate?.name !== footer_memo_name?.trim() || currentTemplate?.content !== footer_memo?.trim();

    if (footer_memo_name && footer_memo && templateHasChanged) {
      if (dataToUpdate.footer_memo_template_id) {
        await updateInvoiceMemoTemplate.mutateAsync({
          invoiceMemoTemplateId: dataToUpdate.footer_memo_template_id,
          data: {
            name: footer_memo_name,
            content: footer_memo,
          },
        });
      } else {
        const template = await createInvoiceMemoTemplate.mutateAsync({
          data: {
            name: footer_memo_name,
            content: footer_memo,
          },
        });

        dataToUpdate.footer_memo_template_id = template.id;
      }
    }

    if (!footer_memo_name || !footer_memo) dataToUpdate.footer_memo_template_id = null;

    if (id) {
      await updateEntity({ data: dataToUpdate, entityId: values.id });
    } else {
      await createEntity(dataToUpdate);
    }
    onClose();
  };

  const currentTemplate = templates.find((template) => template.id === entity.footer_memo_template_id);

  const availableTags = [
    ...DEFAULT_AVAILABLE_TAGS,
    ...transactionMetadataTags,
    ...customerMetadataTags,
    ...transactionCustomerMetadataTags,
  ];

  return (
    <ModalContainer>
      <Formik
        initialValues={{
          ...entity,
          footer_memo_name: currentTemplate?.name ?? '',
          footer_memo: currentTemplate?.content ?? '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSave}
        enableReinitialize
      >
        {({ values, setFieldValue, submitForm }) => (
          <Form>
            <Modal overflow="auto" width="600px" minHeight="400px" height="auto" maxHeight="90vh">
              <ModalTitle bold>Sender organization</ModalTitle>
              <ModalCloseIcon onClick={onClose} />

              <ModalBody>
                <Spacer height="16px" />
                <BillingCompanyInformation values={values} setFieldValue={setFieldValue} />

                <FooterMemoInput
                  availableTags={availableTags}
                  values={values}
                  setFieldValue={setFieldValue}
                  templates={templates}
                />
                <Spacer height="16px" />
              </ModalBody>

              <ModalFooter flexEnd>
                <ModalButton onClick={onClose}>Close</ModalButton>
                <ModalButton primary onClick={submitForm} data-cy="entity-modal__save-button">
                  Save
                </ModalButton>
              </ModalFooter>
            </Modal>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};

export const useBillingEntityModal = () => {
  const [selectedEntity, setSelectedEntity] = useState(null);
  const openModal = useCallback((entity) => {
    setSelectedEntity(entity);
  }, []);

  const Modal = useCallback(
    () =>
      selectedEntity ? <BillingEntityModal onClose={() => setSelectedEntity(null)} entity={selectedEntity} /> : null,
    [selectedEntity],
  );
  return {
    openBillingEntityModal: openModal,
    BillingEntityModal: Modal,
  };
};
