import React from 'react';
import styled from 'styled-components';
import { cssVar } from 'polished';
import { useHistory } from 'react-router';
import { ReactComponent as HeadIcon } from 'images/doodle-21.svg';
import { ReactComponent as RefreshIcon } from 'images/refresh.svg';
import { ReactComponent as PlusIcon } from 'images/circle-plus.svg';
import { Centerer } from 'components/Core/Flex';
import { IconButton } from 'components/Buttons';
import { CONFIGURATION_TABS } from 'views/Configuration/consts';

const Wrapper = styled.div`
  max-width: 300px;
  text-align: center;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  margin-top: 20px;
  margin-bottom: 12px;
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const CreateButton = styled.div`
  display: flex;
  align-items: center;

  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryGreen);
  cursor: pointer;

  svg {
    margin-right: 7px;
  }

  &:hover {
    color: var(--secondaryGreen);
  }
`;

export const OrgNoCustomers = ({ onCreateClick }) => {
  const history = useHistory();

  return (
    <Centerer style={{ marginTop: 40 }}>
      <Wrapper className="flexer-col">
        <HeadIcon />
        <Title>You don't have customers!</Title>
        <Subtitle>You should configure customers imports or manually create customers</Subtitle>

        <IconButton
          icon={<RefreshIcon />}
          iconFillColor={cssVar('--primaryGreen')}
          onClick={() => history.push(`/configure?tab=${CONFIGURATION_TABS.GENERAL_SETTINGS}`)}
        >
          Import from External Source
        </IconButton>

        <CreateButton onClick={onCreateClick}>
          <PlusIcon />
          Manually create
        </CreateButton>
      </Wrapper>
    </Centerer>
  );
};
