import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { capitalize } from 'lodash';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { usePrevious } from 'utils/hooks/usePrevious';
import { Header, HeaderTitle, HEADER_TITLES } from 'shared/Layout';
import { ReactComponent as FinishButtonIcon } from 'images/cloud-upload.svg';
import { IconButton, LinkBackButton } from 'components/Buttons';
import { Row } from 'components/Core';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { COLORS } from 'consts/colors';
import { ImportForm } from './ImportForm';
import { CsvUploader } from './CSVReaderUploader';
import { CsvUploadHeader } from './CsvUploadHeader';
import { HowItWorks } from './HowItWorks';
import { mapFromParsedCsv, updateColumnData } from './utils';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding-left: 20px;
`;

export const CsvUpload = ({
  entityName,
  defaultMapper,
  isLoading,
  getColumnsWithCsvUploadState,
  csvColumnsTransformations = {},
  handleSubmit,
  schema,
  selectedMetadataFields,
  backLink,
  showViewModes,
}) => {
  const {
    organizations,
    appSettings: { currencyISOCode: homeCurrency },
  } = useContext(AppContext);

  //  Example: { 1: {customer_id: { confidence, ... }, product_id: {confidence, ...} },
  const [fuzzyRowsMapper, setFuzzyRowsMapper] = useState({});

  const [csvColumnsMapper, setCsvColumnsMapper] = useState(defaultMapper ?? {});
  const prevCsvColumnsMapper = usePrevious(csvColumnsMapper);

  const [parsedCsv, setParsedCsv] = useState();
  const [mappedData, setMappedData] = useState();
  const [formIsValid, setFormIsValid] = useState();

  const formRef = useRef();

  useEffect(() => {
    //clean fuzzy mapper after new data loaded
    setFuzzyRowsMapper({});
  }, [isLoading]);

  // Here we update the form when the user changes data source for csv column
  useEffect(() => {
    const changedColumnMapping = Object.keys(csvColumnsMapper).filter((key) => {
      return csvColumnsMapper?.[key] !== prevCsvColumnsMapper?.[key];
    });

    if (parsedCsv && !!Object?.keys(csvColumnsMapper ?? {})?.length) {
      if (!mappedData) {
        setMappedData([
          ...mapFromParsedCsv({
            parsedCsv,
            csvColumnsMapper,
            prevCsvColumnsMapper,
            csvColumnsTransformations,
            allProducts: organizations[0]?.products,
          }),
        ]);
      } else if (changedColumnMapping?.length === 1) {
        // update data in column
        updateColumnData({
          changedColumnMapping,
          setFuzzyRowsMapper,
          parsedCsv,
          csvColumnsMapper,
          formRef,
          csvColumnsTransformations,
          allProducts: organizations[0]?.products,
        });
      }
    }

    // eslint-disable-next-line
  }, [csvColumnsMapper, setFuzzyRowsMapper, parsedCsv, organizations, selectedMetadataFields]);

  return (
    <Fragment>
      <Wrapper>
        <Header
          activePage={entityName}
          headerLeft={
            <>
              <LinkBackButton to={backLink ?? `/${entityName}`}>All {capitalize(entityName)}</LinkBackButton>
              <HeaderTitle>{HEADER_TITLES['csv']}</HeaderTitle>
            </>
          }
          headerRight={
            <>
              <IconButton
                disabled={!parsedCsv || !formIsValid || isLoading}
                filled
                border
                color={COLORS.GREEN}
                icon={<FinishButtonIcon />}
                iconFillColor={'#FFF'}
                onClick={() => formRef?.current?.submitForm()}
              >
                Finish Uploading
              </IconButton>
            </>
          }
        />
        <CsvUploadHeader homeCurrency={homeCurrency} entityName={entityName} />
        <Row>
          {parsedCsv && <HowItWorks />}
          <CsvUploader smallView={parsedCsv} setParsedCsv={setParsedCsv} entityName={entityName} />
        </Row>
        {parsedCsv && (
          <TimeLoaderContainer isLoading={isLoading}>
            {isLoading ? (
              <TimeLoader pageName={`${entityName}Csv`} />
            ) : (
              <ImportForm
                entityName={entityName}
                formRef={formRef}
                parsedCsv={parsedCsv}
                setFormIsValid={setFormIsValid}
                setCsvColumnsMapper={setCsvColumnsMapper}
                csvColumnsMapper={csvColumnsMapper}
                mappedData={mappedData}
                schema={schema}
                defaultMapper={defaultMapper}
                fuzzyRowsMapper={fuzzyRowsMapper}
                setFuzzyRowsMapper={setFuzzyRowsMapper}
                handleSubmit={handleSubmit}
                getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
                showViewModes={showViewModes}
              />
            )}
          </TimeLoaderContainer>
        )}
      </Wrapper>
    </Fragment>
  );
};
