import React, { useContext } from 'react';
import { Checkbox } from 'antd';
import dayjs from 'dayjs';

import { ReactComponent as SubrowArrow } from 'images/subrow-arrow.svg';
import { ReactComponent as CheckCircle } from 'images/transaction_check.svg';

import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { AppContext } from 'AppContext';
import {
  InvoiceLink,
  InvoiceInfo,
  InvoiceDate,
  PaidDate,
  Wrapper,
  CheckBoxRow,
  StyledExternalLink,
  LineItemRow,
  LineItemTitleRow,
  LineItemAmount,
  TotalAmount,
} from './styles';

export const ImportInvoiceLine = ({ externalInvoice, showCheckbox, isSelected, onInvoiceAdd, onInvoiceRemove }) => {
  const {
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);

  return (
    <Wrapper>
      <CheckBoxRow>
        {showCheckbox && (
          <Checkbox
            checked={isSelected}
            onClick={() => (isSelected ? onInvoiceRemove && onInvoiceRemove() : onInvoiceAdd && onInvoiceAdd())}
            data-cy={`select-import-invoice__checkbox--${externalInvoice.external_id}`}
          />
        )}
        {!showCheckbox && (
          <StyledExternalLink onClick={() => window.open(externalInvoice.invoice_external_url, '_blank')} />
        )}
        <InvoiceInfo>
          <InvoiceLink onClick={() => window.open(externalInvoice.invoice_external_url, '_blank')}>
            <div>Invoice #{externalInvoice.invoice_number}</div>
            {showCheckbox && <StyledExternalLink />}
          </InvoiceLink>
        </InvoiceInfo>

        <InvoiceDate>{dayjs.utc(externalInvoice.date).format('MMM DD, YYYY')}</InvoiceDate>

        <PaidDate>
          {externalInvoice.paid_at && (
            <>
              <CheckCircle height="16px" />
              Paid: <span>{dayjs.utc(externalInvoice.paid_at).format('MMM DD, YYYY')}</span>
            </>
          )}
        </PaidDate>

        <TotalAmount>
          {numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: externalInvoice?.amount ?? externalInvoice?.Total,
            decimalPlaces: 2,
            currency: externalInvoice.currency ?? defaultOrgCurrency,
          })}
        </TotalAmount>
      </CheckBoxRow>
      {externalInvoice.invoice_items?.map((lineItem, index) => (
        <LineItemRow horizontal="flex-start" key={(lineItem.name ?? lineItem.description) + index}>
          <LineItemTitleRow>
            <SubrowArrow style={{ opacity: 0.2 }} />
            {lineItem.name ?? lineItem.description ?? lineItem.type}

            <LineItemAmount>
              {numberFormatter({
                type: NUMBER_FORMATS.CURRENCY,
                rawValue: lineItem?.amount,
                decimalPlaces: 2,
                currency: externalInvoice.currency ?? defaultOrgCurrency,
              })}
            </LineItemAmount>
          </LineItemTitleRow>
        </LineItemRow>
      ))}
    </Wrapper>
  );
};
