import styled from 'styled-components';
import { StyledInputCSS } from '../styles';

export const ContentEditableWrapper = styled.div`
  .content-editable__input {
    position: relative;
    ${StyledInputCSS}
    cursor: text;
    width: ${(props) => props.inputWidth ?? '100%'};
    min-width: 0;
    white-space: pre-wrap !important;
    height: ${(props) => props.inputHeight ?? '100%'};
    min-height: ${({ minHeight }) => minHeight ?? 'auto'};
    padding: 8px 12px;
    font-weight: ${(props) => (props.blueVer || props.bold) && 'bold'};
    font-size: 14px;
    border-radius: ${(props) => (props.blueVer ? '4px' : '8px')};
    pointer-events: ${(props) => props.disabled && 'none'};
    overflow: auto;

    &:empty:before {
      content: attr(placeholder);
      display: block;
      color: var(--primaryBlack30);
    }

    &::placeholder {
      opacity: 0.3;
    }

    em {
      font-style: normal;
    }

    b {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: white;
      padding: 1px 4px;
      margin: 0 2px;
      background: rgba(64, 80, 98, 0.98);
      border-radius: 4px;
    }

    b.invalid {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: white;
      padding: 1px 4px;
      margin: 0 2px;
      background: var(--tertiaryRed);
      border-radius: 4px;
    }
  }
`;

export const TagsContainer = styled.div`
  position: absolute;
  background: white;
  z-index: 100;
  box-shadow: 10px 10px 40px 0px var(--primaryBlack10);
  border-radius: 12px;
  border: 1px solid var(--neutralGray);
  display: flex;
  max-height: 205px;
  overflow: auto;
  flex-direction: column;
`;

export const Tag = styled.div`
  padding: 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
  border-bottom: 1px solid var(--accentGraySecond);

  &:last-child {
    border-bottom: none;
  }

  ${({ selected }) =>
    selected &&
    `background-color: var(--primaryBlack3);
         color: var(--primaryBlack70)`}

  &:hover {
    background-color: var(--primaryBlack3);
    color: var(--primaryBlack70);
  }
`;
