import React from 'react';
import styled from 'styled-components';
import { cssVar } from 'polished';
import { IconButton } from 'components/Buttons';
import { Spacer } from 'components/Core';
import { ReactComponent as HowWorksIcon } from 'images/lifebuoy.svg';
import { Header, HeaderTitle } from 'shared/Layout';
import { useAIContractReaderModals } from 'views/Contracts';

import { useHowItWorksModal } from './HowItWorksModal';

const HeaderWrapper = styled.div`
  padding: 0 40px 40px 40px;
`;

export const HeaderTab = styled.b`
  font-weight: 900;
  margin-left: 12px;

  i {
    color: var(--primaryBlack50);
  }
`;

export const StyledArrow = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-left: 12px;
  box-shadow: 4px 4px 24px var(--primaryBlack4);
  border-radius: 100px;
  border: 1px solid var(--accentGraySecond);
  transition: all ease 0.4s;
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const ContractsHeader = () => {
  const { Modal: HowItWorksModal, openModal: openHowItWorksModal } = useHowItWorksModal();

  const {
    ContractFilesModal,
    ContractAIPreviewModal,
    ContractTransactionsCreatedModal,
    AIReaderLockedModal,

    AIContractReaderButton,
  } = useAIContractReaderModals();

  return (
    <HeaderWrapper style={{ paddingBottom: 0 }}>
      <Header
        headerLeft={
          <HeaderTitle>
            <HeaderTab>Contract PDFs</HeaderTab>
          </HeaderTitle>
        }
        headerRight={
          <>
            <AIContractReaderButton />
            <Spacer width="10px" />
            <IconButton
              icon={<HowWorksIcon />}
              iconFillColor={cssVar('--primaryGreen')}
              onClick={openHowItWorksModal}
              data-cy="contracts__how-it-works"
            >
              How it works
            </IconButton>
          </>
        }
      />
      <HowItWorksModal />
      <ContractFilesModal />
      <ContractAIPreviewModal />
      <ContractTransactionsCreatedModal />
      <AIReaderLockedModal />
    </HeaderWrapper>
  );
};
