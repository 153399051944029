import { useInvoicingScheduleAPI } from 'api/billing/hooks';
import { BillingProvider } from 'views/Billing/BillingProvider';
import { useInvoicingScheduleModal } from 'views/Billing/InvoicingScheduleModal';
import { ViewScheduleContainer, HorizontalLine, LinkToInvoicingSchedule } from './styles';

export const TransactionLinkToInvoicingSchedule = ({ orgId, invoicingScheduleId }) => {
  const { data: invoicingSchedule } = useInvoicingScheduleAPI({ orgId, invoicingScheduleId, scopes: ['invoices'] });
  const { Modal: InvoicingScheduleModal, openModal: openInvoicingScheduleModal } = useInvoicingScheduleModal();

  return (
    <BillingProvider>
      <ViewScheduleContainer>
        <LinkToInvoicingSchedule
          data-cy="transaction__view-schedule-button"
          onClick={() => openInvoicingScheduleModal({ invoicingSchedule: { id: invoicingScheduleId } })}
        >
          View Invoicing Schedule
        </LinkToInvoicingSchedule>
        <HorizontalLine />
        <span>{invoicingSchedule?.invoices?.length ?? 0} invoices</span>
      </ViewScheduleContainer>
      <InvoicingScheduleModal />
    </BillingProvider>
  );
};
