import React, { useState, useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { getIntegrationDisplayName } from 'models/integration';
import { useExternalCustomerAPI } from 'api/customers/hooks';
import { Flexer, FlexerRow } from 'components/Core';
import { FormikCustomSelector } from 'components/Controls';
import { ExternalLinkFullIcon } from 'components/Icons';
import { CircleLoader } from 'components/Loaders';
import { ReactComponent as SyncIcon } from 'images/refresh.svg';
import { createCustomer } from 'api/customers';
import { TooltipContainer } from 'components/Tooltip';
import { groupBy } from 'utils/arrayUtils';
import { INTERNAL_CUSTOMER_METADATA } from 'models/customer';
import { MAGIC_METADATA } from 'consts/global';
import { useImportsAPI } from 'api/imports';

import { ExternalLink } from '../styles';
import { CUSTOMERS_MODAL_ACTIONS } from './consts';
import { GLCustomerContext } from './GLCustomerContext';
import { GLCustomerSelector } from './GLCustomerSelector';

export const GLCustomerDetailsSelector = ({
  modalAction,
  glIntegrations,
  activeCustomerImportByIntegrationId,
  initialCustomerData,
  editCustomer,
  handleBillWithParentChange,
}) => {
  const { orgId } = useContext(AppContext);
  const {
    isLoading,
    handleSetCustomerGLImport,
    handleRemoveCustomerGLImport,
    customerId,
    setCustomerId,
    handleManualSyncCustomer,
  } = useContext(GLCustomerContext);
  const { values, setFieldValue } = useFormikContext();
  const [glCustomerOptionsMap, setGLCustomerOptionsMap] = useState({});
  const [initialOptions, setInitialOptions] = useState([]);
  const existingGlImport =
    activeCustomerImportByIntegrationId[values.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID]];

  const selectedGlIntegrationId = values.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID];
  const glIntegrationById = groupBy(glIntegrations, 'id', { uniqueness: true });
  const selectedIntegration = glIntegrationById[selectedGlIntegrationId];
  const invoicingServiceLabel = selectedIntegration ? getIntegrationDisplayName(selectedIntegration) : null;

  const {
    data: externalCustomer,
    isLoading: isLoadingExternalCustomer,
    operations: { createCustomerInGl },
  } = useExternalCustomerAPI({
    orgId,
    externalCustomerId: existingGlImport?.provider_object_id,
    integrationId: existingGlImport?.integration_id,
    // we only fetch if we don't already have the customer_name cached
    autoFetch:
      modalAction === CUSTOMERS_MODAL_ACTIONS.EDIT && !!existingGlImport && !existingGlImport?.metadata?.customer_name,
  });

  const {
    operations: { deleteImport },
  } = useImportsAPI({
    orgId,
    autoFetch: false,
  });

  useEffect(() => {
    if (existingGlImport?.metadata?.customer_name) {
      setInitialOptions([
        {
          label: `${existingGlImport.metadata.customer_name || '<No Name>'} / ${existingGlImport.provider_object_id}`,
          value: existingGlImport.provider_object_id,
        },
      ]);
    } else if (externalCustomer) {
      setInitialOptions([
        {
          label: `${externalCustomer.name || '<No Name>'} / ${externalCustomer.id || ''}`,
          value: externalCustomer.id,
        },
      ]);
    } else {
      setInitialOptions([]);
    }
  }, [externalCustomer, existingGlImport]);

  const handleInputChange = (option) => {
    if (option) {
      setFieldValue('provider_object_id', option.value);
      handleSetCustomerGLImport({
        formValues: values,
        customerExternalId: option.value,
        customerExternalName: glCustomerOptionsMap[option.value]?.name,
        disableReingest: option.disableReingest,
        integrationId: selectedGlIntegrationId,
      });
    } else {
      // The user removed the gl import
      setFieldValue('provider_object_id', null);
      handleRemoveCustomerGLImport({
        importId: existingGlImport?.id,
        integrationId: selectedGlIntegrationId,
      });
    }
  };

  const getCustomerImportLabel = (customerImport) => {
    return (
      <FlexerRow alignItems="center">
        {`${invoicingServiceLabel ?? ''} Customer`}
        <ExternalLink onClick={() => window.open(customerImport.external_url, '_blank')}>
          <ExternalLinkFullIcon />
        </ExternalLink>

        <TooltipContainer
          toolTipContent="Sync customer info"
          fontSize="12px"
          width={120}
          tooltipWrapperStyles={{
            display: 'flex',
            alignItems: 'center',
          }}
          xOffset={5}
        >
          <SyncIcon
            onClick={() =>
              handleManualSyncCustomer({
                integrationId: selectedGlIntegrationId,
                customerExternalId: existingGlImport?.provider_object_id,
                formValues: values,
              })
            }
          />
        </TooltipContainer>
      </FlexerRow>
    );
  };

  if (isLoadingExternalCustomer || isLoading) {
    return (
      <div className="w-100 flexer">
        <CircleLoader name="gl-customer-selector" />
      </div>
    );
  }

  const handleUpsertExternalCustomer = async ({ selectorProps, customerName }) => {
    let id = customerId;

    if (!customerId) {
      id = (
        await createCustomer({
          orgId,
          body: {
            name: values?.name,
            metadata: values?.metadata,
            parent_customer_id: values?.parent_customer_id,
            invoicing_details: values?.invoicing_details,
          },
        })
      )?.id;
    } else if (
      selectedGlIntegrationId?.toString() !==
      initialCustomerData.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID]?.toString()
    ) {
      await editCustomer({
        id,
        data: { metadata: values.metadata },
      });
    }
    if (existingGlImport?.id && existingGlImport?.metadata?.customer_name !== customerName) {
      await deleteImport.mutateAsync({ importId: existingGlImport.id });
    }

    const { customerExternalId } = await createCustomerInGl({
      data: { ...values, name: customerName, id, integrationId: selectedGlIntegrationId },
    });

    if (customerExternalId) {
      selectorProps.setValue({
        label: `${customerName || '<No Name>'} / ${customerExternalId || ''}`,
        value: customerExternalId,
        disableReingest: true,
      });
    }

    !customerId && setCustomerId(id);
  };

  return (
    <Flexer gap="20px">
      <FormikCustomSelector
        name={`metadata.${INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID}`}
        options={glIntegrations.map((integration) => ({
          label: `${getIntegrationDisplayName(integration)} / ${integration.id}`,
          value: integration.id,
        }))}
        label="Link with your General Ledger"
        placeholder="Select a GL..."
        containerWidth="100%"
        handleChange={async (option) => {
          const providerObjectId = activeCustomerImportByIntegrationId[option.value]?.provider_object_id ?? null;
          const selectedGlIntegrationId = option.value;
          setFieldValue('provider_object_id', providerObjectId);
          setFieldValue(`metadata.${INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID}`, selectedGlIntegrationId);

          if (
            initialCustomerData?.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID]?.toString() !==
            selectedGlIntegrationId?.toString()
          ) {
            const externalParentCustomerId = null;
            setFieldValue(`metadata.${MAGIC_METADATA.EXTERNAL_PARENT_CUSTOMER_ID}`, externalParentCustomerId);

            await handleBillWithParentChange({
              values,
              updateData: {
                provider_object_id: providerObjectId,
                metadata: {
                  [MAGIC_METADATA.EXTERNAL_PARENT_CUSTOMER_ID]: externalParentCustomerId,
                  [INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID]: selectedGlIntegrationId,
                },
              },
              setFieldValue,
              billWithParent: false,
            });
          }
        }}
        dataCy="customer-modal__general-ledger-selector"
      />

      <GLCustomerSelector
        label={existingGlImport ? getCustomerImportLabel(existingGlImport) : `${invoicingServiceLabel ?? ''} Customer`}
        name="provider_object_id"
        handleInputChange={handleInputChange}
        initialOptions={initialOptions}
        integrationId={selectedIntegration?.id}
        setGLCustomerOptionsMap={setGLCustomerOptionsMap}
        isDisabled={!values.name}
        tooltipInputDisplay={!values.name ? 'Please enter a name first' : undefined}
        handleUpsertExternalCustomer={handleUpsertExternalCustomer}
        customerName={values.name}
        invoicingServiceLabel={invoicingServiceLabel}
      />
    </Flexer>
  );
};
