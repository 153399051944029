import React from 'react';

import { NUMBER_FORMATS } from 'consts/global';
import { useCurrencyNumberFormatter } from 'utils/hooks';

import { ReactComponent as UpForRenewalIcon } from 'images/icon_up_for_renewal.svg';
import { ReactComponent as GrossRenewedIcon } from 'images/icon_gross_renewed.svg';
import { ReactComponent as NetRenewedIcon } from 'images/icon_net_renewed.svg';
import {
  SectionContainer,
  MetricsCard,
  Headers,
  GreyHeader,
  HeaderValue,
  RenewalMetricContainer,
  MetricContent,
  LabelContainer,
  Customers,
} from './styled';

const RENEWAL_METRICS = [
  {
    label: 'UP FOR RENEWAL',
    icon: <UpForRenewalIcon />,
    revenueKey: 'upForRenewal',
    customerKey: 'upForRenewalCustomerCount',
    accentColor: 'var(--accentPurple)',
  },
  {
    label: 'GROSS RENEWED',
    icon: <GrossRenewedIcon />,
    revenueKey: 'grossRenewed',
    customerKey: 'renewedCustomerCount',
    accentColor: 'var(--accentYellow)',
  },
  {
    label: 'NET RENEWED',
    icon: <NetRenewedIcon />,
    revenueKey: 'netRenewed',
    customerKey: 'renewedCustomerCount',
    accentColor: 'var(--accentGreen)',
  },
];

export const MetricsCardsSection = ({ upForRenewalData }) => {
  const numberFormatter = useCurrencyNumberFormatter();

  return (
    <SectionContainer>
      {/* Renewal Metrics */}
      <MetricsCard>
        {RENEWAL_METRICS.map(({ label, icon, revenueKey, customerKey, accentColor }, index) => (
          <RenewalMetricContainer key={label}>
            <MetricContent index={index}>
              <LabelContainer backgroundColor={accentColor}>
                {icon}
                <span style={{ marginLeft: 8 }}>{label}</span>
              </LabelContainer>
              <Headers>
                <HeaderValue style={{ fontWeight: 900 }}>
                  {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: upForRenewalData[revenueKey] })}
                </HeaderValue>
                <Customers>
                  <span style={{ marginRight: 4 }}>{upForRenewalData[customerKey]}</span>
                  <GreyHeader style={{ fontWeight: 400, fontSize: 12 }}>customers</GreyHeader>
                </Customers>
              </Headers>
            </MetricContent>
          </RenewalMetricContainer>
        ))}
      </MetricsCard>
    </SectionContainer>
  );
};
