import { useContext, useEffect, useMemo } from 'react';
import { AppContext } from 'AppContext';
import { Column, Row } from 'components/Core';
import { CircleXIcon, WarningIcon } from 'components/Icons';
import { ReactComponent as WarningTriangle } from 'images/warning-triangle.svg';
import { useIntegrationsAPI } from 'api/integrations';
import { useTransactionsAPI } from 'api/transactions';
import { REQUIRED_BILLING_PARAMS } from 'api/transactions/hooks';
import { useStateWithStorage } from 'utils/hooks';
import { EXTERNAL_UPDATES_SUPPORTED_RESOURCES } from 'views/ExternalUpdates/consts';
import { useExternalUpdatesAPI } from 'api/externalUpdates';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { getBilledAmount, getDisplayNameByTransactionId, getSortedInvoices, getTransactionsAmount } from '../utils';
import { INVOICING_SCHEDULE_TABS_PANEL_TABS } from '../InvoicingScheduleTabsPanel';
import { useSafelySwitchInvoice } from '../useSafelySwitchInvoice';
import {
  Counter,
  DismissAllButton,
  DismissButton,
  InvoiceWarningContainer,
  Warning,
  WarningCounter,
  WarningsContainer,
  WarningsHeader,
} from './styles';
import { CreditNoteContext } from '../CreditNoteContext';
import { getInvoicingScheduleWarnings } from './getInvoicingScheduleWarnings';
import { getScheduleTransactionsIds } from './utils';

export const useInvoiceWarnings = () => {
  const {
    invoicingScheduleFormValues,
    includedTransactions,
    selectedInvoiceId,
    setSelectedInvoiceId,
    expectedNumberOfInvoices,
    setFetchedSelectedInvoice,
    setSelectedTabsPanelTab,
    selectedTabsPanelTab,
    closeModal,
    setInvoiceFormValues,
  } = useContext(InvoicingScheduleContext);
  const { creditNotes } = useContext(CreditNoteContext);
  const {
    orgId,
    dateFormat,
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);

  const [dismissedWarnings, setDismissedWarnings] = useStateWithStorage('storage-dismissed-warnings', []);

  const { ConfirmSaveModal, checkAndSwitchInvoice } = useSafelySwitchInvoice({
    afterActionFn: () => {
      setSelectedTabsPanelTab(INVOICING_SCHEDULE_TABS_PANEL_TABS.WARNINGS);
      setFetchedSelectedInvoice(null);
      setSelectedInvoiceId(null);
      setInvoiceFormValues({}); // looking at warnings, not an invoice
    },
  });

  const {
    data: externalUpdatesData,
    isLoading: isExternalUpdatesLoading,
    operations: { resolveExternalUpdate },
  } = useExternalUpdatesAPI({
    orgId,
    params: {
      page: 1,
      limit: 5000,
      objectType: EXTERNAL_UPDATES_SUPPORTED_RESOURCES.INVOICING_SCHEDULE,
      objectId: invoicingScheduleFormValues?.id,
    },
  });

  const transactionsIds = useMemo(
    () => getScheduleTransactionsIds({ targetObject: externalUpdatesData?.data?.[0]?.targetObject }),
    [externalUpdatesData],
  );

  // for external updates created before `deletedTransactions` has been stored
  const { data: dataArchivedTransactions } = useTransactionsAPI({
    orgId,
    filters: {
      limit: 30000,
      body: { transactionIds: transactionsIds },
      params: { archived: true },
    },
    autoFetch: transactionsIds?.length > 0,
  });

  const { data: disauthenticatedIntegrations = [], isLoading: isIntegrationsLoading } = useIntegrationsAPI({
    orgId,
    params: {
      includeProducts: false,
      onlyDisauthenticated: true,
    },
  });

  const { data: replacedTransactions = [], isLoading: isTransactionsLoading } = useTransactionsAPI({
    orgId,
    filters: {
      limit: 50000,
      params: {
        ...REQUIRED_BILLING_PARAMS,
      },
      body: {
        replacedBy: includedTransactions.map(({ id }) => id),
      },
      scopes: ['invoice_item_sum'],
    },
    enableToasts: false,
    // We only need to fetch replaced transactions if there are any included transactions
    autoFetch: includedTransactions?.length > 0,
  });

  const invoices = useMemo(() => getSortedInvoices({ invoices: invoicingScheduleFormValues?.invoices }), [
    invoicingScheduleFormValues?.invoices,
  ]);

  const displayNameByTransactionId = useMemo(() => getDisplayNameByTransactionId({ includedTransactions }), [
    includedTransactions,
  ]);

  const transactionsAmount = useMemo(() => getTransactionsAmount({ includedTransactions }), [includedTransactions]);

  const billedAmount = useMemo(
    () => getBilledAmount({ invoices, creditNotes }),
    // make sure memo runs when child invoices_items change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(invoices)],
  );

  const warnings = useMemo(
    () =>
      getInvoicingScheduleWarnings({
        billedAmount,
        transactionsAmount,
        dateFormat,
        includedTransactions,
        invoices,
        invoicingScheduleFormValues,
        dataArchivedTransactions,
        displayNameByTransactionId,
        defaultOrgCurrency,
        expectedNumberOfInvoices,
        replacedTransactions,
        closeModal,
        disauthenticatedIntegrations,
        dismissedWarnings,
        externalUpdates: externalUpdatesData?.data,
        resolveExternalUpdate,
      }),
    [
      billedAmount,
      transactionsAmount,
      dataArchivedTransactions,
      includedTransactions,
      invoices,
      expectedNumberOfInvoices,
      invoicingScheduleFormValues,
      displayNameByTransactionId,
      defaultOrgCurrency,
      dateFormat,
      replacedTransactions,
      disauthenticatedIntegrations,
      dismissedWarnings,
      closeModal,
      externalUpdatesData,
      resolveExternalUpdate,
    ],
  );

  const isLoading = isExternalUpdatesLoading || isTransactionsLoading || isIntegrationsLoading;

  useEffect(() => {
    if (!isLoading && !warnings?.length && selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.WARNINGS)
      setSelectedTabsPanelTab(INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES);
  }, [warnings, setSelectedTabsPanelTab, selectedTabsPanelTab, isLoading]);

  const memoButton = useMemo(() => {
    return (
      <>
        {warnings && warnings.length > 0 && (
          <InvoiceWarningContainer
            data-cy="warnings-tab-button"
            active={selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.WARNINGS}
            onClick={() => {
              checkAndSwitchInvoice({
                clickedInvoice: null,
              });
            }}
          >
            <div />
            <Row>
              <Counter>{warnings.length}</Counter>
              Warnings requiring attention
            </Row>

            <WarningIcon size="15px" />
          </InvoiceWarningContainer>
        )}

        <ConfirmSaveModal />
      </>
    );
  }, [warnings, checkAndSwitchInvoice, selectedTabsPanelTab]);

  return {
    WarningsTab: () =>
      warnings &&
      warnings.length > 0 &&
      selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.WARNINGS &&
      !selectedInvoiceId && (
        <Column vertical="flex-start" horizontal="flex-start">
          <WarningsHeader>
            <Row>
              <WarningTriangle />
              Warnings requiring attention
            </Row>

            <DismissAllButton
              onClick={() => {
                const warningForDismiss = warnings?.map((warning) => warning?.dismissKey)?.filter(Boolean);
                const updatedWarnings = new Set([...dismissedWarnings, ...warningForDismiss]);
                setDismissedWarnings([...updatedWarnings]?.filter((warning) => typeof warning === 'string'));
              }}
            >
              Dismiss All
              <CircleXIcon />
            </DismissAllButton>
          </WarningsHeader>

          <WarningsContainer>
            {warnings.map((warning, index) => (
              <Warning key={`warning-${index}`}>
                <WarningCounter>{parseInt(index) + 1}</WarningCounter>
                {typeof warning?.warning === 'function' ? warning?.warning() : warning?.warning}

                {warning?.dismissKey && invoicingScheduleFormValues?.id && (
                  <DismissButton
                    data-cy={`warning__dismiss-${warning?.dismissKey}`}
                    onClick={() => {
                      const updatedWarnings = new Set([...dismissedWarnings, warning?.dismissKey]);
                      setDismissedWarnings([...updatedWarnings]?.filter((warning) => typeof warning === 'string'));
                    }}
                  >
                    Dismiss
                  </DismissButton>
                )}
              </Warning>
            ))}
          </WarningsContainer>
        </Column>
      ),
    WarningsButton: () => memoButton,
  };
};
