import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { validate as validateIsUUID } from 'uuid';

import { AppContext } from 'AppContext';
import { EVENT_RECOGNITION_TYPES, ORG_CONFIGS } from 'consts/global';
import { GL_INTEGRATION_SERVICES, INTEGRATION_TYPES } from 'consts/integrations';
import { useInvoiceAPI, useGetAvailableTags, useInvoicingSchedulesAPI } from 'api/billing';
import { generateInvoicesJSON, getInvoicingSchedule, getScheduleWithUnsavedInvoicesData } from 'api/billing/requests';
import { useImportsAPI } from 'api/imports';
import { useCustomerAPI } from 'api/customers';
import { useProductsAPI } from 'api/products';
import { transactionDisplayTitle } from 'models/transaction';
import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import { useProductsBulkUpdateIncomeAccountModal } from 'views/Configuration/Tabs/ProductsTab/ProductsBulkUpdateIncomeAccountModal';
import { useToasts } from 'components/Toasts';
import { validateFormWithToast } from 'utils/formUtils';
import { groupBy } from 'utils/arrayUtils';
import { INTERNAL_CUSTOMER_METADATA } from 'models/customer';

import {
  DEFAULT_INVOICE_LANGUAGE,
  INVOICING_FREQUENCIES,
  MANUAL_GL_SERVICE_NAME,
  PRODUCT_IMPORT_METADATA_KEYS,
  SERVICE_WITH_INCOME_ACCOUNT_REF,
} from '../consts';
import { useInvoicePreviewModal } from '../InvoicePreviewModalV2';
import { EMPTY_INVOICING_SCHEDULE } from './consts';
import { InvoicingScheduleLayout } from './InvoicingScheduleLayout';
import { InvoicingScheduleContext } from './InvoicingScheduleContext';
import { getSortedInvoices, groupTransactionsByExternalId, pushToastByChangedFields } from './utils';
import { BillingContext } from '../BillingContext';
import { useReplacedTemplateVariablesModal } from '../InvoiceModal/ReplacedTemplateVariablesModal';
import { useDuplicateCustomerNameModal } from './useDuplicateCustomerNameModal';
import { INVOICING_SCHEDULE_TABS_PANEL_TABS } from './InvoicingScheduleTabsPanel';
import { CreditNoteContainer } from './CreditNoteContainer';

export const InvoicingScheduleContainer = ({
  invoicingScheduleId: passedInvoicingScheduleId,
  includedTransactions: passedIncludedTransactions,
  selectedInvoiceId: passedSelectedInvoiceId,
  selectedReminderId: passedSelectedReminderId,
  selectedCustomer: passedSelectedCustomer,
  closeModal: closeInvoicingScheduleModal,
  initialTab,
  options = {},
  initialOpenRef,
  onInvoicingScheduleCreated,
  onInvoicingScheduleUpdated,
  setSelectedInvoice,
  onInvoicingScheduleDeleted,
}) => {
  const { orgId, integrations, orgConfigs, entities } = useContext(AppContext);
  const { editInvoice, refetchInvoicingScheduleRef } = useContext(BillingContext);
  const {
    operations: { editInvoicingSchedule },
  } = useInvoicingSchedulesAPI({ orgId, autoFetch: false });
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(passedSelectedInvoiceId);
  const isSelectedDraftInvoice = validateIsUUID(selectedInvoiceId);
  const { openModal: openDuplicateCustomerNameModal, Modal: DuplicateCustomerNameModal } =
    useDuplicateCustomerNameModal();

  const {
    transactionMetadataTags = [],
    customerMetadataTags = [],
    transactionCustomerMetadataTags = [],
  } = useGetAvailableTags({ orgId });

  const [expectedNumberOfInvoices, setExpectedNumberOfInvoices] = useState();

  const glIntegrations = integrations?.filter((i) => i.type === INTEGRATION_TYPES.GL);

  const {
    data: invoice,
    isLoading: isInvoiceLoading,
    isFetching: isInvoiceFetching,
    operations: { refetch: refetchCurrentInvoice },
  } = useInvoiceAPI({
    orgId,
    invoiceId: selectedInvoiceId,
    autoFetch: !isSelectedDraftInvoice,
    enableToasts: false,
  });

  const { pushToast, pushError } = useToasts();

  const { incomeAccountRefId, billingSenderDefaults = {} } = orgConfigs;

  const invoiceFormRef = useRef();
  const scheduleFormRef = useRef();
  const reminderFormRef = useRef();

  const {
    Modal: InvoicePreviewModal,
    openModal: openInvoicePreviewModal,
    closeModal: closeInvoicePreviewModal,
    isModalOpen: isInvoicePreviewModalOpen,
  } = useInvoicePreviewModal({ orgId, invoiceId: selectedInvoiceId, invoicingScheduleId: passedInvoicingScheduleId });

  const passedTransaction = useMemo(() => passedIncludedTransactions?.[0], [passedIncludedTransactions]);

  const preselectedCustomerId = useMemo(
    () => passedTransaction?.customer_id ?? passedSelectedCustomer?.id,
    [passedTransaction?.customer_id, passedSelectedCustomer?.id],
  );
  const preselectedCustomerName = useMemo(
    () => passedTransaction?.customer_name ?? passedSelectedCustomer?.name,
    [passedTransaction?.customer_name, passedSelectedCustomer?.name],
  );
  const preselectedCustomerMetadata = useMemo(
    () => passedTransaction?.customerMetadata ?? passedSelectedCustomer?.metadata,
    [passedTransaction?.customerMetadata, passedSelectedCustomer?.metadata],
  );

  const [customer, setCustomer] = useState(
    passedTransaction ? { id: passedTransaction.customer_id, name: passedTransaction.customer_name } : null,
  );

  const {
    data: customerDetails,
    isLoading: isCustomerDetailsLoading,
    operations: { refetch: refetchCustomer },
  } = useCustomerAPI({
    orgId,
    customerId: customer?.id,
    filters: {
      scopes: ['imports', 'invoicing_details', 'auto_charge_data'],
    },
    enabled: !!customer?.id,
  });

  const { data: productData, refetch: refetchProducts } = useProductsAPI({ orgId, params: { scopes: ['imports'] } });

  // make sure the import exists
  const customerIntegrationId = glIntegrations?.find((integration) => {
    const hasImport = customerDetails?.imports?.some(
      (existingImport) => existingImport.integration_id === integration.id,
    );
    const integrationId = customerDetails?.metadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID];
    // if there is no selected integrationId, we check if there is an associated import
    return (integration.id === integrationId && hasImport) || (!integrationId && hasImport);
  })?.id;

  const initialIntegrationId =
    customerIntegrationId ??
    glIntegrations?.find(
      (integration) =>
        integration.id === preselectedCustomerMetadata?.[INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID],
    )?.id;

  const initialInvoicingSchedule = useMemo(
    () => ({
      ...EMPTY_INVOICING_SCHEDULE,
      customer_id: preselectedCustomerId,
      customer_name: preselectedCustomerName,
      invoicing_frequency:
        options.defaultFrequency ??
        (EVENT_RECOGNITION_TYPES.includes(passedTransaction?.recognition) ? INVOICING_FREQUENCIES.EVENT_BASED : null),
      ...(options.autoSetAutoCharge && {
        auto_charge: !!customer?.has_active_payment_method,
      }),
      language: preselectedCustomerMetadata?.invoice_language ?? DEFAULT_INVOICE_LANGUAGE,
      external_invoice_template_id: orgConfigs[ORG_CONFIGS.INVOICE_TEMPLATE_ID],
      integration_id: initialIntegrationId ?? glIntegrations?.[0]?.id,
      entity_id: billingSenderDefaults?.default_entity_id?.toString() ?? entities[0]?.id,
    }),
    [
      preselectedCustomerId,
      preselectedCustomerName,
      options.defaultFrequency,
      options.autoSetAutoCharge,
      passedTransaction?.recognition,
      customer?.has_active_payment_method,
      preselectedCustomerMetadata?.invoice_language,
      orgConfigs,
      initialIntegrationId,
      glIntegrations,
      billingSenderDefaults?.default_entity_id,
      entities,
    ],
  );

  // invoicingSchedule is initially passed into this component
  // currentInvoicingSchedule is new invoicingSchedule witch replace initial invoicingSchedule in state,
  // invoicingScheduleFormValues is current transaction form values, we use it to get
  //   draft form values and update it on every form change
  const [currentInvoicingSchedule, setCurrentInvoicingSchedule] = useState(initialInvoicingSchedule);
  const [invoicingScheduleFormValues, setInvoicingScheduleFormValues] = useState();

  const glIntegration = glIntegrations.find(
    (integration) => integration.id === invoicingScheduleFormValues?.integration_id,
  );

  const invoicingService = getServiceCategory(glIntegration?.service);
  const invoicingServiceDisplayName = GL_INTEGRATION_SERVICES.includes(invoicingService)
    ? getIntegrationDisplayName(glIntegration)
    : '';
  const usesGLConfiguredTaxCodes =
    (glIntegration?.metadata?.useMappedTaxCodes || glIntegration?.metadata?.automaticTaxCalculation) ?? false;

  // Left side tabs panel selected tab
  const [selectedTabsPanelTab, setSelectedTabsPanelTab] = useState(
    initialTab ?? INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES,
  );

  const [allowResend, setAllowResend] = useState(false);

  // used in remind view
  const [selectedReminder, setSelectedReminder] = useState(undefined);
  const [reminderFormValues, setReminderFormValues] = useState(false);
  const [reminderLoading, setReminderLoading] = useState(false);

  const [showUnsavedWarning, setShowUnsavedWarning] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTransactionInvoicesPreviewModal, setShowTransactionInvoicesPreviewModal] = useState(false);
  const [detachInvoicesBeforeRemoveSchedule, setDetachInvoicesBeforeRemoveSchedule] = useState(false);
  const [includedTransactions, setIncludedTransactions] = useState(
    isEmpty(passedIncludedTransactions) ? [] : passedIncludedTransactions,
  );

  const [invoiceUpdateLoading, setInvoiceUpdateLoading] = useState(false);

  const [fetchedSelectedInvoice, setFetchedSelectedInvoice] = useState();
  const [invoiceFormValues, setInvoiceFormValues] = useState();
  const [isHistoryTabCollapsed, setIsHistoryTabCollapsed] = useState(true);
  const [bulkChangeInvoices, setBulkChangeInvoices] = useState([]);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const { data: productImports } = useImportsAPI({
    orgId,
    filters: {
      chifferObjectName: 'product',
      chifferObjectIds: includedTransactions?.map((transaction) => transaction.product_id)?.filter((id) => !!id) ?? [],
      integrationIds: glIntegration?.id ? [glIntegration.id] : [],
    },
    autoFetch: !!includedTransactions && !!glIntegration?.id,
  });

  const productImportsByProductId = useMemo(
    () => groupBy(productImports ?? [], 'chiffer_object_id', { uniqueness: true }),
    [productImports],
  );

  const { OpenReplacedTemplateVariablesModalButton, ReplacedTemplateVariablesModal } =
    useReplacedTemplateVariablesModal({ orgId, invoiceId: selectedInvoiceId, invoiceData: invoiceFormValues });

  const isScheduleDraft =
    !currentInvoicingSchedule?.id || !invoicingScheduleFormValues?.invoices?.some((invoice) => invoice?.id);

  const {
    data: glInvoiceImports,
    isLoading: isGlInvoiceImportsLoading,
    isFetching: isGlInvoiceImportsFetching,
  } = useImportsAPI({
    orgId,
    filters: {
      chifferObjectName: 'invoice',
      chifferObjectIds: invoicingScheduleFormValues?.invoices?.map((invoice) => invoice.id)?.filter((id) => !!id) ?? [],
    },
    autoFetch: !!(invoicingScheduleFormValues && currentInvoicingSchedule?.id && invoicingService),
  });

  useEffect(() => {
    if (invoice) {
      setFetchedSelectedInvoice(invoice);
    } else if (isSelectedDraftInvoice) {
      setFetchedSelectedInvoice(
        invoicingScheduleFormValues?.invoices?.find((invoice) => invoice?.unsavedId === selectedInvoiceId) ?? {},
      );
    } else {
      setFetchedSelectedInvoice(null);
    }
    // eslint-disable-next-line
  }, [invoice, selectedInvoiceId, isSelectedDraftInvoice]);

  const [loading, setLoading] = useState(false);
  const fetchInvoicingScheduleRef = useRef(() => {});

  useEffect(() => {
    if (
      passedSelectedReminderId &&
      fetchedSelectedInvoice?.id &&
      (selectedReminder === undefined ||
        (isInvoicePreviewModalOpen && selectedReminder && selectedReminder?.id === passedSelectedReminderId))
    ) {
      const reminder = fetchedSelectedInvoice?.reminders?.find(
        (reminder) => reminder.id === passedSelectedReminderId && !reminder?.sent_at,
      );

      setSelectedReminder(reminder);
      openInvoicePreviewModal();
    }
  }, [
    passedSelectedReminderId,
    selectedReminder,
    fetchedSelectedInvoice,
    openInvoicePreviewModal,
    isInvoicePreviewModalOpen,
  ]);

  const safeCloseModal = (values) => {
    if (isEqual(currentInvoicingSchedule, values) && !isScheduleDraft) {
      closeInvoicingScheduleModal && closeInvoicingScheduleModal();
    } else {
      setShowUnsavedWarning(true);
    }
  };

  useEffect(() => {
    if (currentInvoicingSchedule?.customer_id) {
      setCustomer({ id: currentInvoicingSchedule?.customer_id, name: currentInvoicingSchedule?.customer_name });
    }

    if (
      invoicingScheduleFormValues?.invoices?.length &&
      !fetchedSelectedInvoice &&
      !selectedInvoiceId &&
      selectedTabsPanelTab !== INVOICING_SCHEDULE_TABS_PANEL_TABS.WARNINGS
    ) {
      // auto select first invoice after modal open
      const firstInvoice = getSortedInvoices({ invoices: invoicingScheduleFormValues?.invoices })?.[0];
      setFetchedSelectedInvoice(firstInvoice);
      setSelectedInvoiceId(firstInvoice?.id ?? firstInvoice?.unsavedId);
    }
  }, [
    currentInvoicingSchedule,
    fetchedSelectedInvoice,
    invoicingScheduleFormValues,
    selectedInvoiceId,
    selectedTabsPanelTab,
  ]);

  useEffect(() => {
    if (
      invoicingScheduleFormValues &&
      customerDetails &&
      customerDetails.name !== invoicingScheduleFormValues?.customer_name
    ) {
      setInvoicingScheduleFormValues({
        ...invoicingScheduleFormValues,
        customer_name: customerDetails.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails, invoicingScheduleFormValues, setInvoicingScheduleFormValues]);

  useEffect(() => {
    if (isScheduleDraft && customerIntegrationId) {
      if (currentInvoicingSchedule?.integration_id !== customerIntegrationId) {
        setCurrentInvoicingSchedule({
          ...currentInvoicingSchedule,
          integration_id: customerIntegrationId,
        });
      }
    }
  }, [currentInvoicingSchedule, customerIntegrationId, isScheduleDraft]);

  const refetchInvoicingSchedule = useCallback(
    async ({ silently = false } = {}) => {
      if (!silently) {
        setLoading(true);
      }

      const invoicingScheduleFromBackend = await getInvoicingSchedule({
        orgId,
        invoicingScheduleId: passedInvoicingScheduleId,
        params: {
          scopes: ['transactions', 'invoices'],
          invoiceScopes: ['auto_charge_data', 'external_invoice'],
        },
      });

      const draftInvoices = invoicingScheduleFormValues?.invoices?.filter((invoice) => invoice?.unsavedId) ?? [];

      const invoices = [...draftInvoices, ...(invoicingScheduleFromBackend?.invoices ?? [])];

      const invoicesNewToSend = invoices.filter(
        (invoice) => !invoice.voided_at && !invoice.paid_at && !invoice.sent_at,
      );

      const externalTemplateId =
        invoices?.find((invoice) => !!invoice.metadata?.external_invoice_template_id && !invoice.sent_at)?.metadata
          ?.external_invoice_template_id ??
        invoices?.find((invoice) => !!invoice.metadata?.external_invoice_template_id)?.metadata
          ?.external_invoice_template_id;

      // after refetch schedule draft invoices should be in place
      setCurrentInvoicingSchedule({
        ...invoicingScheduleFromBackend,
        invoices,
        ...(invoicesNewToSend.length > 0 && {
          auto_send: invoicesNewToSend.every((invoice) => invoice.auto_send),
        }),
        ...(externalTemplateId && {
          external_invoice_template_id: externalTemplateId,
        }),
        integration_id: invoicingScheduleFromBackend?.integration_id ?? initialIntegrationId ?? null,
        entity_id:
          invoicingScheduleFromBackend?.entity_id ??
          billingSenderDefaults.default_entity_id?.toString() ??
          entities[0]?.id,
      });

      setIncludedTransactions(
        groupTransactionsByExternalId({
          transactions: invoicingScheduleFromBackend.transactions,
          asArray: true,
        }).flat(),
      );

      if (!silently) {
        setLoading(false);
      }
    },
    [
      orgId,
      passedInvoicingScheduleId,
      invoicingScheduleFormValues?.invoices,
      initialIntegrationId,
      billingSenderDefaults.default_entity_id,
      entities,
    ],
  );

  refetchInvoicingScheduleRef.current = refetchInvoicingSchedule;

  const { openModal: handleUpdateBulkIncomeAccount, ProductsBulkUpdateIncomeAccountModal } =
    useProductsBulkUpdateIncomeAccountModal({
      onSuccess: passedInvoicingScheduleId ? refetchInvoicingSchedule : null,
    });

  // if only the invoicing schedule ID is passed, fetch it so we have the full object
  useEffect(() => {
    if (passedInvoicingScheduleId) {
      refetchInvoicingSchedule();
    }
    // eslint-disable-next-line
  }, [passedInvoicingScheduleId, orgId]);

  const fillScheduleWithDraftInvoices = useCallback(
    async ({ invoices, invoicingSchedule, frequency, allowPastInvoices, applyRuleset } = {}) => {
      const scheduleWithFilledInvoiceData = await getScheduleWithUnsavedInvoicesData({
        orgId,
        body: {
          ...invoicingScheduleFormValues,
          ...invoicingSchedule,
          invoices: invoices ?? invoicingScheduleFormValues?.invoices,
          invoicing_frequency: frequency ?? invoicingScheduleFormValues?.invoicing_frequency,
          transactions: invoicingScheduleFormValues?.transactions ?? includedTransactions,
        },
        params: {
          applyRuleset,
        },
      });

      setCurrentInvoicingSchedule({ ...scheduleWithFilledInvoiceData, allow_past_invoices: allowPastInvoices });

      const newInvoices = scheduleWithFilledInvoiceData?.invoices || [];
      const firstInvoice = getSortedInvoices({ invoices: newInvoices })[0];
      setFetchedSelectedInvoice(firstInvoice);
      setSelectedInvoiceId(firstInvoice?.id ?? firstInvoice?.unsavedId);
    },
    [includedTransactions, invoicingScheduleFormValues, orgId],
  );

  const validateInvoiceFormAndExecuteAction = async ({ action, formRefCurrent }) => {
    const isFormValid = await validateFormWithToast({
      formCurrent: formRefCurrent ?? invoiceFormRef?.current,
      pushToast,
    });

    if (isFormValid) {
      setInvoiceUpdateLoading(true);
      try {
        await action();
      } catch (err) {
        if (
          err.response?.data?.errors?.message === `Duplicate customer name exists in ${invoicingServiceDisplayName}.`
        ) {
          openDuplicateCustomerNameModal({ customer: customerDetails });
        } else {
          throw err;
        }
      } finally {
        // Even if request fails we end the loading
        setInvoiceUpdateLoading(false);
      }
    }
  };

  useEffect(() => {
    const recalculateExpectedNumberOfInvoices = async () => {
      if (invoicingScheduleFormValues?.id && includedTransactions?.length) {
        try {
          const { data } = await generateInvoicesJSON({
            orgId,
            body: {
              customerId: includedTransactions?.[0]?.customer_id,
              transactionIds: includedTransactions.map(({ id }) => id),
              frequency: invoicingScheduleFormValues?.invoicing_frequency,
              allowPastInvoices: false,
              invoicingSchedule: invoicingScheduleFormValues,
            },
          });

          setExpectedNumberOfInvoices(data?.length);
        } catch (err) {
          pushError(err, 'Failed to set expected number of invoices.');
          console.error({ message: err.message, component: 'InvoiceScheduledContainer', stack: err });
        }
      }
    };
    recalculateExpectedNumberOfInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    includedTransactions,
    orgId,
    invoicingScheduleFormValues?.id,
    invoicingScheduleFormValues?.invoicing_frequency,
    pushError,
  ]);

  const performInvoicingScheduleUpdate = async (invoicingScheduleData) => {
    setInvoiceUpdateLoading(true);

    try {
      const invoiceSchedule = { ...currentInvoicingSchedule, ...invoicingScheduleData };

      const invoicingSchedule = await editInvoicingSchedule.mutateAsync({
        id: invoiceSchedule.id,
        data: invoiceSchedule,
        disableToast: true,
      });

      if (setSelectedInvoice) {
        const firstInvoice = getSortedInvoices({ invoices: invoicingSchedule?.invoices })?.[0];
        setSelectedInvoice(firstInvoice);
      }

      onInvoicingScheduleUpdated(invoicingSchedule);

      pushToastByChangedFields({ pushToast, invoicingScheduleData, invoice });
    } catch (err) {
      pushError(err, 'Failed to update Invoicing Schedule.');
      console.error({ message: err.message, component: 'InvoicingScheduleModal', stack: err });
    }

    setInvoiceUpdateLoading(false);
  };

  const disableSaveScheduleButton =
    SERVICE_WITH_INCOME_ACCOUNT_REF.includes(invoicingService) &&
    !incomeAccountRefId &&
    includedTransactions.some(
      (transaction) =>
        !productImportsByProductId[transaction?.product_id]?.metadata?.[PRODUCT_IMPORT_METADATA_KEYS.INCOME_ACCOUNT],
    );

  const isSelectedInvoiceImported = useMemo(
    () =>
      (invoiceFormValues?.is_imported &&
        getServiceCategory(invoiceFormValues?.import_service ?? invoiceFormValues?.imported_service) ===
          getServiceCategory(invoicingService)) ||
      glInvoiceImports?.some(
        (importedInvoice) =>
          selectedInvoiceId &&
          importedInvoice.chiffer_object_id === selectedInvoiceId &&
          importedInvoice.integration_id === glIntegration?.id,
      ),
    [
      glIntegration?.id,
      glInvoiceImports,
      invoiceFormValues?.import_service,
      invoiceFormValues?.imported_service,
      invoiceFormValues?.is_imported,
      invoicingService,
      selectedInvoiceId,
    ],
  );

  const invoicesCreatedInGl = useMemo(() => {
    if (!currentInvoicingSchedule?.id) {
      return invoicingScheduleFormValues?.invoices?.filter((invoice) => invoice.is_imported) ?? [];
    }

    const fromGlImports =
      glInvoiceImports?.filter(
        (invoice) => invoice.provider_name === invoicingService && invoice.metadata?.is_historical,
      ) ?? [];

    return (
      invoicingScheduleFormValues?.invoices?.filter((invoice) =>
        fromGlImports.some((importedInvoice) => importedInvoice.chiffer_object_id === invoice.id),
      ) ?? []
    );
  }, [currentInvoicingSchedule?.id, glInvoiceImports, invoicingScheduleFormValues?.invoices, invoicingService]);

  const productOptions = useMemo(
    () =>
      productData?.products.map((p) => {
        const existingGlImport = (p?.imports ?? []).find(
          (i) => getServiceCategory(i.provider_name) === invoicingService,
        );

        return {
          label: p.display_name || p.name,
          name: p.name,
          subLabel: existingGlImport
            ? `${existingGlImport?.metadata?.product_name || '<No Name>'} / ${existingGlImport?.provider_object_id}`
            : invoicingService && invoicingService !== MANUAL_GL_SERVICE_NAME
            ? 'New product'
            : `${p.name} / ${p.id}`,
          subValue: existingGlImport?.provider_object_id,
          value: p.id,
        };
      }),
    [productData?.products, invoicingService],
  );

  const transactionOptions = useMemo(
    () =>
      includedTransactions.map((t) => ({
        label: transactionDisplayTitle(t),
        recognition: t.recognition,
        value: t.id,
        transaction: t, // used by credit note selector
      })),
    [includedTransactions],
  );

  return (
    <InvoicingScheduleContext.Provider
      value={{
        closeModal: closeInvoicingScheduleModal,
        onInvoicingScheduleCreated,
        onInvoicingScheduleUpdated,
        onInvoicingScheduleDeleted,

        invoicingScheduleFormValues,
        setInvoicingScheduleFormValues,
        currentInvoicingSchedule,
        setCurrentInvoicingSchedule,
        allowResend,
        setAllowResend,

        selectedTabsPanelTab,
        setSelectedTabsPanelTab,

        disableSaveScheduleButton,

        fillScheduleWithDraftInvoices,

        performInvoicingScheduleUpdate,

        closeInvoicePreviewModal,
        openInvoicePreviewModal,
        InvoicePreviewModal,
        isInvoicePreviewModalOpen,

        isHistoryTabCollapsed,
        setIsHistoryTabCollapsed,

        OpenReplacedTemplateVariablesModalButton,
        ReplacedTemplateVariablesModal,

        isScheduleDraft,

        // 'defaultFrequency' allows the invoices to be generated instantly (skipping the frequency dropdown menu)
        defaultFrequency: options.defaultFrequency,
        initialOpenRef,

        bulkChangeInvoices,
        setBulkChangeInvoices,

        orgId,
        invoicingService,
        invoicingServiceDisplayName,
        glIntegration,
        customer,
        setCustomer,
        customerDetails,
        isCustomerDetailsLoading,
        refetchCustomer,
        customerIntegrationId,
        includedTransactions,
        setIncludedTransactions,
        preselectedCustomer: !!preselectedCustomerId || !!passedInvoicingScheduleId,
        usesGLConfiguredTaxCodes,

        productData,
        refetchProducts,
        productOptions,
        transactionOptions,
        productImportsByProductId,

        selectedInvoiceId,
        setSelectedInvoiceId,

        isInvoiceLoading,
        isInvoiceFetching,

        isSelectedInvoiceImportedLoading: isGlInvoiceImportsLoading || isGlInvoiceImportsFetching,
        isSelectedInvoiceImported,
        invoicesCreatedInGl,

        fetchedSelectedInvoice,
        setFetchedSelectedInvoice,
        refetchCurrentInvoice,
        refetchInvoicingSchedule,

        invoiceFormValues,
        setInvoiceFormValues,

        invoiceFormRef,
        reminderFormRef,
        scheduleFormRef,

        invoiceUpdateLoading,
        setInvoiceUpdateLoading,

        validateInvoiceFormAndExecuteAction,

        reminderFormValues,
        setReminderFormValues,
        reminderLoading,
        setReminderLoading,
        selectedReminder,
        setSelectedReminder,

        showUnsavedWarning,
        setShowUnsavedWarning,
        showDeleteModal,
        setShowDeleteModal,
        showTransactionInvoicesPreviewModal,
        setShowTransactionInvoicesPreviewModal,
        loading,
        setLoading,
        fetchInvoicingScheduleRef,
        safeCloseModal,

        expectedNumberOfInvoices,
        setExpectedNumberOfInvoices,

        handleUpdateBulkIncomeAccount,

        editInvoice,

        disableSubmitButton,
        setDisableSubmitButton,

        transactionMetadataTags,
        customerMetadataTags,
        transactionCustomerMetadataTags,

        detachInvoicesBeforeRemoveSchedule,
        setDetachInvoicesBeforeRemoveSchedule,
      }}
    >
      <CreditNoteContainer
        customerId={customer?.id}
        onAddCreditNote={() => setSelectedTabsPanelTab(INVOICING_SCHEDULE_TABS_PANEL_TABS.CREDIT_NOTES)}
        currency={invoicingScheduleFormValues?.currency}
        invoices={invoicingScheduleFormValues?.invoices}
        invoicingScheduleId={invoicingScheduleFormValues?.id}
      >
        <InvoicingScheduleLayout />
      </CreditNoteContainer>
      <ProductsBulkUpdateIncomeAccountModal />
      <DuplicateCustomerNameModal />
    </InvoicingScheduleContext.Provider>
  );
};
