import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { useOrganizationCountsAPI } from 'api/organizations';
import { useExternalUpdatesAPI } from 'api/externalUpdates';
import { EXTERNAL_UPDATES_SUPPORTED_RESOURCES } from 'views/ExternalUpdates/consts';
import { AlertFilledIcon } from 'components/Icons';
import { TRANSACTIONS_PAGES_ROUTES } from 'views/Transactions';
import { PopoverLinkContents, PopoverLinkContentsItem } from './PopoverLinkContents';
import { ProblemsCounter } from './styles';

export const TransactionLinksPopover = ({ togglePortal }) => {
  const title = 'Transactions';
  const { orgId } = useContext(AppContext);
  const { data: transactionsExternalUpdates } = useExternalUpdatesAPI({
    orgId,
    params: { page: 1, limit: 10000, objectType: EXTERNAL_UPDATES_SUPPORTED_RESOURCES.TRANSACTION },
  });

  const { data } = useOrganizationCountsAPI({
    orgId,
    scopes: ['unconfirmedTransactions'],
  });

  const unresolvedExternalUpdatesCount = transactionsExternalUpdates?.data?.length ?? 0;
  const unconfirmedTransactionsCount = data?.unconfirmedTransactions ?? 0;

  return (
    <PopoverLinkContents title={title}>
      <PopoverLinkContentsItem
        title={title}
        item={{
          title: 'All transactions',
          description: 'Manage all your transactions',
          to: {
            pathname: '/transactions',
            state: { transactionsConfirmation: 'All transactions' },
          },
        }}
        onClick={togglePortal}
      />

      {unconfirmedTransactionsCount > 0 && (
        <PopoverLinkContentsItem
          title={title}
          item={{
            titlePrefix: <AlertFilledIcon />,
            title: 'Unconfirmed data',
            titleSuffix: (
              <ProblemsCounter data-cy="transactions-external-updates-popover__counter">
                {unconfirmedTransactionsCount}
              </ProblemsCounter>
            ),
            description: 'Bulk process unconfirmed transactions',
            to: {
              pathname: '/transactions',
              state: { transactionsConfirmation: 'To be confirmed' },
            },
          }}
          onClick={togglePortal}
        />
      )}

      {unresolvedExternalUpdatesCount > 0 && (
        <PopoverLinkContentsItem
          title={title}
          item={{
            titlePrefix: <AlertFilledIcon />,
            title: 'Pending updates',
            titleSuffix: <ProblemsCounter>{unresolvedExternalUpdatesCount}</ProblemsCounter>,
            description: 'Check all external updates',
            to: TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS_UPDATES,
          }}
        />
      )}

      <PopoverLinkContentsItem
        title={title}
        item={{
          title: 'Contract PDFs',
          description: 'Upload contract PDF files',
          to: {
            pathname: '/contracts',
          },
        }}
        onClick={togglePortal}
      />
    </PopoverLinkContents>
  );
};
