import { useContext, useState, useCallback } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { AIBetaTagIcon, FileInvoiceGradientPurpleIcon } from 'components/Icons';
import { IconButton } from 'components/Buttons';
import { TooltipContainer } from 'components/Tooltip';
import { Spacer } from 'components/Core';

import { useContractFilesModal } from './ContractFilesModal/useContractFilesModal';
import { getUniqueAttachments } from './utils';
import { useContractTransactionsCreatedModal } from './ContractTransactionsCreatedModal/useContractTransactionsCreatedModal';
import { useContractAIPreviewModal } from './ContractAIPreviewModal/useContractAIPreviewModal';
import { useAIReaderLockedModal } from './AIReaderLockedModal/useAIReaderLockedModal';
import { ContractsListContainer } from './ContractsListView';

// TODO: idk why width is set to 20px if I don't use `!important` tag here
const StyledAIBetaIcon = styled(AIBetaTagIcon)`
  height: 20px;
  width: auto !important;
`;

// customer is an optional param
const useAIContractReaderModals = ({ customer } = {}) => {
  const { orgId, orgConfigs } = useContext(AppContext);

  const isContractReaderEnabled = !!orgConfigs?.contractReaderAssistant?.assistant_id;
  const [dataForCreatedModal, setDataForCreatedModal] = useState([]);

  const {
    ContractTransactionsCreatedModal,
    openContractTransactionsCreatedModal,
  } = useContractTransactionsCreatedModal({
    data: dataForCreatedModal,
    onSubmit: useCallback(() => {}, []),
  });

  const [dataForPreviewModal, setDataForPreviewModal] = useState({});

  const { ContractAIPreviewModal, openContractAIPreviewModal } = useContractAIPreviewModal({
    data: dataForPreviewModal,
    onSubmit: useCallback(
      (data) => {
        setDataForCreatedModal(data.createdTransactions);
        openContractTransactionsCreatedModal();
      },
      [openContractTransactionsCreatedModal],
    ),
  });

  const { ContractFilesModal, openContractFilesModal } = useContractFilesModal({
    customerId: customer?.id,
    uploadNew: true,
    onSubmit: useCallback(
      (data) => {
        setDataForPreviewModal({
          organization_id: orgId,
          contract_id: data.id,
          ...data.interpreted_values,
          // if customer is already explicitly set, then use that
          customer_id: customer?.id,
          customer_name: customer?.name,
        });
        openContractAIPreviewModal();
      },
      [customer, orgId, openContractAIPreviewModal],
    ),
  });

  const { AIReaderLockedModal, openAIReaderLockedModal } = useAIReaderLockedModal();

  // Use this to skip the Upload modal
  const openContractAIPreviewModalWithData = useCallback(
    (data) => {
      if (isContractReaderEnabled) {
        setDataForPreviewModal(data);
        openContractAIPreviewModal();
      } else {
        openAIReaderLockedModal();
      }
    },
    [isContractReaderEnabled, setDataForPreviewModal, openContractAIPreviewModal, openAIReaderLockedModal],
  );

  const AIContractReaderButton = () => (
    <TooltipContainer toolTipContent="Create transactions and invoices by uploading a contract">
      <IconButton
        icon={<FileInvoiceGradientPurpleIcon />}
        onClick={isContractReaderEnabled ? openContractFilesModal : openAIReaderLockedModal}
      >
        Upload Contract <Spacer width="5px" /> <StyledAIBetaIcon />
      </IconButton>
    </TooltipContainer>
  );

  return {
    ContractFilesModal,
    ContractAIPreviewModal,
    ContractTransactionsCreatedModal,
    AIReaderLockedModal,

    AIContractReaderButton,
    openContractAIPreviewModalWithData,
  };
};

const UploadContractButton = ({ onClick }) => (
  <IconButton icon={<FileInvoiceGradientPurpleIcon />} onClick={onClick}>
    Upload Contract <Spacer width="5px" /> <StyledAIBetaIcon />
  </IconButton>
);

export {
  useContractFilesModal,
  getUniqueAttachments,
  useAIContractReaderModals,
  ContractsListContainer,
  UploadContractButton,
};
