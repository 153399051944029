import React, { createContext, useState } from 'react';
import { RATES_TYPE, SPREAD_TYPE } from 'consts/global';
import { useAppSetting, useStateWithStorage } from 'utils/hooks';
import { fetchOrgConfigs } from 'utils';
import { useEntitiesAPI } from 'api/billingEntities/hooks';
import { INTEGRATION_TYPES } from 'consts/integrations';

export const AppContext = createContext();

export const AppContextProvider = ({
  children,
  dataFilter,
  setDataFilter,
  organizations,
  setOrganizations,
  integrations,
  setIntegrations,
  addProduct,
  setOrgConfigs,
  orgConfigs,
  dateFormat,
  formatDateWithLocale,
}) => {
  const [metadataFilter, setMetadataFilter] = useStateWithStorage('metadataFilter', null);
  const [rollup, setRollup] = useAppSetting('rollup', { initialValue: false });
  const [isARR, setIsARR] = useAppSetting('isARR');
  const [isCommitted, setIsCommitted] = useAppSetting('isCommitted', {
    initialValue: false,
  });
  const [optimisticAnalytics, setOptimisticAnalytics] = useAppSetting('optimisticAnalytics', {
    initialValue: false,
  });
  const [isIncreases, setIsIncreases] = useAppSetting('increasesFirst', {
    initialValue: false,
  });
  const [countInfluxAsRenewed, setCountInfluxAsRenewed] = useStateWithStorage('countInfluxAsRenewed', false);
  const [influxMonths] = useAppSetting('influxMonths');
  const [winbackMonths] = useAppSetting('winbackMonths');
  const [exchangeRatesDate, setExchangeRatesDate] = useAppSetting('exchangeRatesDate', {
    initialValue: null,
  });

  const [exchangeRatesType, setExchangeRatesType] = useAppSetting('exchangeRatesType', {
    initialValue: RATES_TYPE.BOOKING_DATES,
  });

  const [currencyISOCode, setCurrencyISOCode] = useAppSetting('currencyISOCode', {
    initialValue: 'USD',
  });

  const glIntegrations = integrations.filter((integration) => integration.type === INTEGRATION_TYPES.GL);

  const glIntegrationsById = glIntegrations.reduce((total, integration) => {
    total[integration.id] = integration;
    return total;
  }, {});

  const {
    data: entities,
    operations: { refetch: refetchEntities },
  } = useEntitiesAPI({
    orgId: organizations[0].id,
  });

  const refreshOrgConfigs = () => {
    fetchOrgConfigs({ organizations, setOrgConfigs });
  };
  const [requiredPlugins, setRequiredPlugins] = useState(null);

  return (
    <AppContext.Provider
      value={{
        // This adds a weird behaviour when ARR is on and the user tries to toggle off Recurring Revenue type
        // Maybe disable the filter when ARR is on?
        dataFilter: isARR ? { ...dataFilter, revenueTypes: [SPREAD_TYPE.RECURRING] } : dataFilter,
        setDataFilter,
        integrations,
        glIntegrations,
        glIntegrationsById,
        setIntegrations,
        entities: entities ?? [],
        refetchEntities,
        organizations,
        orgId: organizations[0].id,
        setOrganizations,
        addProduct,
        metadataFilter,
        setMetadataFilter,
        dateFormat,
        formatDateWithLocale,
        orgConfigs,
        refreshOrgConfigs,
        requiredPlugins,
        setRequiredPlugins,
        appSettings: {
          isARR,
          isIncreases,
          isCommitted,
          optimisticAnalytics,
          rollup,
          setIsARR,
          setIsIncreases,
          setRollup,
          setIsCommitted,
          setOptimisticAnalytics,
          countInfluxAsRenewed,
          setCountInfluxAsRenewed,
          influxMonths,
          winbackMonths,
          currencyISOCode,
          setCurrencyISOCode,
          exchangeRatesType,
          setExchangeRatesType,
          exchangeRatesDate,
          setExchangeRatesDate,
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
