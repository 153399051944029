import dayjs from 'dayjs';
import React from 'react';
import { isNil } from 'lodash';
import { WarningBar } from 'components/Blocks';
import { INVOICE_MAGIC_METADATA } from 'views/Billing/consts';
import { hasInvoiceFailedToSend } from 'views/Billing/utils';

export const InvoiceFailedToSendWarning = ({ invoice }) => {
  const invoiceHasFailedToSend = hasInvoiceFailedToSend({ invoice });
  if (!invoiceHasFailedToSend) return null;

  const invoiceFailedCount = invoice.metadata?.[INVOICE_MAGIC_METADATA.SEND_EMAIL_ERROR_COUNT];
  const warnings = [];
  if (isNil(invoiceFailedCount)) {
    warnings.push(
      <>
        <b>Failed to send</b> invoice on{' '}
        {dayjs.utc(invoice.metadata?.[INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_FAILED_AT]).format('MMM DD, YYYY')}.
        <br />
        <b>Reason:</b>{' '}
        <i>
          {invoice.metadata?.[INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_ERROR]}
          <b />
        </i>
      </>,
    );
  } else if (invoiceFailedCount === 1) {
    warnings.push(
      <>
        <b>Failed to send</b> invoice on{' '}
        {dayjs.utc(invoice.metadata?.[INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_FAILED_AT]).format('MMM DD, YYYY')}.
        Subscript will try to resend it on {dayjs.utc(invoice.send_date).format('MMM DD, YYYY')}.
        <br />
        <b>Reason:</b>{' '}
        <i>
          {invoice.metadata?.[INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_ERROR]}
          <b />
        </i>
      </>,
    );
  } else if (invoiceFailedCount >= 2) {
    warnings.push(
      <>
        The second automatic attempt to send the invoice <b>failed</b> on{' '}
        {dayjs.utc(invoice.metadata?.[INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_FAILED_AT]).format('MMM DD, YYYY')}.
        Schedule it again or send it manually.
      </>,
    );
  }

  return invoiceHasFailedToSend && <WarningBar fullWidth warnings={warnings} />;
};
