import { useCallback, useContext, useMemo, useRef, useState } from 'react';

import { AppContext } from 'AppContext';
import { useReceiptsAPI } from 'api/billingReceipts';
import { BillingEntitySelector } from 'views/Billing/Common/BillingEntitySelector';
import { Centerer, Column, FlexEndContainer, Row, Spacer } from 'components/Core';
import { useConfirmModal } from 'shared/ConfirmModal';
import { SwitchContainer, SwitchWithLabel } from 'components/Controls';
import { Modal, ModalButton, ModalCloseIcon, ModalContainer, ModalFooter } from 'components/Modal';
import { TooltipContainer } from 'components/Tooltip';
import { InlineButton, SubmitButton } from 'components/Buttons';
import { CircleLoader } from 'components/Loaders';
import { stringToBoolean } from 'utils/stringUtils';
import { hasInvoiceFailedToSend } from 'views/Billing/utils';
import { DEFAULT_INVOICE_LANGUAGE, INVOICE_MAGIC_METADATA } from '../../../consts';
import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';
import { InvoicingScheduleLanguageSelector } from '../../InvoicingScheduleLanguageSelector';
import { groupTransactionsByExternalId } from '../../utils';
import { IntegrationSelector } from '../../IntegrationSelector';
import { useInvoiceCurrencySwitch } from '../../InvoicingScheduleCreatePanel/useInvoiceCurrencySwitch';
import { SelectTransactionsTable } from '../../InvoicingScheduleSelectTransactionsPanel/SelectTransactionsTable';
import { useExternalInvoiceTemplateSelector } from '../../useExternalInvoiceTemplateSelector';
import { useSaveSettingsModal } from './useSaveSettingsModal';
import {
  Dot,
  Button,
  SubText,
  StyledHelpCircleIcon,
  SettingsColumn,
  ColumnTitle,
  AutoChargeBlock,
  AutoChargeTitle,
  StyledArrowIcon,
  TransationsIncludedColumn,
  TransactionsHeader,
  TransactionsCounter,
  TransactionsTitle,
  SelectorsRow,
} from './styles';
import { useSelectTransactionModal } from '../../InvoicingScheduleSelectTransactionsPanel';

const SettingsModal = ({ onClose }) => {
  const {
    orgConfigs,
    orgId,
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);

  const {
    glIntegration,
    isScheduleDraft,
    includedTransactions,
    currentInvoicingSchedule,
    invoicingScheduleFormValues,
    invoiceUpdateLoading,
    scheduleFormRef,
    invoiceFormRef,
    invoiceFormValues,
  } = useContext(InvoicingScheduleContext);

  const { billingReceiptDefaults } = orgConfigs;

  const initialEntityIdRef = useRef(invoicingScheduleFormValues?.entity_id);

  const setFieldValue = scheduleFormRef?.current?.setFieldValue;
  const singleInvoiceSetFieldValue = invoiceFormRef?.current?.setFieldValue;

  const { data: receipts } = useReceiptsAPI({
    orgId,
    filters: {
      invoiceIds: invoicingScheduleFormValues?.invoices?.map((invoice) => invoice.id)?.filter((id) => !!id) ?? null,
    },
    autoFetch: !!invoicingScheduleFormValues?.invoices?.find((invoice) => !!invoice.id),
  });

  const { Modal: SelectTransactionModal, openModal: openSelectTransactionModal } = useSelectTransactionModal();

  const autoSendReceiptValue = useMemo(() => {
    const unsentReceipts = receipts?.filter((receipt) => !receipt.sent_at);

    return (
      invoicingScheduleFormValues?.auto_send_receipts ??
      (unsentReceipts && unsentReceipts.length > 0
        ? unsentReceipts.every((receipt) => receipt.auto_send)
        : billingReceiptDefaults.auto_send)
    );
  }, [billingReceiptDefaults.auto_send, invoicingScheduleFormValues?.auto_send_receipts, receipts]);

  const {
    ExternalInvoiceTemplateSelector,
    externalInvoiceTemplateOptions,
    hasInvoiceTemplates,
  } = useExternalInvoiceTemplateSelector({
    name: 'external_invoice_template_id',
    integrationId: glIntegration?.id,
  });

  const {
    openConfirmModal: openConfirmTurnOffAutoSendModal,
    ConfirmModal: ConfirmTurnOffAutoSendModal,
  } = useConfirmModal({
    title: 'Turn off auto-send?',
    width: '500px',
    content: (
      <Column>
        Turning off this setting means you will have to manually save and send all invoices. Subscript won't
        automatically retry invoices that previously failed to send.
      </Column>
    ),
    denyButtonText: 'No, cancel',
    confirmButtonText: 'Yes, turn off auto-send',
    onConfirm: () => {
      setFieldValue('auto_send', !invoicingScheduleFormValues?.auto_send);
      singleInvoiceSetFieldValue('metadata', {
        ...invoiceFormValues?.metadata,
        [`${INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_FAILED_AT}`]: null,
        [`${INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_ERROR}`]: null,
        [`${INVOICE_MAGIC_METADATA.SEND_EMAIL_ERROR_COUNT}`]: null,
      });
      setFieldValue(
        'invoices',
        invoicingScheduleFormValues?.invoices.map((invoice) => ({
          ...invoice,
          metadata: {
            ...invoice.metadata,
            [`${INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_FAILED_AT}`]: null,
            [`${INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_ERROR}`]: null,
            [`${INVOICE_MAGIC_METADATA.SEND_EMAIL_ERROR_COUNT}`]: null,
          },
        })),
      );
    },
    onDeny: () => {},
  });

  const handleAutoSendClick = useCallback(
    (selectedValue) => {
      if (!isScheduleDraft) {
        if (
          stringToBoolean(selectedValue) === false &&
          invoicingScheduleFormValues.invoices.some((invoice) => hasInvoiceFailedToSend({ invoice }))
        ) {
          openConfirmTurnOffAutoSendModal();
          return;
        }
      }

      setFieldValue('auto_send', !invoicingScheduleFormValues?.auto_send);
    },
    [
      invoicingScheduleFormValues?.auto_send,
      isScheduleDraft,
      setFieldValue,
      openConfirmTurnOffAutoSendModal,
      invoicingScheduleFormValues.invoices,
    ],
  );

  const handleAutoSendReceiptsClick = useCallback(() => setFieldValue('auto_send_receipts', !autoSendReceiptValue), [
    autoSendReceiptValue,
    setFieldValue,
  ]);

  const handleAutoChargeClick = useCallback(
    (value) => {
      setFieldValue('auto_charge', value);
      setFieldValue('auto_send', false);
    },
    [setFieldValue],
  );

  const AutoSendComponent = () => (
    <SwitchContainer>
      <SwitchWithLabel
        name="schedule-auto-send"
        onChange={handleAutoSendClick}
        checked={invoicingScheduleFormValues?.auto_send}
        label="Send all invoices automatically"
        disabled={invoicingScheduleFormValues?.auto_charge === true}
        labelSize="12px"
      />
    </SwitchContainer>
  );

  const { InvoiceCurrencySwitch, hasOriginalCurrencyToBillIn } = useInvoiceCurrencySwitch({
    setFieldValue,
    values: invoicingScheduleFormValues,
  });

  const { openSaveSettingsModal, SaveSettingsModal } = useSaveSettingsModal({
    showCurrency: hasOriginalCurrencyToBillIn,
    hasInvoiceTemplates,
    autoSendReceiptValue,
    autoSendInvoicesValue: invoicingScheduleFormValues?.auto_send,
    autoChargeValue: invoicingScheduleFormValues?.auto_charge,
    currencyValue: invoicingScheduleFormValues?.currency ?? defaultOrgCurrency,
    externalInvoiceTemplateValue: invoicingScheduleFormValues?.external_invoice_template_id,
    languageValue: invoicingScheduleFormValues?.language ?? DEFAULT_INVOICE_LANGUAGE,
    receipts,
    afterConfirmFn: () => onClose(),
    externalInvoiceTemplateOptions,
    initialEntityId: initialEntityIdRef.current,
  });

  const handleOnClose = () => {
    scheduleFormRef?.current && scheduleFormRef.current.resetForm();
    onClose();
  };

  return (
    <>
      <ModalContainer>
        <Modal width="1200px" minHeight="500px" padding="0px" height="auto" overflow="visible">
          <ModalCloseIcon onClick={handleOnClose} />

          {invoiceUpdateLoading ? (
            <Centerer style={{ marginTop: 40, marginBottom: 'auto' }}>
              <CircleLoader />
            </Centerer>
          ) : (
            <Row horizontal="space-between" vertical="flex-start" style={{ flexGrow: 1 }}>
              <SettingsColumn>
                <ColumnTitle>Main settings</ColumnTitle>

                <BillingEntitySelector name="entity_id" value={invoicingScheduleFormValues?.entity_id} />

                <Spacer height="16px" />

                <SelectorsRow>
                  <IntegrationSelector />

                  <ExternalInvoiceTemplateSelector />
                </SelectorsRow>

                <Spacer height="16px" />

                <InvoicingScheduleLanguageSelector />

                <Spacer height="16px" />

                {!!hasOriginalCurrencyToBillIn && (
                  <>
                    <InvoiceCurrencySwitch />
                    <Spacer height="16px" />
                  </>
                )}

                <SwitchWithLabel
                  name="schedule-auto-send-receipts"
                  onChange={handleAutoSendReceiptsClick}
                  checked={autoSendReceiptValue}
                  label={
                    <span>
                      Send all receipts to customers after payments{' '}
                      <TooltipContainer
                        fontSize="12px"
                        toolTipContent={
                          <ul
                            style={{
                              textAlign: 'left',
                            }}
                          >
                            <li>
                              <Dot /> Subscript will auto-create and auto-send a receipt upon auto-charging a customer.
                            </li>
                            <li>
                              <Dot /> To auto-send a receipt when invoices are marked as paid in your GL, please
                              generate the receipt manually first.
                            </li>
                            <li>
                              <Dot /> Receipts will not be auto-sent when an invoice is manually marked as paid in
                              Subscript.
                            </li>
                          </ul>
                        }
                      >
                        <StyledHelpCircleIcon />
                      </TooltipContainer>
                    </span>
                  }
                  labelSize="12px"
                />

                <Spacer height="16px" />

                {invoicingScheduleFormValues?.auto_charge === true ? (
                  <TooltipContainer toolTipContent="When auto-charge is enabled, the invoices will be charged on invoice date, and you will not need to send them">
                    <AutoSendComponent />
                  </TooltipContainer>
                ) : (
                  <AutoSendComponent />
                )}

                <AutoChargeBlock>
                  <AutoChargeTitle isOn={invoicingScheduleFormValues?.auto_charge}>
                    Auto-charging is <span>{invoicingScheduleFormValues?.auto_charge ? 'on' : 'off'}</span>
                  </AutoChargeTitle>
                  <SubText>
                    Change the payment method and ask customer to pay by credit card with auto-charge for future
                    invoices:
                  </SubText>
                  <Button
                    data-cy="billing__invoice-schedule-modal__auto-charge-button"
                    onClick={() => {
                      if (!invoicingScheduleFormValues?.auto_charge) {
                        handleAutoChargeClick(true);
                      } else {
                        handleAutoChargeClick(false);
                      }
                    }}
                  >
                    {invoicingScheduleFormValues?.auto_charge ? 'Stop auto-charge' : 'Auto-charge'} the customer
                  </Button>
                </AutoChargeBlock>
              </SettingsColumn>

              <TransationsIncludedColumn>
                <TransactionsHeader>
                  <TransactionsCounter data-cy="invoicing-schedule__settings-modal__transactions-counter">
                    {includedTransactions?.length}
                  </TransactionsCounter>
                  <TransactionsTitle>Transactions included</TransactionsTitle>
                  <InlineButton
                    data-cy="billing__invoice-schedule-modal__open-transactions-list-button"
                    style={{ padding: '4px 8px' }}
                    isSecondary
                    withBackground
                    onClick={(event) => {
                      event.preventDefault();
                      openSelectTransactionModal();
                    }}
                  >
                    Add transactions
                  </InlineButton>
                </TransactionsHeader>

                <Column horizontal="flex-start" vertical="flex-start" style={{ maxHeight: 400, overflow: 'auto' }}>
                  <SelectTransactionsTable
                    isNotCreatedSchedule={false}
                    currentInvoicingSchedule={currentInvoicingSchedule}
                    transactionIdsAndOptions={includedTransactions?.reduce((acc, transaction) => {
                      acc[transaction?.id] = {};
                      return acc;
                    }, {})}
                    setTransactionIdsAndOptions={() => {}}
                    initiallySelectedTransactions={includedTransactions}
                    groupedTransactions={groupTransactionsByExternalId({
                      transactions: includedTransactions,
                      initiallySelectedTransactions: includedTransactions,
                    })}
                  />
                </Column>
              </TransationsIncludedColumn>
            </Row>
          )}

          <ModalFooter noFixedHeight padding="12px 36px">
            <Row horizontal="space-between">
              <div></div>
              <FlexEndContainer>
                <ModalButton
                  data-cy="invoicing-schedule__settings-modal__cancel"
                  style={{ marginRight: 8 }}
                  onClick={handleOnClose}
                >
                  Cancel
                </ModalButton>
                <SubmitButton
                  data-cy="invoicing-schedule__settings-modal__confirm"
                  style={{ fontSize: 12 }}
                  onClick={() => openSaveSettingsModal()}
                >
                  <b>Save Settings</b>
                  <StyledArrowIcon />
                </SubmitButton>
              </FlexEndContainer>
            </Row>
          </ModalFooter>
        </Modal>
      </ModalContainer>

      <SaveSettingsModal />
      <ConfirmTurnOffAutoSendModal />
      <SelectTransactionModal />
    </>
  );
};

export const useSettingsModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(() => setShowModal(true), []);

  const Modal = useCallback(
    () => (showModal ? <SettingsModal onClose={() => setShowModal(false)} {...props} /> : null),
    [props, showModal],
  );

  return {
    openSettingsModal: openModal,
    SettingsModal: Modal,
    isSettingsModalVisible: showModal,
  };
};
