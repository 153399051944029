import React, { useCallback, useContext } from 'react';
import { AppContext } from 'AppContext';
import { useInvoicePdfAPI } from 'api/billing';
import { getServiceCategory } from 'models/integration';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer, ModalHeader, ModalTitleText } from 'components/Modal';
import { Centerer, Column, Flexer, Row } from 'components/Core';
import { AlarmIcon, AITagSolidIcon, FileTextIcon } from 'components/Icons';
import aiCalendarTimeIcon from 'images/ai-calendar-time.svg';

import { CustomDatePicker } from 'components/Controls';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';
import { getModalInvoiceStatus } from '../InvoiceModal/utils';
import { INVOICE_STATUSES, SERVICE_WITHOUT_INVOICE_SEND_EMAIL, TITLE_BY_STATUS } from '../consts';
import { InvoicePreviewContents } from './InvoicePreviewContents';
import { InvoiceDetailsForm } from './InvoiceDetailsForm';
import { InvoiceRemindForm } from './InvoiceRemindForm';
import { InvoicePreviewFooter } from './InvoicePreviewFooter';
import {
  GeneratingPreview,
  InvoiceLink,
  ScheduleTabs,
  StyledLeftTabButton,
  StyledRightTabButton,
  Wrapper,
} from './styles';
import { invoicePDFDownload } from './utils';
import { SentInvoiceHistory } from './InvoiceHistory/SentInvoiceHistory';
import { ExternalServiceLink } from './ExternalServiceLink';
import { AITooltipContainer } from './AITooltipContainer';

export const InvoicePreviewModal = ({ onClose, isResend }) => {
  const { orgId } = useContext(AppContext);
  const {
    invoiceUpdateLoading,
    invoiceFormValues,
    reminderFormValues,
    invoiceFormRef,
    setSelectedReminder,
    reminderFormRef,
    invoicingService,
  } = useContext(InvoicingScheduleContext);

  const { data: invoicePdf, isLoading: isPdfLoading, isFetching: isPdfFetching } = useInvoicePdfAPI({
    orgId,
    invoiceId: invoiceFormValues?.id,
    autoFetch: true,
  });

  const invoice = invoiceFormValues;

  const invoiceStatus = invoice ? getModalInvoiceStatus({ invoice }) : '';

  const onPreviewClose = useCallback(() => {
    setSelectedReminder(null);
    onClose();
  }, [onClose, setSelectedReminder]);

  const isRemindModal = invoiceStatus === INVOICE_STATUSES.REMIND && !isResend;
  const isReminderGeneratedByAI = isRemindModal && reminderFormValues?.ai_generated;

  return (
    <ModalContainer style={{ flexDirection: 'column', backgroundColor: 'var(--primaryBlack95)' }}>
      <Modal
        padding="0"
        direction="row"
        maxHeight={'90vh'}
        width={'90vw'}
        overflow="auto"
        background="var(--primaryGray)"
        data-cy="invoice-preview-modal"
      >
        {!invoice || invoiceUpdateLoading ? (
          <Centerer>
            <GeneratingPreview gap="4px">
              <AlarmIcon /> {invoice?.pdf ? 'Loading...' : 'Generating the preview...'}
            </GeneratingPreview>
          </Centerer>
        ) : (
          <>
            {isRemindModal && <SentInvoiceHistory />}
            <Column horizontal="start" style={{ paddingTop: 28 }}>
              <ModalHeader padding style={{ paddingTop: 0 }}>
                <ModalCloseIcon data-cy="invoice-modal__close-button" onClose={onPreviewClose} />

                <Row>
                  <ModalTitleText compact>
                    <Flexer alignItems="center" gap="8px">
                      {isReminderGeneratedByAI && <AITagSolidIcon />}
                      <b>
                        {invoice?.auto_charge ? 'Email:' : isResend ? 'Resend Invoice' : TITLE_BY_STATUS[invoiceStatus]}
                      </b>
                    </Flexer>
                  </ModalTitleText>

                  {!SERVICE_WITHOUT_INVOICE_SEND_EMAIL.includes(getServiceCategory(invoicingService)) && !isResend && (
                    <ScheduleTabs>
                      <StyledLeftTabButton
                        active={isRemindModal ? !reminderFormValues?.auto_send : !invoice?.auto_send}
                        onClick={async () => {
                          if (isRemindModal) {
                            reminderFormRef?.current?.setFieldValue('auto_send', false);
                          } else {
                            invoiceFormRef?.current?.setFieldValue('auto_send', false);
                          }
                        }}
                        isBig={false}
                      >
                        Send right now
                      </StyledLeftTabButton>

                      <StyledRightTabButton
                        onClick={() => {
                          if (isRemindModal) {
                            reminderFormRef?.current?.setFieldValue('auto_send', true);
                          } else {
                            invoiceFormRef?.current?.setFieldValue('auto_send', true);
                          }
                        }}
                        active={isRemindModal ? reminderFormValues?.auto_send : invoice?.auto_send}
                        isBig={false}
                        data-cy="invoice-preview-modal__schedule-send-button"
                      >
                        Schedule send
                      </StyledRightTabButton>

                      {(isRemindModal ? reminderFormValues?.auto_send : invoice?.auto_send) && (
                        <div style={{ position: 'relative' }}>
                          <AITooltipContainer
                            content={reminderFormValues?.metadata?.aiResponse?.reason_for_follow_up}
                            isVisible={isReminderGeneratedByAI}
                          >
                            <CustomDatePicker
                              formik
                              floatErrors
                              errorBottomPosition="-65px"
                              name={isRemindModal ? 'scheduled_for' : 'send_date'}
                              meta={
                                isRemindModal
                                  ? reminderFormRef?.current?.getFieldMeta('scheduled_for')
                                  : invoiceFormRef?.current?.getFieldMeta('send_date')
                              }
                              calendarIcon={isReminderGeneratedByAI ? aiCalendarTimeIcon : null}
                              dateFormat="MMM dd, yyyy"
                              selected={
                                isRemindModal
                                  ? reminderFormValues?.scheduled_for
                                    ? formatDateForDatepicker(reminderFormValues?.scheduled_for)
                                    : null
                                  : invoice?.send_date
                                  ? formatDateForDatepicker(invoice?.send_date)
                                  : null
                              }
                              onChange={(name, send_date) =>
                                isRemindModal
                                  ? reminderFormRef?.current?.setFieldValue(name, updateDateFromDatePicker(send_date))
                                  : invoiceFormRef?.current?.setFieldValue(name, updateDateFromDatePicker(send_date))
                              }
                            />
                          </AITooltipContainer>
                        </div>
                      )}
                    </ScheduleTabs>
                  )}
                </Row>

                <Row>
                  {invoicePdf?.pdf && (
                    <InvoiceLink onClick={() => invoicePDFDownload({ invoicePDF: invoicePdf })}>
                      <FileTextIcon />
                      PDF
                    </InvoiceLink>
                  )}

                  <ExternalServiceLink />
                </Row>
              </ModalHeader>

              <ModalBody maxWidth={isRemindModal ? 'calc(90vw - 290px)' : '90vw'} paddingLeft="0px" paddingRight="0px">
                <Wrapper>
                  {isRemindModal ? (
                    <InvoiceRemindForm values={invoice} />
                  ) : (
                    <InvoiceDetailsForm
                      invoicePDFDownload={() => invoicePdf?.pdf && invoicePDFDownload({ invoicePDF: invoicePdf })}
                      isPdfLoading={isPdfLoading || isPdfFetching}
                      isResend={isResend}
                    />
                  )}

                  <InvoicePreviewContents invoicePdf={invoicePdf} isPdfLoading={isPdfLoading || isPdfFetching} />
                </Wrapper>
              </ModalBody>

              <Row style={{ width: '100%' }} horizontal="end">
                <InvoicePreviewFooter
                  invoice={invoice}
                  modalInvoiceStatus={invoiceStatus}
                  onClose={onPreviewClose}
                  isResend={isResend}
                />
              </Row>
            </Column>
          </>
        )}
      </Modal>
    </ModalContainer>
  );
};
