export const initialState = {
  selectedCRMIntegration: null,
  closedWonStages: null,
  loading: true,
  seatsVar: null,
  contractStartDateField: null,
  contractEndDateField: null,
  selectedCRM: 'hubspot',
  products: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'POPULATE_INTEGRATION':
      const { selectedCRMIntegration, closedWonStages, seatsVar, products } = action.payload;
      return {
        ...state,
        selectedCRMIntegration,
        closedWonStages,
        seatsVar,
        products,
        loading: false,
      };

    case 'UPDATE_INTEGRATION':
      return {
        ...state,
        selectedCRMIntegration: action.payload.updatedIntegration,
        loading: false,
      };

    case 'UPDATE_PRODUCT':
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === action.payload.changedProduct.id ? action.payload.changedProduct : product,
        ),
      };

    case 'SET_CLOSED_WON_STAGES':
      return {
        ...state,
        closedWonStages: action.payload,
      };

    case 'SELECT_CRM': {
      return {
        ...state,
        selectedCRM: action.payload,
      };
    }

    case 'SET_SEATS_VAR': {
      return {
        ...state,
        seatsVar: action.payload,
      };
    }

    case 'SET_CONTRACT_START_DATE': {
      return {
        ...state,
        contractStartDateField: action.payload,
      };
    }
    case 'SET_CONTRACT_END_DATE': {
      return {
        ...state,
        contractEndDateField: action.payload,
      };
    }
    default:
      return state;
  }
};
