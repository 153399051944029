import { isEmpty, set } from 'lodash';

const getColumnValue = ({ csvColumnsTransformations, csvRow, columnKey, csvColumnsMapper, allProducts }) => {
  const transformer = csvColumnsTransformations?.[columnKey] ?? ((v) => v);
  const rawValue = csvRow[csvColumnsMapper[columnKey]];
  let newValue = rawValue && rawValue.length ? transformer(rawValue, allProducts) : null;

  return newValue;
};

export const updateColumnData = ({
  changedColumnMapping,
  csvColumnsMapper,
  parsedCsv,
  formRef,
  csvColumnsTransformations,
  setFuzzyRowsMapper,
  allProducts,
}) => {
  if (formRef?.current) {
    parsedCsv?.data?.forEach((csvRow, index) => {
      if (index === parsedCsv?.data?.length - 1 && Object.values(csvRow).every(isEmpty)) return null;

      changedColumnMapping.forEach((columnKey) => {
        if (Object.keys(csvRow).includes(csvColumnsMapper[columnKey]) || csvColumnsMapper[columnKey] === null) {
          const newValue = csvColumnsMapper[columnKey]
            ? getColumnValue({
                csvColumnsTransformations,
                columnKey,
                csvColumnsMapper,
                csvRow,
                allProducts,
              })
            : null;

          //clean previous fuzzy search
          setFuzzyRowsMapper((oldMapper) => ({ ...oldMapper, [index]: { ...oldMapper[index], [columnKey]: {} } }));

          formRef?.current?.setFieldValue(`[${index}].${columnKey}`, newValue, false);
        }
      });
    });
  }
};

export const mapFromParsedCsv = ({ parsedCsv, csvColumnsMapper, csvColumnsTransformations, allProducts }) => {
  return parsedCsv?.data
    ?.map((csvRow, index) => {
      if (index === parsedCsv?.data?.length - 1 && Object.values(csvRow).every(isEmpty)) return null;
      const newRow = {};
      Object.keys(csvColumnsMapper).forEach((columnKey) => {
        if (Object.keys(csvRow).includes(csvColumnsMapper[columnKey])) {
          const newValue = getColumnValue({
            csvColumnsTransformations,
            columnKey,
            csvColumnsMapper,
            csvRow,
            allProducts,
          });

          set(newRow, columnKey, newValue);
        }
      });
      return newRow;
    })
    .filter((row) => row)
    .map((row, index) => ({ ...row, rowIndex: index }));
};
