import React, { useMemo, useRef, useState, useContext } from 'react';
import { isEmpty, isEqual, isNil } from 'lodash';

import { AppContext } from 'AppContext';
import { TransactionSinglePageLayout } from 'views/Transactions/TransactionSinglePage';
import { getCustomer, getProduct } from 'api';
import { useTransactionsAPI } from 'api/transactions';
import { useInvariantCheck } from 'utils/hooks';
import { normalizeAmountFields } from 'models/transaction';
import { GlobalTogglesContextProvider } from 'shared/GlobalToggles';

import { TransactionCoordinator } from './TransactionCoordinator';
import { TransactionLayout } from './TransactionLayout';
import { TransactionContext } from './TransactionContext';
import { EMPTY_TRANSACTION, TRANSACTION_MODAL_MODE, TRANSACTION_VIEW_MODE } from './consts';
import { checkTransactionFieldsMatch } from './invariantChecks';

export const TransactionSingleContentContainer = ({
  view,
  mode,
  saveNewTransaction = true,
  transaction, // required if mode is EDIT
  // can be a full Transaction object, or just { id: }
  // when you provide only the ID, the component will fetch the transaction
  changedData, // should be an object with field keys
  // equal to the actual keys of a transaction. If these are
  // present, they will be highlighted in the modal
  organization,
  initialTransactionsBulk, // only required if mode is EDIT_BULK
  transactionPageLoading, // only required if mode is EDIT_BULK (to show a loader and not an abrupt change values)
  closeModal,
  fetchTransactions,
  onTransactionCreated, // only required if mode is CREATE
  onTransactionUpdated, // only required if mode is EDIT or EDIT_BULK
  onTransactionDeleted,
  onTransactionDuplicated,
  onFormSubmitted, // handle provided to allow external processing of data on submit. Currently used by EXTERNAL_UPDATE
}) => {
  const {
    appSettings: { currencyISOCode: currency },
    orgConfigs,
    orgId,
  } = useContext(AppContext);

  const {
    operations: { duplicateTransaction },
  } = useTransactionsAPI({ orgId, autoFetch: false });

  const initialTransaction = useMemo(() => {
    const initial = normalizeAmountFields(
      (mode === TRANSACTION_MODAL_MODE.CREATE
        ? { ...EMPTY_TRANSACTION, ...transaction }
        : mode === TRANSACTION_MODAL_MODE.EDIT_BULK
        ? initialTransactionsBulk[0]
        : transaction) ?? EMPTY_TRANSACTION,
    );
    if (!initial.currency) return { ...initial, currency };
    else return initial;
  }, [initialTransactionsBulk, transaction, mode, currency]);

  useInvariantCheck({
    readyData: initialTransaction && transaction ? { initialTransaction, transaction } : null,
    checkers: [checkTransactionFieldsMatch],
  });

  const { transactionsLockBeforeDate, accountingSpreadsLockBeforeDate } = orgConfigs;

  // InitialTransaction is initially passed into this component
  // CurrentTransaction is new transaction witch replace initial transaction in state,
  //   for example when we open audit transaction
  //   or when we change transaction in bulk
  // transactionFormValues is current transaction form values, we use it to get
  //   draft form values and update it on every form change
  const [currentTransaction, setCurrentTransaction] = useState(initialTransaction);
  const [currentTransactionSpread, setCurrentTransactionSpread] = useState({});
  const [currentInvoicingScheduleId, setCurrentInvoicingScheduleId] = useState(
    initialTransaction?.invoicing_schedule_id,
  );

  const [transactionFormValues, setTransactionFormValues] = useState();

  // This format of the data is only used in the EXTERNAL_UPDATE mode
  // it represents the initial data with the changed data applied to them
  // it automatically updates inside the coordinator due to an useEffect hook
  const [transactionWithChangedData, setTransactionWithChangedData] = useState(initialTransaction);
  const [changedDataFormatted, setChangedDataFormatted] = useState({});

  // TransactionsBulk vs initialTransactionsBulk: initialTransactionsBulk is passed into this component,
  //  but during bulk processing, the bulk may change. However, when a transaction in the bulk is either deleted
  //  or confirmed, it is removed from the bulk.
  const [transactionsBulk, setTransactionsBulk] = useState(initialTransactionsBulk);
  const [currentBulkIndex, setCurrentBulkIndex] = useState(0);

  const [replacingChecked, setReplacingChecked] = useState(!isNil(initialTransaction?.replacing));
  const [relatedTransactions, setRelatedTransactions] = useState([]);
  const [relatedTransactionsLoading, setRelatedTransactionsLoading] = useState(false);
  const [selectedAudit, setSelectedAuditRaw] = useState(null);

  // transaction is locked if the current form values are before the lock date.
  // this prevents saving the data with the currently set values.
  // transaction is FULLY locked if the dates of the transaction are *already* before the lock date.
  // this prevents the user to change the dates to a date AFTER the lock date and saving it (since it wouldn't be locked anymore),
  // which would still change past revenue
  const [isTransactionLocked, setIsTransactionLocked] = useState(false);
  const [isTransactionFullyLocked, setIsTransactionFullyLocked] = useState(false);

  const [customer, setCustomer] = useState();
  const [replacedByTransaction, setReplacedByTransaction] = useState(null);
  const [flaggingReasons, setFlaggingReasons] = useState({});

  const [showUnsavedWarning, setShowUnsavedWarning] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateInvoicesModal, setShowUpdateInvoicesModal] = useState(false);
  const [showTransactionInvoicesPreviewModal, setShowTransactionInvoicesPreviewModal] = useState(false);
  const [hasConfirmedUnsentInvoices, setHasConfirmedUnsentInvoices] = useState(false);
  const [updateInvoicesInstantly, setUpdateInvoicesInstantly] = useState(true);

  const [invoicesToUpdate, setInvoicesToUpdate] = useState({ invoicesToInsert: [], invoicesToDelete: [] });

  const [originalAccountingSpreads, setOriginalAccountingSpreads] = useState();

  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const fetchTransactionRef = useRef(() => {});
  const fetchRelatedTransactionsRef = useRef(() => {});

  const safeCloseModal = (values) => {
    if (
      mode === TRANSACTION_MODAL_MODE.EDIT_BULK ||
      !!currentTransaction.replaced_by ||
      isEqual(currentTransaction, values)
    ) {
      closeModal && closeModal();
    } else {
      setShowUnsavedWarning(true);
    }
  };

  const setSelectedAudit = async (newSelectedAudit) => {
    setSelectedAuditRaw(newSelectedAudit);
    if (isEmpty(newSelectedAudit)) return;

    setLoading(true);

    // Making the audit object the same as a transaction object
    const auditTransaction = { ...newSelectedAudit };
    auditTransaction.id = auditTransaction.transaction_id;
    const organizationId = auditTransaction.organization_id;

    delete auditTransaction.created_at;
    delete auditTransaction.transaction_id;
    delete auditTransaction.organization_id;

    auditTransaction.product_name = null;
    if (auditTransaction.product_id) {
      const fetchedProduct = await getProduct(organizationId, auditTransaction.product_id);
      auditTransaction.product_name = fetchedProduct.name;
    }

    auditTransaction.customer_name = null;
    if (auditTransaction.customer_id) {
      const fetchedCustomer = await getCustomer(organizationId, auditTransaction.customer_id);
      auditTransaction.customer_name = fetchedCustomer.name;
    }

    setCurrentTransaction(auditTransaction);
    setLoading(false);
  };

  const handleDuplicateTransaction = async () => {
    setLoading(true);

    try {
      const newTransaction = await duplicateTransaction.mutateAsync({ id: currentTransaction.id });
      normalizeAmountFields(newTransaction);
      window.open(`${window.location.origin}/transactions/${newTransaction?.id}`, '_blank').focus();

      onTransactionDuplicated?.({ transaction: newTransaction });
    } catch (err) {
      console.error({ message: err.message, component: 'TransactionFormActions.js', stack: err });
    }

    setLoading(false);
  };

  return (
    <TransactionContext.Provider
      value={{
        view,
        mode,
        saveNewTransaction,
        organization,
        changedData,
        transactionPageLoading,
        fetchTransactions,
        closeModal,
        onTransactionCreated,
        onTransactionUpdated,
        onTransactionDeleted,
        onTransactionDuplicated,
        onFormSubmitted,
        transactionsLockBeforeDate,
        accountingSpreadsLockBeforeDate,

        handleDuplicateTransaction,

        isTransactionLocked,
        setIsTransactionLocked,
        isTransactionFullyLocked,
        setIsTransactionFullyLocked,

        transactionFormValues,
        setTransactionFormValues,
        currentTransaction,
        setCurrentTransaction,
        currentTransactionSpread,
        setCurrentTransactionSpread,
        transactionsBulk,
        setTransactionsBulk,
        currentBulkIndex,
        setCurrentBulkIndex,
        transactionWithChangedData,
        setTransactionWithChangedData,
        changedDataFormatted,
        setChangedDataFormatted,
        originalAccountingSpreads,
        setOriginalAccountingSpreads,

        replacingChecked,
        setReplacingChecked,
        relatedTransactions,
        setRelatedTransactions,
        relatedTransactionsLoading,
        setRelatedTransactionsLoading,
        replacedByTransaction,
        setReplacedByTransaction,
        selectedAudit,
        setSelectedAudit,

        customer,
        setCustomer,
        flaggingReasons,
        setFlaggingReasons,
        showUnsavedWarning,
        setShowUnsavedWarning,
        showDeleteModal,
        setShowDeleteModal,
        showUpdateInvoicesModal,
        setShowUpdateInvoicesModal,
        showTransactionInvoicesPreviewModal,
        setShowTransactionInvoicesPreviewModal,
        loading,
        setLoading,
        formRef,
        fetchTransactionRef,
        fetchRelatedTransactionsRef,
        safeCloseModal,

        invoicesToUpdate,
        setInvoicesToUpdate,
        hasConfirmedUnsentInvoices,
        setHasConfirmedUnsentInvoices,
        updateInvoicesInstantly,
        setUpdateInvoicesInstantly,
        currentInvoicingScheduleId,
        setCurrentInvoicingScheduleId,
      }}
    >
      <TransactionCoordinator>
        <GlobalTogglesContextProvider>
          {view === TRANSACTION_VIEW_MODE.PAGE ? <TransactionSinglePageLayout /> : <TransactionLayout />}
        </GlobalTogglesContextProvider>
      </TransactionCoordinator>
    </TransactionContext.Provider>
  );
};
