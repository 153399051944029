import React, { useCallback, useContext, useMemo } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { ISO_CODE_TO_SYMBOL, NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { ReactComponent as ExternalLink } from 'images/external-link-billing.svg';
import { CirclePlusIcon, TrashIcon } from 'components/Icons';
import { FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { MANUAL_GL_SERVICE_NAME, NO_GL_SERVICE_NAME } from 'views/Billing/consts';
import { Centerer, Flexer, FlexerColumn } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import { humanize } from 'utils/stringUtils';
import { CreditNoteContext } from '../CreditNoteContext';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { ExternalProductSelector } from '../ExternalProductSelector';
import { RemoveWrapper, InvoiceItemRecognition } from '../InvoiceScheduleWarnings/styles';

const EmptyTransactionOption = styled(Flexer)`
  align-items: center;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
    path {
      fill: var(--primaryBlack30);
    }
  }
`;

const StyledExternalLink = styled(Centerer)`
  margin-top: ${({ marginTop }) => marginTop};
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const CreditNoteItem = ({ index, readOnly = false, allocatedExternally = false }) => {
  const { currencyISOCode: orgCurrency } = useContext(AppContext);
  const { invoicingService, usesGLConfiguredTaxCodes } = useContext(InvoicingScheduleContext);
  const { creditNoteTransactionOptions } = useContext(CreditNoteContext);
  const { values, setFieldValue } = useFormikContext();

  const disabledExplanation = useMemo(
    () =>
      allocatedExternally
        ? `You can only update the transaction now as this credit note has already been allocated in ${humanize(
            invoicingService,
          )}`
        : null,
    [allocatedExternally, invoicingService],
  );

  const transactionOptionsWithEmptyValue = useMemo(() => {
    return [
      {
        label: (
          <EmptyTransactionOption>
            <CirclePlusIcon />
            Create a new Transaction
          </EmptyTransactionOption>
        ),
        value: null,
      },
      ...creditNoteTransactionOptions?.map((option) => ({
        ...option,
        label: `${option.label} (${numberFormatter({
          type: NUMBER_FORMATS.CURRENCY,
          rawValue: option.transaction.amount,
          currency: option.transaction.currency ?? orgCurrency,
          decimalPlaces: 2,
        })})`,
      })),
    ];
  }, [creditNoteTransactionOptions, orgCurrency]);

  const removeCreditNoteItem = useCallback(
    (itemIndex) => {
      const newInvoiceItems = ([...values.items] ?? []).filter((_, index) => index !== itemIndex);
      setFieldValue(`items`, newInvoiceItems);
    },
    [setFieldValue, values],
  );

  return (
    <FlexerColumn gap="8px" data-cy={`credit-note-view__credit-note-item--${index}`}>
      <Flexer gap="16px" width="100%" alignItems="center">
        <Flexer gap="10px" width="100%" alignItems="center" justifyContent="center">
          {allocatedExternally && <InvoiceItemRecognition index={index}>{index + 1}</InvoiceItemRecognition>}

          <FormikCustomSelector
            label={
              index === 0 && (
                <TooltipContainer toolTipContent="Assign a transaction that matches the credit note item so Subscript can use for recurring revenue and accounting revenue calculations">
                  Transaction (internal)
                </TooltipContainer>
              )
            }
            placeholder="Select transaction"
            name={`items[${index}].transaction_id`}
            options={transactionOptionsWithEmptyValue}
            handleChange={({ value, transaction }) => {
              setFieldValue(`items[${index}].transaction_id`, value);
              if (!allocatedExternally) {
                setFieldValue(`items[${index}].product_id`, transaction?.product_id ?? null);
                setFieldValue(`items[${index}].seats`, transaction?.seats ?? null);
                setFieldValue(`items[${index}].amount`, transaction?.amount ?? null);
              }
            }}
            containerWidth="80%"
            greyVer
            noSort
            data-cy={`credit-note-view__credit-note-item--${index}__transaction-selector`}
            isDisabled={readOnly}
          />

          {!!values.items[index].transaction_id && (
            <StyledExternalLink
              marginTop={index === 0 && '18px'}
              onClick={() => window.open(`/transactions/${values.items[index].transaction_id}`, '_blank')}
            >
              <ExternalLink />
            </StyledExternalLink>
          )}

          <ExternalProductSelector
            index={index}
            valuesEntity={`items`}
            tooltipInputDisplay={disabledExplanation}
            disableEditing={allocatedExternally || readOnly || !!values.items[index].transaction_id}
            data-cy={`credit-note-view__credit-note-item--${index}__transaction-selector`}
          />

          <FormikCustomInput
            name={`items[${index}].seats`}
            data-cy={`credit-note-view__credit-note-item--${index}__seats`}
            label={index === 0 && 'Quantity'}
            type="number"
            handleChange={(val) => setFieldValue(`items[${index}].seats`, val)}
            labelFlex="end"
            isDisabled={allocatedExternally || readOnly}
            tooltipInputDisplay={disabledExplanation}
            inputWidth="80px"
          />

          <FormikCustomInput
            inputWidth="120px"
            name={`items[${index}].amount`}
            data-cy={`credit-note-view__credit-note-item--${index}__amount`}
            label={index === 0 && 'Amount'}
            suffix={ISO_CODE_TO_SYMBOL[values?.currency ?? orgCurrency] ?? '$'}
            precision={2}
            type="number"
            handleChange={(val) => setFieldValue(`items[${index}].amount`, val)}
            labelFlex="end"
            isDisabled={allocatedExternally || readOnly}
            tooltipInputDisplay={disabledExplanation}
          />

          <div style={{ position: 'relative' }}>
            <FormikCustomInput
              inputWidth="100px"
              name={`items[${index}].tax_amount`}
              data-cy={`credit-note-view__credit-note-item--${index}__taxes`}
              label={index === 0 && 'Taxes'}
              suffix={ISO_CODE_TO_SYMBOL[values?.currency ?? orgCurrency] ?? '$'}
              precision={2}
              type="number"
              handleChange={(val) => setFieldValue(`items[${index}].tax_amount`, val)}
              labelFlex="end"
              tooltipInputDisplay={
                allocatedExternally || [MANUAL_GL_SERVICE_NAME, NO_GL_SERVICE_NAME].includes(invoicingService)
                  ? null
                  : `We will sync back taxes once the credit note is applied to an invoice and saved to ${humanize(
                      invoicingService,
                    )}`
              }
              isDisabled={allocatedExternally || readOnly || usesGLConfiguredTaxCodes}
            />
          </div>
        </Flexer>

        {!readOnly && !allocatedExternally && (
          <RemoveWrapper
            data-cy={`credit-note-view__credit-note-item--${index}__remove-button`}
            onClick={() => removeCreditNoteItem(index)}
          >
            <TrashIcon />
          </RemoveWrapper>
        )}
      </Flexer>
    </FlexerColumn>
  );
};
