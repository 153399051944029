import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { Document, Page, pdfjs } from 'react-pdf';
import { Loader } from 'components/Loaders';
import { FlexerColumn } from 'components/Core';
import { WarnBlock } from 'components/Blocks';
import { TooltipContainer } from 'components/Tooltip';
import { RefreshIcon } from 'components/Icons';
import { InvoicePreviewCard, PDFWrapper } from './styles';
import { BillingContext } from '../BillingContext';
import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';

// https://github.com/wojtekmaj/react-pdf/wiki/Known-issues#create-react-app
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const RefreshContainer = styled.div`
  & svg {
    width: 12px;
    height: 12px;

    path {
      fill: var(--primaryBlue);
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

export const InvoicePreviewContents = ({ invoicePdf, isPdfLoading, width, height, pdfWidth }) => {
  const { regenerateInvoicePDF } = useContext(BillingContext);

  const [totalPages, setTotalPages] = useState(1);

  const { validateInvoiceFormAndExecuteAction, invoiceFormValues, invoicingServiceDisplayName } = useContext(
    InvoicingScheduleContext,
  );

  return (
    <FlexerColumn gap="8px" height="100%">
      {invoicePdf?.is_pdf_final_version ? (
        <WarnBlock
          rightIcon={
            <RefreshContainer>
              <TooltipContainer toolTipContent="Regenerate Invoice PDF" width={200} fontSize="12px">
                <RefreshIcon
                  onClick={() => {
                    validateInvoiceFormAndExecuteAction({
                      action: async () => {
                        await regenerateInvoicePDF({
                          id: invoiceFormValues?.id,
                          data: invoiceFormValues,
                        });
                      },
                    });
                  }}
                />
              </TooltipContainer>
            </RefreshContainer>
          }
        >
          {invoicePdf?.is_pdf_version_sent
            ? 'This is the last version sent to the customer'
            : 'Displaying the most recent version. A previous version was sent to the customer'}
        </WarnBlock>
      ) : null}
      <InvoicePreviewCard width={width} height={height}>
        <PDFWrapper>
          {isPdfLoading ? (
            <Loader containerStyles={{ width: 40 }} />
          ) : invoicePdf?.is_pdf_link ? (
            `Please click "PDF" link above to open the PDF preview directly in ${invoicingServiceDisplayName}`
          ) : invoicePdf?.pdf ? (
            <Document
              file={`data:application/pdf;base64,${invoicePdf.pdf}`}
              onLoadSuccess={({ numPages }) => {
                setTotalPages(numPages);
              }}
            >
              {Array.from(new Array(totalPages || 0), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} width={pdfWidth ?? 340} />
              ))}
            </Document>
          ) : (
            'No PDF preview available. Save the invoice to generate a PDF.'
          )}
        </PDFWrapper>
      </InvoicePreviewCard>
    </FlexerColumn>
  );
};
