import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { FilterConditionHead } from 'shared/Filters';
import { ReactComponent as ExchangeIcon } from 'images/exchange.svg';
import { addDays, formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { Row } from 'components/Core';
import { CustomDatePicker } from 'components/Controls';
import { usePortal } from 'components/Portal';
import { useOrganizationMetadataAPI } from 'api/organizations';
import { TooltipContainer } from 'components/Tooltip';
import { COMMITTED_POLICY, RATES_TYPE } from 'consts/global';
import { CONFIGURATION_TABS } from 'views/Configuration/consts';
import { CircleCheckbox } from 'views/Wizard/styles';
import { STORAGE_TOGGLES_KEYS } from './consts';
import { ButtonsContainer, ExchangeRow } from '../GlobalToggles.styles';

const ExchangeRatesDropdownBody = styled.div`
  border-radius: 12px;
  border: 1px solid var(--neutralGray);
  background: var(--accentGrayFourth);
  box-shadow: 16px 16px 60px 0px var(--primaryBlack20);
  width: 270px;
`;

const BodyTitle = styled.div`
  color: var(--primaryBlack50);
  font-size: 12px;
  font-style: italic;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const BodyContent = styled.div`
  padding: 12px;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
`;

const ExchangeInfo = styled.div`
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
`;

const DropdownFooter = styled.div`
  padding: 12px;
  font-size: 12px;
  line-height: 16px;
  font-style: italic;
  color: var(--primaryBlack50);
  display: flex;
  align-items: center;
  border-top: 1px solid var(--primaryBlack3);
`;

const Link = styled.div`
  margin-left: 3px;
  color: var(--primaryBlue);
  cursor: pointer;

  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const GlobalToggleExchangeRates = ({ globalTogglesState, setGlobalTogglesState, committedPolicy }) => {
  const {
    orgId,
    organizations,
    appSettings: { currencyISOCode: currency, isCommitted },
  } = useContext(AppContext);

  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({
    YOffset: -10,
  });

  const history = useHistory();

  const exchangeRatesType = globalTogglesState[STORAGE_TOGGLES_KEYS.EXCHANGE_RATES_TYPE];
  const exchangeRatesDate = globalTogglesState[STORAGE_TOGGLES_KEYS.EXCHANGE_RATES_DATE];

  const { data: organizationMetadata } = useOrganizationMetadataAPI({ orgId });
  const { currencies: allOrgNonHomeCurrencies } = organizationMetadata ?? {};
  const usesMetricsEngine = organizations?.[0]?.usesMetricsEngine;

  return allOrgNonHomeCurrencies?.length > 0 ? (
    <ExchangeRow>
      <ButtonsContainer>
        <FilterConditionHead data-cy="global-toggles__exchange-date" ref={triggerRef} onClick={togglePortal}>
          {currency}
        </FilterConditionHead>

        <ExchangeInfo>
          ({allOrgNonHomeCurrencies?.join(',')} → {currency} exchange rates on{' '}
          {exchangeRatesType === RATES_TYPE.PARTICULAR_DATE
            ? dayjs.utc(exchangeRatesDate).format('MMM DD, YYYY')
            : exchangeRatesType === RATES_TYPE.BOOKING_DATES
            ? 'Booking dates'
            : exchangeRatesType === RATES_TYPE.SPREAD_DATES
            ? 'date the revenue occurred'
            : exchangeRatesType === RATES_TYPE.MONTHLY_AVERAGE
            ? `average for ${dayjs.utc(exchangeRatesDate).format('MMMM YYYY')}`
            : 'Oh no! We hit an error 😢'}
          )
        </ExchangeInfo>

        {isPortalVisible && (
          <Portal>
            <ExchangeRatesDropdownBody>
              <BodyContent>
                <BodyTitle>
                  Rates based on:
                  <ExchangeIcon />
                </BodyTitle>

                <Row
                  onClick={() => {
                    setGlobalTogglesState({
                      name: STORAGE_TOGGLES_KEYS.EXCHANGE_RATES_TYPE,
                      value: RATES_TYPE.BOOKING_DATES,
                    });
                  }}
                  horizontal="flex-start"
                  style={{ cursor: 'pointer', marginBottom: 8 }}
                >
                  <CircleCheckbox
                    checked={exchangeRatesType === RATES_TYPE.BOOKING_DATES}
                    width="16px"
                    height="16px"
                    borderWidth={5}
                  />
                  Booking dates
                </Row>

                {!isCommitted || committedPolicy === COMMITTED_POLICY.NEW_ONLY ? (
                  <Row
                    onClick={() => {
                      setGlobalTogglesState({
                        name: STORAGE_TOGGLES_KEYS.EXCHANGE_RATES_TYPE,
                        value: RATES_TYPE.SPREAD_DATES,
                      });
                    }}
                    horizontal="flex-start"
                    style={{ cursor: 'pointer', marginBottom: 8 }}
                  >
                    <CircleCheckbox
                      checked={exchangeRatesType === RATES_TYPE.SPREAD_DATES}
                      width="16px"
                      height="16px"
                      borderWidth={5}
                    />
                    Revenue spread date
                  </Row>
                ) : (
                  <TooltipContainer
                    toolTipContent={
                      'Not available with committed policy Highest. Please ask Subscript to change committed policy to New Only'
                    }
                    width={400}
                    tooltipWrapperStyles={{ zIndex: 1 }}
                  >
                    <Row horizontal="flex-start" style={{ marginBottom: '10px' }}>
                      Revenue spread date not available
                    </Row>
                  </TooltipContainer>
                )}

                <Row horizontal="flex-start" style={{ marginBottom: 8, minHeight: 24 }}>
                  <Row
                    data-cy="global-toggles__exchange-date__particular"
                    style={{ cursor: 'pointer', marginRight: 4 }}
                    onClick={() => {
                      setGlobalTogglesState({
                        name: STORAGE_TOGGLES_KEYS.EXCHANGE_RATES_TYPE,
                        value: RATES_TYPE.PARTICULAR_DATE,
                      });
                    }}
                  >
                    <CircleCheckbox
                      checked={exchangeRatesType === RATES_TYPE.PARTICULAR_DATE}
                      width="16px"
                      height="16px"
                      borderWidth={5}
                    />
                    Particular date
                  </Row>

                  {exchangeRatesType === RATES_TYPE.PARTICULAR_DATE && (
                    <CustomDatePicker
                      noPortal
                      name="rate-date"
                      height="24px"
                      hideCalendarIcon
                      selected={
                        exchangeRatesDate && dayjs(exchangeRatesDate).isValid()
                          ? formatDateForDatepicker(exchangeRatesDate)
                          : null
                      }
                      onChange={(date) => {
                        setGlobalTogglesState({
                          name: STORAGE_TOGGLES_KEYS.EXCHANGE_RATES_DATE,
                          value: updateDateFromDatePicker(date) ?? dayjs.utc(),
                        });
                      }}
                    />
                  )}
                </Row>

                <Row horizontal="flex-start" style={{ minHeight: 24 }}>
                  <Row
                    data-cy="global-toggles__exchange-date__average"
                    style={{ cursor: 'pointer', marginRight: 4 }}
                    onClick={() => {
                      setGlobalTogglesState({
                        name: STORAGE_TOGGLES_KEYS.EXCHANGE_RATES_TYPE,
                        value: RATES_TYPE.MONTHLY_AVERAGE,
                      });
                    }}
                  >
                    <CircleCheckbox
                      checked={exchangeRatesType === RATES_TYPE.MONTHLY_AVERAGE}
                      width="16px"
                      height="16px"
                      borderWidth={5}
                    />
                    Monthly average
                  </Row>

                  {exchangeRatesType === RATES_TYPE.MONTHLY_AVERAGE && (
                    <CustomDatePicker
                      noPortal
                      name="rate-date"
                      height="24px"
                      hideCalendarIcon
                      showMonthYearPicker
                      dateFormat="MMMM yyyy"
                      selected={
                        exchangeRatesDate && dayjs(exchangeRatesDate).isValid()
                          ? formatDateForDatepicker(exchangeRatesDate)
                          : null
                      }
                      onChange={(date) => {
                        setGlobalTogglesState({
                          name: STORAGE_TOGGLES_KEYS.EXCHANGE_RATES_DATE,
                          //Add 14 days to avoid date issues when we define month from date on the backend
                          value: date ? updateDateFromDatePicker(addDays({ date, days: 14 })) : dayjs.utc(),
                        });
                      }}
                    />
                  )}
                </Row>
                {!!exchangeRatesDate && usesMetricsEngine && (
                  <Row horizontal="flex-start">
                    Warning! Particular date exchange <br />
                    rates take one day to update.
                  </Row>
                )}
              </BodyContent>

              <DropdownFooter>
                Edit{' '}
                <Link
                  onClick={() => {
                    history.push(`/configure?tab=${CONFIGURATION_TABS.EXCHANGE_RATES}`);
                  }}
                >
                  exchange rates
                </Link>
              </DropdownFooter>
            </ExchangeRatesDropdownBody>
          </Portal>
        )}
      </ButtonsContainer>
    </ExchangeRow>
  ) : null;
};
