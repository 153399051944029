import React, { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserAPI } from 'api/users';
import { initiateIntegrationLogin, useIntegrationsAPI } from 'api/integrations';
import {
  ModalContainer,
  Modal,
  ModalHeader,
  ModalCloseIcon,
  ModalTitle,
  ModalTitleText,
  ModalBody,
} from 'components/Modal';
import { OAUTH_CALLBACK_PATH } from 'views/Wizard/consts';
import { WizardIntegrationButtonsContainer } from 'views/Wizard/styles';
import {
  CRMIntegrationButtons,
  GLIntegrationButtons,
  BillingIntegrationButtons,
} from 'views/Wizard/IntegrationButtons';

export const AddIntegrationModal = ({ onClose }) => {
  const { organizations } = useContext(AppContext);
  const orgId = organizations?.[0].id;

  const { data: integrationTypes, isFetching } = useIntegrationsAPI({ orgId });
  const { user: authUser = {} } = useAuth0();
  const { data: userData } = useUserAPI({ email: authUser.email });

  const [selectedIntegration, setSelectedIntegration] = useState();
  const handleConnect = () => {
    if (selectedIntegration) {
      initiateIntegrationLogin(userData.id, selectedIntegration, OAUTH_CALLBACK_PATH.CONFIGS);
    }
  };

  return (
    <ModalContainer>
      <Modal data-cy="add-integration-modal">
        <ModalCloseIcon onClose={onClose} data-cy="add-integration-modal__close-button" />
        <ModalHeader>
          <ModalTitle>
            <ModalTitleText>
              <b>Connect New Integrations</b>
            </ModalTitleText>
            <i
              style={{
                fontSize: 16,
              }}
            >
              *Mutiple General Ledger Connection is in Beta Mode
            </i>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <WizardIntegrationButtonsContainer>
            <CRMIntegrationButtons
              handleConnect={handleConnect}
              selectedIntegration={selectedIntegration}
              setSelectedIntegration={setSelectedIntegration}
              integrationTypes={integrationTypes}
              showConnected={false}
              isFetching={isFetching}
            />
          </WizardIntegrationButtonsContainer>
          <WizardIntegrationButtonsContainer>
            <GLIntegrationButtons
              handleConnect={handleConnect}
              selectedIntegration={selectedIntegration}
              setSelectedIntegration={setSelectedIntegration}
              disabled={isFetching}
              connectedIntegration={integrationTypes.find((integration) => integration.type === 'GL')?.service}
              showConnected={false}
            />
            <BillingIntegrationButtons
              handleConnect={handleConnect}
              selectedIntegration={selectedIntegration}
              setSelectedIntegration={setSelectedIntegration}
              disabled={isFetching || integrationTypes.some((integration) => integration.type === 'Billing')}
              connectedIntegration={integrationTypes.find((integration) => integration.type === 'Billing')?.service}
              showConnected={false}
            />
          </WizardIntegrationButtonsContainer>
        </ModalBody>
      </Modal>
    </ModalContainer>
  );
};
