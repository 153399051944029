import React, { useContext, useState, useCallback } from 'react';
import { pick } from 'lodash';
import { Form, Formik } from 'formik';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { EVENTS } from 'consts/analytics';
import { createCustomer } from 'api';
import { useUsageSubscriptionsAPI, useDiscountsAPI, getPricingPlansFromSearch } from 'api/usageBasedEngine';
import { HelpCircleIcon } from 'components/Icons';
import { ModalContainer, Modal, ModalFooter, ModalCloseIcon } from 'components/Modal';
import { CancelButton, SaveButton } from 'components/Buttons';
import { Centerer, FlexBetweenContainer, FlexEndContainer } from 'components/Core';
import { FormikCustomSelector, CustomDatePicker, FormikCustomInput } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { getCustomersFromSearch } from 'shared/TransactionContent';
import { FormikDiscountSection } from 'shared/FormikDiscountSection';
import { DISCOUNT_TYPE } from 'views/Billing/consts';
import { getCustomerDisplayName } from 'models/customer';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { useAnalytics } from 'utils/hooks';
import { subscriptionValidationSchema } from './consts';

const Header = styled.div`
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 20px;

  font-weight: 900;
`;

const FormRow = styled(FlexBetweenContainer)`
  margin-bottom: 20px;
  gap: 12px;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const Wrapper = styled.div`
  padding: 0 50px;
  background-color: ${({ bgcolor }) => bgcolor ?? 'var(--primaryGray)'};
`;

const FormFooter = styled(ModalFooter)`
  width: 100%;
`;

export const SUBSCRIPTION_EDIT_MODAL_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
};

const INITIAL_EMPTY_VALUES = {
  customer_id: '',
  pricing_plan_id: '',
  start_date: null,
  end_date: null,
  trial_units: 0,
  has_discount: false,
  discount_code: null,
  discount_type: DISCOUNT_TYPE.PERCENTAGE,
  discount_value: null,
};

export const SubscriptionEditModal = ({
  onClose,
  selectedSubscription = INITIAL_EMPTY_VALUES,
  mode = SUBSCRIPTION_EDIT_MODAL_MODE.CREATE,
}) => {
  const { orgId, currencyISOCode: orgCurrency } = useContext(AppContext);

  const {
    operations: { createUsageSubscription, editUsageSubscription },
  } = useUsageSubscriptionsAPI({
    orgId,
    autoFetch: false,
  });

  const {
    operations: { createDiscount },
  } = useDiscountsAPI({
    orgId,
    autoFetch: false,
  });

  const handleSubmit = async (values) => {
    if (values.has_discount) {
      if (!values.discount_id) {
        const discount = await createDiscount.mutateAsync({
          data: {
            type: values.discount_type,
            value: values.discount_value,
          },
        });
        values.discount_id = discount?.id;
      }
    } else {
      values.discount_id = null;
    }

    const data = pick(values, [
      'start_date',
      'end_date',
      'customer_id',
      'pricing_plan_id',
      'trial_units',
      'discount_id',
    ]);

    if (mode === SUBSCRIPTION_EDIT_MODAL_MODE.CREATE) {
      await createUsageSubscription.mutateAsync({ data });
    } else if (mode === SUBSCRIPTION_EDIT_MODAL_MODE.EDIT) {
      await editUsageSubscription.mutateAsync({ id: selectedSubscription.id, data });
    }

    onClose();
  };

  return (
    <ModalContainer>
      <Modal overflow="visible" width="640px" data-cy="edit-subscription-modal" height="auto" maxHeight="auto">
        <ModalCloseIcon onClose={onClose} />
        <Formik
          initialValues={{
            ...selectedSubscription,
            has_discount: !!selectedSubscription?.discount_id,
            discount_id: selectedSubscription?.discount?.id,
            discount_code: selectedSubscription?.discount?.code ?? null,
            discount_type: selectedSubscription?.discount?.type ?? DISCOUNT_TYPE.PERCENTAGE,
            discount_value: selectedSubscription?.discount?.value,
            currency: selectedSubscription?.pricing_plan?.currency ?? orgCurrency,
          }}
          validationSchema={subscriptionValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, handleSubmit, getFieldMeta }) => (
            <Form>
              <Wrapper>
                <Header data-cy="edit-subscription-modal__title">
                  {mode === SUBSCRIPTION_EDIT_MODAL_MODE.EDIT ? 'Edit' : 'Create'} Usage-based Subscription
                </Header>

                <FormRow>
                  <FlexGrow>
                    <FormikCustomSelector
                      isDisabled={mode === SUBSCRIPTION_EDIT_MODAL_MODE.EDIT}
                      label="Customer"
                      placeholder="Select customer"
                      value={
                        values?.customer_id
                          ? {
                              label: getCustomerDisplayName({
                                customerName: values?.customer_name,
                                customerId: values?.customer_id,
                              }),
                              value: values?.customer_id,
                            }
                          : null
                      }
                      name="customer_id"
                      handleChange={(option) => {
                        if (option) {
                          setFieldValue('customer_id', option.value);
                          setFieldValue('customer_name', option.label);
                        } else {
                          setFieldValue('customer_id', null);
                          setFieldValue('customer_name', null);
                        }
                      }}
                      loadOptions={(searchQuery, prevOptions, additional) =>
                        getCustomersFromSearch({ searchQuery, orgId, prevOptions, additional })
                      }
                      onCreateOption={async (newCustomerName) => {
                        const newCustomer = await createCustomer({
                          orgId,
                          customerName: newCustomerName,
                        });
                        setFieldValue('customer_id', newCustomer.id);
                        setFieldValue('customer_name', newCustomer.name);
                      }}
                      creatable
                      isPaginateable
                      isClearable
                    />
                  </FlexGrow>
                  <FlexGrow>
                    <FormikCustomSelector
                      label="Pricing Plan"
                      placeholder="Select pricing plan"
                      name="pricing_plan_id"
                      value={
                        values?.pricing_plan_id
                          ? {
                              label: values?.pricing_plan_name,
                              value: values?.pricing_plan_id,
                            }
                          : null
                      }
                      handleChange={(option) => {
                        if (option) {
                          setFieldValue('pricing_plan_id', option.value);
                          setFieldValue('pricing_plan_name', option.label);
                          setFieldValue('currency', option.data?.currency);
                        } else {
                          setFieldValue('pricing_plan_id', null);
                          setFieldValue('pricing_plan_name', null);
                          setFieldValue('currency', null);
                        }
                      }}
                      loadOptions={(searchQuery, prevOptions, additional) =>
                        getPricingPlansFromSearch({ searchQuery, orgId, prevOptions, additional })
                      }
                      isPaginateable
                      isClearable
                    />
                  </FlexGrow>
                </FormRow>

                <FormRow>
                  <FlexGrow>
                    <FormikCustomInput
                      name="trial_units"
                      data-cy="subscription-create-modal__trial-units"
                      label={
                        <Centerer>
                          <span>Trial Units</span>
                          <TooltipContainer toolTipContent="We will only start counting after the customer has used up this number of units">
                            <HelpCircleIcon />
                          </TooltipContainer>
                        </Centerer>
                      }
                      placeholder="Enter trial units"
                      type="number"
                      handleChange={(value) => setFieldValue('trial_units', value)}
                    />
                  </FlexGrow>
                  <CustomDatePicker
                    meta={getFieldMeta('start_date')}
                    name="start_date"
                    label="Start Date"
                    selected={values?.start_date ? formatDateForDatepicker(values?.start_date) : null}
                    onChange={(value) => setFieldValue('start_date', updateDateFromDatePicker(value))}
                  />
                  <CustomDatePicker
                    meta={getFieldMeta('end_date')}
                    name="end_date"
                    label="End Date"
                    selected={values?.end_date ? formatDateForDatepicker(values?.end_date) : null}
                    onChange={(value) => setFieldValue('end_date', updateDateFromDatePicker(value))}
                  />
                </FormRow>

                <FormikDiscountSection onDiscountUpdated={onClose} />
              </Wrapper>
              <FormFooter>
                <FlexEndContainer>
                  <CancelButton onClick={onClose}>Cancel</CancelButton>
                  <SaveButton
                    loading={createUsageSubscription.isLoading || editUsageSubscription.isLoading}
                    data-cy="usage-subscription-modal__save-button"
                    onClick={handleSubmit}
                  />
                </FlexEndContainer>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </ModalContainer>
  );
};

export const useSubscriptionEditModal = () => {
  const { trackEvent } = useAnalytics();
  const [openParams, setOpenParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(
    (openParams = {}) => {
      trackEvent({
        name: EVENTS.OPEN_USAGE_SUBSCRIPTION_MODAL,
        properties: { mode: openParams?.mode ?? SUBSCRIPTION_EDIT_MODAL_MODE.EDIT },
      });
      setOpenParams(openParams);
      setShowModal(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowModal],
  );
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <SubscriptionEditModal onClose={closeModal} {...openParams} /> : <></>),
    [showModal, closeModal, openParams],
  );

  return {
    openModal,
    SubscriptionEditModal: Modal,
  };
};
