import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from 'views/Billing/BillingDynamicFieldsTooltip';
import { ReactComponent as CheckIcon } from 'images/transaction_check.svg';
import { convertToPlainText, templateToHTMLString } from 'utils/htmlUtils';
import { useInvoiceMemoTemplateAPI, useInvoiceMemoTemplatesAPI } from 'api/invoiceMemoTemplates/hooks';
import { Loader } from 'components/Loaders';
import { ModalContainer, Modal, ModalFooter } from 'components/Modal';
import { CentererVertical, Spacer } from 'components/Core';
import { FormikCustomInput } from 'components/Controls';
import { ContentEditableInput } from 'components/Controls/FormikCustomInput/ContentEditableInput';
import { DynamicFieldsSection } from './DynamicFieldsSection';

const Header = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: capitalize;

  b {
    font-weight: 900;
  }
`;

const ActionItem = styled.div`
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  padding: 0 50px;
`;

const InputLabel = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
`;

const FormFooter = styled(ModalFooter)`
  width: 100%;
`;
const FormButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CancelButton = styled.div`
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  margin-right: 14px;
  padding: 11px 14px;
  cursor: pointer;
`;

const SaveButton = styled.div`
  background: var(--primaryGreen);
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  color: #ffffff;
  padding: 8px 14px;
  padding-right: 8px;
  cursor: pointer;
  pointer-events: ${(props) => props.disabled && 'none'};
  opacity: ${(props) => props.disabled && '0.3'};

  svg {
    margin-left: 10px;
  }
`;

export const MemoTemplatesActionsModal = ({
  invoiceMemoTemplateId,
  onClose,
  generalTags,
  transactionMetadataTags,
  customerMetadataTags,
  transactionCustomerMetadataTags,
}) => {
  const { orgId } = useContext(AppContext);

  const {
    operations: { createInvoiceMemoTemplate, updateInvoiceMemoTemplate },
  } = useInvoiceMemoTemplatesAPI({ orgId, autoFetch: false });

  const { data: memoTemplateData, isLoading } = useInvoiceMemoTemplateAPI({ orgId, invoiceMemoTemplateId });

  const [draftMemoTemplate, setDraftMemoTemplate] = useState({});

  const handleSaveMemoTemplate = async () => {
    if (invoiceMemoTemplateId) {
      // edit
      await updateInvoiceMemoTemplate.mutateAsync({
        invoiceMemoTemplateId,
        data: draftMemoTemplate,
      });
    } else {
      // create
      await createInvoiceMemoTemplate.mutateAsync({
        data: draftMemoTemplate,
      });
    }
    setDraftMemoTemplate({});
    onClose();
  };

  useEffect(() => {
    if (invoiceMemoTemplateId) {
      setDraftMemoTemplate(memoTemplateData);
    }
  }, [invoiceMemoTemplateId, memoTemplateData]);

  const handleChangeName = (name) => {
    setDraftMemoTemplate({ ...draftMemoTemplate, name });
  };

  const handleChangeContent = (content) => {
    setDraftMemoTemplate({ ...draftMemoTemplate, content: convertToPlainText(content) });
  };

  return (
    <ModalContainer>
      <Modal
        overflow="scroll"
        width="1000px"
        minHeight="400px"
        data-cy="invoice_memo_template-actions-modal"
        height="auto"
        maxHeight="90vh"
      >
        {isLoading ? (
          <div className="w-100 flexer">
            <Loader containerStyles={{ width: 40 }} />
          </div>
        ) : (
          <>
            <Wrapper>
              <Header data-cy="invoice_memo_template-actions-modal__title">
                <b>{invoiceMemoTemplateId ? 'Edit' : 'Create'}</b> memo template
              </Header>

              <ActionItem>
                <InputLabel className="text-muted">Name</InputLabel>
                <FormikCustomInput
                  value={draftMemoTemplate?.name || ''}
                  name="name"
                  placeholder="Enter name..."
                  handleChange={(value) => handleChangeName(value)}
                  data-cy="invoice_memo_template-actions-modal__name-input"
                />
              </ActionItem>

              <ActionItem>
                <CentererVertical gap="8px">
                  <InputLabel className="text-muted">Content</InputLabel>
                  <BillingDynamicFieldsTooltip
                    tags={{
                      [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: generalTags,
                      [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                      [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                    }}
                    XOffset={700}
                  />
                </CentererVertical>
                <ContentEditableInput
                  data-cy="invoice_memo_template-actions-modal__content-input"
                  minHeight="200px"
                  type="HTMLEdit"
                  HTMLString={templateToHTMLString({
                    text: draftMemoTemplate?.content || '',
                    availableTags: [...generalTags, ...transactionMetadataTags, ...customerMetadataTags],
                  })}
                  availableTags={[...generalTags, ...transactionMetadataTags, ...customerMetadataTags]}
                  onChange={(value) => handleChangeContent(value)}
                />
              </ActionItem>

              <DynamicFieldsSection
                title="Dynamic fields"
                generalTags={generalTags}
                transactionMetadataTags={transactionMetadataTags}
                customerMetadataTags={customerMetadataTags}
                transactionCustomerMetadataTags={transactionCustomerMetadataTags}
              />
            </Wrapper>
            <FormFooter>
              <FormButtonsRow>
                <CancelButton onClick={onClose}>Cancel</CancelButton>
                <SaveButton
                  disabled={!draftMemoTemplate?.name || !draftMemoTemplate?.content}
                  onClick={() => handleSaveMemoTemplate()}
                  data-cy="invoice_memo_template-actions-modal__save-button"
                >
                  <span>{invoiceMemoTemplateId ? 'Update' : 'Save'}</span>
                  <Spacer width="10px" />
                  <CheckIcon height="20px" />
                </SaveButton>
              </FormButtonsRow>
            </FormFooter>
          </>
        )}
      </Modal>
    </ModalContainer>
  );
};
