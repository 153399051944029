import React, { useState } from 'react';
import { PopoverWrapper, TooltipPopover, usePortal } from 'components/Portal';
import { FilterConditionHead } from 'shared/Filters';
import { SelectorItem, StyledSelectorPopover } from 'shared/Filters/styles';

const SelectorOptionWrapper = ({ noOptionsStyles, children, current, ...rest }) =>
  noOptionsStyles ? (
    <div {...rest}>{children}</div>
  ) : (
    <SelectorItem current={current} {...rest}>
      {children}
    </SelectorItem>
  );

export const SelectDropdownButton = ({
  options,
  selected,
  onSelect,
  noOptionsStyles,
  name,
  showSelectedDirectly = false,
  noMargin = false,
  fontWeight = null,
  fontSize = null,
  allCaps = false,
  toolTipContent = null,
  toolTipWidth = null,
  padding = null,
  XOffset,
  actions,
  optionStyles,
  ...rest
}) => {
  const { triggerRef, showPortal, hidePortal, isPortalVisible, Portal } = usePortal({ YOffset: 27, XOffset });

  const [showTooltip, setShowTooltip] = useState(false);

  const trigger = showSelectedDirectly ? selected : options[selected];
  const decoratedTrigger = toolTipContent ? (
    <PopoverWrapper>
      <div onMouseEnter={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
        {trigger}
      </div>
      {showTooltip && <TooltipPopover width={toolTipWidth}>{toolTipContent}</TooltipPopover>}
    </PopoverWrapper>
  ) : (
    trigger
  );

  const wrappedSetShowDropDown = (shown) => {
    if (shown) {
      showPortal();
      setShowTooltip(false);
    } else {
      hidePortal();
    }
  };

  return (
    <FilterConditionHead
      onClick={() => wrappedSetShowDropDown(!isPortalVisible)}
      ref={triggerRef}
      data-cy={`${name}-selector-toggler`}
      noMargin={noMargin}
      fontWeight={fontWeight}
      fontSize={fontSize}
      allCaps={allCaps}
      padding={padding}
      {...rest}
    >
      {decoratedTrigger}
      {isPortalVisible && (
        <Portal>
          <StyledSelectorPopover noOptionsStyles={noOptionsStyles} data-cy={`${name}-selector`}>
            {Object.entries(options).map(([key, value]) => {
              const valueIsComponent = React.isValidElement(value);

              // can add to array the options that we want to be beta
              const label = !valueIsComponent && ['Seats'].includes(value) ? `${value} (beta)` : value;

              return (
                <SelectorOptionWrapper
                  noOptionsStyles={noOptionsStyles}
                  key={key}
                  current={selected === key || selected === value}
                  onClick={() => onSelect(key)}
                  data-cy={`${name}-selector--${key}`}
                  style={optionStyles}
                >
                  {/* pass 'current' state if selector option is a react component instead of string */}
                  {valueIsComponent
                    ? React.cloneElement(value, {
                        current: selected === key || selected === value,
                      })
                    : label}
                </SelectorOptionWrapper>
              );
            })}
            {actions && actions}
          </StyledSelectorPopover>
        </Portal>
      )}
    </FilterConditionHead>
  );
};
