import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { AppContext } from 'AppContext';
import { ReactComponent as MoreInfoIcon } from 'images/bx-archive.svg';
import { MAGIC_METADATA, EVENT_RECOGNITION_TYPES } from 'consts/global';
import { FEATURES, isFeatureActive } from 'utils/featureUtils';
import { Centerer, Flexer, Spacer } from 'components/Core';
import { TabButton } from 'components/Buttons';
import { TooltipContainer } from 'components/Tooltip';
import { useStateWithStorage } from 'utils/hooks';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { SectionIcon, SectionTitle } from 'shared/TransactionContent/styles';

import { TransactionRecognitionsSchedule } from '../TransactionRecognitionsSchedule';
import { INITIAL_SPREAD_VALUE, TransactionSpread } from '../TransactionSpread';
import { CustomerChartTab } from './CustomerChartTab';
import { AccountingScheduleTab } from './AccountingScheduleTab';
import { CardWrapper, MoreInfoSection } from './styles';
import { useContractFilesModal } from 'views/Contracts';

export const RECOGNITION_TABS = {
  RECOGNITION_SCHEDULE: 'Recognition Schedule',
  ACCOUNTING_SCHEDULE: 'Accounting Schedule',
  ATTACHMENTS: 'Attachments',
  CUSTOMER_CHART: 'Customer Chart',
};

export const TransactionRecognitionSection = ({ disabled }) => {
  const {
    orgConfigs,
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);
  const {
    currentTransaction,
    currentTransactionSpread,
    setCurrentTransactionSpread,
    transactionFormValues,
  } = useContext(TransactionContext);

  const { ContractFilesModal, openContractFilesModal } = useContractFilesModal({
    transactionIds: currentTransaction?.id ? [currentTransaction?.id] : [],
    customerId: currentTransaction?.customer_id,
  });

  const billingIsEnabledForOrg = isFeatureActive({ feature: FEATURES.BILLING, orgConfigs });

  const [selectedTab, setSelectedTab] = useStateWithStorage(
    'transaction-modal-selected-recognition-tab',
    RECOGNITION_TABS.RECOGNITION_SCHEDULE,
  );

  const handleClickTab = (tab) => {
    if (tab === RECOGNITION_TABS.ATTACHMENTS) {
      openContractFilesModal();
    } else {
      setSelectedTab(tab);
    }
  };

  return (
    <>
      <Flexer>
        <Centerer>
          <MoreInfoSection>
            <SectionIcon>
              <MoreInfoIcon />
            </SectionIcon>
            <SectionTitle>More Info: </SectionTitle>
          </MoreInfoSection>
          <Flexer alignItems="center" justifyContent="flex-start">
            {Object.values(RECOGNITION_TABS).map((tab) => {
              if (tab === RECOGNITION_TABS.ACCOUNTING_SCHEDULE && !billingIsEnabledForOrg) return null;
              if (tab === RECOGNITION_TABS.ATTACHMENTS && !currentTransaction?.id)
                return (
                  <TooltipContainer toolTipContent="Please save the transaction before adding attachments!" isVisible>
                    <TabButton isBig={false} isDisabled={true}>
                      {tab}
                    </TabButton>
                  </TooltipContainer>
                );

              return (
                <TabButton
                  isBig={false}
                  key={tab}
                  active={selectedTab === tab}
                  onClick={() => handleClickTab(tab)}
                  data-cy={`transaction-modal__recognition-schedule__${tab}-tab`}
                  isDisabled={disabled && tab === RECOGNITION_TABS.ATTACHMENTS}
                >
                  {tab}
                </TabButton>
              );
            })}
          </Flexer>
        </Centerer>
      </Flexer>

      <Spacer height="16px" />

      {selectedTab === RECOGNITION_TABS.CUSTOMER_CHART && <CustomerChartTab />}

      {selectedTab === RECOGNITION_TABS.ACCOUNTING_SCHEDULE && <AccountingScheduleTab disabled={disabled} />}

      {selectedTab === RECOGNITION_TABS.RECOGNITION_SCHEDULE &&
        (!EVENT_RECOGNITION_TYPES.includes(transactionFormValues?.recognition) ? (
          <CardWrapper>
            <TransactionRecognitionsSchedule
              includeEndMonth={transactionFormValues?.include_end_month}
              startDate={transactionFormValues?.start_date}
              confirmed={transactionFormValues?.confirmed}
              endDate={transactionFormValues?.end_date}
              replacedBy={transactionFormValues?.replaced_by}
              recognition={transactionFormValues?.recognition}
              recurringAmount={transactionFormValues?.recurring_amount}
              amount={transactionFormValues?.amount}
              values={transactionFormValues}
              initialValues={currentTransaction}
              isARR={isARR}
              currency={transactionFormValues?.currency ?? currency}
              transactionId={currentTransaction.id}
              disabled={disabled}
            />
          </CardWrapper>
        ) : (
          <TransactionSpread
            onChange={setCurrentTransactionSpread}
            currentTransactionSpread={
              isEmpty(currentTransactionSpread) ? INITIAL_SPREAD_VALUE : currentTransactionSpread
            }
            transactionAmount={transactionFormValues?.amount}
            recognitionType={transactionFormValues?.recognition}
            isARR={isARR}
            currency={transactionFormValues?.currency ?? currency}
            usagePricingBreakdown={currentTransaction?.metadata?.[MAGIC_METADATA.USAGE_PRICING_BREAKDOWN]}
            usageEventName={currentTransaction?.metadata?.[MAGIC_METADATA.USAGE_EVENT_NAME]}
            customerId={transactionFormValues?.customer_id}
            disabled={disabled}
          />
        ))}

      <ContractFilesModal />
    </>
  );
};
