import React, { useCallback, useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from 'AppContext';
import { useInvoicingScheduleAPI } from 'api/billing';
import { templateToHTMLString } from 'utils/htmlUtils';
import { GENERATE_NEW_PAYMENT_LINK_EMAIL_TAGS } from 'consts/billing';
import { CentererVertical, FlexerColumn, FlexerRow } from 'components/Core';
import { FormikCustomInput, FormikEmailSelector, SwitchWithLabel } from 'components/Controls';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitleText,
} from 'components/Modal';
import { useToasts } from 'components/Toasts';
import { DIRECTIONS, TooltipContainer } from 'components/Tooltip';
import { Loader } from 'components/Loaders';
import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from 'views/Billing/BillingDynamicFieldsTooltip';
import { InvoiceSenderEmail } from 'views/Billing/InvoicePreviewModalV2/InvoiceSenderEmail';
import { FileCard } from 'views/Configuration/Tabs/SubscriptBillingTab/styles';
import { SENDER_DEFAULT_EMAIL } from 'views/Configuration/Tabs/SubscriptBillingTab/BillingSenderSetttings/consts';

const DEFAULT_SUBJECT = '[Action Required] Provide new payment details';
const DEFAULT_BODY =
  'Hi {customer_name} team,<br><br>Please provide your new payment information: {invoice_payment_link}<br><br>Thank you for your help in getting this to the right place.If you have any questions, please let us know<br><br>{company_name}';

const MODAL_ACTIONS = {
  TEST_SEND: 'TEST_SEND',
  COPY_TO_CLIPBOARD: 'COPY_TO_CLIPBOARD',
  SEND_EMAIL: 'SEND_EMAIL',
};

const GenerateNewPaymentLinkModal = ({ onClose, invoice }) => {
  const { user = {} } = useAuth0();
  const { orgId, orgConfigs } = useContext(AppContext);
  const {
    operations: { regenerateAutoChargePaymentKey },
  } = useInvoicingScheduleAPI({ orgId, invoicingScheduleId: invoice.invoicing_schedule_id, autoFetch: false });
  const { pushToast, pushError } = useToasts();
  const { billingInvoiceDefaults } = orgConfigs;

  const [isLoading, setIsLoading] = useState(false);

  const handleModalAction = async (data) => {
    try {
      const { action, ...body } = data;
      setIsLoading(true);

      switch (action) {
        case MODAL_ACTIONS.TEST_SEND:
          await regenerateAutoChargePaymentKey({
            data: {
              ...body,
              invoiceId: invoice.id,
              sentTestEmail: true,
            },
          });
          pushToast('Test email sent successfully', 'success');
          break;
        case MODAL_ACTIONS.COPY_TO_CLIPBOARD:
          const resultLink = await regenerateAutoChargePaymentKey({
            data: {
              ...body,
              invoiceId: invoice.id,
            },
          });
          navigator.clipboard.writeText(resultLink);
          pushToast('Payment link copied to clipboard successfully', 'success');
          onClose();
          break;
        case MODAL_ACTIONS.SEND_EMAIL:
          await regenerateAutoChargePaymentKey({
            data: {
              ...body,
              invoiceId: invoice.id,
              sendEmail: true,
            },
          });
          pushToast('Email sent successfully', 'success');
          onClose();
          break;
        default:
          break;
      }
    } catch (err) {
      pushError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalContainer>
      <Modal width="840px" height="614px" overflow="auto" background="var(--primaryGray)">
        <ModalHeader padding style={{ paddingTop: 0 }}>
          <ModalCloseIcon onClose={onClose} />
          <ModalTitleText>
            <b>Email</b>
          </ModalTitleText>
        </ModalHeader>

        <Formik
          initialValues={{
            email_addresses: invoice.email_addresses?.filter((e) => typeof e === 'string'),
            email_cc: invoice.email_cc ?? billingInvoiceDefaults?.email_CC,
            email_subject: DEFAULT_SUBJECT,
            email_body: DEFAULT_BODY,
            attach_invoice_pdf: false,
          }}
          validationSchema={Yup.object({
            email_addresses: Yup.array()
              .of(Yup.string())
              .required()
              .min(1, 'Please provide at least one email address'),
            email_cc: Yup.array().of(Yup.string()),
            email_subject: Yup.string()
              .typeError('Please enter an email subject')
              .required('Please enter an email subject'),
            email_body: Yup.string().typeError('Please enter an email body').required('Please enter an email body'),
            attach_invoice_pdf: Yup.boolean(),
          })}
          onSubmit={handleModalAction}
        >
          {({ values, setFieldValue, submitForm }) =>
            isLoading ? (
              <div className="w-100 flexer">
                <Loader containerStyles={{ width: 40, margin: 20 }} />
              </div>
            ) : (
              <>
                <ModalBody paddingLeft="36px" paddingRight="36px" paddingBottom="28px">
                  <Form>
                    <FlexerColumn width="100%" height="100%" gap="16px" alignItems="flex-start">
                      <FlexerRow width="100%" gap="16px">
                        <FormikEmailSelector
                          containerWidth="100%"
                          name="email_addresses"
                          label="Recipient email(s)"
                          value={values.email_addresses?.filter((e) => typeof e === 'string')} // Strip out any non-string values
                          onChange={(selectedValues) => setFieldValue('email_addresses', selectedValues, true)}
                        />
                        <FormikEmailSelector
                          containerWidth="100%"
                          name="email_cc"
                          label="CC"
                          value={values.email_cc?.filter((e) => typeof e === 'string')} // Strip out any non-string values
                          onChange={(selectedValues) => setFieldValue('email_cc', selectedValues, true)}
                        />
                      </FlexerRow>

                      <FlexerRow width="100%" gap="16px">
                        <FormikCustomInput
                          width="100%"
                          name="email_subject"
                          disableLabelOpacity
                          label="Email subject"
                          LabelAction={
                            <CentererVertical>
                              <BillingDynamicFieldsTooltip
                                tags={{
                                  [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERATE_NEW_PAYMENT_LINK_EMAIL_TAGS]: GENERATE_NEW_PAYMENT_LINK_EMAIL_TAGS,
                                }}
                                XOffset={260}
                              />
                            </CentererVertical>
                          }
                          minHeight="40px"
                          HTMLString={templateToHTMLString({
                            text: values.email_subject,
                            availableTags: GENERATE_NEW_PAYMENT_LINK_EMAIL_TAGS,
                          })}
                          availableTags={GENERATE_NEW_PAYMENT_LINK_EMAIL_TAGS}
                          type="HTMLEdit"
                        />
                      </FlexerRow>

                      <FlexerRow width="100%" gap="16px">
                        <FlexerColumn width="100%" height="100%" gap="16px">
                          <FormikCustomInput
                            width="100%"
                            name="email_body"
                            disableLabelOpacity
                            label="Email body"
                            LabelAction={
                              <CentererVertical>
                                <BillingDynamicFieldsTooltip
                                  tags={{
                                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERATE_NEW_PAYMENT_LINK_EMAIL_TAGS]: GENERATE_NEW_PAYMENT_LINK_EMAIL_TAGS,
                                  }}
                                  XOffset={260}
                                />
                              </CentererVertical>
                            }
                            minHeight="40px"
                            HTMLString={templateToHTMLString({
                              text: values.email_body,
                              availableTags: GENERATE_NEW_PAYMENT_LINK_EMAIL_TAGS,
                            })}
                            availableTags={GENERATE_NEW_PAYMENT_LINK_EMAIL_TAGS}
                            type="HTMLEdit"
                          />
                        </FlexerColumn>

                        <FlexerColumn width="320px" gap="16px">
                          {/* This is the default email, because we always send it through sendgrid template */}
                          <InvoiceSenderEmail senderEmail={SENDER_DEFAULT_EMAIL} />

                          <FileCard disableIconHover>
                            Attach invoice PDF
                            <SwitchWithLabel
                              bolded
                              name="attach_invoice_pdf"
                              labelSize="12px"
                              onChange={(value) => setFieldValue('attach_invoice_pdf', value)}
                              checked={values?.attach_invoice_pdf}
                            />
                          </FileCard>
                        </FlexerColumn>
                      </FlexerRow>
                    </FlexerColumn>
                  </Form>
                </ModalBody>

                <ModalFooter flexEnd noFixedHeight topSeparator padding="12px 36px" gap="8px">
                  <TooltipContainer
                    width={220}
                    direction={DIRECTIONS.TOP}
                    hideArrow
                    yOffset={28}
                    fontSize="12px"
                    toolTipContent={`Send the email to yourself: ${user?.email}`}
                  >
                    <ModalButton
                      default
                      onClick={() => {
                        setFieldValue('action', MODAL_ACTIONS.TEST_SEND);
                        submitForm();
                      }}
                    >
                      Test Send
                    </ModalButton>
                  </TooltipContainer>
                  <ModalButton
                    default
                    onClick={() => {
                      setFieldValue('action', MODAL_ACTIONS.COPY_TO_CLIPBOARD);
                      submitForm();
                    }}
                  >
                    Save & Copy to Clipboard
                  </ModalButton>
                  <ModalButton
                    primary
                    onClick={() => {
                      setFieldValue('action', MODAL_ACTIONS.SEND_EMAIL);
                      submitForm();
                    }}
                  >
                    Send Email Now
                  </ModalButton>
                </ModalFooter>
              </>
            )
          }
        </Formik>
      </Modal>
    </ModalContainer>
  );
};

export const useGenerateNewPaymentLinkModal = ({ invoice }) => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const handleCloseModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <GenerateNewPaymentLinkModal onClose={handleCloseModal} invoice={invoice} /> : null),
    [showModal, handleCloseModal, invoice],
  );

  return {
    Modal,
    openModal,
  };
};
