import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Checkbox } from 'antd';
import { AppContext } from 'AppContext';
import { FEATURES, isFeatureActive } from 'utils/featureUtils';
import { useRevenueSpreadsAPI } from 'api/revenueSpreads';
import { useAccountingRevenueForDateRangeAPI } from 'api/accountingRevenue';
import { exportRevenueRecognitionReportAsSeparateFiles } from 'api/accountingSpreads/requests';
import { customFormatParseDate } from 'utils/dateUtils';
import { EVENTS } from 'consts/analytics';
import { PAGE_CONTEXT_VALUES, usePageContext, useAnalytics, useToasts } from 'utils/hooks';
import { downloadBlobFile } from 'utils/export';
import { Spacer } from 'components/Core';
import { ChartArcsIcon, ClipboardListIcon, GreyDot, InfoIcon } from 'components/Icons';
import { HEADER_TITLES } from 'shared/Layout';
import { ShareButton } from 'shared/ShareButton';
import { useSyncedExport } from 'shared/SyncedExports';
import { GlobalTogglesContextProvider, GlobalTogglesFloatingContainer } from 'shared/GlobalToggles';
import { RevenueTypeFilter } from 'shared/Filters';
import { DataTypeFilter } from 'shared/Filters';
import { FiltersAndSegmentBy } from 'shared/Filters/FiltersAndSegmentBy';
import { InsightsProvider } from 'shared/Insights/InsightsProvider';
import { TransactionSingleContent, TRANSACTION_MODAL_MODE } from 'shared/TransactionContent';
import { HeaderReports } from 'shared/Reports';
import { useDebouncedSearchBar } from 'components/Blocks';
import { StyledTableExportIcon } from 'shared/SyncedExports/ExportButton';
import SpreadsTable from 'views/Spreads/SpreadsTable';
import { TooltipContainer } from 'components/Tooltip';
import { ACCOUNTING_SPREAD_POLICY_SPREAD_BY, RATES_TYPE } from 'consts/global';
import { ReactComponent as NoMetric } from 'images/spreads-empty-state.svg';
import { AccountingRevenueChartAndTable } from './AccountingRevenueChartAndTable';
import { AccountingMonthDrilldownModal } from './AccountingMonthDrilldownModal';
import { SPREAD_REPORT_TYPE } from './utils';

const MainContainer = styled.section`
  width: 100%;
  position: relative;
  min-height: 700px;
  padding-bottom: 40px;
`;

const HeaderToolTip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0 4px 20px rgba(0, 21, 46, 0.1);
  border-radius: 100px;
`;

const SpreadReportTypeSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  isolation: isolate;

  border: 1px solid var(--accentGraySecond);
  border-radius: ${({ active }) => (active ? '16px 16px 0px 0px' : '16px')};
`;

const SpreadReportTypeSelectionLabel = styled.div`
  font-weight: 900;
  font-size: 9px;
  display: flex;
  align-items: stretch;
  text-align: center;
  justify-content: center;
  align-self: center;
  text-transform: uppercase;
  opacity: 0.2;
  transform: rotate(-90deg);
  margin: 20px;
`;

const SpreadReportTypeSelectionButton = styled.div`
  display: flex;
  padding: 16px;
  gap: 8px;

  height: 72px;
  background: ${({ active }) => (active ? 'rgba(0, 117, 255, 0.05)' : 'white')};
  border: ${({ active }) => (active ? '1px solid #0075ff' : '1px solid #f3f3f3')};
  box-shadow: 2px 2px 12px rgba(0, 21, 46, 0.02);
  border-radius: 16px;
  margin: 20px 12px 20px 0;
  justify-content: space-between;
  flex: 1;

  cursor: ${({ active }) => (active ? 'default' : 'pointer')};

  svg {
    color: ${({ active }) => (active ? '#0075ff' : '#00152e')};
  }
`;

const StyledChartArcsIcon = styled(ChartArcsIcon)`
  width: 28px;
  height: 28px;

  path {
    fill: ${({ active }) => (active ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
  }
`;

const StyledClipboardListIcon = styled(ClipboardListIcon)`
  width: 28px;
  height: 28px;

  path {
    stroke: ${({ active }) => (active ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
  }
`;

const SpreadReportType = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    margin-bottom: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: ${({ active }) => active && '#0075ff'};
  }

  div svg {
    margin-left: 8px;
  }

  span {
    font-size: 12px;
    line-height: 16px;
    opacity: ${({ active }) => (active ? '1' : '0.5')};
  }
`;

const CheckboxLabel = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: var(--primaryBlack);
  font-style: italic;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const Spreads = () => {
  const { organizations, orgConfigs, orgId } = useContext(AppContext);
  const { urlState: pageState } = usePageContext({ page: PAGE_CONTEXT_VALUES.revenue_spread.key });
  const {
    dataFilter,
    setDataFilter,
    metadataFilter,
    setMetadataFilter,
    isARR,
    isCommitted,
    exchangeRatesType,
    exchangeRatesDate,
    rollup,
    optimisticAnalytics,
  } = pageState;
  const { trackEvent } = useAnalytics();
  const { pushToast } = useToasts();
  const { state } = useLocation();

  const { accountingSpreadPolicy = ACCOUNTING_SPREAD_POLICY_SPREAD_BY.MONTHLY_AMOUNT } = orgConfigs;

  const [transactionToShow, setTransactionToShow] = useState(null);
  const [spreadReportType, _setSpreadReportType] = useState(SPREAD_REPORT_TYPE.SaaS);
  const setSpreadReportType = useCallback(
    (data) => {
      if (data)
        trackEvent({
          name:
            data === SPREAD_REPORT_TYPE.Accounting
              ? EVENTS.VIEW_ACCOUNTING_SPREADS
              : data === SPREAD_REPORT_TYPE.SaaS
              ? EVENTS.VIEW_SAAS_SPREADS
              : null,
        });
      _setSpreadReportType(data);
    },
    [_setSpreadReportType, trackEvent],
  );

  // used for Accounting Spreads drilldown
  const [showRevenueModal, setShowRevenueModal] = useState(false);
  const [month, setMonth] = useState();
  const [revenueType, setRevenueType] = useState();
  const [customerId, setCustomerId] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [exportWithOriginalCurrency, setExportWithOriginalCurrency] = useState(false);

  const [modalGLIntegrationId, setModalGLIntegrationId] = useState();
  const [showCustomersWithDeferred, setShowCustomersWithDeferred] = useState(false);
  const [showCustomersWithAccrued, setShowCustomersWithAccrued] = useState(false);
  const [showCustomersWithInvoiced, setShowCustomersWithInvoiced] = useState(false);
  const [showCustomersWithAccountingRevenue, setShowCustomersWithAccountingRevenue] = useState(false);

  const startDate = customFormatParseDate(
    `${dataFilter.startMonth.getFullYear()}-${dataFilter.startMonth.getMonth() + 1}`,
    'YYYY-M',
  ).startOf('month');
  const endDate = customFormatParseDate(
    `${dataFilter.endMonth.getFullYear()}-${dataFilter.endMonth.getMonth() + 1}`,
    'YYYY-M',
  ).endOf('month');
  const spreadTypes = dataFilter.revenueTypes;

  const { spreadSegmentBy: segmentBy } = dataFilter;

  const billingIsEnabledForOrg = isFeatureActive({ feature: FEATURES.BILLING, orgConfigs });

  const {
    data: saasData,
    shownCustomers: saasShownCustomers,
    isLoading: saasIsLoading,
    operations: saasOperations,
  } = useRevenueSpreadsAPI({
    orgId,
    params: {
      startDate: startDate.format('YYYY-MM-D'),
      endDate: endDate.format('YYYY-MM-D'),
      spreadTypes,
      isCommitted,
      rollup,
      optimisticAnalytics,
      segmentBy,
      includeLastTransactionUpdatedAt: true,
      metadataFilter,
      customerMetadata: JSON.stringify(metadataFilter?.customerMetadata),
      transactionMetadata: JSON.stringify(metadataFilter?.transactionMetadata),
      ratesType: exchangeRatesType ?? RATES_TYPE.BOOKING_DATES,
      ratesDate: exchangeRatesDate,
      searchQuery,
    },
    autoFetch: spreadReportType === SPREAD_REPORT_TYPE.SaaS,
  });

  const handleSearchQueryChange = useCallback(
    (newSearchQuery) => {
      setSearchQuery(newSearchQuery);
    },
    [setSearchQuery],
  );

  const { DebouncedSearchBar } = useDebouncedSearchBar({
    onSearchQueryChange: handleSearchQueryChange,
    initialValue: searchQuery,
    placeholder: 'Search customer name...',
    width: '300px',
    debounceTime: 750,
  });

  const { data: accountingRevenueForDateRangeData, isLoading: accountingRevenueForDateRangeIsLoading } =
    useAccountingRevenueForDateRangeAPI({
      orgId,
      params: {
        startMonth: startDate.format('YYYY-MM'),
        endMonth: endDate.format('YYYY-MM'),
        ratesType: exchangeRatesType ?? RATES_TYPE.BOOKING_DATES,
        ratesDate: exchangeRatesDate,
        metadataFilter,
        customerMetadata: JSON.stringify(metadataFilter?.customerMetadata),
        transactionMetadata: JSON.stringify(metadataFilter?.transactionMetadata),
      },
      autoFetch: billingIsEnabledForOrg && spreadReportType === SPREAD_REPORT_TYPE.Accounting,
    });

  const exportScreenshotRef = useRef();

  const exportInOriginalCurrencyCheckbox = (
    <Checkbox
      onChange={(event) => setExportWithOriginalCurrency(event.target.checked)}
      checked={exportWithOriginalCurrency}
    >
      <CheckboxLabel>Export in original currency</CheckboxLabel>
    </Checkbox>
  );

  const accountingSpreadsExportParams = {
    startMonth: startDate.format('YYYY-MM'),
    endMonth: endDate.format('YYYY-MM'),
    useOriginalCurrency: exportWithOriginalCurrency,
  };

  const { SyncedExportInfoBar, SyncedExportModal, ExportButton } = useSyncedExport({
    exportScreenshotRef,
    orgId,
    type: spreadReportType === SPREAD_REPORT_TYPE.Accounting ? 'accounting_spreads' : 'spreads',
    customization: ({ selectAllData }) => ({
      isARR,
      isCommitted,
      rollup,
      optimisticAnalytics,
      spreadTypes,
      segmentBy: selectAllData ? null : segmentBy,
      ratesType: exchangeRatesType ?? RATES_TYPE.BOOKING_DATES,
      ratesDate: exchangeRatesDate,
      dataType: dataFilter?.dataType,
      startMonth: dataFilter?.startMonth,
      ...(!selectAllData && (metadataFilter ?? {})),
      ...accountingSpreadsExportParams,
    }),
    isFilterOn: (metadataFilter && Object.values(metadataFilter).length !== 0) || segmentBy,
    startDateKey: 'startMonth',
    endDateKey: 'endMonth',
    additionalExportOption:
      spreadReportType === SPREAD_REPORT_TYPE.Accounting ? exportInOriginalCurrencyCheckbox : null,
    additionalMenuOptions:
      spreadReportType === SPREAD_REPORT_TYPE.Accounting
        ? [
            {
              title: 'Export as separate files',
              action: async () => {
                pushToast('Download started...');
                const zipFile = await exportRevenueRecognitionReportAsSeparateFiles({
                  orgId,
                  params: {
                    ...accountingSpreadsExportParams,
                    ...(metadataFilter ?? {}),
                  },
                });
                downloadBlobFile({
                  file: zipFile,
                  format: 'zip',
                  filename: 'revenue_recognition_report_export',
                });
              },
              dataCy: 'export-actions__popover__item--separateFiles',
              isAvailable: true,
              icon: <StyledTableExportIcon />,
            },
            {
              title: 'Export flattened',
              action: async () => {
                pushToast('Download started...');
                const zipFile = await exportRevenueRecognitionReportAsSeparateFiles({
                  orgId,
                  params: {
                    ...accountingSpreadsExportParams,
                    flattened: true,
                  },
                });
                downloadBlobFile({
                  file: zipFile,
                  format: 'zip',
                  filename: 'revenue_recognition_flattened_report_export',
                });
              },
              dataCy: 'export-actions__popover__item--flattened',
              isAvailable: true,
              icon: <StyledTableExportIcon />,
            },
          ]
        : undefined,
  });

  useEffect(() => {
    setSpreadReportType(state?.spreadReportType ?? SPREAD_REPORT_TYPE.SaaS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AccountingMonthDrilldownModalComponent = () => (
    <AccountingMonthDrilldownModal
      month={month}
      revenueType={revenueType}
      onClose={() => setShowRevenueModal(false)}
      customerId={customerId}
      setCustomerId={setCustomerId}
      accountingSpreadPolicy={accountingSpreadPolicy}
      setTransactionToShow={setTransactionToShow}
      setMonth={setMonth}
      showCustomersWithDeferred={showCustomersWithDeferred}
      setShowCustomersWithDeferred={setShowCustomersWithDeferred}
      showCustomersWithAccrued={showCustomersWithAccrued}
      setShowCustomersWithAccrued={setShowCustomersWithAccrued}
      showCustomersWithInvoiced={showCustomersWithInvoiced}
      setShowCustomersWithInvoiced={setShowCustomersWithInvoiced}
      showCustomersWithAccountingRevenue={showCustomersWithAccountingRevenue}
      setShowCustomersWithAccountingRevenue={setShowCustomersWithAccountingRevenue}
      modalGLIntegrationId={modalGLIntegrationId}
    />
  );

  if (!organizations) {
    return null;
  }

  return (
    <MainContainer>
      <HeaderReports
        activePage="spreads"
        headerTitle={HEADER_TITLES.spreads}
        titleActions={
          <TooltipContainer width={200} toolTipContent="Revenue broken down by customer, by month">
            <HeaderToolTip>
              <InfoIcon />
            </HeaderToolTip>
          </TooltipContainer>
        }
        headerRight={
          spreadReportType && (
            <>
              <ShareButton headerTitle={HEADER_TITLES.spreads} />
              <Spacer width="10px" />
              <ExportButton />
            </>
          )
        }
        page={PAGE_CONTEXT_VALUES.revenue_spread.key}
        pageState={pageState}
      />

      {billingIsEnabledForOrg && (
        <SpreadReportTypeSelectionContainer active={!!spreadReportType}>
          <SpreadReportTypeSelectionLabel>Type</SpreadReportTypeSelectionLabel>
          <SpreadReportTypeSelectionButton
            onClick={() => setSpreadReportType(SPREAD_REPORT_TYPE.SaaS)}
            active={spreadReportType === SPREAD_REPORT_TYPE.SaaS}
            data-cy="spreads_saas-button"
          >
            <SpreadReportType active={spreadReportType === SPREAD_REPORT_TYPE.SaaS}>
              <div>SaaS Revenue</div>
              <span>SaaS Revenue broken down by customer, by month</span>
            </SpreadReportType>
            <StyledChartArcsIcon active={spreadReportType === SPREAD_REPORT_TYPE.SaaS} />
          </SpreadReportTypeSelectionButton>
          <SpreadReportTypeSelectionButton
            onClick={() => setSpreadReportType(SPREAD_REPORT_TYPE.Accounting)}
            active={spreadReportType === SPREAD_REPORT_TYPE.Accounting}
            data-cy="spreads_accounting-button"
          >
            <SpreadReportType active={spreadReportType === SPREAD_REPORT_TYPE.Accounting}>
              <div>Accounting Revenue</div>
              <span>Recognized revenue (when service is delivered) broken down by customer, by month</span>
            </SpreadReportType>
            <StyledClipboardListIcon active={spreadReportType === SPREAD_REPORT_TYPE.Accounting} />
          </SpreadReportTypeSelectionButton>
        </SpreadReportTypeSelectionContainer>
      )}

      <GlobalTogglesContextProvider urlState={pageState}>
        <InsightsProvider>
          <FiltersAndSegmentBy
            hidePeriodSelection
            sameMonthAllowed={true}
            currentPageSegmentKey={spreadReportType === SPREAD_REPORT_TYPE.SaaS ? 'spreadSegmentBy' : null}
            orgId={orgId}
            showConditions={true}
            dataFilter={dataFilter}
            setDataFilter={setDataFilter}
            showGlobalToggles={spreadReportType === SPREAD_REPORT_TYPE.SaaS}
            removeTopBorderRadius={true}
            metadataFilter={metadataFilter}
            setMetadataFilter={setMetadataFilter}
            showOrSeparator
            allowSegment={spreadReportType === SPREAD_REPORT_TYPE.SaaS}
            segmentName="spreadSegmentBy"
            showCustomerMetadata={false}
            showChangeCategory={false}
            showTransactionMetadata={false}
            showTransaction={true}
          >
            {/* Doing it this insane way because each of these needs to be a direct child of the above component because of some prop adding it does */}
            {spreadReportType === SPREAD_REPORT_TYPE.SaaS && <GreyDot spacing="4px" />}
            {spreadReportType === SPREAD_REPORT_TYPE.SaaS && <RevenueTypeFilter />}
            {spreadReportType === SPREAD_REPORT_TYPE.SaaS && <GreyDot spacing="4px" />}
            {spreadReportType === SPREAD_REPORT_TYPE.SaaS && <DataTypeFilter />}
          </FiltersAndSegmentBy>
        </InsightsProvider>
        <SyncedExportInfoBar />

        {spreadReportType === SPREAD_REPORT_TYPE.SaaS ? (
          <GlobalTogglesFloatingContainer>
            <Spacer height="16px" />
            <DebouncedSearchBar />
            <SpreadsTable
              exportScreenshotRef={exportScreenshotRef}
              segmentBy={segmentBy}
              organizations={organizations}
              spreadsData={saasData ?? []}
              isLoading={saasIsLoading}
              shownCustomers={saasShownCustomers}
              sortCustomers={saasOperations.sortCustomers}
              loadMoreCustomers={saasOperations.loadMoreCustomers}
              fetchSpreadsData={saasOperations.refetch}
              setTransactionToShow={setTransactionToShow}
              dataTypeToShow={dataFilter?.dataType}
            />
            {!!transactionToShow && (
              <TransactionSingleContent
                mode={TRANSACTION_MODAL_MODE.EDIT}
                transaction={transactionToShow}
                organization={organizations[0]}
                closeModal={() => setTransactionToShow(null)}
                onTransactionUpdated={saasOperations.refetch}
                onTransactionDeleted={() => setTransactionToShow(null)}
                onTransactionDuplicated={({ transaction }) => setTransactionToShow(transaction)}
              />
            )}
          </GlobalTogglesFloatingContainer>
        ) : billingIsEnabledForOrg && spreadReportType === SPREAD_REPORT_TYPE.Accounting ? ( // only available if Billing enable for org
          <>
            <AccountingRevenueChartAndTable
              data={accountingRevenueForDateRangeData}
              isLoading={accountingRevenueForDateRangeIsLoading}
              handleModalOpen={({ monthKey, rowTitle }) => {
                trackEvent({
                  name: EVENTS.VIEW_ACCOUNTING_DRILLDOWN,
                  properties: { monthKey, rowTitle },
                });
                setMonth(monthKey);
                setRevenueType(rowTitle);
                setCustomerId(null);
                setShowRevenueModal(true);
              }}
              setShowCustomersWithDeferred={setShowCustomersWithDeferred}
              setShowCustomersWithAccrued={setShowCustomersWithAccrued}
              setShowCustomersWithInvoiced={setShowCustomersWithInvoiced}
              setShowCustomersWithAccountingRevenue={setShowCustomersWithAccountingRevenue}
              setModalGLIntegrationId={setModalGLIntegrationId}
            />
            {showRevenueModal && <AccountingMonthDrilldownModalComponent />}
            {!!transactionToShow && (
              <TransactionSingleContent
                mode={TRANSACTION_MODAL_MODE.EDIT}
                transaction={transactionToShow}
                organization={organizations[0]}
                closeModal={() => setTransactionToShow(null)}
                onTransactionDeleted={() => setTransactionToShow(null)}
                onTransactionDuplicated={({ transaction }) => setTransactionToShow(transaction)}
              />
            )}
          </>
        ) : (
          <NoMetric style={{ display: 'flex', margin: '74px auto' }} />
        )}
      </GlobalTogglesContextProvider>

      <SyncedExportModal />
    </MainContainer>
  );
};

export default Spreads;
