import styled from 'styled-components';

import { AlertUnfilledIcon } from './AlertUnfilledIcon';
import { AddIcon } from './AddIcon';
import { ArrowBoldDownIcon } from './ArrowBoldDownIcon';
import { ArrowBoldRightIcon } from './ArrowBoldRightIcon';
import { ArrowBoldUpIcon } from './ArrowBoldUpIcon';
import { ArrowDownIcon } from './ArrowDownIcon';
import { ArrowNarrowLeftIcon } from './ArrowNarrowLeftIcon';
import { ArrowNarrowUpIcon } from './ArrowNarrowUpIcon';
import { BinIcon } from './BinIcon';
import { BlueAddIcon } from './BlueAddIcon';
import { CircleCheckIcon } from './CircleCheckIcon';
import { CircleCheckStrokeIcon } from './CircleCheckStrokeIcon';
import { CircleCheckBlackIcon } from './CircleCheckBlackIcon';
import { CirclePlusIcon } from './CirclePlusIcon';
import { CircledArrowDownIcon } from './CircledArrowDownIcon';
import { CircledArrowRightIcon } from './CircledArrowRightIcon';
import { ExternalLinkIcon } from './ExternalLinkIcon';
import { ExternalLinkFullIcon } from './ExternalLinkFullIcon';
import { EyeIcon } from './EyeIcon';
import { FillDot } from './FillDot';
import { BlueDot } from './BlueDot';
import { ForecastingModalIcon } from './ForecastingModalIcon';
import { GearBlue } from './GearBlue';
import { GreenUpArrow } from './GreenUpArrow';
import { GreyDot } from './GreyDot';
import { HelpIcon } from './HelpIcon';
import { HelpIconBlack } from './HelpIconBlack';
import { InfoCircleOrange } from './InfoCircleOrange';
import { MinusIcon } from './MinusIcon';
import { PencilIcon } from './PencilIcon';
import { PlusSignIcon } from './PlusSignIcon';
import { RedDownArrow } from './RedDownArrow';
import { RedWarnIcon } from './RedWarnIcon';
import { SubrowArrow } from './SubrowArrow';
import { TooltipIcon } from './TooltipIcon';
import { WhiteDownArrow } from './WhiteDownArrow';
import { WhiteUpArrow } from './WhiteUpArrow';
import { InfoIcon } from './InfoIcon';
import { DoubleCheckIcon } from './DoubleCheckIcon';
import { CalendarWithClockIcon } from './CalendarWithClockIcon';
import { GitForkIcon } from './GitForkIcon';
import { CircleXIcon } from './CircleXIcon';
import { EditCircleIcon } from './EditCircleIcon';
import { ReceiptIcon } from './ReceiptIcon';
import { RechargeIcon } from './RechargeIcon';
import { ChargedIcon } from './ChargedIcon';
import { FilterIcon } from './FilterIcon';
import { PillCheck } from './PillCheck';
import { SplitIcon } from './SplitIcon';
import { LinkIcon } from './LinkIcon';
import { PointerIcon } from './PointerIcon';
import { IconContainerPill } from './IconContainerPill';
import { DashboardCardIcon, DASHBOARD_CARDS_ICON_NAMES } from './DashboardCardIcon';
import { LightbulbIcon } from './LightbulbIcon';
import { ThumbsUpIcon } from './ThumbsUpIcon';
import { LightStrokeThumbsUpIcon } from './LightStrokeThumbsUpIcon';
import { TrashIcon, BorderedTrashIcon } from './TrashIcon';
import { TriangleWarningIcon } from './TriangleWarningIcon';
import { MailSendIcon } from './MailSendIcon';
import { FilePlusIcon } from './FilePlusIcon';
import { WarningIcon } from './WarningIcon';
import { AlertCircleIcon } from './AlertCircleIcon';
import { ReactComponent as ChartIcon } from 'images/unconfimed-transactions-error-chart.svg';
import { ReactComponent as CircleArrowDownIcon } from 'images/circle-arrow-down.svg';
import { ReactComponent as CircleArrowUpIcon } from 'images/circle-arrow-up.svg';
import { ReactComponent as DirectionsIcon } from 'images/directions.svg';
import { ReactComponent as FilePlus } from 'images/file-plus.svg';
import { ReactComponent as RefreshIcon } from 'images/refresh-icon.svg';
import { ReactComponent as ReportIcon } from 'images/report-analytics.svg';
import { ReactComponent as ChartBarIcon } from 'images/chart-bar.svg';
import { ReactComponent as ToolsIcon } from 'images/tools.svg';
import { ReactComponent as TransactionIcon } from 'images/transactions-1-2.svg';
import { ReactComponent as CustomersIcon } from 'images/customers-1.svg';
import { ReactComponent as SettingsIcon } from 'images/settings.svg';
import { ReactComponent as SearchAltIcon } from 'images/bx-search-alt.svg';
import { ReactComponent as BulkProcessBannerIcon } from 'images/icon-bulk-process-in-banner.svg';
import { ReactComponent as RevertBannerIcon } from 'images/icon-revert-in-banner.svg';
import { ReactComponent as SaveIcon } from 'images/device-floppy.svg';
import { ReactComponent as ScaleIcon } from 'images/scale.svg';
import { RecognitionIcon } from './RecognitionTypesIcons';
import { ReactComponent as ChevronRight } from 'images/chevron-right.svg';
import { ReactComponent as GeometryIcon } from 'images/geometry.svg';
import { ReactComponent as ChartDonut4Icon } from 'images/chart-donut-4.svg';
import { ReactComponent as WaterfallIcon } from 'images/bx-water.svg';
import { ReactComponent as VectorTriangleIcon } from 'images/vector-triangle.svg';
import { ReactComponent as PresentationIcon } from 'images/presentation.svg';
import { ReactComponent as FoldersIcon } from 'images/folders.svg';
import { ReactComponent as ChartArcsIcon } from 'images/chart-arcs-3.svg';
import { ReactComponent as ClipboardListIcon } from 'images/clipboard-list.svg';
import { ReactComponent as TableIcon } from 'images/table.svg';
import { ReactComponent as SendIcon } from 'images/send.svg';
import { ReactComponent as CheckIcon } from 'images/circle-check.svg';
import { ReactComponent as WalletIcon } from 'images/wallet-1.svg';
import { ReactComponent as ArrowRightCircleIcon } from 'images/arrow-right-circle.svg';
import { ReactComponent as ClockIcon } from 'images/clock.svg';
import { ReactComponent as HelpCircleIcon } from 'images/help-table-header.svg';
import { ReactComponent as AlarmIcon } from 'images/alarm.svg';
import { ReactComponent as FileTextIcon } from 'images/file-text.svg';
import { ReactComponent as SubtaskIcon } from 'images/subtask.svg';
import { ReactComponent as CheckBoxIcon } from 'images/checkbox.svg';
import { ReactComponent as Message2Icon } from 'images/message-2.svg';
import { ReactComponent as BellIcon } from 'images/bell-ringing.svg';
import { ReactComponent as NoteIcon } from 'images/note-icon.svg';
import { ReactComponent as ErrorCircleIcon } from 'images/bx-error-circle.svg';
import { ReactComponent as AlertFilledIcon } from 'images/alert-circle-filled.svg';
import { ReactComponent as HomeIcon } from 'images/home.svg';
import { ReactComponent as FileInvoiceIcon } from 'images/file-invoice.svg';
import { ReactComponent as FileInvoiceGradientPurpleIcon } from 'images/file-invoice-gradient-purple.svg';
import { ReactComponent as AITagIcon } from 'images/ai-tag-icon.svg';
import { ReactComponent as AITagSolidIcon } from 'images/ai-tag-solid-icon.svg';
import { ReactComponent as BetaTagIcon } from 'images/beta-tag-icon.svg';
import { ReactComponent as AIBetaTagIcon } from 'images/ai-beta-tag-icon.svg';
import { ReactComponent as AICalendarTimeIcon } from 'images/ai-calendar-time.svg';
import { ReactComponent as AIWandIcon } from 'images/ai-wand.svg';
import { ReactComponent as ArchiveIcon } from 'images/archive.svg';
import { ReactComponent as DoughnutIcon } from 'images/doughnut-icon.svg';
import { ReactComponent as LoaderIcon } from 'images/loader.svg';
import { ReactComponent as AlertIcon } from 'images/alert-triangle.svg';
import { ReactComponent as UnlinkIcon } from 'images/unlink-icon.svg';
import { ReactComponent as FileUploadIcon } from 'images/file-upload.svg';
import { ReactComponent as ArrowNarrowRightIcon } from 'images/arrow-narrow-right-list.svg';
export { QuestionIcon } from './QuestionIcon';

const ArrowIcon = styled.div`
  background: ${(props) => (props.disabled ? 'rgba(57, 161, 220, 0.05)' : 'rgba(57, 161, 220, 0.1)')};
  pointer-events: ${(props) => props.disabled && 'none'};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:hover {
    background-color: #b7e5fc;
  }
`;

export {
  AlertUnfilledIcon,
  AddIcon,
  ArrowBoldDownIcon,
  ArrowBoldRightIcon,
  ArrowBoldUpIcon,
  ArrowDownIcon,
  ArrowNarrowLeftIcon,
  ArrowNarrowUpIcon,
  ArrowIcon,
  BinIcon,
  BlueAddIcon,
  BorderedTrashIcon,
  BulkProcessBannerIcon,
  CalendarWithClockIcon,
  ChargedIcon,
  ChartIcon,
  CircledArrowDownIcon,
  CircledArrowRightIcon,
  CircleArrowDownIcon,
  CircleArrowUpIcon,
  CirclePlusIcon,
  CircleCheckIcon,
  CircleCheckStrokeIcon,
  CircleCheckBlackIcon,
  CircleXIcon,
  DASHBOARD_CARDS_ICON_NAMES,
  DashboardCardIcon,
  DirectionsIcon,
  DoubleCheckIcon,
  EditCircleIcon,
  ExternalLinkIcon,
  ExternalLinkFullIcon,
  EyeIcon,
  FilePlus,
  FillDot,
  BlueDot,
  FilterIcon,
  ForecastingModalIcon,
  GearBlue,
  GitForkIcon,
  GreenUpArrow,
  GreyDot,
  HelpIcon,
  HelpIconBlack,
  InfoCircleOrange,
  InfoIcon,
  LinkIcon,
  MinusIcon,
  PencilIcon,
  PlusSignIcon,
  PillCheck,
  PointerIcon,
  RechargeIcon,
  ReceiptIcon,
  RecognitionIcon,
  RedDownArrow,
  RedWarnIcon,
  RefreshIcon,
  ReportIcon,
  RevertBannerIcon,
  SaveIcon,
  SearchAltIcon,
  SplitIcon,
  SubrowArrow,
  TooltipIcon,
  WhiteDownArrow,
  WhiteUpArrow,
  ChevronRight,
  ScaleIcon,
  IconContainerPill,
  ChartBarIcon,
  ToolsIcon,
  TransactionIcon,
  CustomersIcon,
  SettingsIcon,
  GeometryIcon,
  ChartDonut4Icon,
  WaterfallIcon,
  VectorTriangleIcon,
  PresentationIcon,
  FoldersIcon,
  ClipboardListIcon,
  ChartArcsIcon,
  TableIcon,
  LightbulbIcon,
  ThumbsUpIcon,
  LightStrokeThumbsUpIcon,
  SendIcon,
  TrashIcon,
  CheckIcon,
  WalletIcon,
  ArrowRightCircleIcon,
  ClockIcon,
  HelpCircleIcon,
  AlarmIcon,
  FileTextIcon,
  SubtaskIcon,
  CheckBoxIcon,
  Message2Icon,
  BellIcon,
  TriangleWarningIcon,
  MailSendIcon,
  NoteIcon,
  FilePlusIcon,
  WarningIcon,
  AlertCircleIcon,
  ErrorCircleIcon,
  AlertFilledIcon,
  HomeIcon,
  FileInvoiceIcon,
  FileInvoiceGradientPurpleIcon,
  AITagIcon,
  AITagSolidIcon,
  BetaTagIcon,
  AIBetaTagIcon,
  AICalendarTimeIcon,
  AIWandIcon,
  ArchiveIcon,
  DoughnutIcon,
  LoaderIcon,
  AlertIcon,
  UnlinkIcon,
  FileUploadIcon,
  ArrowNarrowRightIcon,
};
