export const STORAGE_TOGGLES_KEYS = {
  IS_COMMITTED: 'isCommitted',
  IS_ARR: 'isARR',
  IS_ROLLUP: 'rollup',
  IS_OPTIMISTIC: 'optimisticAnalytics',
  IS_INFLUX: 'countInfluxAsRenewed',
  EXCHANGE_RATES_TYPE: 'exchangeRatesType',
  EXCHANGE_RATES_DATE: 'exchangeRatesDate',
};

export const TOGGLES_SELECTORS_KEYS = {
  COMMITTED_REVENUE: 'Committed',
  CLASSICAL_REVENUE: 'Classical',
  ARR: 'Annual',
  MRR: 'Monthly',
  NO_ROLL_UP: 'No Roll up by parent',
  ROLL_UP: 'Roll up by parent',
  PESSIMISTIC: 'Pessimistic',
  OPTIMISTIC: 'Optimistic',
  DISABLE_INFLUX: 'Disable',
  ENABLE_INFLUX: 'Enable',
};

export const TOGGLES_SELECTORS_DESCRIPTIONS_DETAILS_KEYS = {
  ENABLE_INFLUX_DETAILS: 'ENABLE_INFLUX_DETAILS',
};

export const TOGGLES_SELECTORS_DESCRIPTIONS = {
  [TOGGLES_SELECTORS_KEYS.COMMITTED_REVENUE]:
    'Counts recurring revenue when booked, regardless of when the service start date is.',
  [TOGGLES_SELECTORS_DESCRIPTIONS_DETAILS_KEYS.COMMITTED_REVENUE_DETAILS]:
    "If there's a cancel date, a contract moves to churn on the cancel date rather than the end date.",
  [TOGGLES_SELECTORS_KEYS.CLASSICAL_REVENUE]: 'Only counts recurring revenue once service start date has arrived.',
  [TOGGLES_SELECTORS_KEYS.ARR]: '',
  [TOGGLES_SELECTORS_KEYS.MRR]: '',
  [TOGGLES_SELECTORS_KEYS.NO_ROLL_UP]:
    'Not rolling up revenue to parents in customer hierarchy – all revenue shown at child level.',
  [TOGGLES_SELECTORS_KEYS.ROLL_UP]: 'Rolls up all revenue to the parent level in the customer hierarchy.',
  [TOGGLES_SELECTORS_KEYS.PESSIMISTIC]: "It assumes everything that hasn't renewed will remain as it is.",
  [TOGGLES_SELECTORS_KEYS.OPTIMISTIC]:
    "Assumes everything that hasn't renewed will renew. But customers may appear churned when the end_date of their contract passes.",
  [TOGGLES_SELECTORS_KEYS.DISABLE_INFLUX]: "It assumes everything that hasn't renewed will remain as it is.",
  [TOGGLES_SELECTORS_KEYS.ENABLE_INFLUX]:
    'Calculates metrics with In Flux as Renewed, and adds In Flux section as sub-category of Existing revenue.',
  [TOGGLES_SELECTORS_DESCRIPTIONS_DETAILS_KEYS.ENABLE_INFLUX_DETAILS]:
    'Transactions will be considered as In Flux during their renewal deadline.',
};

export const ARR_SELECTOR_TO_BOOLEAN = {
  [TOGGLES_SELECTORS_KEYS.ARR]: true,
  [TOGGLES_SELECTORS_KEYS.MRR]: false,
};

export const ARR_BOOLEAN_TO_SELECTOR = {
  true: TOGGLES_SELECTORS_KEYS.ARR,
  false: TOGGLES_SELECTORS_KEYS.MRR,
};

export const COMMITTED_SELECTOR_TO_BOOLEAN = {
  [TOGGLES_SELECTORS_KEYS.COMMITTED_REVENUE]: true,
  [TOGGLES_SELECTORS_KEYS.CLASSICAL_REVENUE]: false,
};

export const COMMITTED_BOOLEAN_TO_SELECTOR = {
  true: TOGGLES_SELECTORS_KEYS.COMMITTED_REVENUE,
  false: TOGGLES_SELECTORS_KEYS.CLASSICAL_REVENUE,
};

export const ROLL_UP_SELECTOR_TO_BOOLEAN = {
  [TOGGLES_SELECTORS_KEYS.ROLL_UP]: true,
  [TOGGLES_SELECTORS_KEYS.NO_ROLL_UP]: false,
};

export const ROLL_UP_BOOLEAN_TO_SELECTOR = {
  true: TOGGLES_SELECTORS_KEYS.ROLL_UP,
  false: TOGGLES_SELECTORS_KEYS.NO_ROLL_UP,
};

export const OPTIMISTIC_SELECTOR_TO_BOOLEAN = {
  [TOGGLES_SELECTORS_KEYS.PESSIMISTIC]: false,
  [TOGGLES_SELECTORS_KEYS.OPTIMISTIC]: true,
};

export const OPTIMISTIC_BOOLEAN_TO_SELECTOR = {
  true: TOGGLES_SELECTORS_KEYS.OPTIMISTIC,
  false: TOGGLES_SELECTORS_KEYS.PESSIMISTIC,
};

export const INFLUX_SELECTOR_TO_BOOLEAN = {
  [TOGGLES_SELECTORS_KEYS.DISABLE_INFLUX]: false,
  [TOGGLES_SELECTORS_KEYS.ENABLE_INFLUX]: true,
};

export const INFLUX_BOOLEAN_TO_SELECTOR = {
  true: TOGGLES_SELECTORS_KEYS.ENABLE_INFLUX,
  false: TOGGLES_SELECTORS_KEYS.DISABLE_INFLUX,
};
