import React, { useRef } from 'react';
import AsyncSelect from 'react-select/async';
import Creatable from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate';
import { useField } from 'formik';

import { ReactComponent as TriangleIcon } from 'images/input_error_message_triagle.svg';
import { ChargedIcon } from 'components/Icons';
import { Spacer } from 'components/Core';
import { InputErrorMessage, LabelIconContainer } from 'components/Blocks';
import { TooltipContainer } from 'components/Tooltip';
import { MAX_DROPDOWN_OPTIONS } from 'shared/Filters/MetadataFilter/consts';

import { selectStyles } from './utils';
import { CustomSelect, InputLabel, InputSuffix, SelectWrapper } from './styles';

const AsyncCreatablePaginate = withAsyncPaginate(Creatable);

const FormikCustomSelector = ({
  label,
  secondaryLabel,
  handleChange,
  suffix,
  width = '100%',
  height,
  minWidth,
  isDisabled,
  noSort,
  errorWithoutTooltip,
  value,
  menuWidth,
  blueVer = false,
  redVer = false,
  greyVer = false,
  fullWidth = false,
  creatable = false,
  customBorderRadius,
  boldValue,
  options,
  isMulti = false,
  onBlur,
  pillStyle = false,
  floatErrors = false,
  isChanged,
  tooltipInputDisplay,
  toolTipWidth,
  containerWidth,
  hasWarning = false,
  isPaginateable = false,
  showErrors = true,
  dataCy,
  menuZIndex,
  labelWidth,
  labelOpacity,
  secondaryLabelOpacity,
  ...props
}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta, helpers] = useField(props);

  const sortedOptions = noSort ? options ?? [] : (options ?? []).sort((a, b) => a?.label?.localeCompare(b?.label));

  const selectRef = useRef();

  const SelectComponent =
    isPaginateable && creatable
      ? AsyncCreatablePaginate
      : isPaginateable
      ? AsyncPaginate
      : creatable
      ? AsyncCreatableSelect
      : AsyncSelect;

  const selectedValues = isMulti
    ? sortedOptions?.filter((option) => field?.value?.includes?.(option.value)) ?? null
    : sortedOptions?.find((option) => option.value === field.value) ?? null;

  const selectComponentProps = {
    ref: selectRef,
    classNamePrefix: isMulti ? 'react-multiselect' : 'react-select',
    menuPlacement: 'auto',
    inputId: props.id || props.name,
    styles: selectStyles({
      pillStyle,
      isDisabled,
      height,
      width,
      menuWidth,
      minWidth,
      blueVer,
      redVer,
      greyVer,
      fullWidth,
      customBorderRadius,
      boldValue,
      isMulti,
      isError: meta.touched && meta.error,
      hasWarning,
      menuZIndex,
    }),
    menuPortalTarget: document.body,
    value: value ?? selectedValues,
    isError: meta.touched && meta.error,
    onBlur: (event) => {
      field.onBlur(event);
      onBlur && onBlur(event);
    },
    loadOptions: (val) =>
      Promise.resolve(
        sortedOptions
          .filter(
            (option) =>
              option &&
              ['label', 'subLabel'].some(
                (field) => option[field] && String(option[field]).toLowerCase().includes(val.toLowerCase()),
              ),
          )
          .slice(0, MAX_DROPDOWN_OPTIONS),
      ),
    defaultOptions: sortedOptions.slice(0, MAX_DROPDOWN_OPTIONS),
    isDisabled: isDisabled,
    isMulti: isMulti,
    options: sortedOptions,
    ...props,
    onChange: (option) => (handleChange ? handleChange(option) : helpers.setValue(option?.value)),
  };

  return (
    <CustomSelect width={containerWidth} isDisabled={isDisabled}>
      {label && (
        <LabelIconContainer labelFlex={secondaryLabel ? 'space-between' : 'start'}>
          <InputLabel
            isError={meta.touched && meta.error}
            htmlFor={props.id || props.name}
            width={labelWidth}
            opacity={labelOpacity}
          >
            {label}
          </InputLabel>
          {isChanged && (
            <>
              <Spacer width="10px" />
              <ChargedIcon size="16px" />
            </>
          )}
          {secondaryLabel ? (
            <InputLabel
              isError={meta.touched && meta.error}
              htmlFor={props.id || props.name}
              opacity={secondaryLabelOpacity}
            >
              {secondaryLabel}
            </InputLabel>
          ) : null}
        </LabelIconContainer>
      )}
      {tooltipInputDisplay ? (
        <TooltipContainer yOffset={-5} hideArrow={true} toolTipContent={tooltipInputDisplay} width={toolTipWidth}>
          <SelectWrapper data-cy={dataCy ?? `custom-select--${props.name}`}>
            <SelectComponent {...selectComponentProps} />
            {suffix && <InputSuffix>{suffix}</InputSuffix>}
          </SelectWrapper>
        </TooltipContainer>
      ) : (
        <SelectWrapper data-cy={dataCy ?? `custom-select--${props.name}`}>
          <SelectComponent {...selectComponentProps} />
          {suffix && <InputSuffix>{suffix}</InputSuffix>}
        </SelectWrapper>
      )}
      {showErrors && meta.touched && meta.error && !errorWithoutTooltip ? (
        <InputErrorMessage floatErrors={floatErrors}>
          <TriangleIcon />
          {meta.error}
        </InputErrorMessage>
      ) : null}
    </CustomSelect>
  );
};

export default FormikCustomSelector;
