import { useContext, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { FormikCustomCheckbox } from 'components/Controls';
import { FlexBetweenContainer, Flexer } from 'components/Core';
import { ModalButton } from 'components/Modal';
import { Popover, PopoverWrapper } from 'components/Portal';
import { useClickOutside } from 'utils/hooks';
import { ReactComponent as RightCircle } from 'images/arrow-right-circle.svg';
import { INTEGRATIONS_OPERATIONS, INTEGRATION_SERVICES, INTEGRATION_TYPES } from 'consts/integrations';
import { INTEGRATIONS_FUNCTIONS } from 'api/integrations/hooks';
import { HelpCircleIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { getIntegrationDisplayName, getServiceCategory } from 'models/integration';
import { groupBy } from 'utils/arrayUtils';
import { MAGIC_METADATA } from 'consts/global';

const StyledPopover = styled(Popover)`
  display: flex;
  flex-direction: column;
  top: ${({ top }) => (top ? `${top}px` : '-120px')};
  padding: 12px;
  width: 250px;
  gap: 8px;
  min-height: 110px;
  justify-content: space-between;
  left: 0px;
`;

const ConfirmSavingButton = styled(FlexBetweenContainer)`
  color: var(--secondaryGreen);
  padding: 8px 10px;
  border-radius: 12px;
  background-color: var(--primaryGreen10);
  font-size: 12px;
  font-weight: 700;
  align-items: center;
  margin-top: 8px;

  &:hover {
    cursor: pointer;
  }

  & > svg path {
    fill: var(--secondaryGreen);
  }
`;

const StyledRightCircle = styled(RightCircle)`
  width: 16px;
  height: 16px;
`;

const StyledHelpCircleIcon = styled(HelpCircleIcon)`
  width: 12px;
  height: 12px;
`;

const WRITABLE_PROVIDER_NAMES = Object.entries(INTEGRATIONS_FUNCTIONS)
  .filter(([_key, value]) => value?.[INTEGRATIONS_OPERATIONS.UPDATE_CUSTOMER])
  .map(([key]) => key);

export const SaveCustomerButton = ({
  onSave,
  activeCustomerImports,
  selectedGlIntegrationId,
  values,
  isFetchingCustomer,
}) => {
  const { integrations } = useContext(AppContext);

  const [showPopover, setShowPopover] = useState(false);
  const triggerRef = useClickOutside(() => setShowPopover(false));

  const integrationById = groupBy(integrations, 'id', { uniqueness: true });

  const getTooltipContent = (integration) => {
    const displayName = getIntegrationDisplayName(integration);
    const service = getServiceCategory(integration?.service);

    switch (service) {
      case INTEGRATION_SERVICES.STRIPE:
        return `Only the customer name will be updated to ${displayName}`;
      case INTEGRATION_SERVICES.SALESFORCE:
        return `Only the customer name, billing address, and shipping address will be updated to ${displayName}`;
      case INTEGRATION_SERVICES.QUICKBOOKS:
      case INTEGRATION_SERVICES.XERO:
      case INTEGRATION_SERVICES.NETSUITE:
        return `All customer info (excluding parent company information) in Subscript will be updated to ${displayName}`;
      default:
        return null;
    }
  };

  const filteredImports = (activeCustomerImports ?? []).filter(
    (imp) =>
      WRITABLE_PROVIDER_NAMES.includes(getServiceCategory(imp.provider_name)) &&
      integrationById[imp.integration_id] &&
      (integrationById[imp.integration_id]?.type !== INTEGRATION_TYPES.GL ||
        imp.integration_id?.toString() === selectedGlIntegrationId?.toString()),
  );

  return (
    <PopoverWrapper>
      <ModalButton
        primary
        data-cy="customers-actions-modal__submit-button"
        onClick={() => {
          if (filteredImports.length > 0 || values?.metadata?.[MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT]) {
            setShowPopover(true);
          } else {
            onSave();
          }
        }}
        fontWeight={700}
        disabled={isFetchingCustomer}
      >
        Save
      </ModalButton>
      {showPopover && (
        <StyledPopover
          ref={triggerRef}
          top={
            -(
              110 +
              (filteredImports.length + (values?.metadata?.[MAGIC_METADATA.QUICKBOOKS_BILLWITHPARENT] ? 1 : 0)) * 15
            )
          }
        >
          {filteredImports.map((imp) => (
            <FormikCustomCheckbox
              name={`multiSave.${imp.id}`}
              data-cy={`input-multiSave__${imp.provider_name}`}
              label={
                <Flexer gap="4px" alignItems="center">
                  <span>Save in {getIntegrationDisplayName(integrationById[imp.integration_id])}</span>
                  <TooltipContainer
                    toolTipContent={getTooltipContent(integrationById[imp.integration_id]) ?? ''}
                    isVisible={!!getTooltipContent(integrationById[imp.integration_id])}
                    tooltipWrapperStyles={{
                      display: 'flex',
                    }}
                    fontSize="12px"
                    width={200}
                  >
                    <StyledHelpCircleIcon />
                  </TooltipContainer>
                </Flexer>
              }
              bold
            />
          ))}

          <ConfirmSavingButton onClick={onSave} data-cy="customers-actions-modal__confirm-submit-button">
            <span>Confirm Saving</span>

            <StyledRightCircle />
          </ConfirmSavingButton>
        </StyledPopover>
      )}
    </PopoverWrapper>
  );
};
