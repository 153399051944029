import React from 'react';

import { FormikCustomCheckbox, FormikEmailSelector } from 'components/Controls';
import { FlexerColumn } from 'components/Core';

import { BillingSettings } from './BillingSettings/BillingSettings';
import { ItemsRow, StyledFieldBody, StyledFieldTitle } from './BillingSettings/styles';
import { AccountingRevenuePolicy } from './AccountingRevenuePolicy';
import { BillingHistory } from './BillingHistory';
import { BillingReceiptsSettings } from './BillingReceiptsSettings';
import { BillingSenderSettings } from './BillingSenderSetttings';
import { AutoChargeInvoiceSendingSettings } from './AutoChargeInvoiceSendingSettings';
import { CreditNoteDefaultEmailSettings } from './CreditNoteSettings';

export const GeneralSettingsContent = ({
  initialValues,
  invoicingServices,
  invoicingServiceDisplayName,
  values,
  setFieldValue,
  getChangedReceiptDefaults,
  gmailIntegration,
}) => {
  return (
    <>
      <BillingSenderSettings
        invoicingServiceDisplayName={invoicingServiceDisplayName}
        invoicingServices={invoicingServices}
        initialValues={initialValues}
        values={values}
        setFieldValue={setFieldValue}
        gmailIntegration={gmailIntegration}
      />

      <BillingSettings
        values={values}
        setFieldValue={setFieldValue}
        invoicingServiceDisplayName={invoicingServiceDisplayName}
      />

      <BillingReceiptsSettings
        values={values}
        setFieldValue={setFieldValue}
        getChangedReceiptDefaults={getChangedReceiptDefaults}
      />

      <CreditNoteDefaultEmailSettings values={values} setFieldValue={setFieldValue} />

      <AutoChargeInvoiceSendingSettings values={values} />

      <StyledFieldBody>
        <StyledFieldTitle noMarginBottom>
          <span>Tax Settings</span>
        </StyledFieldTitle>
        <p>
          "Extended Tax Search" is recommended if you are using a third party service like Avalara, Anrok etc. for
          determining taxes. Turning this on means Subscript will wait longer when looking for calculated taxes to sync
          over, to accommodate for delays in third party service work.
        </p>
        <p>
          If you leave this setting off, you can still turn on "Extended Tax Search" for <i>individual</i> invoices in
          the invoice editor
        </p>
        <FlexerColumn padding="0 28px 8px 28px">
          <ItemsRow>
            <FormikCustomCheckbox
              name="billingInvoiceDefaults.always_long_poll_for_taxes"
              label={`Use "Extended Tax Search" for ALL invoices to pull taxes from upstream integrations`}
            />
          </ItemsRow>
        </FlexerColumn>
        <p>
          <b>Note:</b> More tax settings can be set in each individual integration settings page.
        </p>
        <br />
      </StyledFieldBody>

      <AccountingRevenuePolicy
        initialAccountingRevenuePolicy={initialValues.accountingSpreadPolicy}
        values={values}
        setFieldValue={setFieldValue}
      />

      <StyledFieldBody>
        <StyledFieldTitle noMarginBottom>
          <span>Members who will receive Billing updates via Email:</span>
        </StyledFieldTitle>

        {!(values?.billingUpdatesEmailsToSend?.length > 0) && (
          <p>Unless you select specific users, invoice fails emails will be sent to all admins of the organization.</p>
        )}

        <FlexerColumn padding="0 28px 8px 28px">
          <ItemsRow>
            <FormikEmailSelector
              name="billingUpdatesEmailsToSend"
              value={values?.billingUpdatesEmailsToSend?.filter((e) => typeof e === 'string')}
              onChange={(selectedValues) => {
                setFieldValue('billingUpdatesEmailsToSend', selectedValues);
              }}
              smallVersion
            />
          </ItemsRow>
        </FlexerColumn>
      </StyledFieldBody>

      <BillingHistory />
    </>
  );
};
