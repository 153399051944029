import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from 'AppContext';
import { ReactComponent as PlusIcon } from 'images/plus.svg';
import { useContractsAPI } from 'api/contracts';
import { Row, FlexBetweenContainer, Flexer, FlexerColumn, Spacer, FlexerRow } from 'components/Core';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
} from 'components/Modal';
import { FILE_TYPES, formatFileSize } from 'components/Controls';
import { DEFAULT_MAX_FILE_SIZE, FILE_TYPES_NAMES } from 'components/Controls/FileUploader/consts';
import { useToasts } from 'components/Toasts';
import { Loader } from 'components/Loaders';
import { TooltipContainer } from 'components/Tooltip';
import { useFileUploader } from 'components/Controls/FileUploader/useFileUploader';
import { TextBox } from 'components/Core/Text';
import { useAnalytics } from 'utils/hooks';
import { SidebarEmptyState } from './SidebarEmptyState';
import { PreviewFile } from './PreviewFile';
import { AttachmentItem } from './AttachmentItem';
import { AddIconButton, Details, FilesListSection, SideBar, Title } from './styles';
import { getUniqueAttachments } from '../utils';

export const ContractFilesModal = ({ transactionIds, customerId, uploadNew, readOnly, onClose, onSubmit }) => {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (uploadNew) trackEvent({ name: 'Visit Contract Files AI Reader Modal' });
  }, [trackEvent, uploadNew]);

  const { orgId } = useContext(AppContext);
  const { pushError } = useToasts();
  const inputRef = useRef();
  const [isContractUploadLoading, setIsContractUploadLoading] = useState();
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(null);
  const [newContractId, setNewContractId] = useState(null);
  const {
    data: contracts,
    isFetching,
    isLoading,
    operations: { addContract, addContractAttachment, interpretContract },
  } = useContractsAPI({
    orgId,
    scopes: ['attachment_data'],
    limit: 100,
    contractIds: newContractId ? [newContractId] : undefined,
    transactionIds,
    autoFetch: uploadNew ? !!newContractId : transactionIds?.length > 0,
  });

  const attachments = useMemo(() => getUniqueAttachments({ contracts }), [contracts]);

  useEffect(() => {
    if (attachments?.length > 0) {
      setSelectedAttachmentIndex(attachments.length - 1);
    }
  }, [attachments]);

  const { loading: fileUploadLoading, uploadFile } = useFileUploader({
    orgId,
    onFileUpload: async (result) => {
      // Since we are only allowing attaching files if we are in single transaction view, we can use the first contract
      const contract = contracts?.[0];
      const attachmentData = {
        name: result.name,
        s3_file_key: result.s3FileKey,
        type: result.type,
        size: result.size,
        service: 'subscript',
        uploaded_at: new Date().toISOString(),
      };

      try {
        setIsContractUploadLoading(true);
        if (!contract) {
          const addedContract = await addContract.mutateAsync({
            data: {
              transaction_id: transactionIds?.[0],
              customer_id: customerId,
              attachments: [attachmentData],
            },
          });
          setNewContractId(addedContract.id);
        } else {
          await addContractAttachment.mutateAsync({ id: contract.id, data: attachmentData });
        }
      } finally {
        setIsContractUploadLoading(false);
      }
    },
    privateBucket: true,
  });

  const showLoading =
    isFetching || isLoading || fileUploadLoading || isContractUploadLoading || interpretContract.isLoading;
  // Disable the upload button if there are multiple transactions (we most likely are inside invoicing schedule modal)
  // For now we will just allow upload from the transaction
  const isUploadDisabled = readOnly || transactionIds?.length > 1;

  const handleUploadClick = () => {
    if (!fileUploadLoading) {
      // Reset the input value so that the user can upload the same file again
      if (inputRef?.current?.value) {
        inputRef.current.value = null;
      }
      // Redirect the click event onto the hidden input element
      inputRef.current?.click();
    }
  };

  const handleFileInputSelect = (e) => {
    const selectedFile = e?.target?.files[0];
    if (!selectedFile) {
      return;
    }

    if (!FILE_TYPES.PDF.includes(selectedFile.type)) {
      pushError(
        '',
        `File type is not supported. Supported file types are ${FILE_TYPES.PDF?.map(
          (fileType) => FILE_TYPES_NAMES[fileType],
        ).join(', ')}.`,
      );
    } else if (selectedFile.size <= DEFAULT_MAX_FILE_SIZE) {
      uploadFile({ file: selectedFile });
    } else {
      pushError('', `File size is too big. Max file size is ${formatFileSize(DEFAULT_MAX_FILE_SIZE)}.`);
    }
  };

  return (
    <ModalContainer data-cy="contract-files-modal">
      <Modal width="80vw" height="80vh" overflow="visible" padding="0px">
        <ModalHeader>
          <ModalCloseIcon onClose={onClose} />
        </ModalHeader>

        <ModalBody centered height="100%" paddingLeft="0px" paddingRight="0px">
          <Flexer width="100%" height="100%">
            <SideBar>
              <FlexBetweenContainer width="100%" alignItems="center">
                <FlexerColumn gap="4px" alignItems="flex-start">
                  <Details>{attachments?.length ?? 0} attachments</Details>
                  <Title>Contract Files</Title>
                </FlexerColumn>

                {!readOnly && (
                  <TooltipContainer
                    toolTipContent="Please add the contract file directly in the transaction page"
                    isVisible={isUploadDisabled}
                  >
                    <AddIconButton onClick={handleUploadClick} disabled={isUploadDisabled}>
                      <PlusIcon />
                    </AddIconButton>
                  </TooltipContainer>
                )}

                <input
                  type="file"
                  ref={inputRef}
                  onChange={handleFileInputSelect}
                  style={{ display: 'none' }}
                  accept={FILE_TYPES.PDF.join(', ')}
                  disabled={isUploadDisabled}
                />
              </FlexBetweenContainer>

              {showLoading ? (
                <div className="w-100 flexer">
                  <Loader containerStyles={{ width: 40 }} />
                </div>
              ) : attachments?.length === 0 ? (
                <SidebarEmptyState onUploadFile={handleUploadClick} uploadFileDisabled={isUploadDisabled} />
              ) : (
                <FilesListSection>
                  {attachments?.map((attachment, index) => (
                    <AttachmentItem
                      key={index}
                      // Since we are only allowing removing files if we are in single transaction view, we can use the first contract
                      contractId={contracts?.[0]?.id}
                      attachment={attachment}
                      onClick={() => setSelectedAttachmentIndex(index)}
                      selected={index === selectedAttachmentIndex}
                      disabled={isUploadDisabled}
                    />
                  ))}
                </FilesListSection>
              )}
            </SideBar>

            {showLoading ? (
              <div className="w-100 flexer">
                <Loader containerStyles={{ width: 40 }} />
              </div>
            ) : (
              <PreviewFile file={attachments?.[selectedAttachmentIndex] ?? null} uploadNew={uploadNew} />
            )}
          </Flexer>
        </ModalBody>

        {uploadNew && (
          <ModalFooter>
            <FlexerRow width="100%" justifyContent="space-between">
              <Row>
                <TextBox italic>
                  <b> Subscript AI </b> will create transactions and invoices based on your contract
                </TextBox>
              </Row>
              <Row>
                <ModalButton default onClick={onClose}>
                  Cancel
                </ModalButton>
                <Spacer width="14px" />
                <ModalButton
                  primary
                  disabled={!newContractId || showLoading}
                  onClick={async () => {
                    const results = await interpretContract.mutateAsync({ id: newContractId });
                    onSubmit(results);
                  }}
                >
                  Preview Transactions
                </ModalButton>
              </Row>
            </FlexerRow>
          </ModalFooter>
        )}
      </Modal>
    </ModalContainer>
  );
};
