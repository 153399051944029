import { FormikCustomInput } from 'components/Controls';
import { humanize } from 'utils/stringUtils';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import styled from 'styled-components';
import { SERVICE_WITH_DISCONNECT_ROUTE } from 'views/Billing/consts';

import { ItemsRow } from '../SubscriptBillingTab/BillingSettings/styles';

const LinkText = styled.div`
  color: var(--primaryRed);
  font-weight: 400;
  font-size: 12px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const GeneralSettings = ({ values, service, handleDisconnect }) => {
  return (
    <FieldBody>
      <FieldTitle>
        <span>General information</span>
      </FieldTitle>
      <ItemsRow key={values.id}>
        <FormikCustomInput
          name="display_name"
          label="Name of integration"
          data-cy="general-ledger-configuration__display-name-input"
        />

        <FormikCustomInput isDisabled name="user_account" label={`integrated ${humanize(service)} account`} />
      </ItemsRow>
      {SERVICE_WITH_DISCONNECT_ROUTE.includes(service) && (
        <LinkText onClick={handleDisconnect}>Disconnect integration</LinkText>
      )}
    </FieldBody>
  );
};

export default GeneralSettings;
