import { ReactComponent as MailForward } from 'images/mail-forward.svg';
import { ReactComponent as NotebookIcon } from 'images/notebook-icon.svg';
import { ReactComponent as PreviewIcon } from 'images/file-invoice.svg';
import { ReactComponent as WalletIcon } from 'images/wallet.svg';
import { SendIcon, CheckIcon } from 'components/Icons';
import { ReactComponent as TrashIcon } from 'images/trash.svg';
import { ReactComponent as HistoryIcon } from 'images/history.svg';
import { ReactComponent as DetachIcon } from 'images/unlink-icon.svg';
import { ReactComponent as VoidIcon } from 'images/ban-icon.svg';
import { ReactComponent as ReminderIcon } from 'images/bell-ringing.svg';

export const INVOICE_ACTIONS = {
  PREVIEW: 'preview',
  TEST_SEND: 'test_send',
  INTERNAL_NOTE: 'internal_note',
  VIEW_INVOICES_IN_EXTERNAL_SYSTEM: 'view_invoice_in_external_system',
  COPY_INVOICES_SUBSCRIPT_LINK: 'copy_invoice_subscript_link',
  MARK_SENT: 'mark_sent',
  MARK_PAID: 'mark_paid',
  MARK_UNPAID: 'mark_unpaid',
  CREDIT_NOTE: 'credit_note',
  CHARGE_MANUALLY: 'charge_manually',
  VIEW_HISTORY: 'view_history',
  VOID: 'void',
  DELETE: 'delete',
  DETACH: 'detach',
  REMIND: 'remind',
};

export const PRIMARY_INVOICE_DROPDOWN_ACTIONS = {
  [INVOICE_ACTIONS.REMIND]: {
    title: 'Remind',
    icon: <ReminderIcon />,
  },
  [INVOICE_ACTIONS.TEST_SEND]: {
    title: 'Test Send',
    icon: <SendIcon />,
  },
  [INVOICE_ACTIONS.INTERNAL_NOTE]: {
    title: 'Internal Note',
    icon: <NotebookIcon />,
  },
  [INVOICE_ACTIONS.MARK_SENT]: {
    title: 'Mark as Sent',
    icon: <MailForward />,
  },
  [INVOICE_ACTIONS.MARK_PAID]: {
    title: 'Mark as Paid',
    icon: <WalletIcon />,
  },
  [INVOICE_ACTIONS.MARK_UNPAID]: {
    title: 'Mark as Not Paid',
    icon: <WalletIcon />,
  },
  [INVOICE_ACTIONS.CREDIT_NOTE]: {
    title: 'Credit Note',
    icon: <PreviewIcon />,
  },
  [INVOICE_ACTIONS.CHARGE_MANUALLY]: {
    title: 'Charge Manually',
    icon: <CheckIcon stroke="var(--secondaryGreen)" />,
  },
  [INVOICE_ACTIONS.VIEW_HISTORY]: {
    title: 'Invoice History',
    icon: <HistoryIcon />,
  },
};

export const REMOVING_INVOICE_DROPDOWN_ACTIONS = {
  [INVOICE_ACTIONS.DETACH]: {
    title: 'Detach Invoice',
    icon: <DetachIcon />,
  },
  [INVOICE_ACTIONS.VOID]: {
    title: 'Void Invoice',
    icon: <VoidIcon />,
  },
  [INVOICE_ACTIONS.DELETE]: {
    title: 'Delete Invoice',
    icon: <TrashIcon />,
  },
};
