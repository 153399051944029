import { NUMBER_FORMATS } from 'consts/global';
import { getIntegrationDisplayName } from 'models/integration';

const ACCOUNTING_ENTRY_TYPE = {
  DEBIT: 'DR',
  CREDIT: 'CR',
};

export const getDataForTable = ({ glIntegrationsById, data }) => {
  const result = [];

  result.push({
    title: 'Accounting Revenue',
    rowKey: 'Accounting Revenue',
    isHeader: true,
  });

  for (const glId of Object.keys(glIntegrationsById ?? {})) {
    // Accounting Revenue per GL
    result.push({
      title: getIntegrationDisplayName(glIntegrationsById[glId]),
      glId,
      rowKey: `Accounting Revenue.${glId}`,
      dataType: NUMBER_FORMATS.CURRENCY,
      data: data?.accountingRevenueByMonth?.[glId],
      isGLRow: true,
      subRow: false,
    });

    const incomeAccounts = Object.keys(data?.accountingRevenueByMonthByIncomeAccount[glId] ?? {});
    for (const incomeAccount of incomeAccounts) {
      // Accounting Revenue per GL per income account
      result.push({
        title: incomeAccount,
        glId,
        rowKey: `Accounting Revenue.${glId}.${incomeAccount}`,
        dataType: NUMBER_FORMATS.CURRENCY,
        data: data?.accountingRevenueByMonthByIncomeAccount?.[glId]?.[incomeAccount],
        debitOrCredit: ACCOUNTING_ENTRY_TYPE.CREDIT,
        subRow: true,
      });
    }
  }

  result.push({
    title: 'Invoiced',
    rowKey: 'Invoiced',
    isHeader: true,
  });

  for (const glId of Object.keys(glIntegrationsById ?? {})) {
    // Invoiced per GL
    result.push({
      title: getIntegrationDisplayName(glIntegrationsById[glId]),
      glId,
      rowKey: `Invoiced.${glId}`,
      dataType: NUMBER_FORMATS.CURRENCY,
      data: data?.invoicedByMonth?.[glId],
      isGLRow: true,
      subRow: false,
    });

    const incomeAccounts = Object.keys(data?.invoicedByMonthByIncomeAccount[glId] ?? {});
    for (const incomeAccount of incomeAccounts) {
      // Invoiced per GL per income account
      result.push({
        title: incomeAccount,
        glId,
        rowKey: `Invoiced.${glId}.${incomeAccount}`,
        dataType: NUMBER_FORMATS.CURRENCY,
        data: data?.invoicedByMonthByIncomeAccount?.[glId]?.[incomeAccount],
        debitOrCredit: ACCOUNTING_ENTRY_TYPE.DEBIT,
        subRow: true,
      });
    }
  }

  result.push({
    title: 'Accrued Revenue',
    rowKey: 'Accrued Revenue',
    isHeader: true,
  });

  for (const glId of Object.keys(glIntegrationsById ?? {})) {
    // Accrued Revenue per GL
    result.push({
      title: getIntegrationDisplayName(glIntegrationsById[glId]),
      glId,
      rowKey: `Accrued Revenue.${glId}`,
      dataType: NUMBER_FORMATS.CURRENCY,
      data: data?.accruedRevenueByMonth?.[glId],
      isGLRow: true,
      subRow: false,
    });

    // Accrued Revenue Change per GL
    result.push({
      title: 'Change',
      glId,
      rowKey: `Accrued Revenue Change.${glId}`,
      dataType: NUMBER_FORMATS.CURRENCY,
      data: data?.accruedRevenueChangeByMonth?.[glId],
      subRow: true,
      addSign: true,
    });

    // Accrued Revenue DR per GL
    result.push({
      title: 'Accrued Revenue DR',
      glId,
      rowKey: `Accrued Revenue DR.${glId}`,
      dataType: NUMBER_FORMATS.CURRENCY,
      data: data?.accruedRevenueIncreaseByMonth?.[glId],
      debitOrCredit: ACCOUNTING_ENTRY_TYPE.DEBIT,
      subRow: true,
    });

    // Accrued Revenue CR per GL
    result.push({
      title: 'Accrued Revenue CR',
      glId,
      rowKey: `Accrued Revenue CR.${glId}`,
      dataType: NUMBER_FORMATS.CURRENCY,
      data: data?.accruedRevenueDecreaseByMonth?.[glId] ?? 0,
      debitOrCredit: ACCOUNTING_ENTRY_TYPE.CREDIT,
      subRow: true,
    });
  }

  result.push({
    title: 'Deferred Revenue',
    rowKey: 'Deferred Revenue',
    isHeader: true,
  });

  for (const glId of Object.keys(glIntegrationsById ?? {})) {
    // Deferred Revenue per GL
    result.push({
      title: getIntegrationDisplayName(glIntegrationsById[glId]),
      glId,
      rowKey: `Deferred Revenue.${glId}`,
      dataType: NUMBER_FORMATS.CURRENCY,
      data: data?.deferredRevenueByMonth?.[glId],
      isGLRow: true,
      subRow: false,
    });

    // Deferred Revenue Change per GL
    result.push({
      title: 'Change',
      glId,
      rowKey: `Deferred Revenue Change.${glId}`,
      dataType: NUMBER_FORMATS.CURRENCY,
      data: data?.deferredRevenueChangeByMonth?.[glId],
      subRow: true,
      addSign: true,
    });

    // Deferred Revenue DR per GL
    result.push({
      title: 'Deferred Revenue DR',
      glId,
      rowKey: `Deferred Revenue DR.${glId}`,
      dataType: NUMBER_FORMATS.CURRENCY,
      data: data?.deferredRevenueIncreaseByMonth?.[glId],
      debitOrCredit: ACCOUNTING_ENTRY_TYPE.DEBIT,
      subRow: true,
    });

    // Deferred Revenue CR per GL
    result.push({
      title: 'Deferred Revenue CR',
      glId,
      rowKey: `Deferred Revenue CR.${glId}`,
      dataType: NUMBER_FORMATS.CURRENCY,
      data: data?.deferredRevenueDecreaseByMonth?.[glId] ?? 0,
      debitOrCredit: ACCOUNTING_ENTRY_TYPE.CREDIT,
      subRow: true,
    });
  }

  return result;
};
