import styled from 'styled-components';
import { Checkbox as _Checkbox } from 'antd';
import { NUMBER_FORMATS } from 'consts/global';
import { pluralize } from 'utils/stringUtils';
import { numberFormatter } from 'utils/formatters';
import { ReactComponent as ArrowUp } from 'images/arrow-up.svg';
import { CentererVertical } from 'components/Core';
import { GreyDot } from 'components/Icons';
import { TableSubRowsCountArrow } from 'components/Table';

const Wrapper = styled(CentererVertical)`
  overflow: visible;
  white-space: nowrap;
`;

const Label = styled.div`
  padding: 8px;
  background: rgba(0, 21, 46, 0.03);
  border: 1px solid rgba(0, 21, 46, 0.05);
  border-radius: 100px;

  color: var(--primaryBlack);
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
`;

const SchedulesSummaryText = styled.div`
  margin: 0 12px 0 15px;
  color: var(--primaryBlack);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
`;

const Checkbox = styled(_Checkbox)`
  margin-right: 8px;
`;

export const ExpandableRow = ({
  label,
  totalAmount,
  currency,
  unitCount,
  unitName,
  isExpanded,
  showStats = true,
  showAmount = true,
  showCheckbox = false,
  onCheckboxClick,
  checked,
  disabled,
  ...toggleRowExpandedProps
}) => (
  <Wrapper {...toggleRowExpandedProps}>
    {showCheckbox && <Checkbox onClick={onCheckboxClick} checked={checked && !disabled} disabled={disabled} />}
    <Label>{label}</Label>
    <TableSubRowsCountArrow isHidden={!isExpanded} dashboardTable>
      <ArrowUp />
    </TableSubRowsCountArrow>
    {showStats && (
      <SchedulesSummaryText>
        {pluralize(unitCount ?? 0, unitName)}
        {showAmount && (
          <>
            :{' '}
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: totalAmount,
              currency,
              decimalPlaces: 2,
            })}
          </>
        )}
      </SchedulesSummaryText>
    )}
    <GreyDot size="3px" spacing="4px" />
  </Wrapper>
);
