import axios from 'axios';
import { API_URL } from 'api/consts';
import { OAUTH_CALLBACK_PATH } from 'views/Wizard/consts';
import { getServiceCategory } from 'models/integration';

// For some integrations e.g. Xero, we haven't been able to use non-anonymous OAuth handshake
const SUPPORTS_NON_ANONYMOUS_INTEGRATIONS = ['quickbooks'];

// Hubspot
export const initiateHubspotLogin = async () => {
  const url = `${API_URL}/integrations/hubspot/auth`;
  const { data } = await axios.get(url);
  window.location.href = data;
  return data;
};

export const updateHubspotCompany = async ({ orgId, data }) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/hubspot/companies/${data.id}`;
  const response = await axios.patch(url, data);
  return response.data;
};

// Salesforce
export const updateSalesforceAccount = async ({ orgId, data }) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/salesforce/accounts/${data.id}`;
  const response = await axios.patch(url, data);
  return response.data;
};

// Quickbooks
export const initiateQuickBooksLogin = async () => {
  const url = `${API_URL}/integrations/quickbooks/auth`;
  const { data } = await axios.get(url);
  window.location.href = data;
  return data;
};

export const updateQuickbooksCustomer = async ({ orgId, data, params }) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/quickbooks/customers/${data.id}`;
  const response = await axios.patch(url, data, { params });
  return response.data;
};

// Netsuite
export const updateNetsuiteCustomer = async ({ orgId, data, params }) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/netsuite/customers/${data.id}`;
  const response = await axios.patch(url, data, { params });
  return response.data;
};

// Xero
export const updateXeroContact = async ({ orgId, data, params }) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/xero/contacts/${data.id}`;
  const response = await axios.patch(url, data, { params });
  return response.data;
};

export const disconnectXeroIntegration = async ({ orgId, integrationId }) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/xero/disconnect`;
  const response = await axios.patch(url, { integrationId });
  return response.data;
};

// Stripe
export const updateStripeCustomer = async ({ orgId, data }) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/stripe/customers/${data.id}`;
  const response = await axios.patch(url, data);
  return response.data;
};

// Slack
export const initiateSlackLogin = async () => {
  const url = `${API_URL}/integrations/slack/auth`;
  const { data } = await axios.get(url);
  window.location.href = data;
  return data;
};

// Google
export const initiateGoogleApi = async ({ params = {} }) => {
  const url = `${API_URL}/integrations/gmail/auth`;
  const { data } = await axios.get(url, { params });
  window.location.href = data;
  return data;
};

export const upsertIntegrations = async ({ orgId, data }) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/bulk`;
  const response = await axios.post(url, data);
  return response.data;
};

export const deleteIntegration = async ({ orgId, integrationId }) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/${integrationId}`;
  const { data } = await axios.delete(url);
  return data;
};

export const updateIntegrationsQuickBooks = async (orgId, integrationId, data) => {
  const url = `${API_URL}/integrations/quickbooks/${integrationId}/organizations/${orgId}/`;
  const response = await axios.patch(url, data);
  return response.data;
};

export const initiateIntegrationLogin = async (userId, integration, redirect) => {
  if (SUPPORTS_NON_ANONYMOUS_INTEGRATIONS.includes(integration)) {
    const url = `${API_URL}/integrations/${integration}/auth`;
    const { data } = await axios.get(url);
    window.location.href = data;
    return data;
  }
  return anonymousIntegrationLogin(userId, integration, redirect);
};

export const anonymousIntegrationLogin = async (userId, integration, redirect = OAUTH_CALLBACK_PATH.OLD_WIZARD) => {
  const url = `${API_URL}/integrations/${getServiceCategory(
    integration,
  )}/anonymous-auth?userId=${userId}&redirect=${redirect}`;
  const { data } = await axios.get(url);
  window.location.href = data;
  return data;
};

export const getIntegrationsAndProducts = async (orgId, params = {}) => {
  const url = `${API_URL}/organizations/${orgId}/integrations`;
  const { data } = await axios.get(url, {
    params: { includeProducts: params.includeProducts !== false, onlyDisauthenticated: params.onlyDisauthenticated },
  });
  return data;
};

export const getIntegrationsAnonymous = async (orgId) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/anonymous`;
  const { data } = await axios.get(url);
  return data;
};

export const getIntegrationsObjectFields = async ({ orgId, service }) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/fields`;
  const { data } = await axios.get(url, { params: { service } });
  return data;
};

export const getOneIntegrationsObject = async ({ orgId, params }) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/object`;
  const { data } = await axios.get(url, { params });
  return data;
};

export const getIntegrationsProducts = async (orgId) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/products`;
  const { data } = await axios.get(url);
  return data;
};

export const getPipelinesAndStages = async (orgId) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/pipelines`;
  const { data } = await axios.get(url);
  return data;
};

export const updateIntegration = async (orgId, integrationId, data, skipIngestion) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/${integrationId}`;
  const response = await axios.patch(url, data, { params: { skipIngestion } });
  return response.data;
};

export const getAccounts = async (orgId, integrationId) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/${integrationId}/accounts`;
  const { data } = await axios.get(url);
  return data;
};

export const syncEntity = async ({ orgId, integrationId, entity, internalIds, externalIds, initialSync }) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/${integrationId}/sync/${entity}`;
  const { data } = await axios.put(url, { internalIds, externalIds, initialSync });
  return data;
};

export const refreshTransactionFields = async ({ orgId, transactionId, data }) => {
  const url = `${API_URL}/organizations/${orgId}/integrations/transactions/${transactionId}/refresh`;
  const response = await axios.post(url, data);
  return response.data;
};

export const getIncomeAccountOptions = async ({ orgId, integrationId }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/integrations/incomeAccountOptions`, {
    params: { integrationId },
  });
  return response.data;
};

export const getInvoiceCustomFields = async ({ orgId, integrationId }) => {
  const response = await axios.get(
    `${API_URL}/organizations/${orgId}/integrations/${integrationId}/invoiceCustomFields`,
  );
  return response.data;
};

export const syncInvoiceTemplates = async ({ orgId, integrationId }) => {
  const response = await axios.get(
    `${API_URL}/organizations/${orgId}/integrations/${integrationId}/syncInvoiceTemplates`,
  );
  return response.data;
};
