import styled from 'styled-components';

export const Container = styled.div`
  pointer-events: ${(props) => props.disabled && 'none'};
  opacity: ${(props) => props.disabled && '0.7'};
`;

export const ProductTooltipButton = styled.div`
  background: ${({ noButton }) => (noButton ? 'rgba(255, 255, 255, 0.1)' : 'white')};
  padding: 8px 12px;
  box-shadow: ${({ noButton }) => !noButton && '4px 4px 24px var(--primaryBlack4)'};
  border-radius: 100px;
  font-weight: 900;
  font-size: 10px;
  color: ${({ noButton }) => (noButton ? 'rgba(255, 255, 255, 0.5)' : 'var(--primaryBlack)')};
  line-height: 12px;
  text-transform: uppercase;
  margin-right: ${({ noButton }) => noButton && '8px'};
  cursor: pointer;

  &:hover {
    box-shadow: 4px 4px 24px var(--primaryBlack10);
    background: ${({ noButton }) => noButton && 'rgba(255, 255, 255, 0.2)'};
    color: ${({ noButton }) => noButton && 'white'};
  }
`;

export const ProductRecognitionTooltip = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: white;
  padding: 20px;
  z-index: 20;
  transform: translateX(-50%);
  left: 50%;
  text-align: center;
  background: var(--dark7);
  border-radius: 16px;
  position: absolute;
  top: 38px;
  width: 280px;

  ::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid var(--dark7);
    transform: translate(-50%, 0) rotate(90deg);
    left: 50%;
    top: -10px;
  }
`;

export const SelectIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

export const EditMonthlyRecurringButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  background: var(--primaryBlue10);
  border-radius: 4px;
  margin-left: 4px;
  position: absolute;
  top: 0;

  svg {
    width: 16px;
    height: 16px;
    margin: 0;
  }

  &:hover {
    background: var(--primaryBlue20);

    svg {
      path {
        fill: var(--secondaryBlue);
        transition: fill 0.2s ease-in-out;
      }
    }
  }
`;

export const AmountToBillContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  gap: 4px;
  margin-right: 12px;
  width: auto;
`;

export const ViewScheduleContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 12px;
  font-size: 12px;
  font-style: italic;
  color: var(--primaryBlack40);
`;

export const LinkToInvoicingSchedule = styled.div`
  color: var(--primaryBlue);
  font-size: 12px;
  cursor: pointer;

  :hover {
    color: var(--secondaryBlue);
  }
`;

export const HorizontalLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: var(--accentGraySecond);
`;
