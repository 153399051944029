import dayjs from 'dayjs';
import React from 'react';
import { WarningBar } from 'components/Blocks';
import { REMINDER_ERROR_METADATA_FIELDS } from 'views/Billing/consts';
import { hasReminderFailedToSend } from 'views/Billing/utils';
import { FlexerRow } from 'components/Core';

export const InvoiceReminderFailedToSendWarning = ({ reminder }) => {
  const reminderHasFailedToSend = hasReminderFailedToSend({ reminder });
  if (!reminderHasFailedToSend) return null;

  const invocieFailedCount = reminder.metadata?.[REMINDER_ERROR_METADATA_FIELDS.SEND_EMAIL_ERROR_COUNT];
  const warnings = [];
  if (invocieFailedCount === 1) {
    warnings.push(
      <>
        <b>Failed to send</b> reminder on{' '}
        {dayjs
          .utc(reminder.metadata?.[REMINDER_ERROR_METADATA_FIELDS.LAST_SEND_EMAIL_FAILED_AT])
          .format('MMM DD, YYYY')}
        . Subscript will try to resend it on {dayjs.utc(reminder.scheduled_for).format('MMM DD, YYYY')}.
        <br />
        <b>Reason:</b>{' '}
        <i>
          {reminder.metadata?.[REMINDER_ERROR_METADATA_FIELDS.LAST_SEND_EMAIL_ERROR]}
          <b />
        </i>
      </>,
    );
  } else if (invocieFailedCount >= 2) {
    warnings.push(
      <>
        The second automatic attempt to send the reminder <b>failed</b> on{' '}
        {dayjs
          .utc(reminder.metadata?.[REMINDER_ERROR_METADATA_FIELDS.LAST_SEND_EMAIL_FAILED_AT])
          .format('MMM DD, YYYY')}
        . Schedule it again or send it manually.
      </>,
    );
  }

  return (
    reminderHasFailedToSend && (
      <FlexerRow width="100%" gap="16px">
        <WarningBar fullWidth warnings={warnings} />
      </FlexerRow>
    )
  );
};
