import { useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { AppContext } from 'AppContext';
import { ReactComponent as ArrowDown } from 'images/chevron-down.svg';
import { NUMBER_FORMATS } from 'consts/global';
import { Flexer, FlexerColumn, Row } from 'components/Core';
import { numberFormatter } from 'utils/formatters';

import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { getBilledAmount, getDisplayNameByTransactionId, getSortedInvoices, getTransactionsAmount } from '../utils';
import { CreditNoteContext } from '../CreditNoteContext';

const BillingAmountWrapper = styled(FlexerColumn)`
  font-size: 12px;
  line-height: 16px;
  width: 100%;
`;

const Header = styled(Row)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  ${({ disableAction }) =>
    !disableAction &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

const ContentWrapper = styled(FlexerColumn)`
  margin-top: 26px;
  gap: 8px;
`;

const SubRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
  gap: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SubRowMainText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const SubRowMainValue = styled(Flexer)`
  font-weight: 700;
  width: fit-content;
  white-space: nowrap;
  flex-wrap: nowrap;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--primaryBlack3);
  z-index: 1;
  position: absolute;
  left: 0;
  top: 50px;
`;

const StyledArrowDown = styled(ArrowDown)`
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'auto')};
`;

export const AmountsList = ({ importedInvoices } = {}) => {
  const { includedTransactions, invoicingScheduleFormValues } = useContext(InvoicingScheduleContext);
  const { creditNotes } = useContext(CreditNoteContext);
  const {
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);
  const [isListExpanded, setIsListExpanded] = useState(false);

  const invoices = useMemo(
    () =>
      getSortedInvoices({ invoices: [...(importedInvoices ?? []), ...(invoicingScheduleFormValues?.invoices ?? [])] }),
    [importedInvoices, invoicingScheduleFormValues?.invoices],
  );

  // this includes imported invoices as well
  const billedAmount = useMemo(
    () => getBilledAmount({ invoices, creditNotes }),
    // make sure memo runs when child invoices_items change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(invoices)],
  );

  const importedInvoicesBilledAmount = (importedInvoices ?? []).reduce(
    (acc, invoice) => {
      acc.total += invoice.amount ?? 0;
      (invoice.invoice_items ?? []).forEach((invoiceItem) => {
        const transactionId = invoiceItem.transaction_id;
        if (transactionId) {
          acc[transactionId] = (acc[transactionId] ?? 0) + (invoiceItem.amount ?? 0);
        }
      });
      return acc;
    },
    { total: 0 },
  );

  const displayNameByTransactionId = useMemo(() => getDisplayNameByTransactionId({ includedTransactions }), [
    includedTransactions,
  ]);

  const transactionsAmount = useMemo(() => getTransactionsAmount({ includedTransactions }), [includedTransactions]);

  const canExpand = !!includedTransactions?.length;

  return (
    <BillingAmountWrapper>
      <Header onClick={() => canExpand && setIsListExpanded(!isListExpanded)} disableAction={!canExpand}>
        <span> Total amount to bill:</span>
        <Flexer gap="8px" alignItems="center">
          <b>
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: billedAmount.total,
              currency: invoicingScheduleFormValues?.currency ?? defaultOrgCurrency,
            })}{' '}
            of{' '}
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              // As imported invoices prop will not be included in the transaction, we need to include it's total amount
              rawValue: transactionsAmount.total + importedInvoicesBilledAmount.total,
              currency: invoicingScheduleFormValues?.currency ?? defaultOrgCurrency,
            })}
          </b>
          {canExpand && <StyledArrowDown isExpanded={isListExpanded} />}
        </Flexer>
      </Header>

      {isListExpanded && <Line />}

      {isListExpanded && (
        <ContentWrapper>
          {Object.entries(transactionsAmount)
            .filter(([key]) => key !== 'total')
            .map(([transactionId, value]) => {
              if (typeof billedAmount[transactionId] == 'string') return null;
              const displayName = displayNameByTransactionId[transactionId];
              return (
                <SubRow>
                  <SubRowMainText>{displayName}:</SubRowMainText>
                  <SubRowMainValue>
                    {numberFormatter({
                      type: NUMBER_FORMATS.CURRENCY,
                      rawValue: billedAmount[transactionId] ?? 0,
                      currency: invoicingScheduleFormValues?.currency ?? defaultOrgCurrency,
                    })}{' '}
                    of{' '}
                    {numberFormatter({
                      type: NUMBER_FORMATS.CURRENCY,
                      // As imported invoices prop will not be included in the transaction,
                      // we need to include it's amount for each transaction
                      rawValue: (value ?? 0) + (importedInvoicesBilledAmount[transactionId] ?? 0),
                      currency: invoicingScheduleFormValues?.currency ?? defaultOrgCurrency,
                    })}
                  </SubRowMainValue>
                </SubRow>
              );
            })}
        </ContentWrapper>
      )}
    </BillingAmountWrapper>
  );
};
