import React, { useContext, useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { cssVar } from 'polished';

import { EVENTS } from 'consts/analytics';
import { AppContext } from 'AppContext';
import { ReactComponent as HowWorksIcon } from 'images/lifebuoy.svg';
import { useOrganizationCountsAPI } from 'api/organizations';
import { useTransactionsAPI } from 'api/transactions';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { useToasts } from 'components/Toasts';
import { IconButton, LinkBackButton } from 'components/Buttons';
import { Row, Spacer } from 'components/Core';
import { TransactionsPageBanners } from 'shared/Banners';
import { ReactComponent as AddIcon } from 'images/actions-dropdown__add.svg';
import { ReactComponent as ArchiveIcon } from 'images/actions-dropdown__archive.svg';
import { ReactComponent as BulkIcon } from 'images/actions-dropdown__bulk.svg';
import { ReactComponent as CsvIcon } from 'images/actions-dropdown__csv.svg';
import { ReactComponent as LockIcon } from 'images/actions-dropdown__lock.svg';

import { ActionsPopover, SYNC_ENTITIES, SyncEntityButton } from 'shared/Common';
import { NoItems } from 'shared/ErrorBars';
import { GlobalTogglesFloatingContainer } from 'shared/GlobalToggles';
import { Header, HeaderTitle, HEADER_TITLES } from 'shared/Layout';
import { useLinkShare } from 'shared/LinkShare';
import { useSyncedExport } from 'shared/SyncedExports';
import { addRecRevToCustomers, addChildTagToCustomers } from 'views/Customers/CustomerSingle/utils';
import { TransactionSingleContent, TRANSACTION_MODAL_MODE } from 'shared/TransactionContent';
import { UploadContractButton } from 'views/Contracts';
import {
  DEFAULT_PAGE_SIZE,
  TRANSACTION_TABLE_COLUMN_NAME_MAPPING,
  TRANSACTION_TABLE_COLUMN_TITLES_BY_ID,
  TransactionTable,
} from 'shared/TransactionTable';
import { CONFIRMATION_OPTIONS, GROUP_BY_OPTIONS } from 'shared/TransactionTable/consts';
import { replaceRecordInArray } from 'utils/arrayUtils';
import { ObservableContext } from 'utils/observable/ObservableContext';
import { useAnalytics, usePagination, useStateWithStorage } from 'utils/hooks';
import { sortByToKnexOrderBy } from 'utils/tableUtils';
import { getUrlParams } from 'utils/urlUtils';
import { addTotalAmountToGroups, groupTransactions, addRecRevToGroups, populateIsLocked } from './utils';

import { TRANSACTIONS_PAGES_ROUTES } from './consts';
import { TransactionsContentModals } from './TransactionsContentModals/TransactionsContentModals';
import { TransactionsContext } from './TransactionsContext';

export const TransactionsPage = ({
  hiddenTransactionColumns,
  hasListPageContext,
  setHiddenTransactionColumns,
  showingBookingsTransactions,
  filtersBar,
  transactionsConfirmation,
  transactionsGroupBy,
  additionalTransactionsFilters,
  extendedMetadataFilter,
  initialSortBy,
  headerLeftContent,
  showTransactionLockModal,
  setShowTransactionLockModal,
  showAccountingSpreadsLockModal,
  setShowAccountingSpreadsLockModal,
  widgetView,
  additionalHeaderActions,
  customersWithRecRev,
  // Are we on the Automatic Confirmation Page
  isAutoConfirmView,
  // Are we on deleted transactions page
  isArchivedView,
  showTransactionBanners = true,
}) => {
  const { organizations, orgId, orgConfigs } = useContext(AppContext);
  const { state } = useLocation();

  const { data: organizationCountsData } = useOrganizationCountsAPI({ orgId, scopes: ['unresolvedExternalUpdates'] });
  const externalUpdatesCount = organizationCountsData?.unresolvedExternalUpdates ?? 0;

  const listPageContext = useContext(TransactionsContext);
  const appContext = useContext(AppContext);

  const { metadataFilter, dataFilter, setDataFilter } = hasListPageContext ? listPageContext : appContext;

  const { transactionsObservable } = useContext(ObservableContext);
  const { pushToast } = useToasts();
  const [isProcessingData, setIsProcessingData] = useState(true);
  // eslint-disable-next-line
  const [isReady, setIsReady] = useState(false);
  const [pageCount, setPageCount] = useState();
  const { pageSize, setPageSize, currentPageIndex, setCurrentPageIndex } = usePagination({
    storageSuffix: 'transactions-page',
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });
  const [storageSortBy] = useStateWithStorage('transaction-page-sort-by', [
    {
      id: 'updated_at',
      desc: true,
    },
  ]);
  const [sortBy, setSortBy] = useState(initialSortBy ?? storageSortBy);
  const [searchQuery, setSearchQuery] = useState();
  const [transactionsToShow, setTransactionsToShow] = useState([]);
  const [showTransaction, setShowTransaction] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dataForModal, setDataForModal] = useState({});
  const [showBulkTransactions, setShowBulkTransactions] = useState(false);
  const [showNewTransactionModal, setShowNewTransactionModal] = useState(false);
  const [unconfirmedTransactions, setUnconfirmedTransactions] = useState([]);
  const [transactionIdsToBulkConfirm, setTransactionIdsToBulkConfirm] = useState([]);
  const [transactionIdsToBulkDelete, setTransactionIdsToBulkDelete] = useState([]);
  const [deletedTransactionIds, setDeletedTransactionIds] = useState([]);
  const [openingBulkProcessFromBulkConfirm, setOpeningBulkProcessFromBulkConfirm] = useState(false);
  const [showAutoconfirmHelpModal, setShowAutoconfirmHelpModal] = useState(false);
  const handleShowAutoconfirmHelpModal = () => setShowAutoconfirmHelpModal(true);

  const { trackEvent } = useAnalytics();
  const history = useHistory();
  const [isFirstFetch, setIsFirstFetch] = useState(true);

  const transactionGroupBy = transactionsGroupBy ?? dataFilter.transactionsGroupBy;

  const showTransactionsWithConfirmation = isAutoConfirmView
    ? 'Confirmed'
    : transactionsConfirmation ?? dataFilter.transactionsConfirmation;

  useEffect(() => {
    if (state?.transactionsConfirmation && dataFilter.transactionsConfirmation !== state.transactionsConfirmation) {
      setDataFilter({ ...dataFilter, transactionsConfirmation: state.transactionsConfirmation });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const { referral } = getUrlParams();

  const { transactionsAutoConfirmRuleset, transactionsLockBeforeDate } = orgConfigs;

  let filters = {
    page: currentPageIndex,
    limit: pageSize,
    params: {
      confirmed: CONFIRMATION_OPTIONS[showTransactionsWithConfirmation],
      includeCount: true,
      autoconfirmed: isAutoConfirmView,
      includeAmountLeftToRecognize: true, //used for "Left to recognize" column
      archived: isArchivedView,
      referral,
    },
    scopes: ['replaced_transactions', 'transaction_spreads_sum'],
  };

  if (additionalTransactionsFilters) {
    filters = { ...filters, ...additionalTransactionsFilters };
  }
  if (sortBy?.length) {
    filters.body = {
      ...filters.body,
      orderBy: sortByToKnexOrderBy(sortBy, TRANSACTION_TABLE_COLUMN_NAME_MAPPING),
    };
  }
  if (searchQuery?.length) {
    filters.params = {
      ...filters.params,
      searchQuery,
    };
  }

  const {
    data,
    metadata,
    isLoading,
    isFetching,
    operations: { invalidateCache, invalidateAll, unconfirmTransaction },
  } = useTransactionsAPI({
    orgId,
    filters,
    metadataFilter: extendedMetadataFilter ? extendedMetadataFilter : metadataFilter,
  });

  const {
    data: unconfirmedTransactionsResult,
    isLoading: isLoadingUnconfirmed,
    isFetching: isFetchingUnconfirmed,
  } = useTransactionsAPI({
    orgId,
    filters: {
      page: 1,
      limit: 50000,
      params: {
        confirmed: false,
        includeReplaced: false,
        archived: false,
      },
    },
  });

  const handleUnconfirmTransaction = ({ transaction }) => unconfirmTransaction.mutate({ id: transaction.id });

  useEffect(() => {
    if (isLoading || isFetching) return;

    setIsProcessingData(true);

    const populatedData = populateIsLocked({ transactions: data, transactionsLockBeforeDate });

    setTransactionsToShow(populatedData ?? []);
    setPageCount(metadata?.maxPage);

    if (!data) {
      setIsProcessingData(false);
      return;
    }

    const dataWithRR = customersWithRecRev ? addRecRevToCustomers({ transactionsForTable: data }) : data;

    if (transactionGroupBy && transactionGroupBy !== 'null') {
      const groupedTransactions = groupTransactions({ transactions: dataWithRR, groupBy: transactionGroupBy });

      addTotalAmountToGroups(groupedTransactions);
      addRecRevToGroups(groupedTransactions);

      if (additionalTransactionsFilters?.body?.customerIds?.length === 1) {
        // for a single customer
        addChildTagToCustomers({
          transactionsForTable: groupedTransactions,
          customerId: additionalTransactionsFilters?.body?.customerIds[0],
        });
      }

      setTransactionsToShow(groupedTransactions ?? []);
    }

    setIsProcessingData(false);
    setIsFirstFetch(false);
    /* eslint-disable-next-line */
  }, [data, isLoading, isFetching, metadata]);

  useEffect(() => {
    if (isLoadingUnconfirmed || isFetchingUnconfirmed) {
      return;
    }
    setUnconfirmedTransactions(unconfirmedTransactionsResult);
  }, [unconfirmedTransactionsResult, isLoadingUnconfirmed, isFetchingUnconfirmed]);

  const openBulkTransactionsWithCheck = useCallback(() => {
    trackEvent({
      name: EVENTS.OPEN_BULK_PROCESS_TRANSACTIONS_MODAL,
      properties: {
        from: 'externalUpdatesBanner',
      },
    });

    if (isFetching && !showBulkTransactions) {
      pushToast('Please wait for all background fetches to finish before opening the bulk process modal.', 'error');
      return;
    }
    setShowBulkTransactions(true);
  }, [isFetching, pushToast, showBulkTransactions, trackEvent]);

  // We debounce to avoid flashing "data unavailable" component
  /* eslint-disable-next-line */
  const debouncedSetIsReady = useCallback(
    debounce((isLoading, isProcessingData) => {
      setIsReady(!isLoading && !isProcessingData);
    }, 500),
    [],
  );
  useEffect(() => {
    debouncedSetIsReady(isLoading, isProcessingData);
  }, [isLoading, isProcessingData, debouncedSetIsReady]);

  const { SyncedExportInfoBar, SyncedExportModal, ExportButton } = useSyncedExport({
    orgId: organizations[0].id,
    type: 'transactions',
    buttonWhite: true,
    customization: ({ selectAllData }) => ({
      filters: {
        ...filters.params,
        orgId,
        customerIds: filters?.body?.customerIds,
        notTransactionIds: filters?.body?.notTransactionIds,
        startDate: filters?.body?.startDate,
        endDate: filters?.body?.endDate,
        ...(!selectAllData && (metadataFilter ?? {})),
      },
      scopes: filters.scopes,
    }),
    isFilterOn: metadataFilter && Object.values(metadataFilter).length !== 0,
  });

  const { LinkShareButton, LinkShareModal } = useLinkShare({ headerTitle: HEADER_TITLES.transactions });

  useEffect(() => {
    if (organizations?.length) {
      fetchTransactions();
    }
    // eslint-disable-next-line
  }, [showTransactionsWithConfirmation, transactionGroupBy]);

  useEffect(() => {
    return transactionsObservable.subscribe(updateAllPages);
    // eslint-disable-next-line
  }, []);

  const fetchTransactions = async () => {
    setCurrentPageIndex(1);
    invalidateCache();
  };

  const updateAllPages = async () => {
    invalidateAll();
  };

  const decorateTransactionForListView = (transaction) => {
    if (!transaction.product_id || transaction.product_name) return;
    transaction.product_name = organizations?.[0]?.products?.find(
      (product) => product.id === transaction.product_id,
    )?.name;
  };

  const removeTransactionFromUnconfirmedList = (transaction) => {
    if (!transaction.confirmed) return;
    setUnconfirmedTransactions(unconfirmedTransactions.filter((t) => t.id !== transaction.id));
  };

  const updateTransactionInTable = (updatedTransaction) => {
    removeTransactionFromUnconfirmedList(updatedTransaction);
    if (transactionGroupBy === GROUP_BY_OPTIONS.CUSTOMER) {
      const newData = [...transactionsToShow];

      for (const customer of newData) {
        if (customer.group_customer_id === updatedTransaction.customer_id) {
          customer.subRows = replaceRecordInArray(customer.subRows, updatedTransaction);
        } else {
          customer.subRows = customer.subRows.filter((transaction) => transaction.id !== updatedTransaction.id);
        }
      }

      setTransactionsToShow(newData);
    } else {
      setTransactionsToShow(replaceRecordInArray(transactionsToShow, updatedTransaction));
    }
  };

  const handleCloseModal = () => {
    setShowBulkTransactions(false);
    setShowTransaction(false);
    setShowNewTransactionModal(false);
    if (openingBulkProcessFromBulkConfirm) {
      setOpeningBulkProcessFromBulkConfirm(false);
    }
  };

  const handleTransactionDeleted = ({ deletedTransaction }) => {
    if (showBulkTransactions) {
      // TRANSACTION_MODAL_MODE.EDIT_BULK
      const updatedUnconfirmedTransactions = unconfirmedTransactions.filter((t) => t.id !== deletedTransaction.id);
      setUnconfirmedTransactions(updatedUnconfirmedTransactions);
      if (updatedUnconfirmedTransactions.length === 0) {
        setShowBulkTransactions(false);
      }
    } else if (showTransaction) {
      // TRANSACTION_MODAL_MODE.EDIT
      handleCloseModal();
    }
    setShowDeleteModal(false);
  };

  const handleTransactionDuplicated = ({ transaction }) => {
    decorateTransactionForListView(transaction);
    setDataForModal(transaction);
  };

  const handleTransactionCreated = ({ newTransaction }) => {
    decorateTransactionForListView(newTransaction);
  };

  const handleTransactionUpdated = ({ updatedTransaction, isLastInBulk }) => {
    decorateTransactionForListView(updatedTransaction);
    updateTransactionInTable(updatedTransaction);
    if (showBulkTransactions && isLastInBulk) {
      setShowBulkTransactions(false);
    }
  };

  const showNoItemsPrompt = !transactionsToShow.length && !searchQuery?.length && isReady;

  return (
    <div style={{ width: '100%', minHeight: !showingBookingsTransactions && 1000, position: 'relative' }}>
      {!widgetView && (
        <Header
          margin={!!showingBookingsTransactions ? '10px 0' : null}
          headerLeft={
            headerLeftContent ?? (
              <>
                {(isAutoConfirmView || isArchivedView) && (
                  <LinkBackButton
                    to={TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS}
                    data-cy="auconfirmed-transactions__back-button"
                  >
                    Back
                  </LinkBackButton>
                )}
                <HeaderTitle>
                  {isAutoConfirmView
                    ? HEADER_TITLES.transactionsAuto
                    : isArchivedView
                    ? HEADER_TITLES.transactionsArchive
                    : HEADER_TITLES.transactions}
                </HeaderTitle>
                <SyncEntityButton entity={SYNC_ENTITIES.TRANSACTIONS} />
              </>
            )
          }
          headerRight={
            <Row>
              {isAutoConfirmView && (
                <>
                  <IconButton
                    icon={<HowWorksIcon />}
                    iconFillColor={cssVar('--primaryGreen')}
                    onClick={() => setShowAutoconfirmHelpModal(true)}
                    data-cy="transactions__how-it-works"
                  >
                    How it works
                  </IconButton>
                  <Spacer width="8px" />
                </>
              )}

              <UploadContractButton onClick={() => history.push('/contracts')} />
              <LinkShareButton />
              <Spacer width="8px" />
              <ExportButton />
              <Spacer width="8px" />
              <ActionsPopover
                width="240px"
                dropdownXOffset={-64}
                unconfirmedTransactionsCount={unconfirmedTransactions?.length}
                actions={{
                  Transaction: {
                    icon: <AddIcon />,
                    cb: () => {
                      setShowNewTransactionModal(true);
                    },
                  },

                  'Upload CSV Transactions': {
                    icon: <CsvIcon />,
                    cb: () => history.push('/csv/transactions'),
                  },

                  'Upload CSV Transaction Spreads': {
                    icon: <CsvIcon />,
                    cb: () => history.push('/csv/spreads'),
                  },

                  'Upload CSV Accounting Spreads': {
                    icon: <CsvIcon />,
                    cb: () => history.push('/csv/accountingSpreads'),
                  },

                  [unconfirmedTransactions?.length > 0 ? 'Bulk Process' : null]: {
                    icon: <BulkIcon />,
                    cb: () => {
                      trackEvent({
                        name: EVENTS.START_BULK_CONFIRMING_TRANSACTIONS,
                        properties: {
                          triggerFrom: 'ActionsPopover',
                        },
                      });

                      openBulkTransactionsWithCheck();
                    },
                  },

                  'Transactions Locking': {
                    icon: <LockIcon />,
                    cb: () => {
                      trackEvent({
                        name: EVENTS.OPEN_LOCK_OLD_TRANSACTIONS,
                        properties: {
                          triggerFrom: 'ActionsPopover',
                        },
                      });

                      setShowTransactionLockModal(true);
                    },
                  },

                  'Accounting Spreads Locking': {
                    icon: <LockIcon />,
                    cb: () => {
                      trackEvent({
                        name: EVENTS.OPEN_LOCK_ACCOUNTING_SPREADS,
                        properties: {
                          triggerFrom: 'ActionsPopover',
                        },
                      });

                      setShowAccountingSpreadsLockModal(true);
                    },
                  },

                  'Deleted Transactions': {
                    icon: <ArchiveIcon />,
                    cb: () => {
                      history.push(TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS_ARCHIVED);
                    },
                  },
                }}
              />
            </Row>
          }
        />
      )}
      <TimeLoaderContainer>
        {!isReady && isFirstFetch ? (
          <TimeLoader pageName="transactions" showLoadingGif={false} />
        ) : (
          <>
            {!widgetView && <SyncedExportInfoBar />}
            {!isAutoConfirmView && filtersBar}
            {!widgetView && showTransactionBanners && (
              <TransactionsPageBanners
                unconfirmedTransactionsCount={unconfirmedTransactions?.length}
                updatedTransactionsCount={externalUpdatesCount}
                showAutoconfirmBanner={
                  transactionsGroupBy !== GROUP_BY_OPTIONS.CONFIRMED_AT &&
                  transactionsAutoConfirmRuleset?.skip !== undefined &&
                  transactionsAutoConfirmRuleset?.skip !== null &&
                  transactionsAutoConfirmRuleset?.skip !== true
                }
                onBulkProcessClick={openBulkTransactionsWithCheck}
                onCheckUpdatesClick={() => {
                  trackEvent({ name: EVENTS.VISIT_EXTERNAL_UPDATES });
                  history.push(TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS_UPDATES);
                }}
                onAutoUpdateClick={() => {
                  trackEvent({ name: EVENTS.VISIT_AUTO_CONFIRM_PAGE });
                  history.push(TRANSACTIONS_PAGES_ROUTES.TRANSACTIONS_AUTOMATIC_CONFIRMATIONS);
                }}
              />
            )}
            <GlobalTogglesFloatingContainer>
              <TransactionTable
                columnsTitles={TRANSACTION_TABLE_COLUMN_TITLES_BY_ID}
                dataForTable={transactionsToShow}
                pageCount={pageCount}
                currentPageIndex={currentPageIndex}
                setCurrentPageIndex={setCurrentPageIndex}
                isFetching={!isReady}
                initialHiddenColumns={hiddenTransactionColumns}
                setHiddenTransactionColumns={setHiddenTransactionColumns}
                transactionGroupBy={transactionGroupBy}
                onDeleteClick={({ transaction }) => {
                  setDataForModal(transaction);
                  setShowDeleteModal(true);
                }}
                onUnconfirmClick={({ transaction }) => handleUnconfirmTransaction({ transaction })}
                onTransactionClick={(transaction) => {
                  setDataForModal(transaction);
                  setShowTransaction(true);
                }}
                onBulkConfirm={setTransactionIdsToBulkConfirm}
                onBulkDelete={setTransactionIdsToBulkDelete}
                noTransactions={showNoItemsPrompt ? <NoItems syncEntityVer /> : null}
                initialSortBy={initialSortBy}
                setSortBy={setSortBy}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                widgetView={widgetView}
                additionalHeaderActions={additionalHeaderActions}
                // For now we only show on the Auto Confirmation page
                showChecksColumn={isAutoConfirmView}
                onChecksTooltipClick={handleShowAutoconfirmHelpModal}
              />
            </GlobalTogglesFloatingContainer>
          </>
        )}
      </TimeLoaderContainer>
      {
        // We use unconfirmed transaction here because even though we may be filtering for confirmed transactions
        //  we may still be showing confirmed ones, since we try to show all transactions for a given customer
        (showBulkTransactions || showTransaction || showNewTransactionModal) && (
          <TransactionSingleContent
            mode={
              showBulkTransactions
                ? TRANSACTION_MODAL_MODE.EDIT_BULK
                : showTransaction
                ? TRANSACTION_MODAL_MODE.EDIT
                : TRANSACTION_MODAL_MODE.CREATE
            }
            transaction={
              showBulkTransactions ? unconfirmedTransactions?.[0] : showTransaction ? dataForModal : undefined
            }
            transactionPageLoading={isReady}
            fetchTransactions={fetchTransactions}
            organization={organizations[0]}
            initialTransactionsBulk={showBulkTransactions ? unconfirmedTransactions : undefined}
            closeModal={handleCloseModal}
            onTransactionCreated={handleTransactionCreated}
            onTransactionUpdated={handleTransactionUpdated}
            onTransactionDeleted={handleTransactionDeleted}
            onTransactionDuplicated={handleTransactionDuplicated}
          />
        )
      }

      <TransactionsContentModals
        showDeleteModal={showDeleteModal}
        organizations={organizations}
        dataForModal={dataForModal}
        setShowDeleteModal={setShowDeleteModal}
        handleTransactionDeleted={handleTransactionDeleted}
        transactionIdsToBulkConfirm={transactionIdsToBulkConfirm}
        setTransactionIdsToBulkConfirm={setTransactionIdsToBulkConfirm}
        updateAllPages={updateAllPages}
        setUnconfirmedTransactions={setUnconfirmedTransactions}
        setShowBulkTransactions={setShowBulkTransactions}
        setOpeningBulkProcessFromBulkConfirm={setOpeningBulkProcessFromBulkConfirm}
        transactionIdsToBulkDelete={transactionIdsToBulkDelete}
        setTransactionIdsToBulkDelete={setTransactionIdsToBulkDelete}
        deletedTransactionIds={deletedTransactionIds}
        setDeletedTransactionIds={setDeletedTransactionIds}
        showAutoconfirmHelpModal={showAutoconfirmHelpModal}
        setShowAutoconfirmHelpModal={setShowAutoconfirmHelpModal}
        showTransactionLockModal={showTransactionLockModal}
        setShowTransactionLockModal={setShowTransactionLockModal}
        showAccountingSpreadsLockModal={showAccountingSpreadsLockModal}
        setShowAccountingSpreadsLockModal={setShowAccountingSpreadsLockModal}
        LinkShareModal={LinkShareModal}
        SyncedExportModal={SyncedExportModal}
      />
    </div>
  );
};
