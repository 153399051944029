import React, { useEffect, useReducer } from 'react';
import { Checkbox } from 'antd';
import dayjs from 'dayjs';
import Select from 'react-select';
import { Row, NumberDot, Centerer } from 'components/Core';

import { updateIntegration } from 'api/integrations';
import { ReactComponent as RedWarnIcon } from 'images/red-warn.svg';
import { ReactComponent as SuccessIcon } from 'images/success-icon.svg';
import { ReactComponent as SalesforceIcon } from 'images/salesforce-icon.svg';
import { ReactComponent as HubspotIcon } from 'images/hubspot-icon.svg';
import { ReactComponent as ApiIcon } from 'images/api-icon.svg';
import { ReactComponent as CheckWhite } from 'images/check-white.svg';
import { ReactComponent as AuthorizedIcon } from 'images/authorized_icon.svg';

import { ErrorBar } from 'components/Blocks';
import { SelectField } from 'components/Controls';
import { DottedDivider } from 'components/Core';
import { Loader } from 'components/Loaders';
import baseInputStyles from 'utils/inputStyles';
import { RECOGNITION_TYPES } from 'consts/global';

import { reducer, initialState } from './state';
import { configurationRequired } from './helper';
import CheckItem from './CheckItem';
import PipelinesStages from './PipelinesStages';
import {
  TestConnectionBtn,
  ProductCard,
  ProductCardHeading,
  SaveConfigurationBtn,
  GeneralSettingTabContainer,
  IntegrationButtonsContainer,
  ConnectionContainer,
  ConnectionEstablished,
  ProductCardContent,
} from '../styles';
import { IntegrationButton } from '../Common/IntegrationButton';
import { IntegrationDivider } from '../Common/IntegrationDivider';
import { APIKeyCard } from './APIKeyCard';

const selectStyles = (isDisabled) => {
  return {
    control: (styles) => ({
      ...styles,
      height: 36,
      borderRadius: 100,
      marginTop: 5,
      background: 'var(--primaryBlack5);',
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    placeholder: (styles) => ({ ...styles, fontSize: 14 }),
    SingleValue: (styles) => ({ ...styles, fontSize: 14 }),
    ...(isDisabled && { dropdownIndicator: (styles) => ({ ...styles, display: 'none' }) }),
  };
};

const SourcesTab = (props) => {
  const { isLoading, integrationConnect, crmIntegrations } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    selectedCRMIntegration,
    closedWonStages,
    loading,
    selectedCRM,
    seatsVar,
    contractStartDateField,
    contractEndDateField,
    products,
  } = state;

  const organizations = state.organizations;

  // Starts the authentication flow with the integration
  const connect = () => integrationConnect();
  // To show the loader
  const setLoading = (state) => dispatch({ type: 'LOADING', payload: state });
  // Currently selected CRM for config page
  const setSelectedCRM = (crm) => dispatch({ type: 'SELECT_CRM', payload: crm });

  const closedWonClickHandler = (title) =>
    closedWonStages.includes(title)
      ? dispatch({ type: 'SET_CLOSED_WON_STAGES', payload: closedWonStages.filter((e) => e !== title) })
      : dispatch({ type: 'SET_CLOSED_WON_STAGES', payload: [...closedWonStages, title] });

  useEffect(() => {
    const selectedCRMIntegration = crmIntegrations?.find((e) => e.service === selectedCRM) || null;
    dispatch({
      type: 'POPULATE_INTEGRATION',
      payload: {
        selectedCRMIntegration,
        closedWonStages: selectedCRMIntegration?.metadata?.closedWonStages || [],
        seatsVar: selectedCRMIntegration?.metadata?.seatsVar,
        contractStartDateField: selectedCRMIntegration?.metadata?.contractStartDateField,
        contractEndDateField: selectedCRMIntegration?.metadata?.contractEndDateField,
        products: selectedCRMIntegration?.products,
      },
    });
    setLoading(isLoading);
  }, [organizations, crmIntegrations, selectedCRM, isLoading]);

  const saveChanges = async () => {
    const orgId = organizations?.[0].id;
    setLoading(true);
    // Currently, the only things that can change are closed won deal stages, and seats variable
    const updatedIntegration = await updateIntegration(orgId, selectedCRMIntegration.id, {
      metadata: { closedWonStages, seatsVar, contractStartDateField, contractEndDateField },
    });

    dispatch({ type: 'UPDATE_INTEGRATION', payload: { updatedIntegration } });
  };

  const getDealProperties = () => {
    const { metadata } = selectedCRMIntegration || {};

    // if (!metadata?.seatsVar) return metadata?.dealProperties?.length ? metadata.dealProperties : [];
    return metadata?.dealProperties?.length ? metadata.dealProperties : [];
  };

  const changeProduct = (newValue, product) => {
    const changedProduct = { ...product, recognition: newValue };
    dispatch({ type: 'UPDATE_PRODUCT', payload: { changedProduct } });
  };

  const dealProperties = getDealProperties();
  const configRequired = configurationRequired(selectedCRMIntegration);
  const connected = selectedCRMIntegration;

  return loading ? (
    <div className="w-100 flexer">
      <Loader containerStyles={{ width: 40 }} />
    </div>
  ) : (
    <GeneralSettingTabContainer>
      {configRequired && (
        <ErrorBar>
          <div style={{ width: '90%', marginLeft: 10 }}>
            <h5 className="mt-1" style={{ fontSize: 16 }}>
              {!connected && (
                <>
                  To continue working, please:
                  <br />— connect at least <b>one source and configure it</b> at the Transactions Sources tab
                  <br />— or create manually at least <b>one product</b> at the General Settings tab
                </>
              )}
              {connected && (
                <>
                  To continue sync, please <b>finish Hubspot integration</b>
                </>
              )}
            </h5>
          </div>
        </ErrorBar>
      )}

      <Row horizontal="start" className="flexer mb-2 mt-2">
        <NumberDot>1</NumberDot>
        <span style={{ fontWeight: 700 }}>Choose Transaction Source</span>
      </Row>

      <IntegrationButtonsContainer>
        <IntegrationButton
          title="HubSpot"
          active={selectedCRM === 'hubspot'}
          connected={connected}
          configurationRequired={configRequired}
          icon={HubspotIcon}
          onClick={() => setSelectedCRM('hubspot')}
        />
        <IntegrationButton
          title="Salesforce"
          disabled
          icon={SalesforceIcon}
          onClick={() => setSelectedCRM('salesforce')}
        />
        <IntegrationButton title="API" disabled icon={ApiIcon} onClick={() => setSelectedCRM('api')} />
      </IntegrationButtonsContainer>

      <ConnectionContainer>
        <Row horizontal="space-between">
          <TestConnectionBtn onClick={connect}>
            {connected ? 'RELINK BY OAUTH' : 'CREATE CONNECTION BY OAUTH'}
          </TestConnectionBtn>

          <div className="flexer">
            {!connected && <RedWarnIcon style={{ marginRight: 6 }} height={20} width={20} />}
            {connected && configRequired && <AuthorizedIcon style={{ marginRight: 6 }} height={20} width={20} />}
            {connected && !configRequired && <SuccessIcon style={{ marginRight: 6 }} height={20} width={20} />}

            <ConnectionEstablished>
              {!connected && 'Connection not established'}
              {connected && configRequired && "You're authorized"}
              {connected && !configRequired && 'Connection established'}
              {connected && <span>on {dayjs(selectedCRMIntegration?.createdAt).format('MMM YY hh:mma')}</span>}
            </ConnectionEstablished>
          </div>
        </Row>
      </ConnectionContainer>

      {connected && <IntegrationDivider integration="Hubspot" />}

      {connected && (
        <div>
          <Row horizontal="start" className="flexer mb-2">
            <NumberDot>2</NumberDot>
            <span style={{ fontWeight: 700 }}>Integrated products</span>
          </Row>
          <Row wrap="wrap" horizontal="flex-start">
            {products?.map((product) => (
              <ProductCard integrationView key={product.id}>
                <ProductCardContent>
                  <ProductCardHeading>{product.name}</ProductCardHeading>
                  <p>
                    External ID: <span>{product.provider_object_id}</span>
                  </p>
                  {configRequired ? (
                    <SelectField width="218px" height="100%" marginRight="0">
                      <Select
                        menuPlacement="auto"
                        styles={selectStyles(false)}
                        openMenuOnFocus
                        value={product?.recognition ? { label: product.recognition, value: product.recognition } : null}
                        placeholder="Select data..."
                        onChange={(newValue) => {
                          changeProduct(newValue.value, product);
                        }}
                        options={Object.values(RECOGNITION_TYPES).map((t) => ({ label: t, value: t }))}
                      />
                    </SelectField>
                  ) : (
                    <p>
                      Recognition Schedule: <span>{product.recognition}</span>
                    </p>
                  )}
                </ProductCardContent>
              </ProductCard>
            ))}
          </Row>
          <hr color="white" style={{ border: 'solid 1px #F7F7F7' }} />

          <div className="mb-2">
            <Row horizontal="start" className="flexer mt-2 mb-2">
              <NumberDot>3</NumberDot>
              <span style={{ fontWeight: 700 }}>{configRequired ? 'Configure Customers' : 'Configured customers'}</span>
            </Row>
            {configRequired && <Checkbox style={{ marginRight: '10px' }} checked />}
            <span>
              {selectedCRMIntegration?.importCounts?.customer ? (
                <>
                  <b>{selectedCRMIntegration.importCounts?.customer} customers</b> were imported from your HubSpot
                  account
                </>
              ) : (
                <>No customers found</>
              )}
            </span>
          </div>
          {/* Bullet Four Pick Final Stages */}

          <div>
            <hr color="white" style={{ border: 'solid 1px #F7F7F7' }} />
            <Row horizontal="start" className="flexer mt-2 mb-2">
              <NumberDot>4</NumberDot>
              <span style={{ fontWeight: 700 }}>
                {configRequired
                  ? 'Which stages represent deals that are closed won?'
                  : 'Stages, which represent deals that are closed won'}
              </span>
            </Row>
            <PipelinesStages
              selectedCRMIntegration={selectedCRMIntegration}
              closedWonStages={closedWonStages}
              closedWonClickHandler={closedWonClickHandler}
            />
          </div>

          {/* Bullet Five Map Seats  */}
          <div>
            <hr color="white" style={{ border: 'solid 1px #F7F7F7' }} />
            <Row horizontal="start" className="flexer mt-2 mb-2">
              <NumberDot>5</NumberDot>
              <span style={{ fontWeight: 700 }}>
                {configRequired ? 'Map seats to your data from HubSpot' : 'Mapping seats to your data from HubSpot'}
              </span>
            </Row>

            <MetaDataDropdown
              currentValue={selectedCRMIntegration?.metadata?.seatsVar}
              configurationRequired={configRequired}
              prompt={'Select Field to Map to Seats'}
              propertiesObjects={dealProperties}
              selectedValue={seatsVar}
              dispatch={dispatch}
              dispatchType={'SET_SEATS_VAR'}
            />

            {/* Bullet Six Pick Start and End date fields */}

            <hr color="white" style={{ border: 'solid 1px #F7F7F7' }} />
            <Row horizontal="start" className="flexer mt-2 mb-2">
              <NumberDot>6</NumberDot>
              <span style={{ fontWeight: 700 }}>
                {configRequired
                  ? 'Map contract start and end dates from Hubspot'
                  : 'Mapping contract start and end dates to your data from HubSpot'}
              </span>
            </Row>

            <Row horizontal="start" className="flexer mt-2 mb-2">
              <MetaDataDropdown
                currentValue={selectedCRMIntegration?.metadata?.contractStartDateField}
                prompt={'Select field to map to contract start dates'}
                configurationRequired={configRequired}
                propertiesObjects={dealProperties}
                selectedValue={contractStartDateField}
                dispatch={dispatch}
                dispatchType={'SET_CONTRACT_START_DATE'}
              />
              <MetaDataDropdown
                currentValue={selectedCRMIntegration?.metadata?.contractEndDateField}
                prompt={'Select field to map to contract end dates'}
                configurationRequired={configRequired}
                propertiesObjects={dealProperties}
                selectedValue={contractEndDateField}
                dispatch={dispatch}
                dispatchType={'SET_CONTRACT_END_DATE'}
              />
            </Row>

            {/* <Row horizontal="start" style={{ flexWrap: 'wrap' }}>
              {dealProperties?.map((dealProperty) => {
                return (
                  // We used to filter for dealProperty.type === 'number' but apparently sometimes people don't have
                  //  seat variable as a number but a string. So we'll let them pick whatever they want and convert it
                  //  into ints on the backend
                  // deal properties have a name on them, which is more like an id. You show the label, store the id
                  <CheckItem
                    title={dealProperty.label}
                    onClick={() => setSeatsVar(dealProperty.name)}
                    key={dealProperty.name}
                    active={seatsVar === dealProperty.name}
                  />
                );
              })}
              {selectedCRMIntegration?.metadata?.seatsVar && (
                <span>
                  The <b>{selectedCRMIntegration?.metadata?.seatsVar}</b> property from imported deals is being imported
                  as seats.
                </span>
              )}
            </Row> */}
          </div>

          {/* Bring this back once we have this functionality
          <div className="mt-2">
            <Row horizontal="space-between" className="mt-2" style={{ paddingTop: 20, borderTop: '1px solid #F7F7F7' }}>
              <div className="flexer">
                <BulletSix height={19} />
                &nbsp;
                <span>Synchronize data</span>
              </div>
              <div className="flexer">
                <span className="text-muted">Refresh Data</span>&nbsp;&nbsp;
                <RefreshIcon /> &nbsp;&nbsp;
                <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.1" width="1" height="24" fill="#00152E" />
                </svg>
                &nbsp;&nbsp;
                <span className="text-muted">Create Link</span>&nbsp;&nbsp;
                <AddIcon {...iconStyles} />
              </div>
            </Row>

            <table className="w-100">
              <thead>
                <tr
                  className="flexer-space-between mt-2"
                  style={{
                    padding: 10,
                    backgroundColor: '#F7F7F7',
                    color: `rgba(0, 21, 46,0.4)`,
                    fontWeight: 900,
                    fontSize: 10,
                  }}
                >
                  <td>GET THIS FIELD</td>
                  <td>FROM THE OBJECT</td>
                  <td>AND THIS FIELD</td>
                  <td>ACTIONS</td>
                </tr>
              </thead>
              <tbody>
                <tr className="flexer-space-between mt-1">
                  {range(0, 3).map((item) => (
                    <td key={item}>
                      <ReactSelect styles={inputStyles} />
                    </td>
                  ))}
                  <td>
                    <DeleteIcon height={15} width={15} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <AddIcon height={15} width={15} />
                  </td>
                </tr>
                <tr className="flexer-space-between mt-1">
                  {range(0, 3).map((item) => (
                    <td key={item}>
                      <ReactSelect styles={inputStyles} />
                    </td>
                  ))}
                  <td>
                    <DeleteIcon height={15} width={15} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <AddIcon height={15} width={15} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}

          {/* Bullet Five Map Seats  */}
        </div>
      )}
      <DottedDivider />
      <div>
        <Row horizontal="space-between" className="mt-2" style={{ marginBottom: 30, paddingBottom: 30 }}>
          {/* <Column horizontal="flex-start">
            <span className="text-muted" style={{ fontSize: 10 }}>
              Last Updated
            </span>
            <span style={{ fontSize: 14 }}>01 January 2021 at 4:30 pm</span>
          </Column> */}
          <div></div>
          {connected && configRequired && (
            <SaveConfigurationBtn onClick={saveChanges}>
              <CheckWhite /> <span>Save Configuration</span>
            </SaveConfigurationBtn>
          )}
        </Row>
      </div>

      <DottedDivider />

      <Centerer>
        <APIKeyCard />
      </Centerer>
    </GeneralSettingTabContainer>
  );
};

const dropDownStyles = {
  container: (styles) => ({ ...styles, width: '400px', marginBottom: '40px', marginRight: '30px' }),
  singleValue: (styles) => ({ ...styles, fontWeight: 'bold' }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    ...baseInputStyles.option(styles, { isSelected, isFocused }),
    width: '100%',
    marginTop: '5px',
    backgroundColor: '#F2F2F2',
    color: 'var(--primaryBlack)',
  }),
};

const MetaDataDropdown = ({
  currentValue,
  prompt,
  configurationRequired,
  propertiesObjects,
  selectedValue,
  dispatch,
  dispatchType,
}) => {
  return !configurationRequired ? (
    <>
      {currentValue ? (
        <Row horizontal="start" className="mb-2" style={{ flexWrap: 'wrap' }}>
          <CheckItem
            type="checkbox"
            title={
              propertiesObjects.find((property) => {
                return property.name === currentValue;
              })['label']
            }
            readonly={true}
            key={currentValue}
          />
        </Row>
      ) : (
        <></>
      )}
    </>
  ) : (
    <Select
      menuPlacement="auto"
      isSearchable={true}
      placeholder={prompt}
      styles={dropDownStyles}
      options={propertiesObjects?.map(({ label, name }) => {
        return { label, name };
      })}
      value={propertiesObjects?.find(({ name }) => {
        return name === selectedValue;
      })}
      onChange={(option) => {
        dispatch({ type: dispatchType, payload: option.name });
      }}
    />
  );
};

export { SourcesTab };
