import React, { useCallback, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { isEqual, omit, pick } from 'lodash';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';

import { COLORS } from 'consts/colors';
import { GL_INTEGRATION_SERVICES, INTEGRATION_SERVICES } from 'consts/integrations';
import { Button, SplitButton, SplitButtonPart, SplitButtonSeparator } from 'components/Buttons';
import { Centerer, Flexer, FlexerColumn, Row } from 'components/Core';
import { ReactComponent as CircleCheck } from 'images/circle-check-white.svg';
import { ReactComponent as DiskIcon } from 'images/device-floppy.svg';
import { ReactComponent as ArrowRightCircleWhiteIcon } from 'images/arrow-right-circle-white.svg';
import { useClickOutside } from 'utils/hooks';
import { Popover, PopoverWrapper } from 'components/Portal';
import {
  CREDIT_NOTE_JOB_STATUS,
  CREDIT_NOTE_METADATA,
  MANUAL_GL_SERVICE_NAME,
  NO_GL_SERVICE_NAME,
} from 'views/Billing/consts';
import { TooltipContainer } from 'components/Tooltip';
import { ReactComponent as FileIcon } from 'images/file-invoice.svg';
import { ExternalAllocationConfirmModal } from 'views/Billing/InvoicingScheduleModal/InvoiceScheduleSingleInvoicePanel/SingleInvoice/ExternalAllocationConfirmModal';
import { AlertFilledIcon } from 'components/Icons';
import { CircleLoader } from 'components/Loaders';

import { useCreditNotePDFPreviewModal } from './useCreditNotePDFPreviewModal';
import { CREDIT_NOTE_FIELDS } from './consts';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import {
  ExternalLinkText,
  PanelHeaderButton,
  PanelHeaderInfo,
  PanelHeaderSubText,
  PanelHeaderTitle,
  PanelHeaderTitleText,
  PanelHeaderWrapper,
} from '../panel.styles';
import { CreditNotesActions } from '../InvoicingScheduleTabsPanel/CreditNotesActions';
import { CreditNoteContext } from '../CreditNoteContext';

const Option = styled(Flexer)`
  padding: 16px 12px;
  font-size: 12px;
  font-weight: 700;
  opacity: ${({ isClickable }) => (isClickable ? 1 : 0.5)};

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  ${({ isClickable }) =>
    isClickable &&
    css`
      &:hover {
        cursor: pointer;
        background-color: var(--primaryBlack2);
      }
    `}
`;

const StyledDiskIcon = styled(DiskIcon)`
  width: 16px;
  height: 16px;

  path {
    fill: var(--primaryBlack50);
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--accentGraySecond);
`;

const StyledFileIcon = styled(FileIcon)`
  margin-left: 8px;

  path {
    fill: var(--darkGreen);
  }
`;

const ALLOCATION_MESSAGE = {
  [INTEGRATION_SERVICES.QUICKBOOKS]:
    'Save the credit note to Quickbooks and also save the payment between the credit note and the invoice to Quickbooks',
  [INTEGRATION_SERVICES.XERO]:
    'Save the credit note to Xero and also save the allocation between the credit note and the invoice to Xero',
};

export const CreditNoteHeader = ({
  selectedCreditNote,
  onSaveCreditNote,
  setSaveInExternalSystem,
  setAllocateInExternalSystem,
  isWaitingForTaxes,
  handleSendMode,
}) => {
  const { values: creditNoteFormValues, setFieldValue } = useFormikContext();

  const { invoicingService, invoicingServiceDisplayName, isScheduleDraft, currentInvoicingSchedule } = useContext(
    InvoicingScheduleContext,
  );
  const [showSaveMenu, setShowSaveMenu] = useState(false);
  const [showExternalAllocationConfirmModal, setShowExternalAllocationConfirmModal] = useState(false);

  const { openCreditNoteSendModal, CreditNoteSendModal, isCreditNoteProcessing } = useContext(CreditNoteContext);

  const { Modal: CreditNotePDFPreviewModal, openModal: openCreditNotePDFPreviewModal } = useCreditNotePDFPreviewModal({
    language: currentInvoicingSchedule?.language,
    entityId: currentInvoicingSchedule?.entity_id,
    invoiceId: selectedCreditNote?.allocationsArray?.[0]?.invoice_id,
    creditNoteId: selectedCreditNote?.id,
  });

  const handleSaveOptionSelect = useCallback(
    ({ saveInGl = false, allocateInGl = false }) => {
      setSaveInExternalSystem(saveInGl);
      setAllocateInExternalSystem(allocateInGl);
      onSaveCreditNote();
      setShowSaveMenu(false);
    },
    [onSaveCreditNote, setAllocateInExternalSystem, setSaveInExternalSystem],
  );

  const triggerRef = useClickOutside(() => setShowSaveMenu(false));

  const handleSend = useCallback(() => {
    const hasGl = GL_INTEGRATION_SERVICES.includes(invoicingService);
    const pickValidFields = (values) => pick(values, omit(Object.keys(CREDIT_NOTE_FIELDS), ['allocated_invoice']));

    if (
      (hasGl && !selectedCreditNote?.credit_note_external_url) ||
      !isEqual(pickValidFields(selectedCreditNote), pickValidFields(creditNoteFormValues))
    ) {
      handleSaveOptionSelect({ saveInGl: hasGl });
    }

    !creditNoteFormValues.send_to && setFieldValue('send_to', []);
    !creditNoteFormValues.email_subject && setFieldValue('email_subject', '');

    openCreditNoteSendModal();
  }, [
    creditNoteFormValues,
    handleSaveOptionSelect,
    invoicingService,
    openCreditNoteSendModal,
    selectedCreditNote,
    setFieldValue,
  ]);

  return (
    <PanelHeaderWrapper>
      <PanelHeaderTitle>
        <Row gap="8px" alignItems="center">
          <PanelHeaderTitleText>
            Credit Note
            {!!selectedCreditNote?.unsavedId
              ? ': Unsaved'
              : selectedCreditNote?.credit_note_number
              ? `: ${selectedCreditNote?.credit_note_number}`
              : ''}
            {!!creditNoteFormValues?.sent_at &&
              ` (Sent on ${dayjs.utc(creditNoteFormValues.sent_at).format('MMM DD, YYYY')})`}
          </PanelHeaderTitleText>

          {creditNoteFormValues?.metadata?.[CREDIT_NOTE_METADATA.JOB_STATUS] === CREDIT_NOTE_JOB_STATUS.FAILED && (
            <AlertFilledIcon />
          )}

          {isCreditNoteProcessing && (
            <>
              <PanelHeaderInfo data-cy="credit-note-view__processing_info">Will finish processing soon</PanelHeaderInfo>
              <CircleLoader width={20} height={20} />
            </>
          )}
        </Row>

        <Row gap="8px">
          {invoicingServiceDisplayName && invoicingService !== MANUAL_GL_SERVICE_NAME && (
            <PanelHeaderSubText>
              {selectedCreditNote?.credit_note_external_url ? (
                <>
                  See credit note in{' '}
                  <ExternalLinkText href={selectedCreditNote?.credit_note_external_url} target="_blank">
                    {invoicingServiceDisplayName}
                  </ExternalLinkText>
                </>
              ) : (
                `No credit note in ${invoicingServiceDisplayName}`
              )}
            </PanelHeaderSubText>
          )}

          <CreditNotesActions headerView creditNote={selectedCreditNote} isSelected={false} />

          {!selectedCreditNote?.unsavedId && selectedCreditNote?.id && (
            <PanelHeaderButton onClick={() => openCreditNotePDFPreviewModal()}>
              PDF <StyledFileIcon />
            </PanelHeaderButton>
          )}

          <PopoverWrapper>
            {isScheduleDraft ? (
              <TooltipContainer fontSize="12px" width={160} toolTipContent={`← First save the schedule`} hideArrow>
                <Button
                  color={COLORS.GREEN}
                  border
                  filled
                  active
                  onClick={() => setShowSaveMenu(true)}
                  data-cy="credit-note-view__save-button"
                  disabled={!!selectedCreditNote?.voided_at || isScheduleDraft}
                >
                  <Centerer gap="4px">
                    Save
                    <CircleCheck />
                  </Centerer>
                </Button>
              </TooltipContainer>
            ) : [NO_GL_SERVICE_NAME, MANUAL_GL_SERVICE_NAME].includes(invoicingService) ? (
              <SplitButton>
                <SplitButtonPart
                  onClick={() => handleSaveOptionSelect({ saveInGl: false })}
                  data-cy="credit-note-view__save-button"
                  disabled={!!selectedCreditNote?.voided_at || isScheduleDraft || isCreditNoteProcessing}
                >
                  Save
                  <SplitButtonSeparator />
                </SplitButtonPart>
                <SplitButtonPart
                  onClick={handleSend}
                  data-cy="credit-note-view__send-button"
                  disabled={!!selectedCreditNote?.voided_at || isScheduleDraft || isCreditNoteProcessing}
                >
                  & {selectedCreditNote?.sent_at ? ' Resend' : ' Send'}
                  <ArrowRightCircleWhiteIcon width={16} height={16} style={{ marginLeft: 8 }} />
                </SplitButtonPart>
              </SplitButton>
            ) : (
              <SplitButton>
                <SplitButtonPart
                  onClick={() => {
                    if (selectedCreditNote?.allocated_externally) return;

                    if (selectedCreditNote?.credit_note_external_url) {
                      handleSaveOptionSelect({ saveInGl: true });
                    } else {
                      setShowSaveMenu(true);
                    }
                  }}
                  disabled={!!selectedCreditNote?.allocated_externally || isCreditNoteProcessing}
                  hasTooltip
                  data-cy="credit-note-view__save-button"
                >
                  <TooltipContainer
                    tooltipWrapperStyles={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px 14px',
                      whiteSpace: 'nowrap',
                    }}
                    fontSize="12px"
                    width={160}
                    toolTipContent={`Save the credit note but do not allocate it on ${invoicingServiceDisplayName} yet`}
                    hideArrow
                  >
                    Save <SplitButtonSeparator />
                  </TooltipContainer>
                </SplitButtonPart>
                <SplitButtonPart
                  onClick={() =>
                    !selectedCreditNote?.allocated_externally &&
                    !!selectedCreditNote?.credit_note_external_url &&
                    setShowExternalAllocationConfirmModal(true)
                  }
                  disabled={
                    !!selectedCreditNote?.allocated_externally ||
                    !selectedCreditNote?.credit_note_external_url ||
                    isCreditNoteProcessing
                  }
                  hasTooltip
                  data-cy="credit-note-view__save-allocate-button"
                >
                  <TooltipContainer
                    tooltipWrapperStyles={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px 14px',
                      whiteSpace: 'nowrap',
                    }}
                    fontSize="12px"
                    width={320}
                    toolTipContent={
                      !selectedCreditNote?.credit_note_external_url
                        ? `Save the credit note on ${invoicingServiceDisplayName} first`
                        : `${
                            ALLOCATION_MESSAGE[invoicingService] ??
                            `Allocate the credit note to the specified invoice on ${invoicingServiceDisplayName}`
                          } (this cannot be undone)`
                    }
                    hideArrow
                  >
                    Allocate
                    <SplitButtonSeparator />
                  </TooltipContainer>
                </SplitButtonPart>
                <SplitButtonPart
                  onClick={handleSend}
                  data-cy="credit-note-view__send-button"
                  disabled={!!selectedCreditNote?.voided_at || isScheduleDraft || isCreditNoteProcessing}
                  hasTooltip
                >
                  <TooltipContainer
                    tooltipWrapperStyles={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px 14px',
                      whiteSpace: 'nowrap',
                    }}
                    fontSize="12px"
                    width={150}
                    toolTipContent={`We will also save the credit note to ${invoicingServiceDisplayName} (no allocation)`}
                    hideArrow
                  >
                    {selectedCreditNote?.sent_at ? ' Resend' : ' Send'}
                    <ArrowRightCircleWhiteIcon width={16} height={16} style={{ marginLeft: 8 }} />
                  </TooltipContainer>
                </SplitButtonPart>
              </SplitButton>
            )}
            {showSaveMenu && (
              <Popover
                YOffset={45}
                XOffset={-10}
                ref={triggerRef}
                width="200px"
                data-cy="credit-note-view__save-options-popover"
              >
                <FlexerColumn>
                  <TooltipContainer
                    isVisible={!!selectedCreditNote?.allocated_externally}
                    toolTipContent={`This credit note is already allocated in ${invoicingServiceDisplayName}`}
                  >
                    <Option
                      isClickable={!selectedCreditNote.allocated_externally}
                      onClick={() =>
                        !selectedCreditNote?.allocated_externally ? handleSaveOptionSelect({ saveInGl: true }) : null
                      }
                      data-cy="credit-note-view__save-option--with-gl"
                    >
                      <StyledDiskIcon />
                      In {invoicingServiceDisplayName} & Subscript
                    </Option>
                  </TooltipContainer>

                  <Line />

                  <Option
                    isClickable
                    onClick={() => handleSaveOptionSelect({ saveInGl: false })}
                    data-cy="credit-note-view__save-option--without-gl"
                  >
                    <StyledDiskIcon />
                    Save only in Subscript
                  </Option>
                </FlexerColumn>
              </Popover>
            )}
          </PopoverWrapper>
        </Row>
      </PanelHeaderTitle>
      {showExternalAllocationConfirmModal && (
        <ExternalAllocationConfirmModal
          invoicingServiceDisplayName={invoicingServiceDisplayName}
          onClose={() => setShowExternalAllocationConfirmModal(false)}
          onConfirm={() => handleSaveOptionSelect({ saveInGl: true, allocateInGl: true })}
          creditNoteWaitingForTaxes={isWaitingForTaxes ? selectedCreditNote : null}
        />
      )}

      <CreditNotePDFPreviewModal />
      <CreditNoteSendModal
        onSaveCreditNote={({ isSend, isTestSend } = {}) => {
          handleSendMode({ isSend, isTestSend });
          handleSaveOptionSelect({ saveInGl: GL_INTEGRATION_SERVICES.includes(invoicingService) });
        }}
      />
    </PanelHeaderWrapper>
  );
};
