import React, { useContext, useMemo } from 'react';
import { AppContext } from 'AppContext';
import { useContractsAPI } from 'api/contracts';
import { TooltipContainer } from 'components/Tooltip';
import { getUniqueAttachments, useContractFilesModal } from 'views/Contracts';
import { Tab, TabCounter, TabText } from './styles';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';

export const ContractsButton = () => {
  const { orgId } = useContext(AppContext);
  const { includedTransactions, customerDetails } = useContext(InvoicingScheduleContext);
  const transactionIds = useMemo(() => includedTransactions.map((transaction) => transaction.id), [
    includedTransactions,
  ]);
  const { ContractFilesModal, openContractFilesModal } = useContractFilesModal({
    transactionIds,
    customerId: customerDetails?.id,
    readOnly: true,
  });
  const { data: contracts } = useContractsAPI({
    orgId,
    scopes: ['attachment_data'],
    limit: 100,
    transactionIds,
  });

  const attachments = useMemo(() => getUniqueAttachments({ contracts }), [contracts]);

  const contractsModalDisabled = !(attachments?.length > 0);
  const handleClickContractTab = () => {
    if (contractsModalDisabled) {
      return;
    }
    openContractFilesModal();
  };

  return (
    <>
      <TooltipContainer
        toolTipContent="Please add the contract file directly in the transaction page"
        isVisible={contractsModalDisabled}
      >
        <Tab
          onClick={handleClickContractTab}
          data-cy="billing__invoice-schedule-modal__sidebar-tab--contracts"
          disabled={contractsModalDisabled}
        >
          <TabCounter>{attachments.length}</TabCounter>
          <TabText>Contract</TabText>
        </Tab>
      </TooltipContainer>

      <ContractFilesModal />
    </>
  );
};
