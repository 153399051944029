import React, { useCallback, useContext, useMemo } from 'react';

import { AppContext } from 'AppContext';
import { useInvoiceMemoTemplatesAPI } from 'api/invoiceMemoTemplates/hooks';
import { SelectDropdownButton } from 'components/Buttons';

const formatMemoTemplatesForDropdown = (templates) =>
  templates.reduce((total, template) => {
    total[template.id] = template.name;
    return total;
  }, {});

export const MemoTemplateDropdownButton = ({
  isLoading,
  templates,
  onSelect,
  buttonText,
  isDisabled,
  name,
  actions,
  ...props
}) => {
  if (isLoading) return null;

  return (
    <SelectDropdownButton
      name={name}
      showSelectedDirectly
      options={formatMemoTemplatesForDropdown(templates)}
      selected={buttonText}
      XOffset={-150}
      disabled={isDisabled}
      fontSize="12px"
      onSelect={onSelect}
      actions={actions}
      {...props}
    />
  );
};

export const useMemoTemplateDropdownButton = ({ templates: _templates, ...props }) => {
  const { orgId } = useContext(AppContext);

  const { data = [], isLoading } = useInvoiceMemoTemplatesAPI({ orgId, autoFetch: !_templates });
  const templates = useMemo(() => _templates ?? data, [_templates, data]);

  const DropdownButton = useCallback(
    () => <MemoTemplateDropdownButton isLoading={isLoading} templates={templates} {...props} />,
    [isLoading, props, templates],
  );

  return {
    MemoTemplateDropdownButton: DropdownButton,
    templates,
  };
};
