import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { AppContext } from 'AppContext';
import { humanize, pluralize } from 'utils/stringUtils';
import { groupBy } from 'utils/arrayUtils';
import { SelectDropdownButton } from 'components/Buttons';
import { StyledNumberInput } from 'components/Controls/FormikCustomInput/FormikCustomInput';
import { CentererVertical, FlexerColumn, Spacer } from 'components/Core';
import { ArrowNarrowLeftIcon, PlusSignIcon, TrashIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { CircleLoader } from 'components/Loaders';
import { RECOGNITION_TYPES } from 'consts/global';
import {
  INVOICING_FREQUENCIES,
  PAYMENT_OPTIONS,
  INVOICE_DATE_RELATIVE_OPTIONS,
  INVOICE_DATE_REFERENCE_OPTIONS,
  GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY,
  GROUP_TRANSACTIONS_FOR_SCHEDULES_BY,
  GROUP_TRANSACTIONS_FOR_SCHEDULES_KEY_TO_LABEL,
} from 'views/Billing/consts';
import { DEFAULT_AVAILABLE_TAGS } from 'consts/billing';
import { usePastAndFutureInvoiceOptions } from 'views/Billing/InvoicingScheduleModal/InvoicingScheduleTabsPanel/PastAndFutureInvoiceOptions';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { getIntegrationDisplayName } from 'models/integration';
import { BillingEntitySelector } from 'views/Billing/Common/BillingEntitySelector';
import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from 'views/Billing/BillingDynamicFieldsTooltip';
import { useGetAvailableTags } from 'api/billing';
import { ContentEditableInput } from 'components/Controls/FormikCustomInput/ContentEditableInput';
import { convertToPlainText, templateToHTMLString } from 'utils/htmlUtils';
import {
  HeaderText,
  BodyWrapper,
  EmptyWrapper,
  SettingsRow,
  SettingItemWithText,
  SettingItem,
  SettingItemText,
  InvoicingScheduleLine,
  InvoicingScheduleBadge,
  CustomerName,
  InvoicingScheduleSummary,
  BulkActionsButtonsContainer,
  BulkCreateButton,
  BulkDismissButton,
  SettingHeader,
} from './BulkCreateSettings.styles';
import {
  buildNewInvoicingSchedules,
  areSelectedTransactionsEligibleForBulkCreation,
  getSuggestedFrequency,
} from './utils';
import { useInvoiceMemoTemplatesAPI } from 'api/invoiceMemoTemplates/hooks';

export const BulkCreateSettings = ({
  transactionsSelected,
  billingInvoiceDefaults,
  transactionsWithProducts,
  onBulkCreateClick,
  onBulkDismissClick,
  waitingOnBulkCreate,
}) => {
  const { orgId, integrations, orgConfigs, entities } = useContext(AppContext);
  const { billingSenderDefaults } = orgConfigs;

  const glIntegrations = integrations?.filter((integration) => integration.type === INTEGRATION_TYPES.GL);

  const [paymentOptions, setPaymentOptions] = useState(billingInvoiceDefaults?.payment_options ?? []);
  const [frequency, setFrequency] = useState(billingInvoiceDefaults?.frequency ?? INVOICING_FREQUENCIES.MONTHLY);
  const [daysToPay, setDaysToPay] = useState(billingInvoiceDefaults.days_to_pay);
  const [autoSend, setAutoSend] = useState(false);
  const [isSchedulesLoading, setIsSchedulesLoading] = useState(false);
  const [invoicingSchedules, setInvoicingSchedules] = useState([]);
  const [invoiceDateRelative, setInvoiceDateRelative] = useState(INVOICE_DATE_RELATIVE_OPTIONS.EQUAL_TO);
  const [invoiceDateReference, setInvoiceDateReference] = useState(
    INVOICE_DATE_REFERENCE_OPTIONS.TRANSACTION_START_DATE,
  );
  const [daysFromDate, setDaysFromDate] = useState();
  const [memo, setMemo] = useState('');
  const [defaultMemoTemplateId, setDefaultMemoTemplateId] = useState();
  const [groupTransactionsBy, setGroupTransactionsBy] = useState(
    billingInvoiceDefaults?.group_transactions_by ??
      GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY[GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.CUSTOMER],
  );
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(glIntegrations?.[0]?.id);
  const [entityId, setEntityId] = useState(billingSenderDefaults.default_entity_id?.toString() ?? entities[0]?.id);

  const [showDaysToPayInput, setShowDaysToPayInput] = useState(false);

  const {
    transactionMetadataTags = [],
    customerMetadataTags = [],
    transactionCustomerMetadataTags = [],
  } = useGetAvailableTags({ orgId });

  const handleChangeMemo = (content) => {
    setMemo(convertToPlainText(content));
  };

  const { data: invoiceMemoTemplates = [] } = useInvoiceMemoTemplatesAPI({ orgId });

  useEffect(() => {
    for (const template of invoiceMemoTemplates) {
      if ((template.name = 'default')) {
        setMemo(template.content);
        setDefaultMemoTemplateId(template.id);
        break;
      }
    }
  }, [invoiceMemoTemplates]);

  const remainingPaymentOptions = useMemo(
    () => Object.values(PAYMENT_OPTIONS).filter((po) => !paymentOptions?.includes(po)),
    [paymentOptions],
  );

  const addPaymentOption = useCallback(
    (index) => setPaymentOptions([...paymentOptions, remainingPaymentOptions[index]]),
    [remainingPaymentOptions, paymentOptions, setPaymentOptions],
  );

  const removePaymentOption = useCallback(
    (paymentOption) => setPaymentOptions(paymentOptions.filter((po) => po !== paymentOption)),
    [setPaymentOptions, paymentOptions],
  );

  const frequencyOptions = useMemo(
    () =>
      Object.values(INVOICING_FREQUENCIES).reduce((acc, curr) => {
        acc[curr] = humanize(curr);
        return acc;
      }, {}),
    [],
  );

  const invoiceDateRelativeOptions = groupBy(Object.values(INVOICE_DATE_RELATIVE_OPTIONS));

  const invoiceDateReferenceOptions = groupBy(Object.values(INVOICE_DATE_REFERENCE_OPTIONS));

  const integrationOptions = useMemo(
    () =>
      (glIntegrations ?? []).reduce((acc, integration) => {
        acc[integration.id] = getIntegrationDisplayName(integration);
        return acc;
      }, {}),
    [glIntegrations],
  );

  const transactionById = groupBy(transactionsWithProducts, 'id', { uniqueness: true });

  const {
    PastAndFutureInvoiceOptions,
    onlyFutureInvoices,
    allowPastInvoices,
    hasInvoicesThatStartInThePast,
  } = usePastAndFutureInvoiceOptions({
    includedTransactions: Array.from(transactionsSelected).reduce((total, id) => {
      if (transactionById[id]) total.push(transactionById[id]);
      return total;
    }, []),
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsSchedulesLoading(true);
      const result = await buildNewInvoicingSchedules({
        transactionIds: transactionsSelected,
        transactionsWithProducts,
        frequency,
        orgId,
        groupTransactionsBy,
        onlyFutureInvoices,
        allowPastInvoices,
      });
      setInvoicingSchedules(result);
      setIsSchedulesLoading(false);
    };

    fetchData();
  }, [
    groupTransactionsBy,
    transactionsWithProducts,
    transactionsSelected,
    frequency,
    orgId,
    onlyFutureInvoices,
    allowPastInvoices,
  ]);

  const canBulkCreate = useMemo(
    () =>
      areSelectedTransactionsEligibleForBulkCreation({
        transactionIds: transactionsSelected,
        transactionsWithProducts,
      }),
    [transactionsSelected, transactionsWithProducts],
  );

  const allTransactionsAreLinearOrImmediate = Array.from(transactionsSelected).every((transactionId) =>
    [RECOGNITION_TYPES.linear, RECOGNITION_TYPES.linearNotRecurring, RECOGNITION_TYPES.immediate].includes(
      transactionById?.[transactionId]?.recognition,
    ),
  );

  useEffect(() => {
    const suggestedFrequency = getSuggestedFrequency({
      transactionIds: transactionsSelected,
      transactionsWithProducts,
    });
    if (suggestedFrequency) {
      setFrequency(suggestedFrequency);
    }
  }, [transactionsSelected, transactionsWithProducts, setFrequency]);

  return (
    <>
      {isSchedulesLoading ? (
        <div className="w-100 h-100 flexer" style={{ position: 'relative' }}>
          <CircleLoader isAbsolute thickness="4px" />
        </div>
      ) : (
        <>
          <HeaderText>Settings & preview of new invoicing schedules</HeaderText>
          <BodyWrapper data-cy="bulk-create-settings">
            {isEmpty(transactionsSelected) ? (
              <EmptyWrapper>
                <ArrowNarrowLeftIcon />
                First, select some transactions to create invoicing schedules and invoices
              </EmptyWrapper>
            ) : (
              <>
                <SettingsRow>
                  <SettingItemWithText data-cy="bulk-create-settings__selected-billing-entity">
                    <b>Sender organization:</b>
                    <BillingEntitySelector
                      name="selected-billing-entity"
                      value={entityId}
                      setValue={setEntityId}
                      isButton
                      fontWeight={700}
                      fontSize="12px"
                      padding="4px 8px"
                      data-cy="bulk-create-settings__selected-billing-entity-selector"
                    />
                  </SettingItemWithText>
                </SettingsRow>

                <SettingsRow>
                  <SettingItemWithText data-cy="bulk-create-settings__selected-integration">
                    <b>General Ledger:</b>
                    <SelectDropdownButton
                      name="selected-integration"
                      selected={selectedIntegrationId}
                      options={integrationOptions}
                      onSelect={setSelectedIntegrationId}
                      fontWeight={700}
                      fontSize="12px"
                      padding="4px 8px"
                      data-cy="bulk-create-settings__selected-integration-selector"
                    />
                  </SettingItemWithText>
                </SettingsRow>

                <SettingsRow data-cy="bulk-create-settings__payment-options">
                  {paymentOptions.map((option, index) => (
                    <SettingItem
                      key={option}
                      withMarginRight={index < paymentOptions.length - 1}
                      onClick={() => removePaymentOption(option)}
                      data-cy={`bulk-create-settings__payment-option--${option}`}
                    >
                      {humanize(option)}
                      <TrashIcon size="16px" fill="var(--primaryBlack30)" />
                    </SettingItem>
                  ))}
                  {remainingPaymentOptions.length > 0 && (
                    <SelectDropdownButton
                      name="payment-option"
                      showSelectedDirectly
                      selected={
                        <SettingItemText>
                          <PlusSignIcon size="16px" fill="var(--primaryBlack30)" />
                          Payment opt.
                        </SettingItemText>
                      }
                      options={remainingPaymentOptions}
                      onSelect={addPaymentOption}
                      data-cy="bulk-create-settings__add-payment-option"
                    />
                  )}
                </SettingsRow>
                <SettingsRow>
                  <SettingItemWithText>
                    {showDaysToPayInput ? (
                      <StyledNumberInput
                        blueVer
                        value={daysToPay}
                        onChange={setDaysToPay}
                        inputWidth={80}
                        min={0}
                        fontSize="12px"
                        data-cy="bulk-create-settings__days-to-pay-input"
                      />
                    ) : (
                      <SettingItem
                        data-cy="bulk-create-settings__edit-days-to-pay"
                        onClick={() => setShowDaysToPayInput(true)}
                      >
                        {daysToPay}
                      </SettingItem>
                    )}
                    <Spacer width="8px" />
                    <span>Terms (Net)</span>
                  </SettingItemWithText>
                  <SettingItemWithText>
                    <SettingItem
                      data-cy="bulk-create-settings__auto-send-toggle"
                      onClick={() => setAutoSend(!autoSend)}
                    >
                      {autoSend ? 'Enabled' : 'Disabled'}
                    </SettingItem>
                    <Spacer width="8px" />
                    <span>Auto-Send</span>
                  </SettingItemWithText>
                  <SettingItemWithText data-cy="bulk-create-settings__frequency">
                    <SelectDropdownButton
                      name="frequency"
                      selected={frequency}
                      options={frequencyOptions}
                      onSelect={setFrequency}
                      fontWeight={700}
                      fontSize="12px"
                      padding="4px 8px"
                      data-cy="bulk-create-settings__frequency-selector"
                    />
                    <span>billing frequency</span>
                  </SettingItemWithText>
                </SettingsRow>
                {/* If every transaction selected is linear and immediate */}
                {allTransactionsAreLinearOrImmediate && (
                  <SettingsRow>
                    <SettingItemWithText>
                      <span>Set invoice dates</span>
                      {[
                        INVOICE_DATE_RELATIVE_OPTIONS.N_DAYS_AFTER,
                        INVOICE_DATE_RELATIVE_OPTIONS.N_DAYS_BEFORE,
                      ].includes(invoiceDateRelative) && (
                        <>
                          <Spacer width="8px" />
                          <StyledNumberInput
                            blueVer
                            value={daysFromDate}
                            onChange={setDaysFromDate}
                            inputWidth={30}
                            min={0}
                            fontSize="12px"
                            data-cy="bulk-create-settings__days-from-date-input"
                            placeholder="N days"
                          />
                        </>
                      )}
                      <SelectDropdownButton
                        name="invoice_date_relative"
                        selected={invoiceDateRelative}
                        options={invoiceDateRelativeOptions}
                        onSelect={setInvoiceDateRelative}
                        fontWeight={700}
                        fontSize="12px"
                        padding="4px 8px"
                        data-cy="bulk-create-settings__invoice_date_relative-selector"
                      />
                      <SelectDropdownButton
                        name="invoice_date_reference"
                        selected={invoiceDateReference}
                        options={invoiceDateReferenceOptions}
                        onSelect={setInvoiceDateReference}
                        fontWeight={700}
                        fontSize="12px"
                        padding="4px 8px"
                        data-cy="bulk-create-settings__invoice_date_reference-selector"
                      />
                    </SettingItemWithText>
                  </SettingsRow>
                )}
                <SettingsRow>
                  <SettingItemWithText>
                    <span>Group transactions by</span>
                    <SelectDropdownButton
                      name="invoicing_schedule_group_transactions_by"
                      selected={groupTransactionsBy}
                      options={GROUP_TRANSACTIONS_FOR_SCHEDULES_KEY_TO_LABEL}
                      onSelect={setGroupTransactionsBy}
                      fontWeight={700}
                      fontSize="12px"
                      padding="4px 8px"
                      data-cy="bulk-create-settings__invoicing_schedule_group_transactions_by-selector"
                    />
                  </SettingItemWithText>
                </SettingsRow>
                {hasInvoicesThatStartInThePast && <SettingsRow>{PastAndFutureInvoiceOptions}</SettingsRow>}
                {canBulkCreate && (
                  <>
                    <FlexerColumn marginBottom="16px">
                      <CentererVertical gap="8px" marginBottom="8px">
                        <SettingHeader>Memo</SettingHeader>
                        <BillingDynamicFieldsTooltip
                          tags={{
                            [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                            [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                            [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                            [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_CUSTOMER_METADATA_TAGS]: transactionCustomerMetadataTags,
                          }}
                          XOffset={300}
                        />
                      </CentererVertical>
                      <ContentEditableInput
                        minHeight="200px"
                        type="HTMLEdit"
                        HTMLString={templateToHTMLString({
                          text: memo,
                          availableTags: [
                            ...DEFAULT_AVAILABLE_TAGS,
                            ...transactionMetadataTags,
                            ...customerMetadataTags,
                            ...transactionCustomerMetadataTags,
                          ],
                        })}
                        availableTags={[
                          ...DEFAULT_AVAILABLE_TAGS,
                          ...transactionMetadataTags,
                          ...customerMetadataTags,
                          ...transactionCustomerMetadataTags,
                        ]}
                        onChange={(value) => handleChangeMemo(value)}
                      />
                    </FlexerColumn>
                    <FlexerColumn>
                      {invoicingSchedules.map((invoicingSchedule, index) => (
                        <InvoicingScheduleLine key={index}>
                          <InvoicingScheduleBadge>{index + 1}</InvoicingScheduleBadge>
                          <CustomerName>{invoicingSchedule.customerName}</CustomerName>
                          <InvoicingScheduleSummary>
                            ({invoicingSchedule.description}: {invoicingSchedule.hasEventBased ? 'up to ' : ''}
                            {pluralize(invoicingSchedule.invoicesCount, 'invoice')})
                          </InvoicingScheduleSummary>
                        </InvoicingScheduleLine>
                      ))}
                    </FlexerColumn>
                  </>
                )}
              </>
            )}
            <Spacer height="16px" />
            {transactionsSelected.size > 0 && (
              <BulkActionsButtonsContainer gap="8px">
                <span>Actions:</span>
                <TooltipContainer
                  isVisible={!canBulkCreate}
                  toolTipContent="You can only bulk create from transactions with products"
                >
                  <BulkCreateButton
                    fontWeight="700"
                    onClick={() =>
                      onBulkCreateClick({
                        paymentOptions,
                        daysToPay,
                        autoSend,
                        frequency,
                        invoiceDateRelative: allTransactionsAreLinearOrImmediate ? invoiceDateRelative : null,
                        invoiceDateReference: allTransactionsAreLinearOrImmediate ? invoiceDateReference : null,
                        daysFromDate:
                          invoiceDateRelative === INVOICE_DATE_RELATIVE_OPTIONS.EQUAL_TO ? null : daysFromDate,
                        groupTransactionsBy,
                        allowPastInvoices,
                        onlyFutureInvoices,
                        integrationId: selectedIntegrationId,
                        entityId,
                        memo,
                        defaultMemoTemplateId,
                      })
                    }
                    disabled={!canBulkCreate || waitingOnBulkCreate}
                    data-cy="bulk-create-settings__bulk-create-button"
                  >
                    <span>{waitingOnBulkCreate ? 'Bulk Creating...' : 'Bulk Create'}</span>
                  </BulkCreateButton>
                </TooltipContainer>
                <BulkDismissButton fontWeight="700" onClick={onBulkDismissClick}>
                  <span>Dismiss</span>
                </BulkDismissButton>
              </BulkActionsButtonsContainer>
            )}
          </BodyWrapper>
        </>
      )}
    </>
  );
};
