import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc); // without this, time comparisons get messed up
dayjs.extend(customParseFormat);

export const sortDates = (datesList, format) => {
  return datesList.sort((a, b) => (dayjs(a, format) < dayjs(b, format) ? -1 : 1));
};

export const addDays = ({ date, days }) => {
  date.setDate(date.getDate() + days);
  return date;
};

export const reFormatDatesList = (datesList, originalFormat, newFormat) => {
  return datesList.map((date) => dayjs(date, originalFormat).format(newFormat));
};

export const getStartAndEndOfPeriodFromQuarterString = ({ quarterDate, quarters, quartersYearOffset }) => {
  const date = periodKeyToDayJS({
    periodKey: quarterDate,
    periodType: 'quarter',
    quarters,
    quartersYearOffset,
  });

  return {
    startOfQuarterPeriod: date.subtract(2, 'month').format('MMM YYYY'),
    endOfQuarterPeriod: `${date.format('MMM YYYY')}`,
  };
};

export const getStartAndEndOfYearPeriod = ({ yearDate, quarters }) => {
  const date = getDateEndOfYear({ date: yearDate, quarters });

  return {
    startOfYearPeriod: date.subtract(11, 'month').format('MMM YYYY'),
    endOfYearPeriod: date.format('MMM YYYY'),
  };
};

export const reFormatDate = (date, originalFormat, newFormat) => {
  return dayjs(date, originalFormat).format(newFormat);
};

export const customFormatParseDate = (date, originalFormat, strict = false) => {
  return dayjs(date, originalFormat, strict);
};

export const formatDateForGraph = (date) => dayjs(date).format('MMM YYYY');

export const forEachMonth = ({ startDate, endDate, callback, inclusive = false }) => {
  for (
    let [month, year] = [startDate.getMonth() + 1, startDate.getFullYear()];
    year < endDate.getFullYear() ||
    (year === endDate.getFullYear() && (inclusive ? month <= endDate.getMonth() + 1 : month < endDate.getMonth() + 1));
    [month, year] = month < 12 ? [month + 1, year] : [1, year + 1]
  ) {
    callback({ year, month });
  }
};

export const generateMonths = ({ startDate, endDate, inclusive = false }) => {
  const months = [];

  forEachMonth({
    startDate,
    endDate,
    inclusive,
    callback: ({ year, month }) => {
      months.push(formatYearMonth({ year, month }));
    },
  });

  return months;
};

/**
 * Takes a date in UTC format (like 2021-03-10T00:00:00.000Z) and creates a local date
 *  out of it using only the year, month, and date part (ignoring hour and minute).
 *  This makes it so that the above date appears as March 10, no matter what timezone
 * @param {Date} date
 */
export const formatDateForDatepicker = (date, { showTime } = {}) => {
  // WARNING: if you change this, please be sure to thoroughly check all date pickers while using
  //  the developer tools features to make your location Sydney and San Francisco
  const dayjsDate = dayjs.utc(date);

  const year = dayjsDate.get('year');
  const month = dayjsDate.get('month');
  const day = dayjsDate.get('date');

  if (showTime) {
    const hour = dayjsDate.get('hour');
    const minute = dayjsDate.get('minute');

    return new Date(year, month, day, hour, minute);
  }

  return new Date(year, month, day);
};

/**
 * Takes a date in local time that date picker has picked, and extracts year-month-date from it
 *  Then inserts that into a dayjs object in UTC mode.
 * @param {Date} date
 */
export const updateDateFromDatePicker = (date, { includeTime } = {}) => {
  // WARNING: if you change this, please be sure to thoroughly check all date pickers while using
  //  the developer tools features to make your location Sydney and San Francisco
  if (!date) return null;

  const year = date.getFullYear();
  const month = date.getMonth() + 1; // for some nonsensical reason, getMonth() is 0 indexed but nothing else is
  const day = date.getDate();

  let dateString;
  let dateFormat;
  if (includeTime) {
    const hour = date.getHours();
    const minute = date.getMinutes();
    dateString = `${year}-${month}-${day} ${hour}:${minute}`;
    dateFormat = 'YYYY-M-D h:m';
  } else {
    dateString = `${year}-${month}-${day}`;
    dateFormat = 'YYYY-M-D';
  }

  return dayjs.utc(dateString, dateFormat).toDate();
};

/**
 * CustomDatePicker picks the first day of the month and due to timezone, sometimes the month
 *  selected isn't correct (it could be the month before). Add 15 days to make sure.
 * @param {Date} date
 */
export const extractMonthFromPickedDate = (date) => dayjs(date).add(15, 'days').format('YYYY-MM');

/**
 * Convert a string YYYY-MM to a Date object. Here we choose the day 15 to avoid timezone issues.
 * @param {Date} date
 */
export const monthStringToDate = (monthString) => dayjs(`${monthString}-15`).toDate();

export const formatYearMonth = ({ year, month }) => {
  const paddedMonth = month < 10 ? `0${month}` : month;
  return `${year}-${paddedMonth}`;
};

export const getCurrentMonthKey = () => dayjs.utc().format('YYYY-MM');

export const getLastMonthDate = (date) => dayjs.utc(date).subtract(1, 'month').toDate();

/**
 * verifies if given date is between start and end date
 */
export const isBetween = ({ date, startDate, endDate, granularity }) => {
  return !date.isBefore(startDate, granularity) && !date.isAfter(endDate, granularity);
};

// Disclaimer: Do NOT use to sort a large array. If you need to sort a large array by date,
// use something more efficient.
export const compareDate = (x, y) => (x && y && dayjs(x).isAfter(dayjs(y)) ? 1 : -1);

export const safeParseDate = (date, fallback) => (dayjs(date).isValid() ? dayjs(date).toDate() : fallback);

// returns minimum between two dates
export const minDate = (a, b) => {
  if (a === undefined && b === undefined) return undefined;
  if (a === undefined) return b;
  if (b === undefined) return a;

  if (dayjs(a).isBefore(dayjs(b))) return a;
  return b;
};

// returns max between two dates
export const maxDate = (a, b) => {
  if (a === undefined && b === undefined) return undefined;
  if (a === undefined) return b;
  if (b === undefined) return a;

  if (dayjs(a).isAfter(dayjs(b))) return a;
  return b;
};

export const periodKeyToDayJS = ({ periodKey, periodType, quarters, quartersYearOffset = 0 }) => {
  switch (periodType) {
    case 'quarter':
      const [qYear, quarter] = periodKey.split('-');
      let quarterYear = parseInt(qYear);
      const quarterIndex = (quarter ? parseInt(quarter.replace('Q', '')) : 0) - 1;
      const endOfQuarter = quarters[quarterIndex];
      if (quarterIndex === 3 && ['Jan', 'Feb'].includes(quarters[quarterIndex])) {
        quarterYear += 1;
      } else {
        const monthEndOfYear = dayjs(`${quarters[quarters.length - 1]}`, 'MMM').month() + 1;
        const monthEndOfQuarter = dayjs(`${endOfQuarter}`, 'MMM').month() + 1;
        // If month is after end of year month it means quarter key belongs to next year
        // So we are subtracting 1 year to get the date
        // That does not apply for end of year on Jan and Fev as they are [lastYear]-4 (previous condition)
        // Example for [4, 7,10, 1] for year starting on Apr 2020
        // 2020-04 -> 2020-Q1, 2020-07 -> 2020-Q2, 2020-10 -> 2020-Q3, 2021-01 -> 2020-Q4
        // Example for ABL [9, 12, 3, 6] for year starting on Sep 2020
        // 2020-09 -> 2020-Q1, 2020-12 -> 2020-Q2, 2021-03 -> 2020-Q3, 2021-06 -> 2020-Q4
        if (![1, 2].includes(monthEndOfYear) && monthEndOfQuarter > monthEndOfYear) {
          quarterYear -= 1;
        }
      }
      return dayjs.utc(`${quarterYear - quartersYearOffset}-${endOfQuarter}`, 'YYYY-MMM');
    case 'year':
      let year = parseInt(periodKey);
      const endOfYearMonth = quarters[quarters.length - 1];
      if (endOfYearMonth === 'Jan' || endOfYearMonth === 'Feb') {
        year += 1;
      }
      return dayjs.utc(`${year - quartersYearOffset}-${endOfYearMonth}`, 'YYYY-MMM');
    default:
      return dayjs.utc(periodKey);
  }
};

export const convertQuartersConfig = (quarters = []) => {
  return quarters.map((quarter) => dayjs(`${dayjs().year()}-${quarter}-01`, 'YYYY-MMM-DD').month() + 1);
};

// Same as backend/dateUtils.js
export const getYearAndQuarter = ({ quarterEndMonths, quartersYearOffset = 0, year, month }) => {
  const endOfYear = quarterEndMonths[quarterEndMonths.length - 1];
  let index = 0;
  for (const quarterMonth of quarterEndMonths) {
    if (index === 3 && endOfYear === 1 && [11, 12, 1].includes(month)) {
      // This only applies if end of year is Jan
      return {
        year: year - ([1].includes(month) ? 1 : 0) + quartersYearOffset,
        quarter: index + 1,
      };
    } else if (index === 3 && endOfYear === 2 && [12, 1, 2].includes(month)) {
      // This only applies if end of year is Feb
      return {
        year: year - ([1, 2].includes(month) ? 1 : 0) + quartersYearOffset,
        quarter: index + 1,
      };
    } else if (month > quarterMonth - 3 && month <= quarterMonth) {
      // If month is after end of year month it means quarter key belongs to next year
      // So we are adding 1 year to set the key
      // That does not apply for end of year on Jan and Fev as they are [lastYear]-4 (previous condition)
      // Example for [4, 7,10, 1] for year starting on Apr 2020
      // 2020-04 -> 2020-Q1, 2020-07 -> 2020-Q2, 2020-10 -> 2020-Q3, 2021-01 -> 2020-Q4
      // Example for ABL [9, 12, 3, 6] for year starting on Sep 2020
      // 2020-09 -> 2020-Q1, 2020-12 -> 2020-Q2, 2021-03 -> 2020-Q3, 2021-06 -> 2020-Q4
      const yearDelta = ![1, 2].includes(endOfYear) && month > endOfYear ? 1 : 0;
      return {
        year: year + yearDelta + quartersYearOffset,
        quarter: index + 1,
      };
    }
    index++;
  }
  return {};
};

const getDateEndOfQuarter = ({ date, quarters }) => {
  const quarterEndMonths = convertQuartersConfig(quarters);
  const month = dayjs(date).month() + 1;
  const year = dayjs(date).year();

  const endOfYear = quarterEndMonths[quarterEndMonths.length - 1];
  let index = 0;
  for (const quarterMonth of quarterEndMonths) {
    if (index === 3 && endOfYear === 1 && [11, 12, 1].includes(month)) {
      // This only applies if end of year is Jan
      return dayjs(`${year + ([11, 12].includes(month) ? 1 : 0)}-${quarterMonth}`, 'YYYY-M').startOf('month');
    } else if (index === 3 && endOfYear === 2 && [12, 1, 2].includes(month)) {
      // This only applies if end of year is Feb
      return dayjs(`${year + ([12].includes(month) ? 1 : 0)}-${quarterMonth}`, 'YYYY-M').startOf('month');
    } else if (month > quarterMonth - 3 && month <= quarterMonth) {
      return dayjs(`${year}-${quarterMonth}`, 'YYYY-M').startOf('month');
    }
    index++;
  }
  return dayjs();
};

const getDateEndOfYear = ({ date, quarters }) => {
  const quarterEndMonths = convertQuartersConfig(quarters);
  const month = dayjs(date).month() + 1;
  const year = dayjs(date).year();

  const endOfYear = quarterEndMonths[quarterEndMonths.length - 1];
  return dayjs(`${year + (month > endOfYear ? 1 : 0)}-${endOfYear}`, 'YYYY-M').startOf('month');
};

export const getEndOfPeriodDayJS = ({ date, periodType, quarters }) => {
  switch (periodType) {
    case 'quarter':
      return getDateEndOfQuarter({
        date,
        quarters,
      });
    case 'year':
      return getDateEndOfYear({
        date,
        quarters,
      });
    default:
      return dayjs(date).startOf('month');
  }
};

/**
 * Returns the previous month's dateKey
 * @param {*} {dateKey} string in format 'YYYY-MM'
 */
// Same as backend/dateUtils.js
export const getPreviousMonth = (dateKey) => {
  const dateParts = dateKey.split('-');
  const year = dateParts[0];
  const month = dateParts[1];

  const yearNumeric = parseInt(year);
  const monthNumeric = parseInt(month);

  //if the previousMonth is 0, then we go to Dec (which is 12)
  let previousYear = yearNumeric;
  let previousMonth = monthNumeric - 1;
  if (monthNumeric - 1 === 0) {
    previousMonth = 12;
    previousYear -= 1;
  }

  return formatYearMonth({
    year: previousYear,
    month: previousMonth,
  });
};

/**
 * Returns the previous qurarter's dateKey
 * @param {*} {dateKey} string in format 'YYYY-Q{number}'
 */
export const getPreviousQuarter = (dateKey) => {
  const dateParts = dateKey.split('-');
  const year = dateParts[0];
  const quarter = dateParts[1];

  const yearNumeric = parseInt(year);

  //if the previousMonth is 0, then we go to Dec (which is 12)
  let previousYear = yearNumeric;
  let previousQuarter;

  switch (quarter) {
    case 'Q1':
      previousQuarter = 'Q4';
      previousYear -= 1;
      break;
    case 'Q2':
      previousQuarter = 'Q1';
      break;
    case 'Q3':
      previousQuarter = 'Q2';
      break;
    case 'Q4':
      previousQuarter = 'Q3';
      break;
    default:
  }

  return `${previousYear}-${previousQuarter}`;
};

/**
 * Returns the previous year's dateKey
 * @param {*} {dateKey} string in format YYYY-Y
 */
export const getPreviousYear = (dateKey) => {
  const dateParts = dateKey.split('-');
  const year = dateParts[0];

  const previousYear = parseInt(year) - 1;

  return `${previousYear}-Y`;
};

/**
 * Returns the previous date (Year, quarter or month) dateKey
 * @param {*} {dateKey} string in format Month: MMM YYYY, Quarter: YYYY-Q{number}, Year: YYYY-Y
 */
export const getPreviousDateKey = (date) => {
  if (date?.includes('-Q')) {
    return getPreviousQuarter(date);
  } else if (date?.includes('-Y')) {
    return getPreviousYear(date);
  } else if (date) {
    return reFormatDate(getPreviousMonth(reFormatDate(date, 'MMM YYYY', 'YYYY-MM')), 'YYYY-MM', 'MMM YYYY');
  }
};

export const guessDateFormat = (date) => {
  const dateOnly = date.split(/[\sT]/)[0];
  const guessedDate = customFormatParseDate(
    dateOnly,
    [
      'YYYY-MM-DD',
      'YYYY-M-D',
      'YYYY/MM/DD',
      'YYYY/M/D',
      'YYYY.MM.DD',
      'YYYY.M.D',
      'MM-DD-YYYY',
      'M-D-YYYY',
      'MM/DD/YYYY',
      'M/D/YYYY',
      'MM.DD.YYYY',
      'M.D.YYYY',
      'DD-MM-YYYY',
      'D-M-YYYY',
      'DD/MM/YYYY',
      'D/M/YYYY',
      'DD.MM.YYYY',
      'D.M.YYYY',

      // Short year format
      'M/D/YY',
      'M-D-YY',
      'M.D.YY',
      'D/M/YY',
      'D-M-YY',
      'D.M.YY',
    ],
    true,
  );

  if (!guessedDate.isValid()) return undefined;

  return guessedDate.format('YYYY-MM-DD');
};

export const isValidDate = (obj) =>
  (typeof obj === 'object' && dayjs.utc(obj).isValid()) || (typeof obj === 'string' && !!guessDateFormat(obj));
