import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToasts } from 'components/Toasts';
import {
  getImports,
  upsertImport as _upsertImport,
  deleteImport as _deleteImport,
  bulkUpsertImport as _bulkUpsertImport,
} from './requests';

export const CACHE_KEY = 'imports';

export const useImportsAPI = ({ orgId, autoFetch = true, enableToasts = true, filters }) => {
  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const { data, error, isLoading, refetch, isFetching } = useQuery(
    [CACHE_KEY, orgId, filters],
    () => getImports({ orgId, params: filters }),
    {
      enabled: autoFetch && !!orgId,
    },
  );

  const queryClient = useQueryClient();

  const upsertImport = useMutation(({ integrationId, data }) => _upsertImport({ orgId, integrationId, data }), {
    onSuccess: () => {
      pushToast('Successfully updated import!', 'success');
    },
    onError: (err) => {
      pushError(err, 'Failed to update import.');
    },
    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const deleteImport = useMutation(({ importId }) => _deleteImport({ orgId, importId }), {
    onSuccess: () => {
      pushToast('Successfully deleted import!', 'success');
    },
    onError: (err) => {
      pushError(err, 'Failed to delete import.');
    },
    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const bulkUpsertImport = useMutation(({ data }) => _bulkUpsertImport({ orgId, data }), {
    onSuccess: () => {
      pushToast('Successfully updated imports!', 'success');
    },
    onError: (err) => {
      pushError(err, 'Failed to update imports.');
    },
    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      upsertImport,
      deleteImport,
      bulkUpsertImport,
    },
  };
};
