/*eslint no-unused-vars: "off"*/
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { isEmpty, isUndefined, omitBy } from 'lodash';
import { useToasts } from 'components/Toasts';
import { useDelegatedMutationPolling, useQueryPolling } from 'api/jobHooks.helper';
import { useMetadataOptionsAPI } from 'api/metadata';
import { CACHE_KEY as TRANSACTIONS_CACHE_KEY } from 'api/transactions/hooks';
import { CACHE_KEY as IMPORTS_CACHE_KEY } from 'api/imports/hooks';
import { CACHE_KEY as EXTERNAL_UPDATES_KEY } from 'api/externalUpdates/hooks';
import { CACHE_KEY as PRODUCTS_CACHE_KEY } from 'api/products/hooks';
import { ORGANIZATION_HELPERS_CACHE_KEY } from 'api/organizations/hooks';
import { METADATA_FILTER_TYPES } from 'shared/Filters/MetadataFilter';
import { SuccessToastWithUndo } from 'shared/Toasts';
import { CREDIT_NOTE_METADATA } from 'views/Billing/consts';
import {
  getInvoicingStatistics,
  getInvoicingSchedules,
  getInvoicingSchedule,
  deleteInvoicingSchedule,
  createInvoicingSchedule,
  updateInvoicingSchedule,
  getInvoices,
  getInvoice,
  getInvoicePdf,
  bulkDownloadPdf,
  updateInvoice,
  deleteInvoice,
  getBillingEmailTemplates,
  updateBillingEmailTemplate,
  createBillingEmailTemplate,
  deleteBillingEmailTemplate,
  generateInvoicingToDo,
  getBillingEmailReminders,
  updateBillingEmailReminder,
  createBillingEmailReminder,
  deleteBillingEmailReminder,
  getInvoingScheduleCheckoutData,
  getBillingAgingReport,
  bulkCreateInvoicingSchedule,
  dismissInvoicingSchedules,
  bulkUpdateInvoices,
  bulkSendInvoices as _bulkSendInvoices,
  createInvoice as _createInvoice,
  bulkUpdateUnsentInvoices as _bulkUpdateUnsentInvoices,
  bulkDetachInvoices as _bulkDetachInvoices,
  bulkDeleteInvoices as _bulkDeleteInvoices,
  bulkDeleteBillingEmailReminder,
  dismissBillingEmailReminder,
  addExternalInvoices,
  chargeInvoice as _chargeInvoice,
  searchResources,
  regenerateInvoicingSchedule as _regenerateInvoicingSchedule,
  bulkResetInvoicesMemo,
  bulkResetInvoicesSecondaryMemo,
  regenerateInvoicePDF as _regenerateInvoicePDF,
  getCreditNotes,
  createCreditNote,
  updateCreditNote,
  deleteCreditNote,
  voidCreditNote as _voidCreditNote,
  creditNoteAllocate,
  getCreditNoteById,
  getCreditNotePdf,
  creditNoteExternalUpdate,
  regenerateDraftInvoices,
  regenerateAutoChargePaymentKey as _regenerateAutoChargePaymentKey,
  resyncInvoicingSchedule as _resyncInvoicingSchedule,
  applyScheduleInvoiceChanges as _applyScheduleInvoiceChanges,
  getBillingInconsistencies,
  getRevRecExceptions,
  dismissTransactionFromBillingInconsistencies as _dismissTransactionFromBillingInconsistencies,
  dismissTransactionFromRevRecExceptions as _dismissTransactionFromRevRecExceptions,
  getCreditNoteTitles,
  getInvoicesStatusCount,
  sendCreditNote as _sendCreditNote,
  regenerateCreditNotePdf as _regenerateCreditNotePdf,
  enableBillingAiReminders,
  bulkUpdateBillingEmailReminder,
} from './requests';

export const CACHE_KEY = 'billing';

export const useInvoicingStatisticsAPI = ({ orgId, autoFetch = true, endDate, startDate }) => {
  const dataKey = [CACHE_KEY, orgId, 'invoicingStatistics', endDate, startDate];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => getInvoicingStatistics({ orgId, params: { endDate, startDate } }),
    { enabled: autoFetch },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useInvoicingSchedulesAPI = ({
  orgId,
  scopes = ['transactions', 'invoices'],
  invoiceScopes,
  groupByCustomer = true,
  searchQuery,
  autoFetch = true,
  enableToasts = true,
}) => {
  const dataKey = [CACHE_KEY, orgId, 'invoicingSchedules', scopes, invoiceScopes, groupByCustomer, searchQuery];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () =>
      getInvoicingSchedules({ orgId, params: { scopes, groupByCustomer, invoiceScopes, filters: { searchQuery } } }),
    { enabled: autoFetch },
  );

  const removeInvoicingSchedule = useMutation(({ id }) => deleteInvoicingSchedule({ orgId, invoicingScheduleId: id }), {
    onError: (err, _newInvoicingSchedule, context) => {
      pushError(err, 'Failed to remove invoicing schedule.');
    },

    onSuccess: () => {
      pushToast('Successfully removed invoicing schedule!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      queryClient.invalidateQueries(EXTERNAL_UPDATES_KEY);
      queryClient.invalidateQueries(ORGANIZATION_HELPERS_CACHE_KEY);
    },
  });

  const addInvoicingSchedule = useMutation(
    ({ data, params }) => createInvoicingSchedule({ orgId, body: data, params }),
    {
      onMutate: async ({ data: newInvoicingSchedule }) => {},

      onError: (err, _newInvoicingSchedule, context) => {
        pushError(err, 'Failed to create invoicing schedule.');
      },

      onSuccess: () => {
        pushToast('Successfully created invoicing schedule!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
        queryClient.invalidateQueries(ORGANIZATION_HELPERS_CACHE_KEY);
      },
    },
  );

  const bulkAddInvoicingSchedule = useMutation((data) => bulkCreateInvoicingSchedule({ orgId, body: data }), {
    onMutate: async ({ data: newInvoicingSchedules }) => {},

    onError: (err, _newInvoicingSchedules, context) => {
      pushError(err, 'Failed to bulk create invoicing schedules.');
    },

    onSuccess: () => {
      pushToast('Successfully bulk created invoicing schedules!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      queryClient.invalidateQueries(ORGANIZATION_HELPERS_CACHE_KEY);
    },
  });

  const editInvoicingSchedule = useMutation(
    ({ id, data }) => updateInvoicingSchedule({ orgId, invoicingScheduleId: id, body: data }),
    {
      onMutate: async ({ id, data: invoicingSchedule }) => {},

      onError: (err, _newInvoicingSchedule, context) => {
        pushError(err, 'Failed to update Invoicing Schedule.');
      },

      onSuccess: (_data, variables) => {
        !variables.disableToast && pushToast('Successfully updated Invoicing Schedule!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const dismissTransactions = useMutation(
    ({ transactionIds }) => dismissInvoicingSchedules({ orgId, transactionIds }),
    {
      onMutate: async () => {},

      onError: (err, _, context) => {
        pushError(err, 'Failed to dismissed transaction(s)');
      },

      onSuccess: () => {
        pushToast('Successfully dismissed transaction(s)', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      },
    },
  );

  const addExternalInvoicesToSchedule = useMutation(({ data }) => addExternalInvoices({ orgId, data }), {
    onMutate: async () => {},
    onError: (err, _, context) => {
      pushError(err, 'Failed to add external invoice to schedule');
    },
    onSuccess: () => {
      pushToast(`Successfully added external invoice${data?.length ? 's' : ''} to schedule`, 'success');
    },
    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const regenerateInvoicingSchedule = useMutation(
    ({ id, data }) => _regenerateInvoicingSchedule({ orgId, invoicingScheduleId: id, body: data }),
    {
      onMutate: async () => {},
      onError: (err, _newInvoicingSchedule, context) => {
        pushError(err, 'Failed to update Invoicing Schedule.');
      },
      onSuccess: (data, vars) => {
        if (!vars?.data?.dryRun) {
          pushToast('Successfully updated Invoicing Schedule!', 'success');
        }
      },
      onSettled: (data, err, vars) => {
        if (!vars?.data?.dryRun) {
          queryClient.invalidateQueries(CACHE_KEY);
          queryClient.invalidateQueries(dataKey);
          queryClient.invalidateQueries(EXTERNAL_UPDATES_KEY);
        }
      },
    },
  );

  const regenerateInvoicingScheduleDraftInvoices = useMutation(
    ({ data }) => regenerateDraftInvoices({ orgId, body: data }),
    {
      onMutate: async () => {},
      onError: (err, _newInvoicingSchedule, context) => {
        pushError(err, 'Failed to regenerate invoices.');
      },
      onSuccess: () => {
        pushToast('Successfully regenerated invoices for invoicing schedule!', 'success');
      },
      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries(EXTERNAL_UPDATES_KEY);
      },
    },
  );

  const applyScheduleInvoiceChanges = useMutation(({ data }) => _applyScheduleInvoiceChanges({ orgId, body: data }), {
    onMutate: async () => {},
    onError: (err) => {
      pushError(err, 'Failed to update invoices..');
    },
    onSuccess: () => {
      pushToast('Successfully updated invoices for invoicing schedule!', 'success');
    },
    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
      queryClient.invalidateQueries(EXTERNAL_UPDATES_KEY);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      removeInvoicingSchedule,
      addInvoicingSchedule,
      editInvoicingSchedule,
      bulkAddInvoicingSchedule,
      dismissTransactions,
      addExternalInvoicesToSchedule,
      regenerateInvoicingSchedule,
      regenerateInvoicingScheduleDraftInvoices,
      applyScheduleInvoiceChanges,
    },
  };
};

export const useInvoicingScheduleAPI = ({
  orgId,
  invoicingScheduleId,
  scopes = ['transactions', 'invoices'],
  autoFetch = true,
}) => {
  const dataKey = [CACHE_KEY, orgId, 'invoicingSchedules', invoicingScheduleId, scopes];
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () => getInvoicingSchedule({ orgId, invoicingScheduleId, params: { scopes } }),
    { enabled: !!invoicingScheduleId && autoFetch },
  );

  const queryClient = useQueryClient();

  const regenerateAutoChargePaymentKey = useMutation(
    ({ data }) => _regenerateAutoChargePaymentKey({ orgId, invoicingScheduleId, body: data }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const resyncInvoicingSchedule = useMutation(
    ({ data }) => _resyncInvoicingSchedule({ orgId, invoicingScheduleId, body: data }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      regenerateAutoChargePaymentKey: regenerateAutoChargePaymentKey.mutateAsync,
      resyncInvoicingSchedule: resyncInvoicingSchedule.mutateAsync,
    },
  };
};

export const useInvoicesAPI = ({
  orgId,
  invoiceStatus,
  startDate,
  endDate,
  autoFetch = true,
  enableToasts = true,
  includeReminders,
  includeCount,
  invoicingScheduleIds,
  invoiceIds,
  customerId,
  notVoided = false,
  searchQuery,
  page,
  limit,
  orderBy,
  metadataFilter,
  includeChildren,
}) => {
  const dataKey = [
    CACHE_KEY,
    orgId,
    'invoices',
    invoiceStatus,
    startDate,
    endDate,
    invoicingScheduleIds,
    invoiceIds,
    customerId,
    notVoided,
    searchQuery,
    page,
    limit,
    orderBy,
    metadataFilter,
    includeChildren,
  ];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () =>
      getInvoices({
        orgId,
        params: {
          invoiceStatus,
          startDate,
          endDate,
          notVoided,
          includeReminders,
          invoicingScheduleIds,
          invoiceIds,
          customerId,
          includeCount,
          searchQuery,
          page,
          limit,
          orderBy,
          metadataFilter,
          includeChildren,
        },
      }),
    { enabled: autoFetch },
  );

  const bulkSend = useMutation(
    ({ invoiceIds, memo, emailSubject, emailBody }) =>
      _bulkSendInvoices({ orgId, body: { invoiceIds, memo, emailSubject, emailBody }, params: { testMode: true } }),
    {
      onError: (err) => {
        pushError(err, 'Failed to send invoice(s). Please contact support.');
      },

      onSuccess: () => {
        pushToast('Started processing Invoices...', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  return {
    data: data || { data: [], metadata: {} },
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      bulkSend,
      // refetches all invoices without being affected by table filters
      invalidateAllInvoices: () => queryClient.invalidateQueries([CACHE_KEY, orgId, 'invoices']),
    },
  };
};

export const useInvoicesStatusCountAPI = ({ orgId, autoFetch = true, params }) => {
  const dataKey = [CACHE_KEY, orgId, params, 'invoices', 'count'];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () => getInvoicesStatusCount({ orgId, params }),
    { enabled: autoFetch },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useInvoiceAPI = ({
  orgId,
  invoiceId,
  autoFetch = true,
  enableToasts = true,
  scopes = [
    'invoice_items',
    'customer',
    'external_invoice',
    'reminders',
    'email_interactions',
    'sender',
    'auto_charge_data',
    'shipping_address',
    'invoice_serial_number',
    'invoice_pdf',
    'custom_fields',
    'receipt',
    'credit_notes',
  ],
}) => {
  const dataKey = [CACHE_KEY, orgId, 'invoice', invoiceId, scopes];
  const [wasProcessingInvoiceId, setWasProcessingInvoiceId] = useState(null);

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQueryPolling({
    key: dataKey,
    fetchFn: () =>
      getInvoice({
        orgId,
        invoiceId,
        params: { scopes },
      }),
    fetchWithJobIdFn: (jobId) => getInvoice({ orgId, invoiceId, params: { jobId, scopes } }),
    queryOptions: {
      enabled: autoFetch && !!orgId && !!invoiceId,
      onSettled: (data) => {
        if (data?.state === 'completed') {
          if (wasProcessingInvoiceId && wasProcessingInvoiceId === invoiceId) {
            // Invalidates the currently opened invoicing schedule if it was ever in processing
            queryClient.invalidateQueries([CACHE_KEY, orgId, 'invoicingSchedules']);
            setWasProcessingInvoiceId(null);

            if (data?.data?.metadata?.job_status === 'failed') {
              wrappedPushError(
                { response: {} },
                `${
                  data?.data?.metadata?.last_save_invoice_error
                    ? 'Failed to update invoice: ' + data?.data?.metadata?.last_save_invoice_error
                    : 'Failed to update invoice.'
                }`,
                10000,
              );
            } else {
              wrappedPushToast('Successfully updated invoice!', 'success');
            }

            // Invalidates the invoices tables
            queryClient.invalidateQueries([CACHE_KEY, orgId, 'invoices']);

            queryClient.invalidateQueries(IMPORTS_CACHE_KEY);
            queryClient.invalidateQueries(PRODUCTS_CACHE_KEY);
            queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
          }
        } else if (!wasProcessingInvoiceId) {
          // Was ever in processing?
          setWasProcessingInvoiceId(invoiceId);
        }
      },
    },
    alwaysReturnData: true,
  });

  const editInvoice = useMutation(
    ({ id, data, ...rest }) => updateInvoice({ orgId, invoiceId: id, body: data, params: rest }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update invoice.', 10000);
      },

      onSuccess: (_, variables) => {
        pushToast(
          // Sync operations
          variables?.voidInvoice ||
            variables?.markAsSent ||
            variables?.markAsPaid ||
            variables?.resetMemo ||
            variables?.resetEmailSubject ||
            variables?.resetEmailBody ||
            variables?.resetSecondaryMemo
            ? 'Successfully updated invoice!'
            : // Async operations
            !!variables?.sendInvoice
            ? 'Queued to send... Invoice will be sent shortly!'
            : 'Queued to save... Invoice will be saved shortly!',
          'success',
        );
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries(IMPORTS_CACHE_KEY);
        queryClient.invalidateQueries(PRODUCTS_CACHE_KEY);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      },
    },
  );

  const createInvoice = useMutation(({ id, data, ...rest }) => _createInvoice({ orgId, body: data, params: rest }), {
    onError: (err) => {
      pushError(err, 'Failed to create invoice.', 8000);
    },

    onSuccess: () => {
      pushToast('Successfully created invoice!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
      queryClient.invalidateQueries(IMPORTS_CACHE_KEY);
      queryClient.invalidateQueries(PRODUCTS_CACHE_KEY);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
    },
  });

  const bulkEditInvoice = useMutation(({ data }) => bulkUpdateInvoices({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to bulk update invoices.', 8000);
    },

    onSuccess: () => {
      pushToast('Successfully bulk updated invoices!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const bulkResetMemo = useMutation(({ data }) => bulkResetInvoicesMemo({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to bulk change template.');
    },

    onSuccess: () => {
      pushToast('Successfully bulk changed template!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const bulkResetSecondaryMemo = useMutation(({ data }) => bulkResetInvoicesSecondaryMemo({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to bulk change secondary memo template.');
    },

    onSuccess: () => {
      pushToast('Successfully bulk changed secondary memo template!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const bulkUpdateUnsentInvoices = useMutation(({ data }) => _bulkUpdateUnsentInvoices({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to update details of existing unsent invoices');
    },

    onSuccess: () => {
      pushToast('Successfully updated details of existing unsent invoices!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const bulkDetachInvoices = useMutation(
    ({ data, ...rest }) => _bulkDetachInvoices({ orgId, body: data, params: rest }),
    {
      onError: (err) => {
        pushError(err, 'Failed to bulk detach invoices');
      },

      onSuccess: () => {
        pushToast('Successfully detached invoices!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const bulkDeleteInvoices = useMutation(
    ({ data, ...rest }) => _bulkDeleteInvoices({ orgId, body: data, params: rest }),
    {
      onError: (err) => {
        pushError(err, 'Failed to bulk delete invoices');
      },

      onSuccess: () => {
        pushToast('Successfully deleted invoices!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const removeInvoice = useMutation(({ id, params }) => deleteInvoice({ orgId, invoiceId: id, params }), {
    onError: (err, context) => {
      pushError(err, 'Failed to remove invoice.');
    },

    onSuccess: () => {
      pushToast('Successfully removed invoice!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const chargeInvoice = useMutation(({ id, params }) => _chargeInvoice({ orgId, invoiceId: id, params }), {
    onError: (err, context) => {
      pushError(err, 'Failed to charge invoice.');
    },

    onSuccess: () => {
      pushToast('Successfully processing invoice payment!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const regenerateInvoicePDF = useMutation(
    ({ id, data }) => _regenerateInvoicePDF({ orgId, invoiceId: id, body: data }),
    {
      onError: (err, context) => {
        pushError(err, 'Failed to regenerate invoice PDF.');
      },

      onSuccess: () => {
        pushToast('Successfully regenerated invoice PDF!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching: isFetching && !['queued_up', 'processing'].includes(data?.metadata?.job_status),
    operations: {
      refetch,
      createInvoice: createInvoice.mutateAsync,
      editInvoice: editInvoice.mutateAsync,
      bulkEditInvoices: bulkEditInvoice.mutateAsync,
      bulkResetMemo: bulkResetMemo.mutateAsync,
      removeInvoice: removeInvoice.mutateAsync,
      bulkDetachInvoices: bulkDetachInvoices.mutateAsync,
      bulkDeleteInvoices: bulkDeleteInvoices.mutateAsync,
      bulkUpdateUnsentInvoices: bulkUpdateUnsentInvoices.mutateAsync,
      chargeInvoice: chargeInvoice.mutateAsync,
      regenerateInvoicePDF: regenerateInvoicePDF.mutateAsync,
      bulkResetSecondaryMemo: bulkResetSecondaryMemo.mutateAsync,
    },
  };
};

export const useInvoicePdfAPI = ({ orgId, invoiceId, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, orgId, 'invoices', invoiceId, 'pdf'];
  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();

  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () =>
      getInvoicePdf({
        orgId,
        invoiceId,
      }),
    { enabled: autoFetch && !!orgId && !!invoiceId },
  );

  const bulkDownloadInvoicesPdf = useDelegatedMutationPolling({
    mutationFn: async ({ invoiceIds, ...rest }) => bulkDownloadPdf({ orgId, invoiceIds, params: rest }),
    mutationOptions: {
      onProgress: () =>
        pushToast(
          `Bulk downloading invoices is in progress, but may take a while. Please check back in a few minutes.`,
          'success',
        ),
      onFailed: () => {
        throw new Error(`We did not finish downloading invoices. Please try again later.`);
      },
      onMaxAttempts: () => {
        Sentry.captureMessage(`Downloading invoices is taking long to complete for org ${orgId}`, 'warning');
      },
      onError: (err, request, context) => {
        pushError(err, `Failed to download invoices. ${err?.response?.data?.error ?? err.message ?? err}`);
      },

      onSuccess: (response) => {
        pushToast('Successfully downloaded invoices!', 'success');
        return response;
      },
    },
  });

  return {
    data,
    operations: {
      bulkDownloadInvoicesPdf: bulkDownloadInvoicesPdf.mutateAsync,
    },
    error,
    isLoading,
    isFetching,
  };
};

export const useBillingEmailTemplatesAPI = ({
  orgId,
  aiTemplatesOnly = false,
  autoFetch = true,
  enableToasts = true,
}) => {
  const dataKey = [CACHE_KEY, orgId, aiTemplatesOnly, 'billing_email_templates'];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () =>
      getBillingEmailTemplates({
        orgId,
        params: { createIfEmpty: true, aiTemplatesOnly },
      }),
    { enabled: autoFetch && !!orgId },
  );

  const editBillingEmailTemplate = useMutation(
    ({ id, data, ...rest }) => updateBillingEmailTemplate({ orgId, id, body: data, params: rest }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update billing email template.');
      },

      onSuccess: () => {
        pushToast('Successfully updated template!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const addBillingEmailTemplate = useMutation(
    ({ data, ...rest }) => createBillingEmailTemplate({ orgId, body: data, params: rest }),
    {
      onError: (err) => {
        pushError(err, 'Failed to create billing email template.');
      },

      onSuccess: () => {
        pushToast('Successfully created template!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const removeBillingEmailTemplate = useMutation(({ id }) => deleteBillingEmailTemplate({ orgId, id }), {
    onError: (err, _newInvoicingSchedule, context) => {
      pushError(err, 'Failed to remove template.');
    },

    onSuccess: () => {
      pushToast('Successfully removed billing email template!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      editBillingEmailTemplate: editBillingEmailTemplate.mutateAsync,
      addBillingEmailTemplate: addBillingEmailTemplate.mutateAsync,
      removeBillingEmailTemplate: removeBillingEmailTemplate.mutateAsync,
    },
  };
};

export const useBillingRemindersAPI = ({ orgId, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, orgId, 'billing_email_reminders'];

  const {
    pushToast: wrappedPushToast,
    pushError: wrappedPushError,
    pushCustomToast: wrappedPushCustomToast,
  } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const pushCustomToast = (...args) => {
    if (enableToasts) wrappedPushCustomToast(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () =>
      getBillingEmailReminders({
        orgId,
      }),
    { enabled: autoFetch && !!orgId },
  );

  const editBillingEmailReminder = useMutation(
    ({ id, data, ...rest }) => updateBillingEmailReminder({ orgId, id, body: data, params: rest }),
    {
      onError: (err, variables) => {
        pushError(
          err,
          `Failed to ${variables?.send ? 'send' : 'update'} billing email reminder ${
            variables?.isPreview ? 'to your Email' : ''
          }.`,
        );
      },

      onSuccess: (_, variables) => {
        pushToast(
          `Successfully ${variables?.send ? 'sent' : 'updated'} reminder ${
            variables?.isPreview ? 'to your Email' : ''
          }!`,
          'success',
        );
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const addBillingEmailReminder = useMutation(
    ({ data, ...rest }) => createBillingEmailReminder({ orgId, body: data, params: rest }),
    {
      onError: (err) => {
        pushError(err, 'Failed to create billing email reminder.');
      },

      onSuccess: () => {
        pushToast('Successfully created reminder!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const undoRemoveReminder = useMutation(
    ({ emailReminderObject }) => createBillingEmailReminder({ orgId, body: { data: emailReminderObject } }), // recreate the email reminder if 'undo'
    {
      onError: () => {
        pushToast('Failed to undo skip email reminder.', 'error');
      },

      onSuccess: () => {
        pushToast('Successfully undid skip email reminder!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const removeBillingEmailReminder = useMutation(
    ({ emailReminderObject, toDoMessage }) => deleteBillingEmailReminder({ orgId, id: emailReminderObject.id }),
    {
      onError: (err, _newInvoicingSchedule, context) => {
        pushError(err, 'Failed to remove reminder.');
      },

      onSuccess: (res, variables, context) => {
        const { emailReminderObject, toDoMessage } = variables;
        pushCustomToast({
          CustomToast: (
            <SuccessToastWithUndo
              message={`You've successfully skipped ${toDoMessage}`}
              onAction={() => undoRemoveReminder.mutate({ emailReminderObject })}
              dataCyLabel="email-reminders"
            />
          ),
          timeout: 10000,
        });
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const bulkRemoveReminders = useMutation(({ data }) => bulkDeleteBillingEmailReminder({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to bulk skip reminders.');
    },

    onSuccess: () => {
      pushToast('Successfully bulk skiped reminders!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const bulkUpdateReminders = useMutation(({ data }) => bulkUpdateBillingEmailReminder({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to bulk update reminders.');
    },

    onSuccess: () => {
      pushToast('Successfully bulk updated reminders!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const enableBillingAIReminders = useMutation(() => enableBillingAiReminders({ orgId }), {
    onError: (err) => {
      pushError(err, 'Failed to update reminders.');
    },

    onSuccess: () => {
      pushToast('Reminders successfully updated!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const dismissReminder = useMutation(({ id }) => dismissBillingEmailReminder({ orgId, id }), {
    onError: (err) => {
      pushError(err, 'Failed to dismiss reminders.');
    },

    onSuccess: () => {
      pushToast('Successfully dismissed reminder!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      bulkRemoveReminders: bulkRemoveReminders.mutateAsync,
      enableBillingAIReminders: enableBillingAIReminders.mutateAsync,
      editBillingEmailReminder: editBillingEmailReminder.mutateAsync,
      addBillingEmailReminder: addBillingEmailReminder.mutateAsync,
      bulkUpdateReminders: bulkUpdateReminders.mutateAsync,
      removeBillingEmailReminder: removeBillingEmailReminder.mutateAsync,
      dismissReminder: dismissReminder.mutateAsync,
    },
  };
};

export const useInvoicingScheduleCheckoutAPI = ({ id, paymentId, paymentKey, invoiceId, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, id, 'checkout', invoiceId];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => getInvoingScheduleCheckoutData({ id, paymentId, paymentKey, invoiceId }),
    {
      enabled: autoFetch,
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useBillingAgingReportAPI = ({ orgId, asOf = new Date(), autoFetch = true }) => {
  const dataKey = [CACHE_KEY, orgId, 'billingAgingReport'];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => getBillingAgingReport({ orgId, params: { asOf } }),
    {
      enabled: autoFetch,
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useExternalInvoiceAPI = ({
  orgId,
  externalCustomerId,
  excludeExistingImports,
  autoFetch = true,
  integrationId,
}) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    [CACHE_KEY, externalCustomerId, orgId],
    () =>
      searchResources({
        orgId,
        params: { searchQuery: externalCustomerId, resource: 'invoice', excludeExistingImports, integrationId },
      }),
    {
      enabled: autoFetch && !!orgId && !!externalCustomerId,
      refetchOnWindowFocus: 'always',
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useCreditNotesAPI = ({
  orgId,
  scopes,
  page,
  limit,
  orderBy,
  invoiceIds,
  invoicingScheduleIds,
  customerIds,
  searchQuery,
  autoFetch = true,
  enableToasts = true,
}) => {
  const params = omitBy(
    {
      scopes,
      orderBy,
      'pagination[page]': page,
      'pagination[limit]': limit,
      'filters[customerIds]': customerIds,
      'filters[invoiceIds]': invoiceIds,
      'filters[invoicingScheduleIds]': invoicingScheduleIds,
      'filters[searchQuery]': searchQuery,
    },
    isUndefined,
  );
  const dataKey = [CACHE_KEY, orgId, 'creditNotes', JSON.stringify(params)];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () => getCreditNotes({ orgId, params }),
    { enabled: autoFetch },
  );

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const addCreditNote = useMutation(({ data }) => createCreditNote({ orgId, body: data }), {
    onMutate: async ({ data: newCreditNote }) => {},
    onError: (err, _newCreditNote, context) => {
      pushError(err, 'Failed to create Credit Note.');
    },

    onSuccess: () => {
      pushToast('Successfully created Credit Note!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
    },
  });

  const editCreditNote = useMutation(({ id, data }) => updateCreditNote({ orgId, creditNoteId: id, body: data }), {
    onMutate: async ({ id, data: CreditNote }) => {},

    onError: (err, _newCreditNote, context) => {
      pushError(err, 'Failed to update Credit Note on Subcript.');
    },

    onSuccess: (_data, variables) => {
      !variables.disableToast && pushToast('Successfully updated Credit Note in Subscript!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
    },
  });

  const removeCreditNote = useMutation(
    ({ id, detachMode, integrationId }) => deleteCreditNote({ orgId, creditNoteId: id, detachMode, integrationId }),
    {
      onError: (err, context) => {
        pushError(err, 'Failed to remove Credit Note.');
      },

      onSuccess: () => {
        pushToast('Successfully removed Credit Note!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      },
    },
  );

  const voidCreditNote = useMutation(
    ({ id, integrationId }) => _voidCreditNote({ orgId, creditNoteId: id, integrationId }),
    {
      onError: (err, context) => {
        pushError(err, 'Failed to void Credit Note.');
      },

      onSuccess: () => {
        pushToast('Successfully voided Credit Note!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      },
    },
  );

  const allocateCreditNote = useMutation(
    ({ id, data }) => creditNoteAllocate({ orgId, creditNoteId: id, body: data }),
    {
      onMutate: async ({ id, data: CreditNote }) => {},

      onError: (err, _newCreditNote, context) => {
        pushError(err, 'Failed to allocate Credit Note.');
      },

      onSuccess: (_data, variables) => {
        if (isEmpty(data) && !variables.disableToast) {
          pushToast(`Successfully unattached Credit Note from Invoice!`, 'success');
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      },
    },
  );

  const updateExternalCreditNote = useMutation(
    ({ id, integrationId }) =>
      creditNoteExternalUpdate({
        orgId,
        creditNoteId: id,
        params: { noUpsert: false, noAllocation: true, integrationId },
      }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update Credit Note in external system.');
      },

      onSuccess: (_data, variables) => {
        !variables.disableToast && pushToast(`Successfully updated Credit Note in external system!`, 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      },
    },
  );

  const allocateExternalCreditNote = useMutation(
    ({ id, integrationId }) =>
      creditNoteExternalUpdate({
        orgId,
        creditNoteId: id,
        params: { noUpsert: true, noAllocation: false, integrationId },
      }),
    {
      onError: (err) => {
        pushError(err, 'Failed to allocate Credit Note in external system.');
      },

      onSuccess: (_data, variables) => {
        !variables.disableToast && pushToast(`Successfully allocated Credit Note in external system!`, 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      addCreditNote,
      editCreditNote,
      removeCreditNote,
      voidCreditNote,
      allocateCreditNote,
      updateExternalCreditNote,
      allocateExternalCreditNote,
    },
  };
};

export const useCreditNoteAPI = ({
  orgId,
  creditNoteId,
  scopes = [],
  autoFetch = true,
  queryOptions,
  enableToasts = true,
}) => {
  const dataKey = [CACHE_KEY, orgId, 'creditNotes', creditNoteId];
  const [wasProcessing, setWasProcessing] = useState(false);

  const { data, error, isLoading, isFetching, refetch } = useQueryPolling({
    key: dataKey,
    fetchFn: () => getCreditNoteById({ orgId, creditNoteId, params: { scopes } }),
    fetchWithJobIdFn: () => getCreditNoteById({ orgId, creditNoteId, params: { scopes } }),
    queryOptions: {
      enabled: !!creditNoteId && autoFetch,
      onSettled: (data) => {
        if (data?.state === 'completed') {
          if (wasProcessing) {
            // Invalidates the currently opened invoicing schedule if it was ever in processing
            queryClient.invalidateQueries([CACHE_KEY, orgId, 'invoicingSchedules']);
            setWasProcessing(false);

            if (data?.data?.metadata?.[CREDIT_NOTE_METADATA.JOB_STATUS] === 'failed') {
              wrappedPushError(
                { response: {} },
                `${
                  data?.data?.metadata?.[CREDIT_NOTE_METADATA.JOB_FAILED_MESSAGE]
                    ? 'Failed to send credit note: ' + data?.data?.metadata?.[CREDIT_NOTE_METADATA.JOB_FAILED_MESSAGE]
                    : 'Failed to send credit note.'
                }`,
                10000,
              );

              queryClient.invalidateQueries([CACHE_KEY, orgId, 'creditNotes']);
            } else {
              wrappedPushToast('Successfully sent credit note!', 'success');
            }
          }
        } else if (!wasProcessing) {
          // Was ever in processing?
          setWasProcessing(true);
        }
      },
      ...queryOptions,
    },
    alwaysReturnData: true,
  });

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const sendCreditNote = useMutation(
    ({ creditNoteId, params }) =>
      _sendCreditNote({
        orgId,
        creditNoteId,
        params,
      }),
    {
      onError: (err) => {
        pushError(err, 'Failed to queue send the Credit Note.');
      },

      onSuccess: (_data, variables) => {
        !variables.disableToast && pushToast(`Queued to send... Credit Note will be sent shortly!`, 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      sendCreditNote,
    },
  };
};

export const useCreditNotePdfAPI = ({
  orgId,
  creditNoteId,
  invoiceId,
  entityId,
  language,
  autoFetch = true,
  enableToasts = true,
}) => {
  const dataKey = [CACHE_KEY, orgId, 'creditNotes', creditNoteId, 'pdf'];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () => getCreditNotePdf({ orgId, creditNoteId, params: { invoiceId, entityId, language } }),
    { enabled: !!creditNoteId && autoFetch },
  );

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const regenerateCreditNotePdf = useMutation(
    ({ creditNoteId: _creditNoteId, data, includePDF = false } = {}) =>
      _regenerateCreditNotePdf({
        orgId,
        creditNoteId: _creditNoteId ?? creditNoteId,
        body: data ?? { invoiceId, entityId, language },
        params: { includePDF },
      }),
    {
      onError: (err) => {
        pushError(err, 'Failed to regenerate credit note PDF');
      },

      onSuccess: (_data) => {
        pushToast(`Regenerated credit note PDF successfully!`, 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries([CACHE_KEY, orgId, 'creditNotes', creditNoteId]);
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      regenerateCreditNotePdf,
    },
  };
};

export const useCreditNoteTitlesAPI = ({ orgId, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, orgId, 'creditNotes', 'titles'];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () => getCreditNoteTitles({ orgId }),
    { enabled: autoFetch },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};

export const useBillingInconsistenciesAPI = ({ orgId, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, orgId, 'billingInconsistencies'];

  const queryClient = useQueryClient();

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () => getBillingInconsistencies({ orgId }),
    {
      enabled: orgId && autoFetch,
    },
  );

  const dismissTransactionFromBillingInconsistencies = useMutation(
    ({ transactionId }) => _dismissTransactionFromBillingInconsistencies({ orgId, transactionId }),
    {
      onMutate: async () => {},

      onError: (err, transaction, context) => {
        pushError(err, 'Failed to dismiss');
      },

      onSuccess: () => {
        pushToast('Dismissed');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      dismissTransactionFromBillingInconsistencies,
    },
  };
};

export const useRevRecExceptionsAPI = ({ orgId, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, orgId, 'revRecExceptions'];

  const queryClient = useQueryClient();

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () => getRevRecExceptions({ orgId }),
    {
      enabled: orgId && autoFetch,
    },
  );

  const dismissTransactionFromRevRecExceptions = useMutation(
    ({ transactionId }) => _dismissTransactionFromRevRecExceptions({ orgId, transactionId }),
    {
      onMutate: async () => {},

      onError: (err, transaction, context) => {
        pushError(err, 'Failed to dismiss');
      },

      onSuccess: () => {
        pushToast('Dismissed');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      dismissTransactionFromRevRecExceptions,
    },
  };
};

export const useGetAvailableTags = ({ orgId }) => {
  const { metadataOptions } = useMetadataOptionsAPI({ orgId });

  const transactionMetadataTags = metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS]
    ? Object.keys(metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS]).map((tag) => `{transaction_metadata_${tag}}`)
    : [];
  const customerMetadataTags = metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS]
    ? Object.keys(metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS]).map((tag) => `{customer_metadata_${tag}}`)
    : [];
  const transactionCustomerMetadataTags = metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS]
    ? Object.keys(metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS]).map(
        (tag) => `{transaction_customer_metadata_${tag}}`,
      )
    : [];

  return { transactionMetadataTags, customerMetadataTags, transactionCustomerMetadataTags };
};
