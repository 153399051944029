import { useContext } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { AITagSolidIcon, ArrowNarrowRightIcon } from 'components/Icons';
import { FlexerColumn, FlexerRow } from 'components/Core';
import { BillingContext } from '../BillingContext';

dayjs.extend(require('dayjs/plugin/utc'));

const Container = styled(FlexerColumn)`
  gap: 20px;
  background: var(--primaryBlack2);
  border-bottom: var(--primaryBlack3);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.03) inset;
  margin-left: -40px;
  margin-right: -30px;
  padding: 20px 40px;
`;

const HeaderTitle = styled(FlexerRow)`
  gap: 4px;
  align-items: center;
  color: var(--primaryBlack);
  font-size: 12px;
  font-weight: 900;
  line-height: 16px;

  span {
    color: var(--primaryBlack50);
    font-style: italic;
  }
`;

const ButtonBase = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 100px;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
`;

const ReminderButton = styled(ButtonBase)`
  background-color: var(--primaryBlue5);
  border: 1px solid var(--primaryBlue10);
  color: var(--primaryBlue);

  svg {
    margin-left: 4px;

    path {
      fill: var(--primaryBlue);
    }

    g {
      opacity: 1;
    }
  }

  &:hover {
    background-color: var(--primaryBlue20);
    color: var(--secondaryBlue);
  }
`;

const DismissButton = styled(ButtonBase)`
  border: 1px solid var(--primaryBlack5);
  margin-left: 12px;
  color: var(--primaryBlack);

  &:hover {
    color: var(--secondaryBlue);
    background: var(--primaryBlue10);
  }
`;

const Content = styled(FlexerRow)`
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 2px 2px 12px 0px var(--primaryBlack2);
  font-size: 12px;
  line-height: 16px;
  font-style: italic;
  color: var(--primaryBlack);
  max-width: 960px;

  span {
    color: var(--primaryBlack50);
  }
`;

export const RemindersByAIBanner = ({ invoice, aiRemindersToSend, onReminderOpen }) => {
  const { formatDateWithLocale } = useContext(AppContext);
  const { dismissReminder } = useContext(BillingContext);
  const autoSendEnabled = aiRemindersToSend?.auto_send;
  const count = invoice?.last_communication_count + 1;

  return (
    <Container>
      <FlexerColumn gap="12px">
        <HeaderTitle>
          <AITagSolidIcon />
          Suggestions by <span>Subscript AI</span>
        </HeaderTitle>
        <Content>
          <FlexerRow gap="12px" alignItems="center">
            <div>
              <b>
                {autoSendEnabled
                  ? `Subscript will send the reminder on ${formatDateWithLocale(invoice?.reminder_date)}`
                  : `Set up automatic sending`}
              </b>
              {!autoSendEnabled &&
                ` of the ${count === 1 ? '1st' : count === 2 ? '2nd' : count === 3 ? '3rd' : `${count}th`} reminder`}
              , which was generated by AI based on the last email from the customer
            </div>
            <ReminderButton onClick={() => onReminderOpen()}>
              Details
              <ArrowNarrowRightIcon />
            </ReminderButton>
          </FlexerRow>
          {!autoSendEnabled && (
            <DismissButton onClick={() => dismissReminder({ id: aiRemindersToSend?.id })}>Dismiss</DismissButton>
          )}
        </Content>
      </FlexerColumn>
    </Container>
  );
};
