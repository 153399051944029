import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { EVENT_RECOGNITION_TYPES, RECOGNITION_TYPES } from 'consts/global';
import { transactionDisplayNameForBilling } from 'models/transaction';
import { pluralize } from 'utils/stringUtils';
import { Centerer, CentererVertical, FlexerColumn } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { RecognitionIcon, WarningIcon } from 'components/Icons';
import { MultiselectRibbon, WarnBlock } from 'components/Blocks';
import { INVOICING_FREQUENCIES } from 'views/Billing/consts';

import { InvoiceLineItem } from './InvoiceLineItem';
import { OldInvoicesChargeWarning } from './OldInvoicesChargeWarning';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { BulkInvoicesActions } from './InvoiceActions/BulkInvoicesActions';

const ScrollContainer = styled(FlexerColumn)`
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-left: 10px;
  width: 370px;
  left: -10px;
`;

const LoaderWrapper = styled(Centerer)`
  margin-top: 100px;
`;

const WarningText = styled.span`
  color: var(--primaryBlack);
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
`;

const EmptyState = styled(Centerer)`
  font-size: 14px;
  color: var(--primaryBlack20);
  height: 100%;
`;

const EmptyStateText = styled.div`
  text-align: center;
  width: 160px;
`;

const INVOICING_FREQUENCY_TIME_UNIT = {
  [INVOICING_FREQUENCIES.MONTHLY]: 'every month',
  [INVOICING_FREQUENCIES.QUARTERLY]: 'every quarter',
  [INVOICING_FREQUENCIES.ANNUALLY]: 'every year',
  [INVOICING_FREQUENCIES.SEMI_ANNUALLY]: 'twice a year',
};

export const InvoicesTab = ({ invoices, loading, WarningsButton }) => {
  const {
    invoicingScheduleFormValues,
    includedTransactions,
    disableSaveScheduleButton,
    bulkChangeInvoices,
    setBulkChangeInvoices,
    isScheduleDraft,
    customerDetails,
  } = useContext(InvoicingScheduleContext);

  const { setFieldValue } = useFormikContext();

  const tillCanceledTransaction = useMemo(
    () => includedTransactions?.find(({ recognition }) => recognition === RECOGNITION_TYPES.tillCanceled),
    [includedTransactions],
  );
  const eventTransactions = useMemo(
    () => includedTransactions?.filter(({ recognition }) => EVENT_RECOGNITION_TYPES.includes(recognition)),
    [includedTransactions],
  );

  return (
    <>
      {!!invoicingScheduleFormValues?.invoices?.length && (
        <>
          {isScheduleDraft && disableSaveScheduleButton && (
            <CentererVertical gap="8px" style={{ marginBottom: 10 }}>
              <WarningIcon size="12px" />
              <WarningText>First setup income accounts for all transactions/products</WarningText>
            </CentererVertical>
          )}

          <OldInvoicesChargeWarning
            setFieldValue={setFieldValue}
            isDraftInvoices={isScheduleDraft}
            invoices={invoices}
            isAutoCharge={invoicingScheduleFormValues?.auto_charge}
          />

          {tillCanceledTransaction && invoicingScheduleFormValues?.invoicing_frequency && (
            <>
              <WarnBlock
                justifyContent="space-between"
                rightIcon={<RecognitionIcon recognitionType={RECOGNITION_TYPES.tillCanceled} />}
              >
                Subscript will generate a new invoice{' '}
                {INVOICING_FREQUENCY_TIME_UNIT[invoicingScheduleFormValues.invoicing_frequency] ?? 'every month'} until{' '}
                {transactionDisplayNameForBilling(tillCanceledTransaction, { shortForm: true })} is canceled.
                {invoicingScheduleFormValues?.auto_charge && (
                  <>
                    {' '}
                    Once the customer has entered the payment details for the first invoice, future invoices will be
                    charged automatically.
                  </>
                )}
              </WarnBlock>
            </>
          )}
          {eventTransactions?.length > 0 && (
            <>
              <WarnBlock
                justifyContent="space-between"
                rightIcon={
                  <RecognitionIcon
                    recognitionType={eventTransactions[0]?.recognition || RECOGNITION_TYPES.eventNotRecurring}
                  />
                }
              >
                {`Subscript will auto-update your invoices when new events of ${eventTransactions
                  .map((t) => t.name)
                  .join('and ')} are added.`}
              </WarnBlock>
            </>
          )}

          <WarningsButton />
        </>
      )}

      {loading ? (
        <LoaderWrapper>
          <CircleLoader name="invoices" width={48} height={48} />
        </LoaderWrapper>
      ) : invoicingScheduleFormValues?.invoices?.length ? (
        <>
          <ScrollContainer data-cy={'billing__invoice_schedule-modal__invoice-list'}>
            {invoices.map((invoice, index) => (
              <InvoiceLineItem
                invoice={invoice}
                invoices={invoices}
                index={index}
                key={invoice.id ?? invoice.unsavedId}
                customer={customerDetails}
              />
            ))}
          </ScrollContainer>

          {!!bulkChangeInvoices?.length ? (
            <MultiselectRibbon
              dataCy="billing__invoice_schedule-modal__invoice-list__bulk-actions"
              left="200px"
              label={`${pluralize(bulkChangeInvoices?.length, 'invoice')}`}
              insertActionsDirectly
              actions={<BulkInvoicesActions />}
              onResetSelection={() => setBulkChangeInvoices([])}
            />
          ) : null}
        </>
      ) : (
        <EmptyState>
          <EmptyStateText>Regenerate the schedule or add new invoices</EmptyStateText>
        </EmptyState>
      )}
    </>
  );
};
