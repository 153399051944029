import styled from 'styled-components';
import { Button } from 'components/Buttons';
import { CentererVertical, Flexer, Centerer, FlexerColumn } from 'components/Core';

export const HeaderText = styled.div`
  color: var(--primaryBlack);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 12px;
  letter-spacing: 0em;
  padding: 21px 24px;
  border-bottom: 1px solid var(--primaryBlack5);
`;

export const BodyWrapper = styled(FlexerColumn)`
  padding: 16px 24px;
  height: 100%;
`;

export const EmptyWrapper = styled(Centerer)`
  height: 100%;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-transform: none;
  gap: 8px;
`;

export const SettingsRow = styled(Flexer)`
  margin-bottom: 12px;
`;

export const SettingItem = styled(Centerer)`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--primaryBlue10);
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-right: ${({ withMarginRight }) => (withMarginRight ? '8px' : undefined)};
  cursor: pointer;
  gap: 4px;
`;

export const SettingItemText = styled(Centerer)`
  margin-top: 1px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  gap: 4px;
`;

export const SettingItemWithText = styled(Centerer)`
  margin-right: 12px;

  > span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const SettingHeader = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

export const PreviewHeader = styled.div`
  color: var(--primaryBlack50);
  line-height: 12px;
  font-size: 10px;
  font-weight: 900;
  margin: 16px 0;
  text-transform: uppercase;
`;

export const DefaultMemo = styled.span`
  color: var(--primaryBlue);
  margin-left: 4px;
  cursor: pointer;
`;

export const InvoicingScheduleLine = styled(Flexer)`
  gap: 8px;
  margin-bottom: 12px;
`;

export const InvoicingScheduleBadge = styled(Centerer)`
  background-color: var(--dark7);
  color: white;
  border-radius: 4px;
  font-size: 9px;
  font-weight: 900;
  line-height: 0;
  padding: 0 5px;
`;

export const CustomerName = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: none;
`;

export const InvoicingScheduleSummary = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

export const BulkActionsButtonsContainer = styled(CentererVertical)`
  padding: 8px 16px;
  border-radius: 100px;
  background-color: var(--backgroundGray);
  position: sticky;
  bottom: 5vh;
  z-index: 20;
  width: fit-content;
  margin: 0 auto;
  color: white;
`;

export const BulkCreateButton = styled(Button)`
  width: fit-content;
  color: white;
  background: ${({ disabled }) => (disabled ? 'var(--primaryGreen50)' : 'var(--primaryGreen)')};
`;

export const BulkDismissButton = styled(Button)`
  color: white;
  background-color: transparent;
  border: 1px solid var(--dark10);
`;
