import styled, { css } from 'styled-components';
import { ReactComponent as ArrowIcon } from 'images/arrow-narrow-right.svg';
import { HelpCircleIcon } from 'components/Icons';

export const SubText = styled.div`
  font-size: 12px;
  color: var(--primaryBlack50);
  margin-bottom: 12px;
  text-wrap: wrap;
`;

export const Button = styled.div`
  padding: 4px 8px;
  border-radius: 8px;
  background-color: var(--primaryBlue10);
  color: var(--primaryBlue);
  font-weight: 700;
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const StyledHelpCircleIcon = styled(HelpCircleIcon)`
  width: 14px;
  height: 14px;
  margin-bottom: -3px;
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
`;

export const SettingsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-self: normal;
  flex-grow: 1;
  max-width: 500px;
  min-width: 450px;
  padding: 28px 36px;
  border-right: 1px solid var(--primaryBlack5);
  background: var(--primaryBlack2);
`;

export const ColumnTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  margin-bottom: 16px;
`;

export const AutoChargeBlock = styled.div`
  display: flex;
  margin-top: 28px;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  margin-left: 8px;

  g {
    opacity: 1;
  }

  path {
    fill: white;
  }
`;

export const AutoChargeTitle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 8px;

  span {
    padding: 0 2px;
    border-radius: 2px;
    color: ${({ isOn }) => (isOn ? 'white' : 'initial')};
    background-color: ${({ isOn }) => (isOn ? 'var(--dark7)' : 'var(--primaryBlack10)')};
  }
`;

export const TransationsIncludedColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 28px 36px;
`;

export const TransactionsHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--accentGraySecond);
`;

export const TransactionsCounter = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primaryBlack5);
  text-transform: uppercase;
`;

export const TransactionsTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  margin: 0 8px;
`;

export const SelectorsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  > div {
    white-space: nowrap;
    width: 100%;
  }
`;
