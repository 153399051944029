import { useContext } from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'images/close-icon.svg';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { InvoicingScheduleUnsavedWarning } from './InvoicingScheduleUnsavedWarning';

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 38px;
  width: 38px;

  rect {
    fill: var(--accentGray);
  }

  path {
    fill: var(--primaryBlack);
  }
`;

export const InvoicingScheduleCloseButton = () => {
  const {
    closeModal,
    safeCloseModal,
    setShowUnsavedWarning,
    showUnsavedWarning,
    invoicingScheduleFormValues,
  } = useContext(InvoicingScheduleContext);

  return showUnsavedWarning ? (
    <InvoicingScheduleUnsavedWarning onConfirm={closeModal} onCancel={() => setShowUnsavedWarning(false)} />
  ) : (
    <StyledCloseIcon
      data-cy="invoice-schedule-modal__close-button"
      height={30}
      onClick={() => safeCloseModal(invoicingScheduleFormValues)}
    />
  );
};
