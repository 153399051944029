const baseInputStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#ebf5fc',
    cursor: 'pointer',
    borderRadius: 6,
    border: 'none',
    color: 'var(--primaryBlue)',
    marginRight: 10,
  }),
  input: (styles) => ({
    ...styles,
    color: 'var(--primaryBlue)',
    fontSize: 12,
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  dropdownIndicator: (styles) => ({ ...styles, color: 'var(--primaryBlack)' }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    fontWeight: 700,
    cursor: 'pointer',
    borderRadius: 4,
    fontSize: 14,
    color: 'var(--primaryBlue)',
    // backgroundColor: isSelected ? "#ebf5fc" : "white",
    backgroundColor: isFocused ? '#ebf5fc' : isSelected ? '#ebf5fc' : 'white',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'var(--primaryBlue)',
    fontWeight: 700,
    fontSize: 12,
    width: 'auto',
  }),
};

export default baseInputStyles;
