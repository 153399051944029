import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { cssVar } from 'polished';
import { AppContext } from 'AppContext';
import { ReactComponent as TableExportIcon } from 'images/table-export.svg';
import { ReactComponent as TitleIcon } from 'images/transactions-title-icon.svg';
import { IconButton } from 'components/Buttons';
import { generateColumns, createCSV } from './utils';
import { Table } from './Table';
import { Row } from 'components/Core';

const TransactionsContainer = styled.div`
  padding-top: 40px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Header = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  margin-left: 12px;
`;

const TransactionCount = styled.span`
  padding: 8px 12px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background-color: var(--primaryBlack2);
  margin-left: 12px;
  border-radius: 100px;
`;

export const UpForRenewalTransactionsTable = ({ upForRenewalData, isARR, currency, selectedDate }) => {
  const { dateFormat } = useContext(AppContext);

  const [exporting, setExporting] = useState(false);

  const { totalTransactions, customers } = upForRenewalData;

  const columns = useMemo(() => generateColumns(dayjs.utc(selectedDate), isARR, currency, dateFormat), [
    selectedDate,
    isARR,
    currency,
    dateFormat,
  ]);

  const data = useMemo(() => {
    const dataForTable = Object.values(customers).map((customer) => ({
      currentMRR: customer.currentMRR,
      previousMRR: customer.previousMRR,
      customerName: customer.customerName,
      subRows: customer.transactions,
    }));

    return dataForTable;
  }, [customers]);

  const handleExport = () => {
    if (exporting) {
      return;
    }
    setExporting(true);
    createCSV({ data, selectedDate });
    setExporting(false);
  };

  return (
    <TransactionsContainer>
      <HeaderContainer>
        <Row horizontal="flex-start">
          <TitleIcon />
          <Header>Transactions</Header>
          <TransactionCount>{totalTransactions}</TransactionCount>
        </Row>
        <IconButton icon={<TableExportIcon />} iconFillColor={cssVar('--primaryGreen')} onClick={handleExport}>
          Export Transactions
        </IconButton>
      </HeaderContainer>
      {data && <Table columns={columns} data={data} />}
    </TransactionsContainer>
  );
};
