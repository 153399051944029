import Checkbox from 'antd/lib/checkbox/Checkbox';
import styled from 'styled-components';
import { usePortal } from 'components/Portal';
import { Row } from 'components/Core';

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 170px;
  background-color: white;
  border: 1px solid var(--neutralGray);
`;

const Counter = styled.div`
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  height: 16px;
  text-transform: uppercase;
  color: var(--primaryBlack50);
  padding: 2px 4px;
  border-radius: 4px;
  background: var(--primaryBlack5);
`;

const SelectRow = styled.div`
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid var(--neutralGray);

  &:last-child {
    border-bottom: none;
  }
`;

const SelectText = styled.div`
  margin-left: 8px;
`;

export const AllRowsSelectorDropdown = ({
  getToggleAllPageRowsSelectedProps = {},
  getToggleAllRowsSelectedProps = {},
  ...props
}) => {
  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({ XOffset: 0, YOffset: -10 });

  const isChecked = getToggleAllPageRowsSelectedProps?.()?.checked ?? getToggleAllRowsSelectedProps?.()?.checked;

  return (
    <div ref={triggerRef}>
      <Checkbox data-cy="table__select-all-dropdown" onClick={togglePortal} checked={isChecked} />

      {isPortalVisible && (
        <Portal>
          <Dropdown>
            <SelectRow>
              <Row>
                <Checkbox data-cy="table__select-all-dropdown__select-page" {...getToggleAllPageRowsSelectedProps()} />
                <SelectText>Select shown</SelectText>
              </Row>

              <Counter>{props?.page?.length}</Counter>
            </SelectRow>

            <SelectRow>
              <Row>
                <Checkbox data-cy="table__select-all-dropdown__select-all" {...getToggleAllRowsSelectedProps()} />
                <SelectText>Select all</SelectText>
              </Row>

              <Counter>{props?.rows?.length}</Counter>
            </SelectRow>
          </Dropdown>
        </Portal>
      )}
    </div>
  );
};
