import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import { getIntegrationDisplayName } from 'models/integration';
import { searchResources } from 'api/billing/requests';
import { Flexer } from 'components/Core';
import { FormikCustomSelector } from 'components/Controls';

export const IntegrationCustomerSelector = ({
  integration,
  activeCustomerImportByIntegrationId,
  customerData,
  fieldName,
  defaultToCustomerName = false,
}) => {
  const { orgId } = useContext(AppContext);
  const { values, setFieldValue } = useFormikContext();
  const [initialOptions, setInitialOptions] = useState([]);
  const existingImport = activeCustomerImportByIntegrationId[integration?.id];

  const integrationDisplayName = getIntegrationDisplayName(integration);

  useEffect(() => {
    if (existingImport) {
      setInitialOptions([
        {
          label: `${
            existingImport.metadata?.customer_name ||
            (defaultToCustomerName ? customerData.name : null) ||
            '<Unknown Name>'
          } / ${existingImport.provider_object_id}`,
          value: existingImport.provider_object_id,
        },
      ]);
    } else {
      setInitialOptions([]);
    }
  }, [existingImport, customerData, defaultToCustomerName]);

  const getIntegrationCustomersFromSearch = useCallback(
    async (searchQuery) => {
      const customers = await searchResources({
        params: { searchQuery, resource: 'customer', integrationId: integration.id },
        orgId,
      });
      return (customers ?? []).map((customer) => ({
        label: `${customer.name || '<Unknown Name>'} / ${customer.id || ''}`,
        value: customer.id,
      }));
    },
    [orgId, integration],
  );

  const handleInputChange = (option) => {
    setFieldValue(fieldName, option.value);
    if (initialOptions.filter((opt) => opt.value === option.value).length === 0) {
      const newInitialOptions = [option, ...initialOptions];
      setInitialOptions(newInitialOptions);
    }
  };

  return (
    <Flexer gap="8px">
      <FormikCustomSelector
        boldValue
        label={`Link with ${integrationDisplayName ?? `your ${integration?.type} integration`}`}
        placeholder="Type to search..."
        name={fieldName}
        handleChange={handleInputChange}
        options={initialOptions}
        loadOptions={getIntegrationCustomersFromSearch}
        isDisabled={!values.name}
        tooltipInputDisplay={!values.name ? 'Please enter a name first' : undefined}
        containerWidth="100%"
      />
    </Flexer>
  );
};
