import { useContext } from 'react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Filters } from 'shared/Filters';
import { PAGE_CONTEXT_VALUES, usePageContext } from 'utils/hooks';
import { GlobalTogglesContextProvider } from 'shared/GlobalToggles';
import { InvoicesFilter } from 'shared/Filters/InvoicesFilter/InvoicesFilter';
import { InvoicesTableContainer, InvoicingSchedulesTableContainer } from '../InvoicingTables';
import { BillingContext } from '../BillingContext';
import { INVOICES_FILTER_GROUP_BY } from '../BillingProvider';
import { INVOICE_STATUSES } from '../consts';
import { ReviewTransactionsBanner } from '../Common/ReviewTransactionsBanner';

const FiltersWrapper = styled.div`
  padding: 0 40px;
  margin-bottom: 16px;
`;

const BannerWrapper = styled.div`
  padding: 0 40px;
`;

export const BillingInvoices = () => {
  const { invoicesGroupBy, billingDataFilter, setBillingDataFilter, metadataFilter, setMetadataFilter } = useContext(
    BillingContext,
  );
  const { state } = useLocation();

  const { urlState } = usePageContext({ page: PAGE_CONTEXT_VALUES.invoices_list.key });

  const isAllInvoicesPage = state?.status === INVOICE_STATUSES.ALL;

  return (
    <>
      {isAllInvoicesPage && (
        <BannerWrapper>
          <ReviewTransactionsBanner />
        </BannerWrapper>
      )}

      <FiltersWrapper>
        <GlobalTogglesContextProvider urlState={urlState}>
          <Filters
            sameMonthAllowed={true}
            hidePeriodSelection
            showConditions
            showGlobalToggles={false}
            dataFilter={{
              ...billingDataFilter,
              startMonth: dayjs(billingDataFilter?.startMonth).toDate(),
              endMonth: dayjs(billingDataFilter?.endMonth).toDate(),
            }}
            setDataFilter={setBillingDataFilter}
            metadataFilter={metadataFilter}
            setMetadataFilter={setMetadataFilter}
          >
            <InvoicesFilter />
          </Filters>
        </GlobalTogglesContextProvider>
      </FiltersWrapper>

      {invoicesGroupBy === INVOICES_FILTER_GROUP_BY.CUSTOMER ? (
        <InvoicingSchedulesTableContainer />
      ) : (
        <InvoicesTableContainer dataFilter={billingDataFilter} />
      )}
    </>
  );
};
