import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { AppContext } from 'AppContext';
import { getIncomeAccountOptions } from 'api/integrations/requests';
import { FormikCustomSelector } from 'components/Controls';

export const IncomeAccountSelector = ({ integrationId, name, label, isDisabled, ...props }) => {
  const { orgId } = useContext(AppContext);

  const { data: incomeAccountOptions = {}, error, isLoading } = useQuery(
    ['income-account-options', integrationId],
    () => getIncomeAccountOptions({ orgId, integrationId }),
    {
      enabled: !isDisabled,
    },
  );

  return (
    <FormikCustomSelector
      name={name}
      options={Object.entries(incomeAccountOptions).map(([id, label]) => ({
        label: `${label} / ${id}`,
        value: id,
      }))}
      isDisabled={isLoading || error || isDisabled}
      label={label ?? 'Income Account'}
      {...props}
    />
  );
};
