import { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import {
  usePortal,
  Portal as PortalContainer,
  Popover,
  PopoverPrompt,
  PopoverActions,
  PopoverButton,
} from 'components/Portal';
import { TrashIcon } from 'components/Icons';
import { StyledButton } from 'components/Blocks/MultiselectRibbon.styles';
import { getModalInvoiceStatus } from 'views/Billing/InvoiceModal/utils';
import { INVOICE_STATUSES } from 'views/Billing/consts';
import { BillingContext } from 'views/Billing/BillingContext';
import { shouldSendEmailFromSubscript } from 'views/Billing/utils';
import { ReactComponent as MailForward } from 'images/mail-forward.svg';
import { ReactComponent as WalletIcon } from 'images/wallet.svg';
import { ReactComponent as DetachIcon } from 'images/unlink-icon.svg';
import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';
import { ListItemWrapper, MarkAsPaidPopoverBody } from './InvoiceActions';
import { INVOICE_ACTIONS } from './consts';
import { findFirstInvoiceAfterRemoval, isActionAvailable } from './utils';

const Container = styled.div`
  position: relative;
  display: flex;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--neutralGray);
  background: white;
  box-shadow: 16px 16px 60px 0px var(--primaryBlack2);

  svg {
    margin-right: 6px;
  }
`;

const BULK_INVOICE_DROPDOWN_ACTIONS = {
  [INVOICE_ACTIONS.MARK_SENT]: {
    title: 'Mark as Sent',
    icon: <MailForward />,
  },
  [INVOICE_ACTIONS.MARK_PAID]: {
    title: 'Mark as Paid',
    icon: <WalletIcon />,
  },
  [INVOICE_ACTIONS.MARK_UNPAID]: {
    title: 'Mark as Unpaid',
    icon: <WalletIcon />,
  },
  [INVOICE_ACTIONS.DETACH]: {
    title: 'Detach Invoices',
    isTrashItem: true,
    icon: <DetachIcon />,
  },
  [INVOICE_ACTIONS.DELETE]: {
    title: 'Delete Invoices',
    icon: <TrashIcon />,
    hideInDropdown: true,
  },
};

export const BulkInvoicesActions = () => {
  const {
    bulkChangeInvoices,
    setBulkChangeInvoices,
    invoicingScheduleFormValues,
    invoicingService,
    setSelectedInvoiceId,
    setFetchedSelectedInvoice,
    currentInvoicingSchedule,
    fillScheduleWithDraftInvoices,
    invoicingServiceDisplayName,
  } = useContext(InvoicingScheduleContext);
  const { bulkEditInvoices, bulkDetachInvoices, bulkDeleteInvoices } = useContext(BillingContext);
  const { orgConfigs } = useContext(AppContext);

  const [showMarkAsPaidPopover, setShowMarkAsPaidPopover] = useState(false);
  const [paidAt, setPaidAt] = useState(new Date());
  const toggleMarkAsPaidPopover = () => setShowMarkAsPaidPopover((prevValue) => !prevValue);

  const [showMarkAsSentPopover, setShowMarkAsSentPopover] = useState(false);
  const toggleMarkAsSentPopover = () => setShowMarkAsSentPopover((prevValue) => !prevValue);

  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const toggleDeletePopover = () => setShowDeletePopover((prevValue) => !prevValue);

  const [showDetachPopover, setShowDetachPopover] = useState(false);
  const toggleDetachPopover = () => setShowDetachPopover((prevValue) => !prevValue);

  const [showMarkAsUnpaidPopover, setShowMarkAsUnpaidPopover] = useState(false);
  const toggleMarkAsUnpaidPopover = () => setShowMarkAsUnpaidPopover((prevValue) => !prevValue);

  const { triggerRef, togglePortal, isPortalVisible, hidePortal, Portal } = usePortal({
    hideOnOutsideClick: true,
    XOffset: -15,
    YOffset: -45,
    showAbove: true,
  });

  const { top, left, height } = triggerRef?.current?.getBoundingClientRect() ?? {};

  const portalContentStyles = {
    zIndex: 999,
    position: 'absolute',
    left: left - document.getElementById('main-navigation')?.offsetWidth,
    top: top + height + document.getElementById('main-content')?.scrollTop,
  };

  const sendEmailFromSubscript = shouldSendEmailFromSubscript({ orgConfigs });

  const availableActions = useMemo(
    () =>
      Object.keys(BULK_INVOICE_DROPDOWN_ACTIONS).filter((action) => {
        const actionAvailable = bulkChangeInvoices?.every((invoice) => {
          const modalInvoiceStatus = getModalInvoiceStatus({ invoice });
          const isNotSendOrRemindInvoice = ![INVOICE_STATUSES.SENT, INVOICE_STATUSES.REMIND].includes(
            modalInvoiceStatus,
          );

          return isActionAvailable({
            headerView: false,
            action,
            invoicingService,
            invoiceFormValues: invoice,
            modalInvoiceStatus,
            isNotSendOrRemindInvoice,
            sendEmailFromSubscript,
          });
        });

        return !!actionAvailable;
      }),
    [bulkChangeInvoices, invoicingService, sendEmailFromSubscript],
  );

  const handleActionSelect = ({ action }) => {
    switch (action) {
      case INVOICE_ACTIONS.MARK_SENT:
        hidePortal();
        toggleMarkAsSentPopover();
        break;
      case INVOICE_ACTIONS.MARK_PAID:
        hidePortal();
        toggleMarkAsPaidPopover();
        break;
      case INVOICE_ACTIONS.MARK_UNPAID:
        hidePortal();
        toggleMarkAsUnpaidPopover();
        break;
      case INVOICE_ACTIONS.DELETE:
        hidePortal();
        toggleDeletePopover();
        break;
      case INVOICE_ACTIONS.DETACH:
        hidePortal();
        toggleDetachPopover();
        break;
      default:
    }
  };

  const handleMarkAsPaid = async (paidAt) => {
    toggleMarkAsPaidPopover();

    const draftInvoices = bulkChangeInvoices?.filter((invoice) => invoice?.unsavedId);
    const existingInvoices = bulkChangeInvoices?.filter((invoice) => !invoice?.unsavedId);

    if (draftInvoices?.length) {
      await fillScheduleWithDraftInvoices({
        invoices: invoicingScheduleFormValues?.invoices?.map((mapInovice) => {
          let invoiceForReturn = mapInovice;
          if (draftInvoices?.some((draftInvoice) => draftInvoice?.unsavedId === mapInovice?.unsavedId)) {
            invoiceForReturn = { ...mapInovice, paid_at: paidAt };
          }
          return invoiceForReturn;
        }),
      });
    }

    if (existingInvoices?.length) {
      await bulkEditInvoices({
        data: existingInvoices.map(({ id }) => ({
          id,
          paid_at: dayjs.utc(paidAt).format('YYYY-MM-DD'),
        })),
      });
    }
    setBulkChangeInvoices([]);
  };

  const handleDetach = async () => {
    toggleDetachPopover();

    if (currentInvoicingSchedule?.id) {
      await bulkDetachInvoices({
        data: bulkChangeInvoices,
      });
    } else {
      // it's not actually saved yet
      await fillScheduleWithDraftInvoices({
        invoices: invoicingScheduleFormValues?.invoices?.filter(
          (filterInvoice) => !bulkChangeInvoices?.some((invoice) => invoice?.unsavedId === filterInvoice?.unsavedId),
        ),
      });
    }
    setBulkChangeInvoices([]);
  };

  const handleDelete = async () => {
    toggleDeletePopover();

    const draftInvoices = bulkChangeInvoices?.filter((invoice) => invoice?.unsavedId) ?? [];
    const existingInvoices = bulkChangeInvoices?.filter((invoice) => !invoice?.unsavedId) ?? [];

    if (draftInvoices?.length) {
      await fillScheduleWithDraftInvoices({
        invoices: invoicingScheduleFormValues?.invoices?.filter(
          (filterInvoice) => !draftInvoices?.some((invoice) => invoice?.unsavedId === filterInvoice?.unsavedId),
        ),
      });
    }

    if (existingInvoices?.length) {
      await bulkDeleteInvoices({
        data: existingInvoices?.map(({ id }) => id),
      });
    }

    findFirstInvoiceAfterRemoval({
      removedInvoicesIds: [
        ...existingInvoices?.map(({ id }) => id),
        ...draftInvoices?.map(({ unsavedId }) => unsavedId),
      ],
      invoicingScheduleFormValues,
      setSelectedInvoiceId,
      setFetchedSelectedInvoice,
    });

    setBulkChangeInvoices([]);
  };

  const handleMarkAsSent = async () => {
    toggleMarkAsSentPopover();

    const draftInvoices = bulkChangeInvoices?.filter((invoice) => invoice?.unsavedId);
    const existingInvoices = bulkChangeInvoices?.filter((invoice) => !invoice?.unsavedId);

    if (draftInvoices?.length) {
      await fillScheduleWithDraftInvoices({
        invoices: invoicingScheduleFormValues?.invoices?.map((mapInovice) => {
          let invoiceForReturn = mapInovice;
          if (draftInvoices?.some((draftInvoice) => draftInvoice?.unsavedId === mapInovice?.unsavedId)) {
            invoiceForReturn = { ...mapInovice, sent_at: new Date() };
          }
          return invoiceForReturn;
        }),
      });
    }

    if (existingInvoices?.length) {
      await bulkEditInvoices({
        data: existingInvoices.map(({ id }) => ({
          id,
          sent_at: dayjs.utc(new Date()).format('YYYY-MM-DD'),
        })),
      });
    }
    setBulkChangeInvoices([]);
  };

  const handleMarkAsUnpaid = async () => {
    toggleMarkAsUnpaidPopover();

    const draftInvoices = bulkChangeInvoices?.filter((invoice) => invoice?.unsavedId);
    const existingInvoices = bulkChangeInvoices?.filter((invoice) => !invoice?.unsavedId);

    if (draftInvoices?.length) {
      await fillScheduleWithDraftInvoices({
        invoices: invoicingScheduleFormValues?.invoices?.map((mapInvoice) => {
          let invoiceForReturn = mapInvoice;
          if (draftInvoices?.some((draftInvoice) => draftInvoice?.unsavedId === mapInvoice?.unsavedId)) {
            invoiceForReturn = { ...mapInvoice, paid_at: null };
          }
          return invoiceForReturn;
        }),
      });
    }

    if (existingInvoices?.length) {
      await bulkEditInvoices({
        data: existingInvoices.map(({ id }) => ({
          id,
          paid_at: null,
        })),
      });
    }
    setBulkChangeInvoices([]);
  };

  return (
    <Container ref={triggerRef} data-cy="invoicing-schedule-modal__invoice-bulk-actions">
      <StyledButton
        data-cy="invoicing-schedule-modal__invoice-bulk-actions__button"
        disabled={!availableActions?.filter((action) => action !== INVOICE_ACTIONS.DELETE).length}
        onClick={togglePortal}
      >
        Actions
      </StyledButton>

      {availableActions?.includes(INVOICE_ACTIONS.DELETE) ? (
        <StyledButton
          data-cy="invoicing-schedule-modal__invoice-bulk-actions__button--delete"
          onClick={toggleDeletePopover}
          isRound={true}
        >
          <TrashIcon size="16px" fill="white" />
        </StyledButton>
      ) : null}

      {isPortalVisible && (
        <Portal data-cy="invoicing-schedule-modal__invoice-bulk-actions__portal">
          <ItemsContainer>
            {Object.entries(BULK_INVOICE_DROPDOWN_ACTIONS).map(
              ([action, { title, icon, hideInDropdown, isTrashItem }]) => {
                return !hideInDropdown && availableActions?.includes(action) ? (
                  <ListItemWrapper
                    key={action}
                    isTrashItem={isTrashItem}
                    onClick={() => handleActionSelect({ action })}
                    data-cy={`invoicing-schedule-modal__invoice-bulk-actions__action--${action}`}
                  >
                    {icon}
                    {title}
                  </ListItemWrapper>
                ) : null;
              },
            )}
          </ItemsContainer>
        </Portal>
      )}

      {showMarkAsPaidPopover && (
        <PortalContainer>
          <div style={portalContentStyles}>
            <Popover XOffset={100} YOffset={-190} zIndex={51} darkMode width="220px">
              {MarkAsPaidPopoverBody({
                bulk: true,
                paidAt,
                setPaidAt,
                onClose: toggleMarkAsPaidPopover,
                onSubmit: () => handleMarkAsPaid(paidAt),
              })}
            </Popover>
          </div>
        </PortalContainer>
      )}

      {showMarkAsSentPopover && (
        <PortalContainer>
          <div style={portalContentStyles}>
            <Popover XOffset={70} YOffset={-135} zIndex={51} darkMode width="200px">
              <PopoverPrompt>Are you sure you want to mark these invoices as sent?</PopoverPrompt>
              <PopoverActions>
                <PopoverButton onClick={toggleMarkAsSentPopover}>No</PopoverButton>
                <PopoverButton
                  data-cy="invoicing-schedule-modal__confirm-mark-as-sent-option"
                  onClick={handleMarkAsSent}
                  primary
                >
                  Yes
                </PopoverButton>
              </PopoverActions>
            </Popover>
          </div>
        </PortalContainer>
      )}

      {showDeletePopover && (
        <PortalContainer>
          <div style={portalContentStyles}>
            <Popover XOffset={70} YOffset={-135} zIndex={51} darkMode width="200px">
              <PopoverPrompt>Are you sure you want to delete these invoices?</PopoverPrompt>
              <PopoverActions>
                <PopoverButton onClick={toggleDeletePopover}>No</PopoverButton>
                <PopoverButton data-cy="invoice-actions--delete--confirm-yes" onClick={handleDelete} primary>
                  Yes
                </PopoverButton>
              </PopoverActions>
            </Popover>
          </div>
        </PortalContainer>
      )}

      {showDetachPopover && (
        <PortalContainer>
          <div style={portalContentStyles}>
            <Popover XOffset={70} YOffset={-150} zIndex={51} darkMode width="200px">
              <PopoverPrompt>
                Are you sure you want to remove these invoices from Subscript (but keep it in{' '}
                {invoicingServiceDisplayName}?)
              </PopoverPrompt>
              <PopoverActions>
                <PopoverButton onClick={toggleDetachPopover}>No</PopoverButton>
                <PopoverButton data-cy="invoice-actions--detach--confirm-yes" onClick={handleDetach} primary>
                  Yes
                </PopoverButton>
              </PopoverActions>
            </Popover>
          </div>
        </PortalContainer>
      )}

      {showMarkAsUnpaidPopover && (
        <PortalContainer>
          <div style={portalContentStyles}>
            <Popover XOffset={70} YOffset={-135} zIndex={51} darkMode width="200px">
              <PopoverPrompt>Are you sure you want to mark this invoice unpaid?</PopoverPrompt>
              <PopoverActions>
                <PopoverButton onClick={toggleMarkAsUnpaidPopover}>No</PopoverButton>
                <PopoverButton
                  data-cy="invoicing-schedule-modal__confirm-mark-as-unpaid-option"
                  onClick={handleMarkAsUnpaid}
                  primary
                >
                  Yes
                </PopoverButton>
              </PopoverActions>
            </Popover>
          </div>
        </PortalContainer>
      )}
    </Container>
  );
};
