import React from 'react';
import { WarningBar } from 'components/Blocks';
import { isInvoiceMissingTaxes } from 'views/Billing/utils';
import { SwitchWithLabel } from 'components/Controls';

export const InvoiceMissingTaxesWarning = ({
  invoice,
  usesGLConfiguredTaxCodes,
  billingInvoiceDefaults,
  invoicingServiceDisplayName,
  values,
  retryForTaxesToggleOnChange,
}) => {
  const invoiceIsMissingTaxes = isInvoiceMissingTaxes({ invoice, usesGLConfiguredTaxCodes });
  if (!invoiceIsMissingTaxes) return null;
  if (Boolean(billingInvoiceDefaults.always_long_poll_for_taxes)) return null;

  const warnings = [
    <>
      <b>Warning:</b>
      {` ${invoicingServiceDisplayName} returned $0 in taxes for this invoice. Were you expecting non-zero taxes?`}
      <br />
      If so, try turning on "extended tax search" and then save/send the invoice again. Subscript will incorporate
      longer delays in retrieving taxes from {` `}
      {invoicingServiceDisplayName}.
      <SwitchWithLabel
        bolded
        onChange={retryForTaxesToggleOnChange}
        checked={values?.metadata?.should_retry_for_taxes}
        label="Extended tax search"
        labelSize="12px"
        name="should_retry_for_taxes"
      />
    </>,
  ];

  return <WarningBar fullWidth warnings={warnings} />;
};
