export const SYNC_ENTITIES = {
  TRANSACTIONS: 'transactions',
  PIPELINE: 'pipeline',
  PRODUCTS: 'products',
  CUSTOMERS: 'customers',
};

export const SYNC_ENTITIES_SINGULAR = {
  [SYNC_ENTITIES.TRANSACTIONS]: 'transaction',
  [SYNC_ENTITIES.PIPELINE]: 'pipeline',
  [SYNC_ENTITIES.PRODUCTS]: 'product',
  [SYNC_ENTITIES.CUSTOMERS]: 'customer',
};
