import React, { useContext } from 'react';

import { AppContext } from 'AppContext';
import { ReactComponent as SubscriptLogo } from 'images/logo_green.svg';
import { useMVP } from 'utils/hooks/useMVP';
import { TooltipContainer } from 'components/Tooltip';
import { Nav, tooltipStyles, NavMenuList } from './styles';
import { getSections, SECTION_TYPE } from './sections';
import { AnchorLink } from './AnchorLink';
import { PopoverLink } from './PopoverLink';
import { ProfileLink } from './ProfileLink';

export const Navbar = ({ active }) => {
  const { organizations, orgConfigs } = useContext(AppContext);
  const isMVP = useMVP();
  const myOrganization = organizations?.[0];
  const sections = getSections({
    active,
    isMVP,
    orgConfigs,
  });

  return (
    <Nav data-cy="navbar" id="main-navigation">
      <TooltipContainer
        toolTipContent={myOrganization?.name}
        tooltipStyles={tooltipStyles}
        tooltipWrapperStyles={{ height: '40px' }}
        xOffset={124}
        yOffset={-44}
        hideArrow={true}
      >
        <SubscriptLogo width="40px" height="40px" />
      </TooltipContainer>

      <NavMenuList>
        {sections.map((section) => {
          switch (section.type) {
            case SECTION_TYPE.ANCHOR:
              return (
                <AnchorLink
                  key={section.title}
                  active={section.active}
                  title={section.title}
                  icon={section.icon}
                  explainTooltipText={section.explainTooltipText}
                  to={section.to}
                  isNewFeature={section.isNewFeature}
                />
              );
            case SECTION_TYPE.POPOVER:
              return (
                <PopoverLink
                  key={section.title}
                  active={section.active}
                  title={section.title}
                  icon={section.icon}
                  explainTooltipText={section.explainTooltipText}
                  listItems={section.listItems}
                  PopoverContents={section.PopoverContents}
                  ExtraPopoverSection={section.ExtraPopoverSection}
                  YOffset={section.YOffset}
                  isNewFeature={section.isNewFeature}
                />
              );
            case SECTION_TYPE.CUSTOM:
              return section.NavbarLink({ section });
            default:
              return <></>;
          }
        })}
      </NavMenuList>

      <ProfileLink />
    </Nav>
  );
};
