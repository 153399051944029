import { useContext, useState } from 'react';
import styled from 'styled-components';

import { Centerer, FlexerColumn } from 'components/Core';
import { Popover, PopoverWrapper } from 'components/Portal';
import { SwitchWithLabel } from 'components/Controls';
import { useAnalytics, useClickOutside } from 'utils/hooks';
import { groupBy } from 'utils/arrayUtils';
import { EVENTS } from 'consts/analytics';

import { TimelineItem } from './TimelineItem';
import { convertInvoiceToTimelineData } from './utils';
import { InvoicingScheduleContext } from '../InvoicingScheduleModal/InvoicingScheduleContext';
import { SentEmailModal } from '../InvoicePreviewModalV2/InvoiceHistory/SentEmailModal';
import { useSafelySwitchInvoice } from '../InvoicingScheduleModal/useSafelySwitchInvoice';
import { useReceiptModal } from '../ReceiptModal';

const BackgroundDrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0.7;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 12px;
  padding-top: 0;
  background-color: #f7f7f8;
  border-radius: 12px;
  border: 1px solid var(--primaryBlack5);
  overflow-y: auto;
`;

const HeaderSection = styled.div`
  background-color: #f7f7f8;
  position: sticky;
  top: 0;
  padding-top: 12px;
`;

const Header = styled(Centerer)`
  background-color: var(--primaryBlue10);
  padding: 12px;
  border-radius: 8px;
  color: var(--primaryBlue);
  font-weight: 700;
  font-size: 12px;
`;

const TimelineWrapper = styled(FlexerColumn)`
  gap: 12px;
`;

const SwitchContainer = styled(Centerer)`
  margin-bottom: 20px;
  margin-top: 15px;
  padding: 12px;
  border: 1px solid var(--accentGraySecond);
  border-radius: 8px;
`;

export const InvoiceHistoryPopover = ({ popoverProps, onClose }) => {
  const { fetchedSelectedInvoice: invoice, currentInvoicingSchedule } = useContext(InvoicingScheduleContext);

  const { ConfirmSaveModal, checkAndSwitchInvoice, isSaveModalVisible } = useSafelySwitchInvoice();

  const [showAllHistory, setShowAllHistory] = useState(false);

  const [showEmailDetail, setShowEmailDetail] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const { openModal: openReceiptModal, Modal: ReceiptModal, isModalOpen: isReceiptModalOpen } = useReceiptModal();

  const triggerRef = useClickOutside(() => !showEmailDetail && !isSaveModalVisible && !isReceiptModalOpen && onClose());

  const { trackEvent } = useAnalytics();

  const timelineData = convertInvoiceToTimelineData(showAllHistory ? currentInvoicingSchedule?.invoices : invoice);

  const invoicesById = groupBy(currentInvoicingSchedule?.invoices ?? [], 'id', { uniqueness: true });

  const onCloseEmailDetailModal = () => {
    setShowEmailDetail(false);
    setSelectedReminder(null);
    setSelectedInvoice(null);
  };

  const onOpenEmailDetailModal = ({ reminder, invoiceId }) => {
    setSelectedReminder(reminder ?? null);
    setSelectedInvoice(invoicesById[invoiceId] ?? null);
    setShowEmailDetail(true);
  };

  const handleSwitchInvoice = (selectedInvoiceId) => {
    selectedInvoiceId &&
      selectedInvoiceId !== invoice?.id &&
      checkAndSwitchInvoice({
        clickedInvoice: invoicesById[selectedInvoiceId],
      });
  };

  return (
    <>
      <PopoverWrapper width="100vw" height="100vh">
        <BackgroundDrop />
        <Popover width="360px" height="100%" boxShadow="none" {...popoverProps}>
          <ContentWrapper ref={triggerRef}>
            <HeaderSection>
              <Header>History</Header>

              <SwitchContainer>
                <SwitchWithLabel
                  label="Show history of all invoices in the schedule"
                  labelSize="12px"
                  checked={showAllHistory}
                  onChange={(value) => {
                    setShowAllHistory(value);
                    value &&
                      trackEvent({
                        name: EVENTS.SHOW_ALL_INVOICES_HOSTORY,
                      });
                  }}
                />
              </SwitchContainer>
            </HeaderSection>

            <TimelineWrapper>
              {timelineData.map((item, index) => (
                <TimelineItem
                  key={index}
                  item={item ?? {}}
                  isHead={index === 0}
                  onOpenEmailDetailModal={onOpenEmailDetailModal}
                  handleSwitchInvoice={handleSwitchInvoice}
                  openReceiptModal={openReceiptModal}
                />
              ))}
            </TimelineWrapper>
          </ContentWrapper>
        </Popover>
      </PopoverWrapper>
      {showEmailDetail && selectedInvoice && (
        <SentEmailModal onClose={onCloseEmailDetailModal} invoice={selectedInvoice} reminder={selectedReminder} />
      )}
      <ConfirmSaveModal />
      <ReceiptModal />
    </>
  );
};
