import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowUp } from 'images/arrow_up_bold.svg';
import { Spacer, Centerer, CentererVertical } from 'components/Core';
import { InlineButton } from 'components/Buttons';
import { HelpCircleIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';

const TableSubRowsArrow = styled.div`
  svg {
    transition: transform ease 0.4s;
    transform: ${(props) => props.isHidden && 'rotate(180deg)'};
  }
`;

export const getColumns = ({ isSubRow = false, formatAmount }) => {
  return [
    {
      Header: isSubRow ? 'Child Customers' : 'Customer',
      accessor: 'name',
      width: '16%',
      alignRight: false,
      Cell: ({ row }) => (
        <CentererVertical gap="8px">
          {row.original.childCustomers.length > 0 && (
            <TableSubRowsArrow {...row.getToggleRowExpandedProps()} isHidden={!row.isExpanded}>
              <ArrowUp />
            </TableSubRowsArrow>
          )}
          <InlineButton onClick={() => window.open(`/customers/${row.original.id}`, '_blank')} isSecondary>
            {row.original.name}
          </InlineButton>
        </CentererVertical>
      ),
    },
    {
      Header: () =>
        isSubRow ? (
          ''
        ) : (
          <Centerer>
            Current
            <Spacer width="4px" />
            <TooltipContainer
              width={360}
              isVisible={true}
              underline={false}
              toolTipContent={
                'Current is anything that is not yet due. The due date is in the future of the report date and the aging is based on the due date of a transaction.'
              }
              tooltipWrapperStyles={{ height: '16px' }}
            >
              <HelpCircleIcon />
            </TooltipContainer>
          </Centerer>
        ),
      accessor: 'current',
      width: '14%',
      Cell: ({ row }) => <div>{formatAmount(row.original.current)}</div>,
    },
    {
      Header: isSubRow ? '' : '1-30',
      accessor: '1-30',
      width: '14%',
      Cell: ({ row }) => <div>{formatAmount(row.original['1-30'])}</div>,
    },
    {
      Header: isSubRow ? '' : '31-60',
      accessor: '31-60',
      width: '14%',
      Cell: ({ row }) => <div>{formatAmount(row.original['31-60'])}</div>,
    },
    {
      Header: isSubRow ? '' : '61-90',
      accessor: '61-90',
      width: '14%',
      Cell: ({ row }) => <div>{formatAmount(row.original['61-90'])}</div>,
    },
    {
      Header: isSubRow ? '' : '91+',
      accessor: '91+',
      width: '14%',
      Cell: ({ row }) => <div>{formatAmount(row.original['91+'])}</div>,
    },
    {
      Header: isSubRow ? '' : 'Total',
      accessor: 'total',
      width: '14%',
      Cell: ({ row }) => <div>{formatAmount(row.original.total)}</div>,
    },
  ];
};
