import axios from 'axios';
import { CONFIGS_TO_FETCH, ORG_CONFIGS } from 'consts/global';
import { syncAppSettings } from 'models/appSettings';
import {
  setSessionCustomerMetadataSegments,
  setSessionTransactionMetadataSegments,
  setSessionOrgConfigs,
  getOrgConfigs,
} from 'models/appSettings/base';
import { getConfigs } from 'api/configs';
import { getOrganizations } from 'api/organizations';
import { getIntegrationsAndProducts } from 'api/integrations';
import { getMetadataSegments } from 'api/configs/requests';

export const addProduct = ({ product, organizations, setOrganizations }) => {
  if (!organizations?.length) return;

  setOrganizations(
    organizations.map((organization) =>
      product.organization_id === organization.id
        ? {
            ...organization,
            products: [...organization.products, product],
          }
        : organization,
    ),
  );
};

export const fetchOrganisationIds = async ({ setLoadingOrgs, setOrganizations, setNotAuthenticatedUser }) => {
  try {
    const backendOrganizations = await getOrganizations({ includeDefaultDashboard: true });
    setLoadingOrgs(false);
    if (backendOrganizations) {
      setOrganizations(backendOrganizations);
    } else {
      // no orgIds, thus no authenticated user (based on Auth0)
      setNotAuthenticatedUser(true);
    }
  } catch (err) {
    console.error({ message: err.message, component: 'App.js', stack: err });
    setNotAuthenticatedUser(true);
  }
};

export const fetchIntegrations = async ({ setIntegrations, organizations }) => {
  try {
    const integrations = await getIntegrationsAndProducts(organizations[0].id);
    if (integrations?.length > 0) {
      setIntegrations(integrations);
    }
  } catch (e) {
    console.error(e);
  }
};

export const fetchOrgConfigs = async ({ organizations, setOrgConfigs }) => {
  try {
    const organizationConfigs = await getConfigs({
      orgId: organizations[0].id,
      configsToFetch: CONFIGS_TO_FETCH,
    });

    const metadataSegments = await getMetadataSegments({ orgId: organizations[0].id, params: { byObjectType: true } });

    syncAppSettings(organizationConfigs?.[ORG_CONFIGS.CUSTOM_SETTINGS]);

    setSessionOrgConfigs({ configs: organizationConfigs });

    setSessionCustomerMetadataSegments({ customerMetadataSegments: metadataSegments?.data?.customer });

    setSessionTransactionMetadataSegments({ transactionMetadataSegments: metadataSegments?.data?.transaction });

    if (setOrgConfigs) setOrgConfigs(getOrgConfigs(organizationConfigs));
  } catch (e) {
    console.error(e);
  }
};

export const setAxiosTokenInterceptor = async (accessToken) => {
  axios.interceptors.request.use(async (config) => {
    const requestConfig = config;
    if (accessToken) {
      requestConfig.headers.common.Authorization = `Bearer ${accessToken}`;
    }
    return requestConfig;
  });
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
