import dayjs from 'dayjs';
import styled from 'styled-components';
import { useContext } from 'react';
import { AppContext } from 'AppContext';
import { Centerer, FlexerColumn, Row } from 'components/Core';
import { ModalContainer, Modal, ModalCloseIcon } from 'components/Modal';
import { TruncateStringWithTooltip } from 'components/Tooltip';
import { CancelButton, SubmitButton } from 'components/Buttons';
import { CircleLoader } from 'components/Loaders';

import { ReactComponent as SyncIcon } from 'images/refresh.svg';
import { ReactComponent as LeftArrow } from 'images/arrow-narrow-left.svg';
import { ArrowNarrowRightIcon } from 'components/Icons';
import { getInvoicesTotalAmount } from 'views/Billing/utils';
import { getModalInvoiceStatus } from 'views/Billing/InvoiceModal/utils';
import { INVOICE_STATUS_TAG_TO_LABEL, InvoiceStatusTag } from 'views/Billing/InvoicingTables/columns';
import { INVOICE_STATUSES } from 'views/Billing/consts';
import { NUMBER_FORMATS } from 'consts/global';
import { pluralize } from 'utils/stringUtils';
import { numberFormatter } from 'utils/formatters';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';

const Body = styled.div`
  padding: 28px 0px 28px 36px;
`;

const Footer = styled.div`
  padding: 20px 36px;
  background-color: var(--primaryBlack2);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLeftArrow = styled(LeftArrow)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const Description = styled.div`
  opacity: 0.5;
  font-size: 12px;
  font-style: italic;
  line-height: 16px;
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

const InvoicesContainer = styled.div`
  height: 446px;
  overflow: auto;
  width: 100%;
  padding-right: 36px;
`;

const StyledSyncIcon = styled(SyncIcon)`
  width: 16px;
  height: 16px;
  margin-left: 8px;

  path {
    fill: white;
  }
`;

const InvoiceCounter = styled.div`
  width: 16px;
  height: 16px;
  font-size: 9px;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 4px;
  margin-right: 8px;
  background: var(--dark7);
`;

const Invoice = styled.div`
  padding: 12px 0;
  display: flex;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--neutralGray);

  &:last-child {
    border-bottom: none;
  }
`;

const InvoiceDate = styled.div`
  font-weight: 700;
  width: 90px;
`;

const InvoiceAmount = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  font-weight: 700;
`;

const InvoiceOriginalAmount = styled.span`
  color: var(--primaryBlack50);
`;

const InvoiceItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 250px;
  text-align: left;
`;

const InvoiceStatus = styled.div`
  display: flex;
  justify-content: center;
  width: 300px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  margin-bottom: 16px;

  span {
    font-style: italic;
    color: var(--primaryBlack50);
  }
`;

export const PreviewScheduleModal = ({
  onBack,
  previewScheduleData,
  handleRegenerateSchedule,
  isLoading,
  customer: passedCustomer,
  confirmButtonLabel = 'Confirm Regeneration',
  backButtonLabel = 'Edit Settings',
}) => {
  const {
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);

  const { invoiceUpdateLoading, customer } = useContext(InvoicingScheduleContext) ?? {
    invoiceUpdateLoading: isLoading,
    customer: passedCustomer,
  };

  return (
    <ModalContainer>
      <Modal
        padding="0"
        width="1040px"
        height="616px"
        data-cy="invoicing-schedule__preview-schedule-modal"
        overflow="visible"
        compact
      >
        <ModalCloseIcon onClose={onBack} />

        {invoiceUpdateLoading ? (
          <Centerer style={{ marginTop: 40 }}>
            <CircleLoader />
          </Centerer>
        ) : (
          <FlexerColumn>
            <Body>
              <Title>
                Preview of invoicing schedule for{' '}
                {numberFormatter({
                  type: NUMBER_FORMATS.CURRENCY,
                  rawValue: getInvoicesTotalAmount({ invoices: previewScheduleData?.invoices ?? [] }),
                  currency: previewScheduleData?.invoices?.[0]?.currency ?? defaultOrgCurrency,
                })}{' '}
                with <span>{pluralize(previewScheduleData?.invoices?.length, 'invoice')}</span>
              </Title>

              <InvoicesContainer>
                {previewScheduleData?.invoices?.map((invoice, index) => {
                  const invoceModalStatus = getModalInvoiceStatus({ invoice, omitReminder: true });
                  const status =
                    invoice.auto_charge &&
                    customer?.has_active_payment_method &&
                    [INVOICE_STATUSES.UNSENT, INVOICE_STATUSES.SENT].includes(invoceModalStatus)
                      ? INVOICE_STATUSES.AUTO_CHARGE
                      : invoceModalStatus;

                  const statusLabel = INVOICE_STATUS_TAG_TO_LABEL[status] ?? status;

                  return (
                    <Invoice
                      key={invoice?.id ?? invoice?.unsavedId}
                      data-cy={`preview-schedule-modal__invoice-${dayjs.utc(invoice?.date).format('MMM D, YYYY')}`}
                    >
                      <InvoiceCounter>{index + 1}</InvoiceCounter>
                      <InvoiceDate>{dayjs.utc(invoice?.date).format('MMM D, YYYY')}</InvoiceDate>
                      <InvoiceStatus>
                        <InvoiceStatusTag status={status}>{statusLabel}</InvoiceStatusTag>
                      </InvoiceStatus>
                      <InvoiceItems>
                        <TruncateStringWithTooltip length={50} tooltipWidth={200}>
                          {invoice?.invoice_items
                            ?.filter((item) => !!item?.transaction_id)
                            ?.map((item) => item?.name ?? item?.description)
                            ?.join(', ')}
                        </TruncateStringWithTooltip>
                      </InvoiceItems>

                      <InvoiceAmount>
                        {invoice?.original?.amount && (
                          <>
                            <InvoiceOriginalAmount>
                              {numberFormatter({
                                currency: invoice?.original?.currency,
                                type: NUMBER_FORMATS.CURRENCY,
                                rawValue: invoice?.original?.amount,
                                decimalPlaces: 2,
                              })}
                            </InvoiceOriginalAmount>
                            <ArrowNarrowRightIcon style={{ marginLeft: '4px', marginRight: '4px' }} />
                          </>
                        )}
                        {numberFormatter({
                          type: NUMBER_FORMATS.CURRENCY,
                          rawValue: getInvoicesTotalAmount({ invoices: [invoice] }),
                          currency: previewScheduleData?.invoices?.[0]?.currency ?? defaultOrgCurrency,
                          decimalPlaces: 2,
                        })}
                      </InvoiceAmount>
                    </Invoice>
                  );
                })}
              </InvoicesContainer>
            </Body>
            <Footer>
              <Description>
                <SyncIcon />
                Subscript will update only draft unsent invoices
              </Description>

              <Row>
                <CancelButton
                  data-cy="invoicing-schedule__preview-schedule-modal__back"
                  style={{ fontSize: 12, padding: 8 }}
                  onClick={onBack}
                >
                  <StyledLeftArrow /> {backButtonLabel}
                </CancelButton>
                <SubmitButton
                  data-cy="invoicing-schedule__preview-schedule-modal__confirm"
                  style={{ fontSize: 12 }}
                  onClick={() => handleRegenerateSchedule({ previewMode: false })}
                >
                  <b>{confirmButtonLabel}</b>
                  <StyledSyncIcon />
                </SubmitButton>
              </Row>
            </Footer>
          </FlexerColumn>
        )}
      </Modal>
    </ModalContainer>
  );
};
