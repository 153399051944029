import React, { useContext } from 'react';
import { SizedLoader } from 'components/Loaders';
import { ModalContainer, FullscreenModal } from 'components/Modal';
import { InvoicingScheduleChangesContainer } from 'shared/InvoicingScheduleChanges';

import { InvoicingScheduleContext } from './InvoicingScheduleContext';
import { InvoicingScheduleForm } from './InvoicingScheduleForm';
import { InvoicingScheduleCloseButton } from './InvoicingScheduleCloseButton';
import { InvoicingScheduleHeader } from './InvoicingScheduleHeader';
import { InvoicingScheduleCreatePanel } from './InvoicingScheduleCreatePanel';
import { ContentWrapper, LoaderContainer, ContentMain, InvoicingScheduleBody } from './InvoicingScheduleLayout.styles';
import { INVOICING_SCHEDULE_TABS_PANEL_TABS, InvoicingScheduleTabsPanel } from './InvoicingScheduleTabsPanel';
import { InvoicingScheduleDeleteModal } from './InvoicingScheduleDeleteModal';
// TODO: REMOVE
// import { InvoicingScheduleInfoPanel } from './InvoicingScheduleInfoPanel';
import { InvoicingGuidePanel } from './InvoicingGuidePanel';
import { InvoiceScheduleSingleInvoicePanel } from './InvoiceScheduleSingleInvoicePanel/InvoiceScheduleSingleInvoicePanel';
import { InvoicingScheduleCreditNotePanel } from './InvoicingScheduleCreditNotePanel';
import { InvoicingScheduleHistoryChangesPanel } from './InvoicingScheduleHistoryChangesPanel';
import { useInvoiceWarnings } from './InvoiceScheduleWarnings/useInvoiceWarnings';

export const InvoicingScheduleLayout = () => {
  const {
    loading,
    showDeleteModal,
    includedTransactions,
    invoicingScheduleFormValues,
    isInvoiceLoading,
    isInvoiceFetching,
    selectedTabsPanelTab,
  } = useContext(InvoicingScheduleContext);

  const { WarningsTab, WarningsButton } = useInvoiceWarnings();

  return (
    <ModalContainer data-cy="invoice-modal">
      <FullscreenModal>
        <ContentWrapper>
          {loading ? (
            <LoaderContainer>
              <SizedLoader size={40} />
            </LoaderContainer>
          ) : (
            <InvoicingScheduleChangesContainer>
              <InvoicingScheduleForm>
                <ContentMain>
                  <InvoicingScheduleHeader />
                  <InvoicingScheduleBody>
                    <InvoicingScheduleCreatePanel />
                    <InvoicingScheduleTabsPanel WarningsButton={WarningsButton} />

                    {!invoicingScheduleFormValues?.id &&
                    (!includedTransactions?.length ||
                      !invoicingScheduleFormValues?.customer_id ||
                      !invoicingScheduleFormValues?.invoices?.length) ? (
                      <InvoicingGuidePanel />
                    ) : (
                      <>
                        {selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES && (
                          <>
                            <InvoiceScheduleSingleInvoicePanel />
                            {invoicingScheduleFormValues?.id &&
                              !isInvoiceLoading &&
                              !isInvoiceFetching &&
                              !!invoicingScheduleFormValues?.invoices?.length && (
                                <InvoicingScheduleHistoryChangesPanel />
                              )}
                          </>
                        )}

                        {selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.CREDIT_NOTES && (
                          <InvoicingScheduleCreditNotePanel />
                        )}

                        {selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.WARNINGS && <WarningsTab />}
                      </>
                    )}
                  </InvoicingScheduleBody>
                </ContentMain>
                <InvoicingScheduleCloseButton />
                {showDeleteModal && <InvoicingScheduleDeleteModal />}
              </InvoicingScheduleForm>
            </InvoicingScheduleChangesContainer>
          )}
        </ContentWrapper>
      </FullscreenModal>
    </ModalContainer>
  );
};
