import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useToasts } from 'components/Toasts';
import {
  getInvoiceMemoTemplate,
  getInvoiceMemoTemplates,
  createInvoiceMemoTemplate as _createInvoiceMemoTemplate,
  updateInvoiceMemoTemplate as _updateInvoiceMemoTemplate,
} from './requests';

export const CACHE_KEY = 'invoiceMemoTemplates';

export const useInvoiceMemoTemplatesAPI = ({ orgId, enableToasts = true, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, orgId];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();

  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };

  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () => getInvoiceMemoTemplates({ orgId }),
    { enabled: autoFetch && !!orgId },
  );

  const createInvoiceMemoTemplate = useMutation(({ data }) => _createInvoiceMemoTemplate({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create invoice memo template');
    },
    onSuccess: () => {
      pushToast('Successfully created invoice memo template!', 'success');
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const updateInvoiceMemoTemplate = useMutation(
    ({ invoiceMemoTemplateId, data }) => _updateInvoiceMemoTemplate({ orgId, invoiceMemoTemplateId, body: data }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update invoice memo template');
      },
      onSuccess: () => {
        pushToast('Successfully updated invoice memo template!', 'success');
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    refetch,
    operations: {
      createInvoiceMemoTemplate,
      updateInvoiceMemoTemplate,
    },
  };
};

export const useInvoiceMemoTemplateAPI = ({ orgId, invoiceMemoTemplateId, autoFetch = true }) => {
  const dataKey = [CACHE_KEY, orgId, invoiceMemoTemplateId];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => getInvoiceMemoTemplate({ orgId, invoiceMemoTemplateId }),
    { enabled: autoFetch && !!orgId && !!invoiceMemoTemplateId },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};
