export const FILE_TYPES = {
  CSV: ['text/csv'],
  IMAGES: ['image/png', 'image/jpg', 'image/jpeg'],
  PDF: ['application/pdf'],
  ALL: ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg', 'text/csv'],
};

export const FILE_TYPES_NAMES = {
  'text/csv': 'csv',
  'image/png': 'png',
  'image/jpg': 'jpg',
  'image/jpeg': 'jpeg',
  'application/pdf': 'pdf',
};

export const DEFAULT_MAX_FILE_SIZE = 5242880;
