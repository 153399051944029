import * as Yup from 'yup';

export const CREDIT_NOTE_FIELDS = {
  id: Yup.string().nullable(),
  customer_id: Yup.string().required('Customer is required'),
  date: Yup.date().required('Date is required'),
  currency: Yup.string().nullable(),
  items: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().nullable(),
        transaction_id: Yup.string().nullable(),

        amount: Yup.number().when(['transaction_id'], {
          is: (transaction_id) => !!transaction_id,
          then: Yup.number().nullable(),
          otherwise: Yup.number().typeError('Amount is required').required('Amount is required'),
        }),
        tax_amount: Yup.number().nullable(),
        seats: Yup.number().when(['transaction_id'], {
          is: (transaction_id) => !!transaction_id,
          then: Yup.number().integer('Quantity must be an integer').nullable(),
          otherwise: Yup.number()
            .integer('Quantity must be an integer')
            .typeError('Quantity must be an integer')
            .required('Quantity is required'),
        }),
        product_id: Yup.string().nullable(),
        external_tax_code: Yup.string().nullable(),
      }),
    )
    .min(1, 'A credit note requires at least one line item')
    .required(),
  allocated_invoice: Yup.string().nullable(),
  credit_note_number: Yup.string().nullable(),
  title: Yup.string().nullable(),
  memo: Yup.string().nullable(),
  footer_memo: Yup.string().nullable(),
  pdf_link: Yup.string().nullable(),
  sent_at: Yup.date().nullable(),
  auto_send_date: Yup.date().when(['auto_send'], {
    is: (auto_send) => !!auto_send,
    then: Yup.date().required('Please select a date').required('Please select a date'),
    otherwise: Yup.date().nullable(),
  }),
  email_subject: Yup.string().required('Email subject is required').typeError('Email subject is required'),
  email_body: Yup.string().nullable(),
  send_to: Yup.array().of(Yup.string()).min(1, 'At least on email is required'),
  send_cc: Yup.array().of(Yup.string()),
  attachments: Yup.array()
    .of(
      Yup.object({
        name: Yup.string().required(),
        s3_file_key: Yup.string().required(),
        type: Yup.string().required(),
        size: Yup.number().required(),
      }),
    )
    .nullable(),
  auto_send: Yup.boolean().nullable(),
  sent_by: Yup.number().nullable(),
  metadata: Yup.object().nullable(),
};

export const CREDIT_NOTE_SCHEMA = Yup.object(CREDIT_NOTE_FIELDS);

export const DEFAULT_CREDIT_NOTE_TITLE = 'Credit Note';
