import { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useHotkeys } from 'react-hotkeys-hook';
import { ReactComponent as CheckIcon } from 'images/transaction_check.svg';
import { ReactComponent as DeleteIcon } from 'images/trash.svg';
import { ReactComponent as DuplicateIcon } from 'images/folders.svg';
import { Centerer } from 'components/Core';
import { GreyDot } from 'components/Icons';
import { RECOGNITION_TYPES } from 'consts/global';
import { FooterButton, HotkeyText } from './TransactionFormActions.styles';
import { TRANSACTION_MODAL_MODE } from '../consts';
import { TransactionContext } from '../TransactionContext';

export const TransactionFormActions = () => {
  const {
    mode,
    currentTransaction,
    currentBulkIndex,
    safeCloseModal,
    setShowDeleteModal,
    handleDuplicateTransaction,
    isTransactionLocked,
    transactionFormValues,
  } = useContext(TransactionContext);
  const { submitForm } = useFormikContext();

  useHotkeys('shift+enter', () => submitForm());
  // [JB] We need to pass values as a dep of useHotkeys, to make sure we pass the updated value to safeCloseModal
  useHotkeys('escape', () => safeCloseModal(transactionFormValues), {}, [transactionFormValues]);

  return (
    <Centerer>
      {mode === TRANSACTION_MODAL_MODE.CREATE ? (
        <FooterButton
          delete
          disabled={mode === TRANSACTION_MODAL_MODE.EDIT_BULK && currentBulkIndex === 0}
          onClick={() => safeCloseModal(transactionFormValues)}
        >
          Cancel
        </FooterButton>
      ) : (
        <>
          {!currentTransaction.archived_at && (
            <FooterButton
              delete
              disabled={isTransactionLocked}
              onClick={() => setShowDeleteModal(true)}
              data-cy="transaction-modal__delete-button"
            >
              <DeleteIcon height="20px" style={{ marginRight: '10px' }} />
              Remove
            </FooterButton>
          )}

          {mode !== TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE &&
            transactionFormValues?.recognition !== RECOGNITION_TYPES.eventNotRecurring && (
              <FooterButton
                duplicate
                disabled={isTransactionLocked}
                onClick={handleDuplicateTransaction}
                data-cy="transaction-modal__duplicate-button"
              >
                <DuplicateIcon height="20px" style={{ marginRight: '10px' }} />
                Duplicate
              </FooterButton>
            )}
        </>
      )}
      <GreyDot width={6} height={6} style={{ marginLeft: 10, marginRight: 20, opacity: 0.3 }} />
      <FooterButton save disabled={isTransactionLocked} onClick={submitForm} data-cy="transaction-modal__save-button">
        {currentTransaction.archived_at ? 'Save & Restore' : 'Save & Close'}
        <HotkeyText>(Shift + Enter)</HotkeyText>
        <CheckIcon height="24px" style={{ marginLeft: '10px' }} />
      </FooterButton>
    </Centerer>
  );
};
