import React from 'react';
import { WarningBar } from 'components/Blocks';
import { isInvoicePollingTaxes } from 'views/Billing/utils';

export const InvoicePullingTaxesWarning = ({ invoice, invoicingServiceDisplayName }) => {
  const invoicePollingTaxes = isInvoicePollingTaxes({ invoice });
  if (!invoicePollingTaxes) return null;

  const warnings = [
    <>
      <b>Warning: </b>
      Please wait while we pull taxes for this invoice from {invoicingServiceDisplayName}. This may take a few moments.
    </>,
  ];

  return <WarningBar fullWidth warnings={warnings} />;
};
