import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EVENTS } from 'consts/analytics';
import { AppContext } from 'AppContext';
import { useAnalytics, useStateWithStorage } from 'utils/hooks';
import { useTransactionsAPI } from 'api/transactions';
import { useInvoicingSchedulesAPI } from 'api/billing';
import { REQUIRED_BILLING_PARAMS } from 'api/transactions/hooks';
import { useReviewBulkInvoicesModal } from './useReviewBulkInvoicesModal';
import { groupTransactionsByCRMId, groupTransactionsByCustomer, groupTransactionsByTransactionDate } from './utils';
import { ReviewTransactionsTable } from './ReviewTransactionsTable/ReviewTransactionsTable';

const PAGE_SIZE = 10;

export const GROUP_BY = {
  CUSTOMER: 'Customer',
  TRANSACTION_DATE: 'Transaction Start Date',
  CRM_ID: 'CRM Id',
};

export const SHOW_TYPE = {
  NOT_DISMISSED: 'Non-dismissed',
  ALL: 'All (including dismissed)',
};

const GROUP_TRANSACTIONS_BY_FN = {
  [GROUP_BY.CUSTOMER]: groupTransactionsByCustomer,
  [GROUP_BY.TRANSACTION_DATE]: groupTransactionsByTransactionDate,
  [GROUP_BY.CRM_ID]: groupTransactionsByCRMId,
};

export const ReviewTransactionsTableContainer = ({ transactionIds }) => {
  const { orgId, orgConfigs } = useContext(AppContext);
  const { trackEvent } = useAnalytics();
  const [transactionsSelected, setTransactionsSelected] = useState(new Set());
  const [waitingOnBulkCreate, setWaitingOnBulkCreate] = useState();
  const [currentPageIndex, setCurrentPageIndex] = useStateWithStorage('review-transactions-table-page-index', 1);
  const [groupBy, setGroupBy] = useStateWithStorage('review-transactions-table-group-by', GROUP_BY.CUSTOMER);
  const [searchQuery, setSearchQuery] = useState();
  const [showType, setShowType] = useState(SHOW_TYPE.NOT_DISMISSED);
  const { billingInvoiceDefaults } = orgConfigs;
  const [dataForTable, setDataForTable] = useState([]);
  const { Modal: ReviewBulkInvoicesModal, openModal: openReviewBulkInvoicesModal } = useReviewBulkInvoicesModal();

  const {
    operations: { bulkAddInvoicingSchedule, dismissTransactions },
  } = useInvoicingSchedulesAPI({ orgId, autoFetch: false });

  const orderBy =
    groupBy === GROUP_BY.CUSTOMER
      ? [
          { column: 'customer_name', order: 'asc' },
          { column: 'start_date', order: 'asc' },
        ]
      : [{ column: 'start_date', order: 'asc' }];

  const { data: transactions, metadata, isFetching } = useTransactionsAPI({
    orgId,
    filters: {
      page: currentPageIndex,
      limit: PAGE_SIZE,
      params: {
        searchQuery,
        withoutInvoicingSchedule: true,
        confirmed: true,
        includeCount: true,
        includeReplaced: true,
        archived: false,
        includeCustomerInvoicingDetails: true,
        includeCRMExternalId: groupBy === GROUP_BY.CRM_ID,
        includeDismissedInvoicing: showType === SHOW_TYPE.ALL,
        ...REQUIRED_BILLING_PARAMS,
      },
      body: {
        orderBy,
        transactionIds,
      },
    },
    enableToasts: false,
  });

  const onBulkCreateClick = async ({
    paymentOptions,
    daysToPay,
    autoSend,
    frequency,
    invoiceDateRelative,
    invoiceDateReference,
    daysFromDate,
    groupTransactionsBy,
    allowPastInvoices,
    onlyFutureInvoices,
    integrationId,
    entityId,
    memo,
    defaultMemoTemplateId,
  }) => {
    setWaitingOnBulkCreate(true);

    bulkAddInvoicingSchedule
      .mutateAsync({
        transactionIds: Array.from(transactionsSelected),
        paymentOptions,
        daysToPay,
        autoSend,
        frequency,
        invoiceDateRelative,
        invoiceDateReference,
        daysFromDate,
        groupTransactionsBy,
        allowPastInvoices,
        onlyFutureInvoices,
        integrationId,
        entityId,
        memo,
        defaultMemoTemplateId,
      })
      .then((result) => {
        openReviewBulkInvoicesModal({ invoicingScheduleIds: result.map(({ id }) => id) });
        setTransactionsSelected(new Set());
      })
      .catch(() => {})
      .finally(() => {
        setWaitingOnBulkCreate(false);
      });
  };

  const onBulkDismissClick = async () => {
    await dismissTransactions.mutateAsync({ transactionIds: Array.from(transactionsSelected) });
    setTransactionsSelected(new Set());
  };

  const onEmailListChange = useCallback(
    ({ customerId, newEmailList }) => {
      const changeInvoicingDetails = (object) => ({
        ...object,
        invoicingDetails:
          object.customerId === customerId || object.customer_id === customerId
            ? {
                ...object.invoicingDetails,
                contacts: [
                  ...(newEmailList ?? []),
                  ...(Array.isArray(object.invoicingDetails?.contacts)
                    ? object.invoicingDetails.contacts.filter((c) => typeof c !== 'string')
                    : typeof object.invoicingDetails?.contacts === 'string'
                    ? [object.invoicingDetails.contacts]
                    : []),
                ],
              }
            : object.invoicingDetails,
        isSelected: transactionsSelected.has(object.id),
      });

      setDataForTable((currentDataForTable) =>
        currentDataForTable.map((group) => ({
          ...changeInvoicingDetails(group),
          subRows: group.subRows.map(changeInvoicingDetails),
        })),
      );
    },
    [setDataForTable, transactionsSelected],
  );

  const transactionsWithProducts = useMemo(
    () => dataForTable.flatMap((group) => group.subRows ?? []).filter((transaction) => transaction.product_id),
    [dataForTable],
  );

  useEffect(() => {
    trackEvent({ name: EVENTS.VISIT_BILLING_REVIEW_TRANSACTIONS_PAGE });
  }, [trackEvent]);

  useEffect(() => setDataForTable(transactions ? GROUP_TRANSACTIONS_BY_FN?.[groupBy]?.({ transactions }) : []), [
    transactions,
    groupBy,
    setDataForTable,
  ]);

  return (
    <Fragment>
      <ReviewTransactionsTable
        pageSize={PAGE_SIZE}
        controlledPageCount={metadata?.maxPage}
        totalTransactionsCount={metadata?.count}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        groupBy={groupBy}
        setGroupBy={setGroupBy}
        showType={showType}
        setShowType={setShowType}
        searchQuery={searchQuery}
        onSearchQueryChange={setSearchQuery}
        isFetching={isFetching}
        dataForTable={dataForTable}
        transactionsWithProducts={transactionsWithProducts}
        transactionsSelected={transactionsSelected}
        setTransactionsSelected={setTransactionsSelected}
        billingInvoiceDefaults={billingInvoiceDefaults}
        onBulkDismissClick={onBulkDismissClick}
        onBulkCreateClick={onBulkCreateClick}
        waitingOnBulkCreate={waitingOnBulkCreate}
        onEmailListChange={onEmailListChange}
      />
      <ReviewBulkInvoicesModal />
    </Fragment>
  );
};
