import { omit } from 'lodash';
import dayjs from 'dayjs';
import { generateInvoicesJSON } from 'api/billing/requests';
import { RECOGNITION_TYPES } from 'consts/global';
import { groupBy, mapFilter } from 'utils/arrayUtils';
import {
  GROUP_TRANSACTIONS_FOR_SCHEDULES_BY,
  GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY,
  INVOICING_FREQUENCIES,
} from 'views/Billing/consts';
import { getSuggestedFrequency as _getSuggestedFrequency } from 'views/Billing/utils';

const getCustomerGroupingDetails = ({ value }) => ({
  customerId: value[0]?.customer_id,
  customerName: value[0]?.customer_name,
  invoicingDetails: value[0]?.invoicingDetails,
  subRows: value.map((transaction) => ({ ...transaction, group: value })),
  totalAmount: value.reduce((acc, curr) => acc + curr.amount, 0),
  latestTransactionDate: Math.max(...value.map((transaction) => new Date(transaction.date))),
});

export const groupTransactionsByCustomer = ({ transactions }) => {
  const key = GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY[GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.CUSTOMER];
  const transactionsByCustomer = transactions.reduce((acc, transaction) => {
    if (transaction[key]) {
      acc[transaction[key]] = acc[transaction[key]] ?? [];
      acc[transaction[key]].push(transaction);
    }
    return acc;
  }, {});

  return Object.values(transactionsByCustomer)
    .map((value) => ({
      name: value[0]?.customer_name,
      ...getCustomerGroupingDetails({ value }),
    }))
    .sort((a, b) => (a.latestTransactionDate < b.latestTransactionDate ? 1 : -1));
};

export const groupTransactionsByTransactionDate = ({ transactions }) => {
  const transactionsByStartDate = transactions.reduce((acc, transaction) => {
    const month = dayjs.utc(transaction.start_date).format('YYYY-MM');
    acc[month] = acc[month] ?? [];
    acc[month].push(transaction);

    return acc;
  }, {});

  Object.keys(transactionsByStartDate).forEach((key) => {
    transactionsByStartDate[key] = transactionsByStartDate[key].sort((a, b) =>
      a.customer_name > b.customer_name ? 1 : -1,
    );
  });

  return Object.entries(transactionsByStartDate)
    .map(([key, value]) => ({
      name: key,
      subRows: value.map((transaction) => ({ ...transaction, group: value })),
      totalAmount: value.reduce((acc, curr) => acc + curr.amount, 0),
    }))
    .sort((a, b) => (new Date(`${a.name}-01`) > new Date(`${b.name}-01`) ? 1 : -1));
};

export const groupTransactionsByCRMId = ({ transactions }) => {
  const key = GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY[GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.CRM_ID];
  const transactionsByCRMID = transactions.reduce((acc, transaction) => {
    if (transaction[key]) {
      acc[transaction[key]] = acc[transaction[key]] ?? [];
      acc[transaction[key]].push(transaction);
    }
    return acc;
  }, {});

  return Object.entries(transactionsByCRMID)
    .map(([crmId, value]) => ({
      name: crmId,
      ...getCustomerGroupingDetails({ value }),
    }))
    .sort((a, b) => (a.latestTransactionDate < b.latestTransactionDate ? 1 : -1));
};

// Put transaction as the first one of the group
export const getTransactionsGroup = ({ transaction }) => {
  const externalId = transaction.external_ids?.[0];
  if (!externalId) return [omit(transaction, 'group')];

  return [
    omit(transaction, 'group'),
    ...transaction.group
      .filter(
        (relatedTransaction) =>
          relatedTransaction.id !== transaction.id &&
          (relatedTransaction.external_ids ?? []).some((relatedExternalId) => relatedExternalId === externalId),
      )
      .map((relatedTransaction) => omit(relatedTransaction, 'group')),
  ];
};

const countInvoices = async ({ transactions, frequency, orgId, onlyFutureInvoices, allowPastInvoices }) => {
  if (frequency === INVOICING_FREQUENCIES.UPFRONT) return 1;

  let data = 0;

  if (frequency && transactions?.length) {
    try {
      const result = await generateInvoicesJSON({
        orgId,
        body: {
          customerId: transactions?.[0]?.customer_id,
          transactionIds: transactions.map(({ id }) => id),
          frequency: frequency,
          onlyFutureInvoices,
          allowPastInvoices,
          todayForClient: dayjs().format('YYYY-MM-DD'),
        },
      });
      data = result.data;
    } catch (err) {
      console.error({ message: err.message, component: 'ReviewTransactions utils', stack: err });
    }
  }

  return data?.length ?? 0;
};

// Simply assume that all transactions of the same customer can be grouped into a single invoicing schedule for now.
// [AT 2023-14-07] TODO: Split transactions of the same customer into groups of non-conflicting recognitions.
// For example, if there are 2 transactions, one is event-based, and one is linear, we have to split into two
//  invoicing schedules.
export const buildNewInvoicingSchedules = async ({
  transactionIds,
  transactionsWithProducts,
  frequency,
  orgId,
  groupTransactionsBy,
  onlyFutureInvoices,
  allowPastInvoices,
}) => {
  const transactionById = groupBy(transactionsWithProducts, 'id', { uniqueness: true });

  const transactionsByGrouping = Array.from(transactionIds).reduce((acc, curr) => {
    const transaction = transactionById[curr];
    if (transaction?.[groupTransactionsBy]) {
      acc[transaction[groupTransactionsBy]] = acc[transaction[groupTransactionsBy]] ?? [];
      acc[transaction[groupTransactionsBy]].push(transaction);
    }
    return acc;
  }, {});

  const crmGroupingKey = GROUP_TRANSACTIONS_FOR_SCHEDULES_BY_LABEL_TO_KEY[GROUP_TRANSACTIONS_FOR_SCHEDULES_BY.CRM_ID];
  const result = await Object.values(transactionsByGrouping).reduce(async (accPromise, transactions) => {
    const acc = await accPromise;

    acc.push({
      customerName: transactions[0].customer_name,
      description:
        transactions.length > 1
          ? `${transactions.length} transactions${
              groupTransactionsBy === crmGroupingKey ? ` (for deal ${transactions[0][crmGroupingKey]})` : ''
            }`
          : transactions[0].product_name,
      invoicesCount: await countInvoices({ transactions, frequency, orgId, onlyFutureInvoices, allowPastInvoices }),
      hasEventBased: transactions.some(({ recognition }) =>
        [RECOGNITION_TYPES.eventRecurring, RECOGNITION_TYPES.eventNotRecurring].includes(recognition),
      ),
    });
    return acc;
  }, Promise.resolve([]));

  return result;
};

export const areSelectedTransactionsEligibleForBulkCreation = ({ transactionIds, transactionsWithProducts }) => {
  const transactionById = groupBy(transactionsWithProducts, 'id', { uniqueness: true });
  return Array.from(transactionIds).every((id) => {
    return transactionById[id];
  });
};

export const getSuggestedFrequency = ({ transactionIds, transactionsWithProducts }) => {
  const transactionById = groupBy(transactionsWithProducts, 'id', { uniqueness: true });
  const selectedTransactions = mapFilter(Array.from(transactionIds), (id) => transactionById[id]);
  return _getSuggestedFrequency({ transactions: selectedTransactions });
};
