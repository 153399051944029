import { guessDateFormat } from 'utils/dateUtils';
import { cleanNumber, stringToBoolean } from 'utils/stringUtils';

export const UPLOADABLE_OBJECTS = {
  CUSTOMERS: 'customers',
  TRANSACTIONS: 'transactions',
};

export const DEFAULT_TRANSACTIONS_MAPPER = {
  transaction_id: 'External transaction ID (optional)',
  date: 'Date (optional)',
  name: 'Deal name (optional)',
  customer_id: 'External customer ID',
  amount: 'Total',
  recurring_amount: 'MRR (optional)',
  product_id: 'Product (optional)',
  start_date: 'Start date',
  end_date: 'End date',
  recognition: 'Recognition (optional)',
  seats: 'Seats (optional)',
  currency: 'Currency (optional)',
  include_end_month: 'Include end month (optional)',
};

const productNameToId = (value, products) =>
  products?.find((product) => product?.name?.toLowerCase() === value?.toLowerCase())?.id ?? value;

export const DEFAULT_TRANSACTIONS_TRANSFORMATIONS = {
  amount: cleanNumber,
  recurring_amount: cleanNumber,
  seats: cleanNumber,

  date: guessDateFormat,
  start_date: guessDateFormat,
  end_date: guessDateFormat,

  product_id: productNameToId,

  include_end_month: stringToBoolean,
};

export const SOURCE_TYPES = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

export const INTERNAL_TRANSACTION_ID_SOURCE = 'Subscript Transaction ID';
export const INTERNAL_PRODUCT_ID_SOURCE = 'Subscript Product ID';
export const INTERNAL_CUSTOMER_ID_SOURCE = 'Subscript Customer ID';
export const NAME_MATCHING_SOURCE = 'nameMatching';
