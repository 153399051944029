import React, { useContext, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { isNumber } from 'lodash';

import { AppContext } from 'AppContext';
import { GROWTH_TYPE_KEYS, NUMBER_FORMATS, RATES_TYPE } from 'consts/global';
import { SNAPSHOT_METRIC_BY_KEY } from 'consts/snapshotMetrics';
import { useMonthlyBreakdownAPI } from 'api/monthlyBreakdown';
import { ReactComponent as GoalIcon } from 'images/golf.svg';
import { ReactComponent as GoalsEmptyStateChartIllustration } from 'images/goals-empty-state-chart-illustration.svg';
import { generateMonths } from 'utils/dateUtils';
import { Centerer, Container, FlexBetweenContainer, Spacer } from 'components/Core';
import { SizedLoader } from 'components/Loaders';
import { IconContainerPill } from 'components/Icons';
import { FormikCustomInput } from 'components/Controls';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import {
  EditHoverState,
  EmptyCard,
  PeriodHeaderColumn,
  PeriodNumber,
  PeriodsTable,
  PeriodsTitleRow,
  PeriodText,
  StyledCheckIcon,
  StyledCrossIcon,
  StyledDateIcon,
} from './styles';

const getMetricValue = (metricValue) => (typeof metricValue === 'object' ? metricValue.value : metricValue);

export const PeriodsForm = ({ values, setFieldValue, startDate, endDate, goal, suffix, changed }) => {
  const {
    orgId,
    organizations,
    requiredPlugins,
    appSettings: {
      isARR,
      isCommitted,
      exchangeRatesType,
      exchangeRatesDate,
      optimisticAnalytics,
      rollup,
      countInfluxAsRenewed,
    },
  } = useContext(AppContext);

  const convertedStartDate = useMemo(() => dayjs(startDate).add(15, 'days'), [startDate]);
  const convertedEndDate = useMemo(() => dayjs(endDate).add(15, 'days'), [endDate]);

  const { data, isLoading } = useMonthlyBreakdownAPI({
    orgId,
    startMonth: convertedStartDate.startOf('month').month(),
    startYear: convertedStartDate.startOf('month').year(),
    endMonth: convertedEndDate.month(),
    endYear: convertedEndDate.year(),
    rollup,
    plugins: requiredPlugins,
    optimisticAnalytics,
    isCommitted,
    growthType: GROWTH_TYPE_KEYS.MoM,
    countInfluxAsRenewed,
    usesMetricsEngine: organizations?.[0]?.usesMetricsEngine,
    ratesType: exchangeRatesType ?? RATES_TYPE.BOOKING_DATES,
    ratesDate: exchangeRatesDate,
  });

  const snapshotMetric = useMemo(() => SNAPSHOT_METRIC_BY_KEY[values.metric] ?? {}, [values.metric]);
  const valueMultiplier = useMemo(() => (isARR && snapshotMetric.isARRMetric ? 12 : 1), [isARR, snapshotMetric]);

  const periodMonths = useMemo(() => {
    if (convertedStartDate && convertedEndDate) {
      return generateMonths({
        startDate: convertedStartDate.toDate(),
        endDate: convertedEndDate.toDate(),
        inclusive: true,
      });
    }
    return [];
  }, [convertedStartDate, convertedEndDate]);

  useEffect(() => {
    setFieldValue('isLoadingPeriods', isLoading);
  }, [setFieldValue, isLoading]);

  useEffect(() => {
    if (periodMonths.length > 0 && isNumber(goal) && data && changed) {
      const startValues = data?.[snapshotMetric.metricCategoryKey]?.[periodMonths[0]]?.[snapshotMetric.monthDataKey];
      const startAmount =
        snapshotMetric.dataType === NUMBER_FORMATS.PERCENT
          ? getMetricValue(startValues) * 100
          : getMetricValue(startValues);
      const normalizedGoal = snapshotMetric.dataType === NUMBER_FORMATS.PERCENT ? goal : goal / valueMultiplier;
      const monthlyGrowthAmount = (normalizedGoal - startAmount) / (periodMonths.length - 1);
      const normalizedStartAmount = normalizedGoal - monthlyGrowthAmount * (periodMonths.length - 1);

      const periods = periodMonths.reduce((acc, monthKey, index) => {
        const value = (normalizedStartAmount + index * monthlyGrowthAmount) * valueMultiplier;
        acc.push({
          month: monthKey,
          value:
            snapshotMetric.dataType === NUMBER_FORMATS.PERCENT && goal % 1 !== 0
              ? parseFloat(value.toFixed(2))
              : Math.round(value),
        });

        return acc;
      }, []);

      setFieldValue('periods', periods);
    }
  }, [periodMonths, goal, data, snapshotMetric, setFieldValue, valueMultiplier, changed]);

  if (!startDate || !endDate || !isNumber(goal)) {
    return (
      <>
        <PeriodsTitleRow>
          <IconContainerPill>
            <GoalIcon width="20px" height="20px" />
          </IconContainerPill>
          <div>Tracking your goal</div>
        </PeriodsTitleRow>

        <Spacer height="20px" />

        <EmptyCard>
          <div>Select a month and set a goal to predict the interim results.</div>
          <GoalsEmptyStateChartIllustration />
        </EmptyCard>
      </>
    );
  }

  if (isLoading)
    return (
      <>
        <Spacer height="20px" />
        <Centerer>
          <SizedLoader size={40} />
        </Centerer>
        <Spacer height="20px" />
      </>
    );

  if (periodMonths.length <= 1) {
    return <></>;
  }

  return (
    <>
      <FlexBetweenContainer>
        <PeriodsTitleRow>
          <IconContainerPill>
            <GoalIcon width="20px" height="20px" />
          </IconContainerPill>
          <div>Tracking your goal by Months</div>
        </PeriodsTitleRow>
        <Centerer>
          <EditHoverState>You can change intermediate goals</EditHoverState>
        </Centerer>
      </FlexBetweenContainer>

      <Spacer height="20px" />

      <PeriodsTable>
        <>
          <PeriodHeaderColumn>#</PeriodHeaderColumn>
          <PeriodHeaderColumn>
            <StyledDateIcon /> Month
          </PeriodHeaderColumn>
          <PeriodHeaderColumn justifyContent="end">Goal, {suffix}</PeriodHeaderColumn>
          <PeriodHeaderColumn justifyContent="end">Actual, {suffix} </PeriodHeaderColumn>
          <PeriodHeaderColumn>Result</PeriodHeaderColumn>
        </>

        <>
          {values.periods.map((period, index) => {
            const metricValue = data?.[snapshotMetric.metricCategoryKey]?.[period.month]?.[snapshotMetric.monthDataKey];
            const actualValue = getMetricValue(metricValue) ?? 0;
            const goalValue = snapshotMetric.dataType === NUMBER_FORMATS.PERCENT ? period.value / 100 : period.value;
            const successfulGoal = snapshotMetric.isDecreasingMetric
              ? actualValue <= goalValue
              : actualValue >= goalValue;

            return (
              <PeriodRow
                key={period.month}
                values={values}
                dataType={snapshotMetric.dataType}
                suffix={suffix}
                index={index}
                period={period}
                actualValue={actualValue * valueMultiplier}
                lastRow={index === values.periods.length - 1}
                successfulGoal={successfulGoal}
              />
            );
          })}
        </>
      </PeriodsTable>
    </>
  );
};

const PeriodRow = ({ dataType, suffix, index, period, actualValue, lastRow, successfulGoal }) => {
  const numberFormatter = useCurrencyNumberFormatter();

  return (
    <>
      <Centerer>
        <PeriodNumber>{index + 1}</PeriodNumber>
      </Centerer>
      <PeriodText>{period.month}</PeriodText>
      {lastRow ? (
        <PeriodText justifyContent="end">
          {numberFormatter({
            type: dataType,
            rawValue: dataType === NUMBER_FORMATS.PERCENT ? period.value / 100 : period.value,
            decimalPlaces: dataType === NUMBER_FORMATS.PERCENT && period.value % 1 !== 0 ? 2 : 0,
          })}
        </PeriodText>
      ) : (
        <Container>
          <FormikCustomInput
            name={`periods.${index}.value`}
            type="number"
            suffix={suffix}
            style={{ textAlign: 'right' }}
          />
        </Container>
      )}
      <PeriodText justifyContent="end">{numberFormatter({ type: dataType, rawValue: actualValue })}</PeriodText>
      <Centerer>{successfulGoal ? <StyledCheckIcon /> : <StyledCrossIcon />}</Centerer>
    </>
  );
};
