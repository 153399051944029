import React, { useState } from 'react';
import { ReactComponent as DotsIcon } from 'images/dots.svg';
import { ReactComponent as DonutIcon } from 'images/chart-donut-2.svg';
import { ReactComponent as ToolsIcon } from 'images/tools.svg';
import { ReactComponent as TrashIcon } from 'images/trash.svg';
import { FlexerColumn } from 'components/Core';
import {
  PopoverWrapper,
  Popover,
  PopoverListItemFull,
  PopoverListItemIconFull,
  PopoverListItemTitleFull,
} from 'components/Portal';
import { DIRECTIONS, TooltipContainer } from 'components/Tooltip';
import { useClickOutside } from 'utils/hooks';
import { EditMetricCardButton } from '../Common/EditMetricCard';

export const POPOVER_ACTIONS = {
  OPEN: 'open',
  EDIT: 'edit',
  DELETE: 'delete',
};

export const POPOVER_OPTIONS = [
  {
    action: POPOVER_ACTIONS.OPEN,
    title: 'Open Details',
    icon: <DonutIcon />,
  },
  {
    action: POPOVER_ACTIONS.EDIT,
    title: 'Edit Settings',
    icon: <ToolsIcon />,
  },
  {
    action: POPOVER_ACTIONS.DELETE,
    title: 'Delete',
    icon: <TrashIcon />,
  },
];

export const EditWidgetDropdown = ({ width, XOffset, onOptionClick, popoverOptions = POPOVER_OPTIONS }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = (event) => {
    event?.stopPropagation();
    setShowDropdown(!showDropdown);
  };
  const dropdownRef = useClickOutside(() => {
    setShowDropdown(false);
  });

  return (
    <PopoverWrapper ref={dropdownRef}>
      <EditMetricCardButton data-cy="edit-metric-card-button" onClick={toggleDropdown} active={showDropdown}>
        <DotsIcon />
      </EditMetricCardButton>
      {showDropdown && (
        <Popover
          data-cy="edit-metric-card-button__popover"
          darkBorder
          width={width ?? '124px'}
          padding="0"
          YOffset={32}
          XOffset={XOffset ?? -30}
          zIndex={1000}
        >
          {popoverOptions.map((option) => {
            const handleOnClick = (event) => {
              event?.stopPropagation();
              onOptionClick && onOptionClick(option.action);
              toggleDropdown();
            };

            const mainComponent = (
              <PopoverListItemFull
                key={option.action}
                data-cy={`edit-metric-card-button__popover__item--${option.action}`}
                gap="8px"
                onClick={handleOnClick}
                isDisabled={option.isDisabled}
              >
                <PopoverListItemIconFull
                  color={option.action === POPOVER_ACTIONS.DELETE ? 'var(--primaryRed50)' : 'var(--primaryBlack50)'}
                >
                  {option.icon}
                </PopoverListItemIconFull>
                <FlexerColumn gap="4px">
                  <PopoverListItemTitleFull>{option.title}</PopoverListItemTitleFull>
                </FlexerColumn>
              </PopoverListItemFull>
            );

            return option.tooltip ? (
              <TooltipContainer
                toolTipContent={option.tooltip}
                width={240}
                yOffset={-160}
                arrowDirection={DIRECTIONS.BOTTOM}
              >
                {mainComponent}
              </TooltipContainer>
            ) : (
              mainComponent
            );
          })}
        </Popover>
      )}
    </PopoverWrapper>
  );
};
