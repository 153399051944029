import { RECOGNITION_TYPES } from 'consts/global';
import * as Yup from 'yup';
import { SOURCE_TYPES, NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE } from './consts';

export const getSchema = ({
  customers,
  transactions,
  products,
  customerIdSourceAndType,
  transactionsIdSourceAndType,
  uploadCsvCustomersIdSource,
}) => {
  const validCustomerIds = new Set(
    [INTERNAL_CUSTOMER_ID_SOURCE, NAME_MATCHING_SOURCE].includes(customerIdSourceAndType?.source)
      ? (customers ?? []).map(({ id }) => id)
      : customers?.map((customer) =>
          customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
            ? customer.metadata?.[uploadCsvCustomersIdSource]
            : customer.provider_object_id,
        ),
  );

  const transactionsSet = new Set([
    null,
    ...(transactions ?? [])?.map((transaction) =>
      transactionsIdSourceAndType?.type === SOURCE_TYPES.INTERNAL ? transaction.id : transaction.provider_object_id,
    ),
  ]);

  const schema = Yup.array().of(
    Yup.object({
      transaction_id: Yup.string()
        .nullable()
        .test('oneOf transactions', "We can't find that transaction", (value) => {
          return transactionsSet.has(value);
        }),
      recognition: Yup.string()
        .oneOf([null, ...Object.values(RECOGNITION_TYPES)], 'Not accepted recognition')
        .nullable(), // nullable because it might be inferred from product
      date: Yup.date().nullable(),
      name: Yup.string().nullable(),
      customer_id: Yup.string()
        .nullable()
        .when('transaction_id', {
          is: (val) => val === null,
          then: Yup.string()
            .required('Customer field is missing')
            .test('oneOf customers', "We can't find that customer", (value) => {
              return validCustomerIds.has(value);
            }),
          otherwise: Yup.string()
            .nullable()
            .test('oneOf customers', "We can't find that customer", (value) => {
              return validCustomerIds.has(value);
            }),
        }),
      amount: Yup.number().typeError('Total must be a number').nullable(), // nullable because it might be inferred from recurring_amount
      recurring_amount: Yup.number().typeError('MRR must be a number').nullable(),
      product_id: Yup.string()
        .nullable()
        .oneOf([null, ...products?.map((product) => product.id)], "We can't find that product"),
      start_date: Yup.date()
        .nullable()
        .when('transaction_id', {
          is: (val) => val === null,
          then: Yup.date().required('Start date is missing'),
          otherwise: Yup.date().nullable(),
        }),
      end_date: Yup.date().when(['recognition', 'transaction_id'], {
        is: (recognition, transaction_id) =>
          (recognition === RECOGNITION_TYPES.linear || !recognition) && !transaction_id,
        then: Yup.date().required('End date is missing'),
        otherwise: Yup.date().nullable(),
      }),
      seats: Yup.number().nullable(),
      include_end_month: Yup.boolean().nullable(),
    }),
  );

  return schema;
};
