import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { useToasts } from 'components/Toasts';
import { MAX_POLLING_COUNT, POLLING_DURATION } from 'consts/global';
import { delay } from 'utils';

import {
  getIntegrationsAndProducts,
  deleteIntegration as _deleteIntegration,
  upsertIntegrations as _upsertIntegrations,
  syncEntity as _syncEntity,
  updateIntegration as _updateIntegration,
  updateSalesforceAccount,
  updateNetsuiteCustomer,
  updateQuickbooksCustomer,
  updateXeroContact,
  updateStripeCustomer,
  getInvoiceCustomFields,
  syncInvoiceTemplates,
  disconnectXeroIntegration,
} from './requests';
import { INTEGRATIONS_OPERATIONS, INTEGRATION_SERVICES } from 'consts/integrations';

export const CACHE_KEY = 'integrations';
export const SYNC_JOB_CACHE_KEY = 'syncEntityJob';

export const INTEGRATIONS_FUNCTIONS = {
  [INTEGRATION_SERVICES.QUICKBOOKS]: {
    [INTEGRATIONS_OPERATIONS.UPDATE_CUSTOMER]: updateQuickbooksCustomer,
  },
  [INTEGRATION_SERVICES.XERO]: {
    [INTEGRATIONS_OPERATIONS.UPDATE_CUSTOMER]: updateXeroContact,
    [INTEGRATIONS_OPERATIONS.DISCONNECT]: disconnectXeroIntegration,
  },
  [INTEGRATION_SERVICES.NETSUITE]: {
    [INTEGRATIONS_OPERATIONS.UPDATE_CUSTOMER]: updateNetsuiteCustomer,
  },
  // TODO: add write access to companies in Hubspot to make this available
  // note that users will have to reintegrate Hubspot
  /** [INTEGRATION_SERVICES.HUBSPOT]: {
    [INTEGRATIONS_OPERATIONS.UPDATE_CUSTOMER]: updateHubspotCompany,
  }, **/
  [INTEGRATION_SERVICES.SALESFORCE]: {
    [INTEGRATIONS_OPERATIONS.UPDATE_CUSTOMER]: updateSalesforceAccount,
  },
  [INTEGRATION_SERVICES.STRIPE]: {
    [INTEGRATIONS_OPERATIONS.UPDATE_CUSTOMER]: updateStripeCustomer,
  },
};

export const useIntegrationsAPI = ({
  orgId,
  params,
  autoFetch = true,
  enableToasts = true,
  refetchOnWindowFocus = false,
}) => {
  const dataKey = [CACHE_KEY, orgId, params];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const { data, error, isLoading, isFetching } = useQuery(dataKey, () => getIntegrationsAndProducts(orgId, params), {
    enabled: autoFetch,
    placeholderData: [],
    refetchOnWindowFocus,
  });

  const queryClient = useQueryClient();

  const invalidateAll = () => {
    queryClient.invalidateQueries(CACHE_KEY);
  };

  const upsertIntegrations = useMutation(({ integrations }) => _upsertIntegrations({ orgId, data: { integrations } }), {
    onSuccess: () => {
      pushToast('You have updated your recurring reports!', 'success');
    },
    onError: (err) => {
      pushError(err, 'Failed to update your recurring reports!');
    },
    onSettled: invalidateAll,
  });

  const updateIntegration = useMutation(
    ({ integrationId, data, skipIngestion }) => _updateIntegration(orgId, integrationId, data, skipIngestion),
    {
      onSuccess: () => {
        pushToast('Successfully updated integration!', 'success');
      },
      onError: (err) => {
        pushError(err, 'Failed to update integration.');
      },
      onSettled: invalidateAll,
    },
  );

  const deleteIntegration = useMutation(({ integrationId }) => _deleteIntegration({ orgId, integrationId }), {
    onSuccess: () => {
      pushToast('Successfully removed integration!', 'success');
    },
    onError: (err) => {
      pushError(err, 'Failed to remove integration.');
    },
    onSettled: invalidateAll,
  });

  const disconnectIntegration = useMutation(
    ({ integrationService, integrationId }) =>
      INTEGRATIONS_FUNCTIONS[integrationService]?.[INTEGRATIONS_OPERATIONS.DISCONNECT] &&
      INTEGRATIONS_FUNCTIONS[integrationService]?.[INTEGRATIONS_OPERATIONS.DISCONNECT]({ orgId, integrationId }),
    {
      onSuccess: () => {
        pushToast('Integration disconnected', 'success');
      },
      onError: (err) => {
        pushError(err, 'Failed to disconnect integration.');
      },
      onSettled: invalidateAll,
    },
  );

  const runOnExternalService = useMutation(
    async ({ integrationService, operation, data, integrationId }) => {
      INTEGRATIONS_FUNCTIONS[integrationService]?.[operation] &&
        (await INTEGRATIONS_FUNCTIONS[integrationService][operation]({ orgId, data, params: { integrationId } }));
    },
    {
      onSuccess: () => {
        pushToast(`Successfully updated in external system!`, 'success');
      },
      onError: (err) => {
        pushError(err, `Failed to update in external system.`);
      },
      onSettled: invalidateAll,
    },
  );

  const syncEntity = useMutation(
    async ({ integrationId, entity, internalIds, externalIds, initialSync }) => {
      let attemptCount = 0;
      while (attemptCount < MAX_POLLING_COUNT) {
        const { state, data } = await _syncEntity({
          orgId,
          integrationId,
          entity,
          internalIds,
          externalIds,
          initialSync,
        });

        if (state === 'failed') {
          throw new Error('Something went wrong, and we did not finish syncing. Please try again later.');
        } else if (state === 'completed') {
          return data;
        }

        if (attemptCount === 10) {
          // have been waiting 10 seconds
          pushToast(
            'Sync is in progress, but may take a while. Please check back in a few minutes to see changes.',
            'success',
          );
        }
        await delay(POLLING_DURATION);
        attemptCount++;
      }
      Sentry.captureMessage(`Sync is taking long to complete for org ${orgId}`, 'warning');
      // Don't throw error, since the sync will still continue for the user, it's just good to warn on Sentry.
    },
    {
      onSuccess: () => pushToast('Finished syncing!', 'success'),
      onError: (err) => {
        console.error({ message: err.message, stack: err });
        pushError(
          err,
          err?.message?.includes(500)
            ? 'Something went wrong, and we did not finish syncing. Please try again later.'
            : err.message,
        );
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      upsertIntegrations,
      deleteIntegration,
      disconnectIntegration,
      syncEntity,
      updateIntegration,
      runOnExternalService,
    },
  };
};

export const useInvoiceCustomFields = ({ orgId, autoFetch = true, integrationId }) => {
  const dataKey = ['invoice-custom-fields', orgId, integrationId];

  const { data, error, isLoading } = useQuery(dataKey, () => getInvoiceCustomFields({ orgId, integrationId }), {
    enabled: autoFetch,
  });

  return {
    data,
    error,
    isLoading,
  };
};

export const useIntegrationInvoiceTemplates = ({
  orgId,
  autoFetch = true,
  integrationId,
  onFetchSuccess = () => {},
}) => {
  const dataKey = ['invoice-templates', orgId, integrationId];

  const { data, error, isLoading, refetch } = useQuery(
    dataKey,
    () =>
      // returns the entire integration with the template ids in the metadata
      syncInvoiceTemplates({ orgId, integrationId }),
    {
      enabled: autoFetch && !!integrationId,
      onSuccess: (data) => {
        onFetchSuccess(data);
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    operations: {
      refetch,
    },
  };
};
