import { useUrlQuery } from './useUrlQuery';

export const useMVP = () => {
  const query = useUrlQuery();

  // Always show in development
  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENVIRONMENT === 'development') {
    return true;
  } else {
    const isMVP = query.get('isMVP');
    return !!isMVP;
  }
};
