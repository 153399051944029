import React from 'react';
import styled from 'styled-components';
import { ReactComponent as HowWorksIcon } from 'images/lifebuoy.svg';
import { TooltipContainer } from 'components/Tooltip';
import { Column, Row } from 'components/Core';

const HowItWorksDice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 163px;
  height: 92px;
  padding: 20px;
  border: 1px solid var(--accentGraySecond);
  border-radius: 20px;
  margin-right: 20px;
  cursor: pointer;

  &:hover {
    background-color: white;
    border: 1px solid var(--neutralGray);
  }
`;

const HowItWorkTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

const HowWorksTooltip = styled.div`
  font-size: 12px;
  line-height: 16px;
  padding: 12px 10px;
  text-align: left;
`;

const ToolTipNumber = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 100px;
`;

const TooltipSubitem = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;

  span {
    opacity: 0.5;
  }
`;

const TooltipItemDot = styled.div`
  margin-top: 4px;
  opacity: 0.23;
  min-width: 8px;
  width: 8px;
  height: 8px;
  border-radius: 100px;
  margin-right: 8px;
  border: 2px solid #ffffff;
`;

const ToolTipContent = (
  <HowWorksTooltip>
    <Row vertical="flex-start">
      <ToolTipNumber>1</ToolTipNumber>
      <Column vertical="flex-start" horizontal="flex-start">
        For a successful import, you need to fill in all the required fields for all data, so you can:
        <TooltipSubitem>
          <TooltipItemDot />
          <span>Match each column of the data in Subscript with the columns of your file</span>
        </TooltipSubitem>
        <TooltipSubitem>
          <TooltipItemDot />
          <span>Fix your CSV file and re-upload it</span>
        </TooltipSubitem>
        <TooltipSubitem>
          <TooltipItemDot />
          <span>Manually make changes</span>
        </TooltipSubitem>
      </Column>
    </Row>

    <Row vertical="flex-start" style={{ marginTop: 16 }}>
      <ToolTipNumber>2</ToolTipNumber>
      <Column vertical="flex-start" horizontal="flex-start">
        When you have checked all the data, please click on the "Finish uploading" button
      </Column>
    </Row>
  </HowWorksTooltip>
);

export const HowItWorks = () => (
  <TooltipContainer width={310} yOffset={10} backgroundColor="rgba(64, 80, 98, 0.98)" toolTipContent={ToolTipContent}>
    <HowItWorksDice>
      <HowWorksIcon />
      <HowItWorkTitle>How does it work?</HowItWorkTitle>
    </HowItWorksDice>
  </TooltipContainer>
);
