import { useState } from 'react';
import styled from 'styled-components';

import { FormikCustomSelector, InputLabel } from 'components/Controls';
import { FlexBetweenContainer } from 'components/Core';
import { convertToPlainText, templateToHTMLString } from 'utils/htmlUtils';
import { StyledDynamicFieldsTooltip } from 'views/Billing/BillingDynamicFieldsTooltip';
import { ContentEditableInput } from 'components/Controls/FormikCustomInput/ContentEditableInput';

const FlexShrink = styled.div`
  flex-shrink: 1;
`;

export const FooterMemoInput = ({ values, availableTags, setFieldValue, templates }) => {
  const [options, setOptions] = useState(
    templates.map((template) => ({
      label: template.name,
      value: template.id,
      template,
    })),
  );

  const selectedOption =
    options.find((option) =>
      values.footer_memo_template_id
        ? option.value === values.footer_memo_template_id
        : option.value === values.footer_memo_name,
    ) ?? null;

  const addCreatableOption = ({ name }) => {
    const hasCreatableOption = options.some((option) => option.isCreatable);
    const creatableOption = {
      label: name,
      value: name,
      isCreatable: true,
      template: {
        name,
      },
    };

    const newOptions = hasCreatableOption
      ? options.map((option) => {
          if (!option.isCreatable) return option;
          return {
            ...creatableOption,
            template: {
              ...option.template,
              ...creatableOption.template,
            },
          };
        })
      : [...options, creatableOption];

    setOptions(newOptions);
  };

  const handleNameChange = (option) => {
    const { template } = option ?? {};
    setFieldValue('footer_memo_template_id', template?.id ?? null);
    setFieldValue('footer_memo_name', template?.name ?? '');
    setFieldValue('footer_memo', template?.content ?? '');
  };

  const handleContentChange = (value) => {
    const adjustedValue = convertToPlainText(value);
    setFieldValue('footer_memo', adjustedValue);
    selectedOption?.isCreatable &&
      setOptions((prev) =>
        prev.map((option) =>
          option.isCreatable
            ? {
                ...option,
                template: {
                  ...option.template,
                  content: adjustedValue,
                },
              }
            : option,
        ),
      );
  };

  const handleCreate = (name) => {
    setFieldValue('footer_memo_template_id', null);
    setFieldValue('footer_memo_name', name);
    values.footer_memo_template_id && setFieldValue('footer_memo', '');
    addCreatableOption({ name });
  };

  return (
    <>
      <FormikCustomSelector
        name="footer_memo_template_id"
        label="Footer memo template"
        placeholder="Select or Create template..."
        value={selectedOption}
        options={options}
        creatable
        onCreateOption={handleCreate}
        handleChange={handleNameChange}
        isClearable
      />

      <FlexBetweenContainer>
        <InputLabel fontSize="12px" margin="0px">
          Footer memo
        </InputLabel>
        <FlexShrink>
          <StyledDynamicFieldsTooltip>
            Type <span>@</span> to use dynamic fields
          </StyledDynamicFieldsTooltip>
        </FlexShrink>
      </FlexBetweenContainer>
      <ContentEditableInput
        name="footer_memo"
        label="Footer memo"
        data-cy="entity-modal__footer-memo__input"
        minHeight="166px"
        HTMLString={templateToHTMLString({ text: values?.footer_memo, availableTags })}
        availableTags={availableTags}
        type="HTMLEdit"
        onChange={handleContentChange}
      />
    </>
  );
};
