import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { useExternalCustomerAPI } from 'api/customers/hooks';
import { useImportsAPI } from 'api/imports';

import { humanize } from 'utils/stringUtils';
import { useClickOutside } from 'utils/hooks';
import { CentererVertical, Flexer } from 'components/Core';
import { EditCircleIcon, AlertCircleIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { Button } from 'components/Buttons';
import { Loader } from 'components/Loaders';
import { Popover, PopoverItemsContainer, PopoverListItem, PopoverWrapper } from 'components/Portal';

import { CustomersActionsModal, CUSTOMERS_MODAL_ACTIONS } from 'views/Customers/CustomersActionsModal';

const StyledButton = styled(Button)`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  box-shadow: 4px 4px 28px var(--primaryBlack5);
  color: ${(props) => props.active && 'var(--primaryBlack70)'};

  &:hover {
    box-shadow: 0 4px 20px var(--primaryBlack10);
    border: 1px solid var(--neutralGray);
  }
`;

const CustomerExternalSelectorWrapper = styled(CentererVertical)`
  font-weight: normal;
  white-space: nowrap;
`;

const RoundedLabelWrapper = styled(Button)`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  box-shadow: 4px 4px 28px var(--primaryBlack5);
  background: ${(props) => (props.warning ? 'var(--primaryYellow15)' : 'var(--primaryBlack2)')};
  color: ${(props) => (props.warning ? 'var(--tertiaryYellow)' : 'var(--dark7)')};
  border: none;

  &:hover {
    background: ${(props) => (props.warning ? 'var(--primaryYellow30)' : 'var(--primaryBlack4)')};
  }
`;

const RoundedWrapper = styled(Flexer)`
  font-size: 14px;
  border-radius: 100px;
  background: ${(props) => (props.warning ? 'var(--primaryYellow15)' : 'var(--primaryBlack2)')};
  color: ${(props) => (props.warning ? 'var(--tertiaryYellow)' : 'var(--primaryBlack)')};
  padding: 4px;
  gap: 8px;
`;

const ExternalCustomerName = styled.div`
  color: var(--primaryBlack);
  width: 100%;
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
`;

const CustomerExternalLinks = ({ invoice }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  const options = useMemo(
    () =>
      invoice?.customer_external?.reduce((acc, curr) => {
        acc.push({
          service: curr.service,
          url: curr.external_url,
        });
        return acc;
      }, []),
    [invoice],
  );

  return options?.length ? (
    <>
      <PopoverWrapper ref={dropdownRef}>
        <StyledButton active={showDropdown} onClick={toggleDropdown}>
          Customer Details
        </StyledButton>

        {showDropdown && (
          <Popover YOffset={44}>
            <PopoverItemsContainer>
              {options.map((option) => (
                <PopoverListItem key={option.service} onClick={() => window.open(option.url, '_blank')}>
                  In {humanize(option.service)}
                </PopoverListItem>
              ))}
            </PopoverItemsContainer>
          </Popover>
        )}
      </PopoverWrapper>
    </>
  ) : (
    <></>
  );
};

export const CustomerExternalSelector = ({
  invoice,
  customerId,
  invoicingService,
  invoicingServiceDisplayName,
  onModalClose,
  integrationId,
}) => {
  const { orgId } = useContext(AppContext);
  const [showCustomersActionModal, setShowCustomersActionModal] = useState(false);
  const [externalCustomerName, setExternalCustomerName] = useState('');

  const { data: glImports, isLoading: importIsLoading } = useImportsAPI({
    orgId: orgId,
    filters: {
      integrationIds: integrationId ? [integrationId] : [],
      chifferObjectName: 'customer',
      chifferObjectIds: [customerId],
    },
  });

  const existingGlImport = glImports?.[0] ?? null;

  const { data: externalCustomer, isLoading: externalCustomerIsLoading } = useExternalCustomerAPI({
    orgId,
    externalCustomerId: existingGlImport?.provider_object_id,
    autoFetch: !!existingGlImport && !existingGlImport?.metadata?.customer_name, // we only fetch if we don't already have the customer_name cached
  });

  useEffect(() => {
    if (existingGlImport?.metadata?.customer_name) {
      setExternalCustomerName(
        `${existingGlImport?.metadata?.customer_name || '<No Name>'} / ${existingGlImport.provider_object_id}`,
      );
    } else if (externalCustomer) {
      setExternalCustomerName(`${externalCustomer.name || '<No Name>'} / ${externalCustomer.id || ''}`);
    }
  }, [externalCustomer, existingGlImport]);

  return (
    <CustomerExternalSelectorWrapper>
      {importIsLoading || externalCustomerIsLoading ? (
        <Loader containerStyles={{ width: 20, margin: 20 }} />
      ) : existingGlImport ? (
        <RoundedWrapper>
          <RoundedLabelWrapper
            data-cy="customer-external-selector--edit"
            onClick={(e) => {
              e.preventDefault();
              setShowCustomersActionModal(true);
            }}
          >
            <CentererVertical gap="8px">
              {`Customer in ${invoicingServiceDisplayName ? invoicingServiceDisplayName : 'GL'}:`}
              <TooltipContainer
                toolTipContent={externalCustomerName}
                tooltipWrapperStyles={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ExternalCustomerName data-cy="customer-external-selector--selected-name">
                  {externalCustomerName}
                </ExternalCustomerName>
              </TooltipContainer>
              <EditCircleIcon size="16px" fill="black" />
            </CentererVertical>
          </RoundedLabelWrapper>
          {invoice && <CustomerExternalLinks invoice={invoice} />}
        </RoundedWrapper>
      ) : invoicingService ? (
        <CentererVertical gap="8px">
          <TooltipContainer
            toolTipContent={`Subscript couldn't find the customer in ${invoicingServiceDisplayName}. Click to select it manually.`}
          >
            <RoundedLabelWrapper
              data-cy="customer-external-selector--edit"
              warning
              onClick={(e) => {
                e.preventDefault();
                setShowCustomersActionModal(true);
              }}
            >
              <CentererVertical gap="8px">
                <AlertCircleIcon size="16px" fill="var(--tertiaryYellow)" />
                {`We'll create a new customer in ${invoicingServiceDisplayName}`}
                <Button fontWeight={700}>Select existing one</Button>
              </CentererVertical>
            </RoundedLabelWrapper>
          </TooltipContainer>
          {invoice && <CustomerExternalLinks invoice={invoice} />}
        </CentererVertical>
      ) : (
        <></>
      )}

      {showCustomersActionModal && (
        <CustomersActionsModal
          closeModal={() => {
            setShowCustomersActionModal(false);
            onModalClose && onModalClose();
          }}
          modalAction={CUSTOMERS_MODAL_ACTIONS.EDIT}
          customer={{ id: customerId }}
        />
      )}
    </CustomerExternalSelectorWrapper>
  );
};
