import styled, { css } from 'styled-components';
import { FlexerColumn, FlexBetweenContainer, Flexer } from 'components/Core';

export const Container = styled(Flexer)`
  margin-bottom: 20px;
`;

export const PanelContainer = styled(FlexerColumn)`
  padding: 20px;
  padding-top: 50px;
  background-color: var(--primaryBlack2);
  border-radius: 8px;
  width: 480px;
  margin-left: 40px;
  gap: 16px;
  border: 1px solid var(--primaryBlack5);
  border-right: ${({ isSidePanelOpened }) => (!isSidePanelOpened ? 'auto' : 'none')};
  border-top-right-radius: ${({ isSidePanelOpened }) => (!isSidePanelOpened ? 'auto' : '0px')};
  border-bottom-right-radius: ${({ isSidePanelOpened }) => (!isSidePanelOpened ? 'auto' : '0px')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  ${({ isLoading }) =>
    isLoading &&
    css`
      justify-content: center;
      align-items: center;
    `}
`;

export const StyledText = styled.span`
  display: inline-block;
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  color: ${({ color }) => color ?? 'var(--primaryBlack)'};
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
  font-style: ${({ fontStyle }) => fontStyle ?? 'auto'};
`;

export const SubStyledText = styled(StyledText)`
  color: var(--primaryBlack50);
`;

export const SectionWrapper = styled(FlexerColumn)`
  gap: 16px;
  border: 1px solid var(--primaryBlack3);
  border-radius: 8px;
  padding: 12px;
  align-items: center;
  background-color: ${({ bgcolor }) => bgcolor ?? 'var(--primaryBlack2)'};
  min-height: 50px;
  position: relative;
  width: 100%;

  ${({ disableActions, selected }) =>
    !disableActions &&
    css`
      background-color: ${selected ? 'var(--primaryBlue10)' : 'auto'};

      &:hover {
        cursor: pointer;
        background-color: ${selected ? 'var(--primaryBlue10)' : 'var(--primaryBlue5)'};
        border: 1px solid var(--primaryBlue10);
      }
    `};
`;

export const Section = styled(FlexBetweenContainer)`
  width: 100%;
`;
