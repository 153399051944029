import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { Centerer, Container, FlexBetweenContainer, Flexer, Spacer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { Portal } from 'components/Portal';
import { templateToHTMLString } from 'utils/htmlUtils';
import { ContentEditableInput } from 'components/Controls/FormikCustomInput/ContentEditableInput';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';

const InputTitle = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  opacity: 0.4;
  line-height: 16px;
`;

const InternalNoteModal = ({ onClose, values }) => {
  const { editInvoice, isInvoiceLoading, isInvoiceFetching } = useContext(InvoicingScheduleContext);

  const [draftValue, setDraftValue] = useState(values?.internal_notes);

  return (
    <ModalContainer data-cy="internal-note-modal">
      <Modal maxWidth="440PX" height="auto">
        <ModalHeader>
          <ModalTitle>
            <ModalTitleText>
              <b>Add Note</b>
            </ModalTitleText>
          </ModalTitle>
          <ModalCloseIcon onClose={onClose} />
        </ModalHeader>

        <Spacer height="20px" />

        {isInvoiceLoading || isInvoiceFetching ? (
          <ModalBody>
            <Centerer width="100%">
              <CircleLoader />
            </Centerer>
          </ModalBody>
        ) : (
          <ModalBody>
            <Container>
              <InputTitle>For invoice on {dayjs.utc(values?.date).format('MMM D, YYYY')}</InputTitle>

              <ContentEditableInput
                onChange={(value) => setDraftValue(value)}
                minHeight="96px"
                HTMLString={templateToHTMLString({ text: draftValue ?? '' })}
                placeholder="Add your notes about this invoice..."
                availableTags={[]}
              />
            </Container>
          </ModalBody>
        )}

        <Spacer height="20px" />

        <ModalFooter>
          <FlexBetweenContainer centerer>
            <div />
            <Flexer>
              <ModalButton onClick={onClose}>Cancel</ModalButton>
              <ModalButton
                data-cy="edit-internal-note-modal__save-button"
                primary
                onClick={() => {
                  editInvoice({
                    id: values?.id,
                    data: {
                      internal_notes: draftValue,
                    },
                  });
                  onClose();
                }}
              >
                Save
              </ModalButton>
            </Flexer>
          </FlexBetweenContainer>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

export const useInternalNoteModal = (props) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const Modal = useCallback(
    () =>
      showModal ? (
        <Portal>
          <InternalNoteModal {...props} onClose={() => setShowModal(false)} />
        </Portal>
      ) : null,
    [showModal, setShowModal, props],
  );

  return {
    openModal,
    Modal,
  };
};
