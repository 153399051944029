import React, { useContext } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { NUMBER_FORMATS } from 'consts/global';
import { AppContext } from 'AppContext';
import { TooltipContainer } from 'components/Tooltip';
import { Centerer, CentererVertical, Flexer, FlexBetweenContainer, FlexerColumn, FlexerRow } from 'components/Core';
import { AlertFilledIcon, ArrowNarrowUpIcon, CircleCheckIcon } from 'components/Icons';
import { CircleLoader } from 'components/Loaders';
import { numberFormatter } from 'utils/formatters';
import { CreditNoteContext } from '../CreditNoteContext';
import { InvoiceCounter, Wrapper } from './InvoiceLineItem';
import { CreditNotesActions } from './CreditNotesActions';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { CREDIT_NOTE_JOB_STATUS, CREDIT_NOTE_METADATA } from 'views/Billing/consts';

const ScrollContainer = styled(FlexerColumn)`
  height: 100%;
  overflow: auto;
`;

const ItemText = styled(Flexer)`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  gap: 8px;
`;

const EmtyStateContainer = styled(Centerer)`
  width: 100%;
  height: 100%;
  opacity: 0.2;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const CreditNoteStatus = styled(CentererVertical)`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  gap: 7px;
`;

export const CreditNotesTab = () => {
  const {
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const { creditNotes, loadingCreditNotes: loading, selectedCreditNoteId, setSelectedCreditNoteId } = useContext(
    CreditNoteContext,
  );

  const { invoicingServiceDisplayName } = useContext(InvoicingScheduleContext);

  return (
    <>
      {loading ? (
        <CircleLoader name="credit_notes" width={48} height={48} />
      ) : creditNotes?.length > 0 ? (
        <ScrollContainer data-cy={'billing__invoice_schedule-modal__credit_nodes-list'}>
          {creditNotes.map((creditNote, index) => (
            <Wrapper
              key={index}
              onClick={() => setSelectedCreditNoteId(creditNote.id ?? creditNote.unsavedId)}
              selected={
                selectedCreditNoteId &&
                ((!!creditNote?.id && creditNote.id === selectedCreditNoteId) ||
                  (!!creditNote.unsavedId && creditNote.unsavedId === selectedCreditNoteId))
              }
            >
              <FlexBetweenContainer>
                <FlexerRow gap="8px" alignItems="center">
                  <InvoiceCounter>{index + 1}</InvoiceCounter>
                  <ItemText>
                    <span>{dayjs.utc(creditNote.date).format('MMM DD, YYYY')}</span>
                    {creditNote.voided_at ? (
                      <CreditNoteStatus data-cy="credit-notes-tab__credit-note-item__status">Voided</CreditNoteStatus>
                    ) : (
                      <>
                        <span>
                          {numberFormatter({
                            rawValue: creditNote.amount ?? 0,
                            type: NUMBER_FORMATS.CURRENCY,
                            decimalPlaces: 2,
                            currency: creditNote.currency ?? currency,
                          })}
                        </span>
                        {!isEmpty(creditNote.allocations) && (
                          <TooltipContainer
                            toolTipContent={
                              creditNote.allocated_externally
                                ? `Saved & Allocated on ${invoicingServiceDisplayName}`
                                : 'Allocated on Subscript'
                            }
                          >
                            <Centerer gap="4px">
                              Allocated
                              <CircleCheckIcon color="var(--primaryGreen)" opacity={1} />
                              {creditNote.allocated_externally && (
                                <CircleCheckIcon color="var(--primaryGreen)" opacity={1} />
                              )}
                            </Centerer>
                          </TooltipContainer>
                        )}
                        {creditNote?.metadata?.[CREDIT_NOTE_METADATA.JOB_STATUS] === CREDIT_NOTE_JOB_STATUS.FAILED && (
                          <AlertFilledIcon />
                        )}
                      </>
                    )}
                  </ItemText>
                </FlexerRow>

                <CreditNotesActions
                  isSelected={
                    selectedCreditNoteId &&
                    ((!!creditNote?.id && creditNote.id === selectedCreditNoteId) ||
                      (!!creditNote.unsavedId && creditNote.unsavedId === selectedCreditNoteId))
                  }
                  creditNote={creditNote}
                />
              </FlexBetweenContainer>
            </Wrapper>
          ))}
        </ScrollContainer>
      ) : (
        <EmtyStateContainer>
          <Centerer width="208px" height="100%" gap="10px" direction="column">
            <ArrowNarrowUpIcon />
            <div>Import from your sources or manually create new credit notes</div>
          </Centerer>
        </EmtyStateContainer>
      )}
    </>
  );
};
