import React, { useCallback, useContext, useMemo, useState } from 'react';
import { AppContext } from 'AppContext';
import { CsvUpload } from 'shared/CsvUpload';
import { useToasts } from 'components/Toasts';
import { addQuotesToKeysAndValuesToCreateValidJSON, stringToBoolean } from 'utils/stringUtils';
import { usePricingPlansAPI } from 'api/usageBasedEngine/hooks';
import { getColumns } from './columns';
import { getSchema } from './schema';
import { DEFAULT_PRICING_PLAN_MAPPER } from './consts';

export const PricingPlansCsvUpload = () => {
  const { organizations, orgId } = useContext(AppContext);
  const [submittingForm, setSubmittingForm] = useState(false);
  const { pushError } = useToasts();

  const {
    operations: { bulkUpload },
  } = usePricingPlansAPI({ autoFetch: false, orgId });

  const getColumnsWithCsvUploadState = useCallback(
    ({
      fuzzyRowsMapper,
      defaultMapper,
      setFuzzyRowsMapper,
      formRef,
      csvColumnsMapper,
      setCsvColumnsMapper,
      csvColumns,
    }) =>
      getColumns({
        organization: organizations[0],
        formRef,
        csvColumns,
        csvColumnsMapper,
        setCsvColumnsMapper,

        fuzzyRowsMapper,
        setFuzzyRowsMapper,

        defaultMapper,
      }),
    [organizations],
  );

  const handleSubmit = async (values) => {
    setSubmittingForm(true);

    // Get all the JSON converted first, throw errors if cannot
    for (const value of values) {
      try {
        value.tiersJSON = JSON.parse(addQuotesToKeysAndValuesToCreateValidJSON(value.tiers));
        delete value.tiers;

        // Also, make sure that bill_in_advance is not null
        value.bill_in_advance = value.bill_in_advance ?? false;
      } catch (error) {
        pushError({
          response: {
            data: { errors: { message: 'Error parsing JSON in tiers column for pricing plan: ' + value.name } },
          },
        });
        setSubmittingForm(false);
        return;
      }
    }

    await bulkUpload({
      data: values.map((value) => ({
        ...value,
        tiers: value.tiersJSON,
      })),
    });

    setSubmittingForm(false);
  };

  const tableSchema = useMemo(() => {
    return getSchema({
      products: organizations[0]?.products,
    });
  }, [organizations]);

  const isLoading = submittingForm;

  return (
    <CsvUpload
      entityName="Pricing-Plans"
      backLink="/billing/usage-based-engine?tab=pricing-plans"
      isLoading={isLoading}
      schema={tableSchema}
      handleSubmit={handleSubmit}
      csvColumnsTransformations={{ bill_in_advance: stringToBoolean }}
      defaultMapper={DEFAULT_PRICING_PLAN_MAPPER}
      getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
    />
  );
};
