import React, { useContext, useState, useEffect, useRef } from 'react';
import { isNil, pick } from 'lodash';
import { Formik } from 'formik';
import { AppContext } from 'AppContext';
import { Centerer } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { getJobData } from 'api/jobs';
import { useQueryPolling } from 'api/jobHooks.helper';
import { CREDIT_NOTE_JOB_STATUS, CREDIT_NOTE_METADATA } from 'views/Billing/consts';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { CreditNoteContext } from '../CreditNoteContext';
import { SingleCreditNote } from './SingleCreditNote';
import { CreditNoteHeader } from './CreditNoteHeader';
import { CreditNoteEmptyState } from './CreditNoteEmptyState';
import { CREDIT_NOTE_FIELDS, CREDIT_NOTE_SCHEMA, DEFAULT_CREDIT_NOTE_TITLE } from './consts';
import { getCreditNoteData } from './utils';

export const InvoicingScheduleCreditNotePanel = () => {
  const { orgId, orgConfigs } = useContext(AppContext);

  const { billingCreditNoteDefaults } = orgConfigs;

  const {
    refetchInvoicingSchedule,
    refetchCurrentInvoice,
    setSelectedInvoiceId,
    currentInvoicingSchedule,
    selectedInvoiceId,
    customerDetails,
  } = useContext(InvoicingScheduleContext);

  const {
    setSelectedCreditNoteId,
    addCreditNote,
    editCreditNote,
    allocateCreditNote,
    updateExternalCreditNote,
    selectedCreditNote,
    allocateExternalCreditNote,
    refetchCreditNotes,
    sendCreditNote,
    creditNoteFormRef,
  } = useContext(CreditNoteContext);

  const [isLoading, setIsLoading] = useState(false);
  const [saveInExternalSystem, setSaveInExternalSystem] = useState(false);
  const [allocateInExternalSystem, setAllocateInExternalSystem] = useState(false);
  const [isSendCreditNote, setIsSendCreditNote] = useState(false);
  const [isTestSend, setIsTestSend] = useState(false);
  const prevPollingTaxesData = useRef(null);

  const { data: pollingTaxesData } = useQueryPolling({
    key: ['billing', orgId, 'creditNotePollingTaxes', selectedCreditNote?.id],
    jobId: selectedCreditNote?.polling_taxes_job_id ?? null,
    fetchWithJobIdFn: (jobId) => getJobData({ orgId, jobId, queueName: 'criticalWithRetry' }),
    alwaysReturnData: true,
    queryOptions: {
      staleTime: 0,
      cacheTime: 0,
    },
  });

  useEffect(() => {
    if (!saveInExternalSystem && !!selectedCreditNote?.credit_note_number) {
      setSaveInExternalSystem(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCreditNote]);

  useEffect(() => {
    if (!isNil(pollingTaxesData) && pollingTaxesData !== prevPollingTaxesData.current) {
      prevPollingTaxesData.current = pollingTaxesData;
      refetchCurrentInvoice();
      refetchCreditNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollingTaxesData]);

  const handleSendMode = ({ isSend = false, isTestSend = false }) => {
    setIsSendCreditNote(isSend);
    setIsTestSend(isTestSend);
  };

  const handleOnSubmit = async (values) => {
    const cleanedValues = getCreditNoteData(
      pick(
        { ...values, title: values.title?.trim(), credit_note_number: values.credit_note_number?.trim() || null },
        Object.keys(CREDIT_NOTE_FIELDS),
      ),
    );

    try {
      setIsLoading(true);
      let updatedCreditNote;

      let reallocated = false;
      const totalAllocatedAmount = Object.values(selectedCreditNote.allocations ?? {}).reduce(
        (acc, curr) => acc + curr,
        0,
      );
      const newAmount =
        (cleanedValues.items ?? []).reduce((acc, curr) => acc + curr.amount, 0) + (selectedCreditNote.total_tax ?? 0);

      cleanedValues.total_tax = cleanedValues.items.reduce((acc, item) => acc + (item.tax_amount ?? 0), 0);

      // The credit note was allocated with a higher amount, need to re-allocate *before* updating the amount
      if (cleanedValues.allocated_invoice && totalAllocatedAmount > newAmount) {
        await allocateCreditNote.mutateAsync({
          id: cleanedValues.id,
          data: {
            [cleanedValues.allocated_invoice]: newAmount,
          },
        });
        reallocated = true;
      }

      if (cleanedValues.metadata?.[CREDIT_NOTE_METADATA.JOB_STATUS] === CREDIT_NOTE_JOB_STATUS.FAILED) {
        cleanedValues.metadata[CREDIT_NOTE_METADATA.JOB_STATUS] = null;
        cleanedValues.metadata[CREDIT_NOTE_METADATA.JOB_FAILED_AT] = null;
        cleanedValues.metadata[CREDIT_NOTE_METADATA.JOB_FAILED_MESSAGE] = '';
      }

      if (!cleanedValues.id) {
        updatedCreditNote = await addCreditNote.mutateAsync({ data: cleanedValues });
        setSelectedCreditNoteId(updatedCreditNote.id);
      } else if (!allocateInExternalSystem) {
        // We don't save the credit note again if allocating in external system, as that would require
        //  long polling for taxes.
        updatedCreditNote = await editCreditNote.mutateAsync({
          id: cleanedValues.id,
          data: cleanedValues,
          reallocating: reallocated,
        });
      } else {
        updatedCreditNote = selectedCreditNote;
      }

      if (!allocateInExternalSystem && cleanedValues.allocated_invoice && !reallocated) {
        await allocateCreditNote.mutateAsync({
          id: updatedCreditNote.id,
          data: {
            [cleanedValues.allocated_invoice]: updatedCreditNote.amount, // amount is inclusive of tax
          },
        });
      }

      if (saveInExternalSystem || allocateInExternalSystem) {
        const action = allocateInExternalSystem ? allocateExternalCreditNote : updateExternalCreditNote;

        const response = await action.mutateAsync({
          id: updatedCreditNote.id,
          integrationId: currentInvoicingSchedule?.integration_id,
        });

        if (!allocateInExternalSystem && response?.metadata?.shouldPollTaxes) {
          refetchCreditNotes();
        }
      }

      if (isSendCreditNote) {
        await sendCreditNote.mutateAsync({
          creditNoteId: selectedCreditNote?.id,
          params: {
            testSend: isTestSend,
            invoiceId: cleanedValues.allocated_invoice,
            entityId: currentInvoicingSchedule?.entity_id,
            language: currentInvoicingSchedule?.language,
          },
        });

        handleSendMode({ isSend: false, isTestSend: false });
      }

      if (cleanedValues.allocated_invoice && cleanedValues.allocated_invoice !== selectedInvoiceId) {
        setSelectedInvoiceId(cleanedValues.allocated_invoice);
      }

      if (cleanedValues.allocated_invoice || selectedCreditNote.allocated_invoice) {
        setTimeout(refetchInvoicingSchedule, 100);
      }

      prevPollingTaxesData.current = null;
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Centerer style={{ width: '100%' }}>
        <CircleLoader name="single-credit-note" />
      </Centerer>
    );
  }

  if (!selectedCreditNote) {
    return <CreditNoteEmptyState />;
  }

  const isWaitingForTaxes = selectedCreditNote.polling_taxes_job_id && isNil(pollingTaxesData);

  return (
    <div style={{ width: '100%' }} data-cy="credit-note-view">
      <Formik
        initialValues={{
          // For now we just allow one allocation
          allocated_invoice: Object.keys(selectedCreditNote.allocations ?? {})?.[0] ?? null,
          ...selectedCreditNote,
          title: selectedCreditNote?.title || DEFAULT_CREDIT_NOTE_TITLE,
          send_to: selectedCreditNote?.send_to?.length
            ? selectedCreditNote.send_to
            : customerDetails?.invoicing_details?.contacts?.length
            ? customerDetails.invoicing_details.contacts
            : undefined,
          email_subject: selectedCreditNote?.email_subject || billingCreditNoteDefaults?.email_subject || undefined,
          send_cc: selectedCreditNote?.send_cc?.length
            ? selectedCreditNote.send_cc
            : billingCreditNoteDefaults?.send_cc ?? [],
          email_body: selectedCreditNote?.email_body || billingCreditNoteDefaults?.email_body,
          metadata: selectedCreditNote?.metadata ?? {},
        }}
        enableReinitialize={true}
        validationSchema={CREDIT_NOTE_SCHEMA}
        onSubmit={handleOnSubmit}
        innerRef={creditNoteFormRef}
      >
        {({ submitForm }) => (
          <>
            <CreditNoteHeader
              selectedCreditNote={selectedCreditNote}
              onSaveCreditNote={submitForm}
              setSaveInExternalSystem={setSaveInExternalSystem}
              setAllocateInExternalSystem={setAllocateInExternalSystem}
              isWaitingForTaxes={isWaitingForTaxes}
              handleSendMode={handleSendMode}
            />
            <SingleCreditNote isWaitingForTaxes={isWaitingForTaxes} pollingTaxesData={pollingTaxesData} />
          </>
        )}
      </Formik>
    </div>
  );
};
