import { ReactComponent as ArrowDown } from 'images/chevron-down.svg';

import { PanelContainer } from './styles';
import { ImportInvoiceList } from './ImportInvoiceList';
import { StyledArrow } from '../styles';

export const InvoicingScheduleSelectImportInvoicesPanel = ({
  onClose,
  isOpen,
  importedInvoices,
  onRemoveInvoice,
  onImportInvoicesSelect,
  isFloating,
  YOffset,
  XOffset,
}) => {
  return (
    <PanelContainer show={isOpen} isFloating={isFloating} YOffset={YOffset} XOffset={XOffset}>
      <ImportInvoiceList
        importedInvoices={importedInvoices}
        onRemoveInvoice={onRemoveInvoice}
        onImportInvoicesSelect={onImportInvoicesSelect}
      />
      <StyledArrow
        onClick={onClose}
        active={isOpen}
        data-cy="billing__invoice-schedule-modal__close-external-invoices-list-button"
        XOffset="-15px"
      >
        <ArrowDown width={16} height={16} />
      </StyledArrow>
    </PanelContainer>
  );
};
