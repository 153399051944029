import styled, { css } from 'styled-components';
import { Centerer, Flexer, FlexBetweenContainer } from 'components/Core';
import { DIRECTIONS } from './consts';

const PopoverWrapper = styled.div`
  position: relative;
  transition: all ease 0.4s;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const Popover = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.darkMode ? 'var(--dark7)' : 'white')};
  left: ${(props) => (props.XOffset ? `${props.XOffset}px` : '50%')};
  transform: translate(-50%, 0);
  border: ${({ darkBorder, darkMode }) =>
    `1px solid ${darkBorder || darkMode ? 'var(--neutralGray)' : 'var(--accentGray)'}`};
  box-shadow: ${({ boxShadow }) => boxShadow ?? '16px 16px 60px var(--primaryBlack20)'};
  border-radius: ${({ borderRadius }) => borderRadius ?? '12px'};
  z-index: ${(props) => props.zIndex ?? 25};
  top: ${(props) => (props.YOffset ? `${props.YOffset}px` : '53px')};
  padding: ${({ padding }) => padding ?? 0};
  width: ${(props) => props.width ?? '140px'};
  height: ${(props) => props.height ?? 'auto'};

  svg {
    margin-right: 6px;
  }

  ::before {
    content: '';
    display: ${(props) => (props.showArrow ? 'block' : 'none')};
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid ${(props) => (props.darkMode ? 'var(--dark7)' : 'white')};
    transform: ${(props) =>
      props.arrowDirection === DIRECTIONS.BOTTOM
        ? 'translate(-50%, 0) rotate(270deg)'
        : 'translate(-50%, 0) rotate(90deg)'};
    left: 50%;
    top: ${(props) => (props.arrowDirection === DIRECTIONS.BOTTOM ? 'calc(100% - 5px)' : '-10px')};
  }
`;

const StyledPopover = styled(Popover)`
  align-items: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-transform: none;
  text-align: center;
`;

// Use the components/Tooltip if it works. Use this instead if having issue with positioning.
const TooltipPopover = ({ children, padding, borderRadius, boxShadow, width, XOffset, YOffset, ...rest }) => (
  <StyledPopover
    darkMode
    padding={padding ?? '4px 8px'}
    borderRadius={borderRadius ?? '4px'}
    boxShadow={boxShadow ?? '3px 3px 10px var(--primaryBlack25)'}
    width={width ?? '125px'}
    XOffset={XOffset ?? 64}
    YOffset={YOffset ?? 22}
    {...rest}
  >
    {children}
  </StyledPopover>
);

const PopoverItemsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  white-space: nowrap;
`;

const PopoverListItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
  padding: 12px;
  width: 100%;
  background-color: ${(props) => (props.disabled ? 'var(--accentGray)' : 'initial')};
  color: ${(props) => (props.color ? props.color : 'var(--primaryBlack)')};
  pointer-events: ${(props) => props.disabled && 'none'};
  gap: ${(props) => props.gap};
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  border-bottom: 1px solid var(--accentGraySecond);

  &:first-child {
    border-top: none;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ disabled }) => !disabled && 'var(--primaryBlack3)'};
    opacity: ${({ disabled }) => !disabled && '0.7'};
  }
  opacity: ${({ disabled }) => disabled && 0.7};
`;

// Full width/height versions
const PopoverListItemIconFull = styled.div`
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
    margin: 0;

    g {
      opacity: 1;
    }

    path {
      fill: ${({ color }) => color ?? 'var(--primaryBlack70)'};
    }
  }
`;

const PopoverListItemTitleFull = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  color: ${({ titleColor }) => titleColor ?? 'var(--primaryBlack)'};
`;

const PopoverListItemDescriptionFull = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: var(--primaryBlack70);
`;

const PopoverListItemFull = styled(Flexer)`
  width: 100%;
  padding: ${({ padding }) => padding ?? '16px 12px'};

  cursor: ${({ isDisabled }) => (isDisabled ? undefined : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background: var(--primaryBlack3);

    ${PopoverListItemIconFull} {
      svg {
        path {
          fill: var(--primaryBlack75);
        }
      }
    }
  }
`;

const PopoverPrompt = styled.div`
  color: white;
  opacity: ${({ opacity }) => opacity ?? 0.5};
  padding: 8px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  white-space: pre-wrap;
`;

const PopoverActions = styled(FlexBetweenContainer)`
  gap: 4px;
  padding: ${({ padding }) => padding ?? '4px'};
  width: 100%;
`;

const PopoverButton = styled(Centerer)`
  background: ${({ primary }) => (primary ? 'var(--white15)' : 'var(--dark5)')};
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  padding: 8px;
  text-align: center;
  color: white;
  font-size: 12px;
  line-height: 16px;
  flex: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export {
  PopoverWrapper,
  Popover,
  TooltipPopover,
  PopoverItemsContainer,
  PopoverListItem,
  PopoverListItemFull,
  PopoverListItemIconFull,
  PopoverListItemTitleFull,
  PopoverListItemDescriptionFull,
  PopoverPrompt,
  PopoverActions,
  PopoverButton,
};
