import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { ReactComponent as PlusIcon } from 'images/plus.svg';
import { GlobalTogglesFloatingContainer } from 'shared/GlobalToggles';
import { Centerer, Spacer } from 'components/Core';
import { CardsGrid } from '../Common/Card';
import { DashboardContext } from '../DashboardContext';
import { DashboardCard } from '../DashboardOverview/DashboardCard';
import { DashboardCustomersCard } from '../DashboardOverview/DashboardCustomersCard';
import { DashboardRecurringRevenueCard } from '../DashboardOverview/DashboardRecurringRevenueCard';
import { DashboardCompanyDataCard } from '../DashboardOverview/DashboardCompanyDataCard';
import { DashboardChartVisualizerCard } from '../DashboardOverview/DashboardChartVisualizerCard';
import { DashboardRevenueWaterfallCard } from '../DashboardOverview/DashboardRevenueWaterfallCard';
import { ReportBuilderContext } from './ReportBuilderContext';
import { AddWidgetDropdown } from './AddWidgetDropdown';
import { EditWidgetDropdown, POPOVER_ACTIONS, POPOVER_OPTIONS } from './EditWidgetDropdown';
import { DashboardTables } from '../DashboardTables';
import { DataTableEditWidgetDropdown } from './DataTableEditWidgetDropdown';
import { COMMON_METRICS_DASHBOARD_TITLE, WIDGET_TYPES } from './consts';

const AddWidgetButtonRightWrapper = styled(Centerer)`
  position: absolute;
  top: 0px;
  right: -40px;
  height: 100%;
`;

const ReportBuilderGrid = styled(CardsGrid)`
  padding-right: 40px;

  ${AddWidgetButtonRightWrapper} {
    display: none;
  }

  &:hover {
    ${AddWidgetButtonRightWrapper} {
      display: block;
    }
  }
`;

const AddWidgetButtonBottom = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ active }) => (active ? 'var(--primaryBlue)' : 'var(--primaryBlack50)')};
  background: ${({ active }) => (active ? 'var(--primaryBlue10)' : 'transparent')};
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: ${({ active }) => (active ? 'var(--primaryBlue10)' : 'var(--primaryBlue5)')};
    color: var(--primaryBlue);
  }
`;

const AddWidgetButtonRight = styled(Centerer)`
  width: 32px;
  height: 100%;
  background: ${({ active }) => (active ? 'var(--primaryBlue10)' : 'transparent')};
  border-radius: 8px;
  cursor: pointer;

  svg {
    path {
      fill: ${({ active }) => (active ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
    }
  }

  &:hover {
    background: ${({ active }) => (active ? 'var(--primaryBlue10)' : 'var(--primaryBlue5)')};

    svg {
      path {
        fill: var(--primaryBlue);
      }
    }
  }
`;

const getDashboardMetricCard = (metricId) => {
  // These are special METRIC_CARDS with custom logic
  switch (metricId) {
    case 'customers':
      return DashboardCustomersCard;
    case 'recurring_revenue':
      return DashboardRecurringRevenueCard;
    case 'company_data':
      return DashboardCompanyDataCard;
    case 'chart_visualizer':
      return DashboardChartVisualizerCard;
    case 'revenue_waterfall':
      return DashboardRevenueWaterfallCard;
    default:
      // Here is the default
      return DashboardCard;
  }
};

const GridCardWidget = ({ metricIndex, metric, AddGridCardButton }) => {
  const { openCardChartModal } = useContext(DashboardContext);
  const { onEditWidget, onDeleteWidget } = useContext(ReportBuilderContext);
  const metricId = metric.id.toUpperCase();

  const handleOptionClick = (action) => {
    switch (action) {
      case POPOVER_ACTIONS.OPEN:
        openCardChartModal({
          metricKey: metric.isTTM ? SNAPSHOT_METRICS[`${metricId}_TTM`].key : SNAPSHOT_METRICS[metricId].key,
        });
        break;
      case POPOVER_ACTIONS.EDIT:
        onEditWidget({ metricIndex, metric });
        break;
      case POPOVER_ACTIONS.DELETE:
        onDeleteWidget({ metricIndex, metric });
        break;
      default:
        break;
    }
  };
  const handleTTMClick = (event) => {
    event?.stopPropagation();
    handleOptionClick(POPOVER_ACTIONS.EDIT);
  };

  const Component = getDashboardMetricCard(metric.id);
  return (
    <Component
      metricId={metricId}
      columnStart={metric.columnStart}
      size={metric.size}
      rowStart={metric.rowStart}
      key={metric.id}
      isTTM={metric.isTTM}
      selectedMetrics={metric.selectedMetrics}
      SettingsButton={
        <EditWidgetDropdown
          onOptionClick={handleOptionClick}
          popoverOptions={
            metric.id === WIDGET_TYPES.REVENUE_WATERFALL
              ? [POPOVER_OPTIONS[POPOVER_OPTIONS.length - 1]]
              : POPOVER_OPTIONS
          }
        />
      }
      onTTMClick={handleTTMClick}
      version={2}
      AddGridCardButton={AddGridCardButton}
    />
  );
};

export const ReportBuilder = () => {
  const { report, pageReportState } = useContext(ReportBuilderContext);
  const { CardChartModal } = useContext(DashboardContext);
  const metricSetting = useMemo(() => pageReportState?.metricsSetting ?? [], [pageReportState]);

  return (
    <GlobalTogglesFloatingContainer showAfterScrolling={800}>
      {![COMMON_METRICS_DASHBOARD_TITLE].includes(report?.title) && (
        <>
          <ReportBuilderGrid version={2}>
            {metricSetting.map((metric, index, metricList) => (
              <GridCardWidget
                key={`${metric.id}-${metric.rowStart}-${metric.columnStart}`}
                metricIndex={index}
                metric={metric}
                AddGridCardButton={
                  // Are we on the last metric or in the last metric of the row?
                  (index === metricList.length - 1 || metric.rowStart < metricList[index + 1].rowStart) && (
                    <AddWidgetButtonRightWrapper>
                      <AddWidgetDropdown
                        height="100%"
                        YOffset="0"
                        XOffset="-108"
                        addInRowIndex={metric.rowStart}
                        addWidgetButton={({ active, onClick }) => (
                          <AddWidgetButtonRight active={active} onClick={onClick}>
                            <PlusIcon />
                          </AddWidgetButtonRight>
                        )}
                      />
                    </AddWidgetButtonRightWrapper>
                  )
                }
              />
            ))}
          </ReportBuilderGrid>

          <Spacer height="24px" />

          <AddWidgetDropdown
            XOffset="100"
            addWidgetButton={({ active, onClick }) => (
              <AddWidgetButtonBottom active={active} onClick={onClick}>
                + Add widgets...
              </AddWidgetButtonBottom>
            )}
          />
        </>
      )}

      {/* For now Snapshot table will always be at the bottom of the page*/}
      {pageReportState?.snapshotTableRows?.length > 0 && (
        <DashboardTables
          snapshotTableRows={pageReportState.snapshotTableRows}
          snapshotSelectedColumns={pageReportState.snapshotSelectedColumns}
          SettingsButton={(props) => (
            <DataTableEditWidgetDropdown
              showDelete={![COMMON_METRICS_DASHBOARD_TITLE].includes(report?.title)}
              {...props}
            />
          )}
        />
      )}

      <CardChartModal />
    </GlobalTogglesFloatingContainer>
  );
};
