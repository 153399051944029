import { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { BillingContext } from 'views/Billing/BillingContext';
import { Popover, PopoverActions, PopoverButton, PopoverPrompt, PopoverWrapper } from 'components/Portal';
import { TooltipContainer } from 'components/Tooltip';
import { INVOICE_MAGIC_METADATA } from 'views/Billing/consts';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { MarkAsPaidPopoverBody } from './InvoiceActions/InvoiceActions';
import { useAutoChargeModal } from './useAutoChargeModal';

const Container = styled.div`
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 12px;
  border: 1px solid var(--primaryBlack5);

  &:hover {
    background-color: white;
  }
`;

const Text = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
`;

const BoldText = styled.div`
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
`;

export const OldInvoicesChargeWarning = ({ isAutoCharge, isDraftInvoices, invoices, setFieldValue }) => {
  const { setLoading, invoicingScheduleFormValues, customerDetails } = useContext(InvoicingScheduleContext);
  const { bulkEditInvoices } = useContext(BillingContext);

  const [showMarkAsPaidPopover, setShowMarkAsPaidPopover] = useState(false);

  const { Modal: ConfirmAutoChargeModal, openModal: openConfirmAutoChargeModal } = useAutoChargeModal({
    setFieldValue,
  });

  const [paidAt, setPaidAt] = useState(new Date());

  const invoicesWithPastDates = useMemo(
    () =>
      invoices?.filter((invoice) => dayjs.utc(invoice?.date).isBefore(dayjs.utc().startOf('day')) && !invoice?.paid_at),
    [invoices],
  );

  const pastInvoices = useMemo(
    () =>
      invoicingScheduleFormValues?.invoices?.filter(
        (invoice) =>
          !invoice.paid_at &&
          !invoice.voided_at &&
          (!customerDetails?.has_active_payment_method || invoice?.metadata?.payment_status !== 'processing') &&
          dayjs.utc(invoice.date).isBefore(dayjs.utc().startOf('day')),
      ) ?? [],
    [customerDetails?.has_active_payment_method, invoicingScheduleFormValues?.invoices],
  );

  const hasPastInvoices = pastInvoices.length > 0;

  const handleMarkAsPaid = async (paidAt) => {
    setShowMarkAsPaidPopover(false);

    if (isDraftInvoices) {
      setFieldValue(
        'invoices',
        invoices?.map((mapInovice) => {
          if (
            invoicesWithPastDates.some(
              (invoiceWithPastDate) => invoiceWithPastDate?.unsavedId === mapInovice?.unsavedId,
            )
          ) {
            mapInovice.paid_at = paidAt;
          }
          return mapInovice;
        }),
      );
    } else {
      setLoading(true);

      await bulkEditInvoices({
        data: invoicesWithPastDates.map((invoice) => ({ ...invoice, paid_at: dayjs.utc(paidAt).format('YYYY-MM-DD') })),
      });

      setLoading(false);
    }
  };

  const pastInvoicesWillBeCharged = pastInvoices.some(
    (invoice) => invoice?.metadata?.[INVOICE_MAGIC_METADATA.DISABLE_AUTO_CHARGE] === false,
  );

  return !!invoicesWithPastDates?.length && isAutoCharge ? (
    <PopoverWrapper>
      <TooltipContainer
        yOffset={-5}
        isVisible={!showMarkAsPaidPopover}
        toolTipContent={
          <div>
            <PopoverPrompt>You can manually mark old invoices as paid</PopoverPrompt>
            <PopoverActions>
              <PopoverButton
                data-cy="schedule-modal__old-invoices-warning__mark-old-as-paid"
                primary
                onClick={() => setShowMarkAsPaidPopover(true)}
              >
                <BoldText>Mark old invoices as paid</BoldText>
              </PopoverButton>

              {hasPastInvoices ? (
                <PopoverButton
                  data-cy="schedule-modal__old-invoices-warning__charge-old-invoices"
                  primary
                  onClick={() => openConfirmAutoChargeModal()}
                >
                  <BoldText>Charge old invoices</BoldText>
                </PopoverButton>
              ) : null}
            </PopoverActions>
          </div>
        }
        width={320}
        hideArrow
      >
        <Container data-cy="schedule-modal__old-invoices-warning">
          {pastInvoicesWillBeCharged ? (
            <Text>
              Old invoices <b>will be auto-charged</b> as well
            </Text>
          ) : (
            <Text>
              Old invoices are <b>not auto-charged</b>
            </Text>
          )}
        </Container>
      </TooltipContainer>

      {showMarkAsPaidPopover && (
        <Popover XOffset={160} YOffset={40} zIndex={51} darkMode width="220px">
          {MarkAsPaidPopoverBody({
            paidAt,
            setPaidAt,
            onClose: () => setShowMarkAsPaidPopover(false),
            onSubmit: () => handleMarkAsPaid(paidAt),
          })}
        </Popover>
      )}

      <ConfirmAutoChargeModal />
    </PopoverWrapper>
  ) : null;
};
